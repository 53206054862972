import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { showAuthentication } from "../../../redux/ui";

import Button from "../../Button/Button.jsx";
import Modal from "../../Modal/Modal.jsx";
import ModalFooter from "../../Modal/ModalFooter/ModalFooter.jsx";
// import "./CheckEmailModal.scss";

class EmailUpdatedModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	/**
	 * close authentication modal window
	 */
	handleClose() {
		this.props.showAuthentication(null);
	}

	render() {
		return (
			<Modal
				icon={
					<img
						src="assets/img/ui/logo-yoga-movement.svg"
						alt="Email updated"
					/>
				}
				maxWidth={600}
				title="Email updated"
				onClose={() => this.handleClose()}
			>
				<div className="auth__contents">
					Your email has been updated.
				</div>
				<ModalFooter>
					<Button
						className="checkEmailModal__btn text-uppercase"
						onClick={() => this.props.showAuthentication(null)}
					>
						Okay
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

const mapDispatchToProps = function (dispatch) {
	return {
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
	};
};
export default connect(null, mapDispatchToProps)(EmailUpdatedModal);

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteConfirmPopup } from "../../../redux/popup";
import "./DeleteConfirmPopup.scss";

class DeleteConfirmPopup extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			close: false,
		};
	}

	closeConfirm = () => {
		this.setState({ close: true });
		this.props.deleteConfirmPopup();
	};

	render() {
		return (
			<div
				className="deleteConfirmPopup"
				data-close={this.state.close && "true"}
			>
				<img
					onClick={this.closeConfirm}
					className="deleteConfirmPopup__button"
					src="img/account/delete_button.svg"
					alt=""
				/>
				<div className="deleteConfirmPopup__content">
					<img src="img/account/delete_icon.svg" alt="" />
					<div>YOUR ACCOUNT HAS BEEN DELETED</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = function (dispatch) {
	return {
		deleteConfirmPopup: bindActionCreators(deleteConfirmPopup, dispatch),
	};
};

export default connect(mapDispatchToProps)(DeleteConfirmPopup);

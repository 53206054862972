import React, { PureComponent } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
	disableMobileScroll,
	showMobileNavigation,
	setActiveSection,
} from "../../../redux/ui";
import HeaderNavItem from "../HeaderNavItem/HeaderNavItem.jsx";
import "./HeaderNavMain.scss";

/**
 * main navigation
 */
class HeaderNavMain extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeMainItem: null,
		};
	}

	/**
	 * when click on navigation item with no children
	 */
	hideMobileNavigation() {
		this.props.showMobileNavigation(false);
		this.props.disableMobileScroll(false);
		this.props.setActiveSection(null);
	}

	handleClick() {
		this.hideMobileNavigation();
	}

	render() {
		const { authentication, ui, profile } = this.props;

		return (
			<nav
				className={
					"headerNavMain " +
					(ui.condenseHeader && " headerNavMain--condensed ")
				}
			>
				<ul className="headerNavMain__list">
					<HeaderNavItem
						name="YM Life"
						to="/"
						className="headerNavMain__super"
						// onClick={() => this.hideMobileNavigation()}
						onClick={() => {
							this.props.setActiveSection("ym-life")
						}}
						exact={true}
						active={this.props.ui.activeSection === 'ym-life'}
						isActive={(match, location) => {
							if (
								location.pathname === "/" ||
								location.pathname.includes("blog")
							) {
								return true;
							}
							return false;
						}}
					>
						<ul className="headerNavMain__sub">
							<li className="headerNavMain__sub-item">
								<NavLink
									to="/?cat=7"
									className="headerNavMain__sub-link animated d-block click-efx"
									activeClassName="headerNavUser__sub-link--active"
									onClick={() => this.handleClick()}
								>
									NEWS & PROMOS
								</NavLink>
							</li>
							<li className="headerNavMain__sub-item">
								<NavLink
									to="/?cat=3"
									className="headerNavMain__sub-link animated d-block click-efx"
									activeClassName="headerNavMain__sub-link--active"
									onClick={() => this.handleClick()}
								>
									LIFESTYLE
								</NavLink>
							</li>

							<li className="headerNavMain__sub-item">
								<NavLink
									to="/merchandise"
									className="headerNavMain__sub-link animated d-block click-efx"
									activeClassName="headerNavMain__sub-link--active"
									onClick={() => this.handleClick()}
								>
									MERCH
								</NavLink>
							</li>
							<li className="headerNavMain__sub-item">
								<NavLink
									to="/careers"
									className="headerNavMain__sub-link animated d-block click-efx"
									activeClassName="headerNavMain__sub-link--active"
									onClick={() => this.handleClick()}
								>
									CAREERS
								</NavLink>
							</li>
						</ul>
					</HeaderNavItem>
					<HeaderNavItem
						name="Studios"
						to="/studios"
						onClick={() => this.hideMobileNavigation()}
						strict={true}
					/>
					<HeaderNavItem
						to="#"
						name="Classes"
						onClick={() => this.props.setActiveSection("classes")}
						active={this.props.ui.activeSection === "classes"}
						isActive={(match, location) => {
							return (
								location.pathname.includes("/classes/") ||
								location.pathname === "/the-team" ||
								location.pathname === "/schedule"
							);
						}}
						className="headerNavMain__super"
					>
						<ul className="headerNavMain__sub">
							<li className="headerNavMain__sub-item">
								<NavLink
									to="/schedule"
									className="headerNavMain__sub-link animated d-block click-efx"
									activeClassName="headerNavMain__sub-link--active"
									onClick={() => this.hideMobileNavigation()}
								>
									Book A Mat
								</NavLink>
							</li>
							<li className="headerNavMain__sub-item">
								<NavLink
									to="/classes/buy-class-pack"
									className="headerNavMain__sub-link animated d-block click-efx"
									activeClassName="headerNavMain__sub-link--active"
									onClick={() => this.hideMobileNavigation()}
								>
									Buy A Class Pack
								</NavLink>
							</li>

							{authentication.accessToken && (
								<>
									<li className="headerNavMain__sub-item">
										<NavLink
											to="/classes/my-classes"
											className="headerNavMain__sub-link animated d-block click-efx"
											activeClassName="headerNavMain__sub-link--active"
											onClick={() =>
												this.hideMobileNavigation()
											}
										>
											My Schedule
										</NavLink>
									</li>
									<li className="headerNavMain__sub-item">
										<NavLink
											to="/classes/my-class-packs"
											className="headerNavMain__sub-link animated d-block click-efx"
											activeClassName="headerNavMain__sub-link--active"
											onClick={() =>
												this.hideMobileNavigation()
											}
										>
											My Class Packs
										</NavLink>
									</li>
								</>
							)}

							<li className="headerNavMain__sub-item">
								<NavLink
									to="/classes/class-types"
									className="headerNavMain__sub-link animated d-block click-efx"
									activeClassName="headerNavMain__sub-link--active"
									onClick={() => this.hideMobileNavigation()}
								>
									Class types
								</NavLink>
							</li>
							<li className="headerNavMain__sub-item">
								<NavLink
									to="/classes/private-classes"
									className="headerNavMain__sub-link animated d-block click-efx"
									activeClassName="headerNavMain__sub-link--active"
									onClick={() => this.hideMobileNavigation()}
								>
									PRIVATE & CORPORATE CLASSES
								</NavLink>
							</li>
							{/* <li className="headerNavMain__sub-item">
								<NavLink
									to="/the-team"
									className="headerNavMain__sub-link animated d-block click-efx"
									activeClassName="headerNavMain__sub-link--active"
									onClick={() => this.hideMobileNavigation()}
								>
									The Team
								</NavLink>
							</li> */}
						</ul>
					</HeaderNavItem>
					<HeaderNavItem
						name="The Team"
						strict={true}
						to="/the-team"
						onClick={() => this.hideMobileNavigation()}
					/>
					<HeaderNavItem
						name="Academy"
						to="/teacher-training"
						onClick={() => this.hideMobileNavigation()}
					/>

					<HeaderNavItem
						name="Faq"
						to="/faq"
						exact={true}
						onClick={() => this.hideMobileNavigation()}
					/>

					<HeaderNavItem
						exact={true}
						name="Contact us"
						to="/contact-us"
						onClick={() => this.hideMobileNavigation()}
					/>

					<HeaderNavItem
						exact={true}
						name="Own A YM"
						to="/own-a-ym"
						onClick={() => this.hideMobileNavigation()}
					/>

					{authentication.accessToken &&
					profile?.profile?.mem_tier !== "newbie" ? (
						<HeaderNavItem
							to="#"
							name="my ym"
							onClick={() => this.props.setActiveSection("myym")}
							active={this.props.ui.activeSection === "myym"}
							isActive={(match, location) => {
								return location.pathname.includes("/account/");
							}}
							className="headerNavMain__super"
						>
							<ul className="headerNavMain__sub">
								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/notifications"
										className="headerNavMain__sub-link animated d-block click-efx"
										activeClassName="headerNavUser__sub-link--active"
										onClick={() => this.handleClick()}
									>
										Notifications
									</NavLink>
								</li>
								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/favourites"
										className="headerNavMain__sub-link animated d-block click-efx"
										activeClassName="headerNavMain__sub-link--active"
										onClick={() => this.handleClick()}
									>
										Favourites
									</NavLink>
								</li>
								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/achievements"
										className="headerNavMain__sub-link animated d-block click-efx"
										activeClassName="headerNavMain__sub-link--active"
										onClick={() => this.handleClick()}
									>
										Achievements
									</NavLink>
								</li>
								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/all-access-pass"
										className="headerNavMain__sub-link animated d-block click-efx"
										activeClassName="headerNavMain__sub-link--active"
										onClick={() => this.handleClick()}
									>
										My All Access Pass
									</NavLink>
								</li>
								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/ym-fam-perks"
										className="headerNavMain__sub-link animated d-block click-efx"
										activeClassName="headerNavMain__sub-link--active"
										onClick={() => this.handleClick()}
									>
										YM FAM Perks
									</NavLink>
								</li>
								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/friends"
										className="headerNavMain__sub-link animated d-block click-efx"
										activeClassName="headerNavMain__sub-link--active"
										onClick={() => this.handleClick()}
									>
										YM Friends
									</NavLink>
								</li>

								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/referral"
										className="headerNavMain__sub-link animated d-block click-efx"
										activeClassName="headerNavMain__sub-link--active"
										onClick={() => this.handleClick()}
									>
										Referral Code
									</NavLink>
								</li>

								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/adhoc-payments"
										className="headerNavMain__sub-link animated d-flex click-efx"
										activeClassName="headerNavMain__sub-link--active"
										onClick={() => this.handleClick()}
									>
										Ad-hoc payments
									</NavLink>
								</li>
								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/payment-settings"
										className="headerNavMain__sub-link animated d-block click-efx"
										activeClassName="headerNavMain__sub-link--active"
										onClick={() => this.handleClick()}
									>
										Payment options
									</NavLink>
								</li>
								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/transaction-history"
										className="headerNavMain__sub-link animated d-block click-efx"
										activeClassName="headerNavMain__sub-link--active"
										onClick={() => this.handleClick()}
									>
										Transaction history
									</NavLink>
								</li>

								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/activities-log"
										className="headerNavMain__sub-link animated d-block click-efx"
										activeClassName="headerNavMain__sub-link--active"
										onClick={() => this.handleClick()}
									>
										Activity log
									</NavLink>
								</li>
								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/edit"
										className="headerNavMain__sub-link animated d-block click-efx"
										activeClassName="headerNavMain__sub-link--active"
										onClick={() => this.handleClick()}
									>
										Edit Profile
									</NavLink>
								</li>
								<li className="headerNavMain__sub-item">
									<NavLink
										to="/new-to-ym"
										className="headerNavMain__sub-link animated d-flex click-efx"
										activeClassName="headerNavMain__sub-link--active"
										target="_blank"
									>
										<div className="accountNavigation__link-text">
											Newbie Guide
										</div>
									</NavLink>
								</li>
							</ul>
						</HeaderNavItem>
					) : (
						<HeaderNavItem
							name="New To YM?"
							to="/new-to-ym"
							onClick={() => this.hideMobileNavigation()}
						/>
					)}
				</ul>
			</nav>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		ui: store.ui,
		authentication: store.authentication,
		profile: store.profile,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		disableMobileScroll: bindActionCreators(disableMobileScroll, dispatch),
		showMobileNavigation: bindActionCreators(
			showMobileNavigation,
			dispatch
		),
		setActiveSection: bindActionCreators(setActiveSection, dispatch),
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(HeaderNavMain)
);

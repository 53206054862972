import axios from "axios";
import immer from "immer";

import { memberEndpointURL } from "../base/config";
import { end, getErrorMessage } from "../base/functions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * get member's transaction history list
 * uses `GetMyActivities` endpoint also but always passing "purchase" as `activity_type` param
 * @params {number}		pageIndex  	  		current page number
 * @params {boolean} 	showInitialList    	determines whether to render the initial list or more pages list
 */
export const getTransactionHistory = (
	pageIndex,
	showInitialList = true,
	countryId
) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "GET_TRANSACTION_HISTORY" });
		return axios
			.get(memberEndpointURL + "GetMyActivities", {
				params: {
					activity_type: "purchase",
					page_index: pageIndex,
					page_size: 10,
				},
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
					countryID: countryId,
				},
			})
			.then((res) =>
				dispatch({
					type: "GET_TRANSACTION_HISTORY_RESOLVE",
					res: res.data,
					showInitialList,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_TRANSACTION_HISTORY_ERROR",
					error: err,
				})
			);
	};
};

/**
 * resets transaction activities list
 */
export const resetTransactionHistory = () => ({
	type: "RESET_TRANSACTION_HISTORY",
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const initialState = {
	status: null,
	error: null,

	list: [],
	totalPages: [],
};

export function transactionHistory(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			// get transaction history list
			case "GET_TRANSACTION_HISTORY":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_TRANSACTION_HISTORY_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_TRANSACTION_HISTORY_SUCCESS";
					draft.totalPages = action.res.data.total_page;

					if (action.showInitialList) {
						draft.list = action.res.data.activity_list;
					} else {
						draft.list = draft.list.concat(
							action.res.data.activity_list
						);
					}
				} else {
					draft.status = "GET_TRANSACTION_HISTORY_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_TRANSACTION_HISTORY_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			// reset transaction history list
			case "RESET_TRANSACTION_HISTORY":
				draft.list = [];
				break;
		}
	});
}

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import FooterNav from "./FooterNav/FooterNav.jsx";
import FooterApps from "./FooterApps/FooterApps.jsx";
import FooterSupport from "./FooterSupport/FooterSupport.jsx";
import FooterCopyright from "./FooterCopyright/FooterCopyright.jsx";

import "./Footer.scss";
import FooterInstagram from "./FooterInstagram/FooterInstagram.jsx";
import FooterSocial from "./FooterSocial/FooterSocial.jsx";

/**
 * Main footer of the site
 */
class Footer extends PureComponent {
	// constructor(props) {
	// 	super(props);
	// 	this.state = {
	// 		isLoadingChat: false,
	// 	};
	// }

	// handleClickChat() {
	// 	this.setState({ isLoadingChat: true });
	// 	embedChatWidget(() => {
	// 		$zopim.livechat.window.show();
	// 		this.setState({ isLoadingChat: false });
	// 	});
	// }

	render() {
		const { ui } = this.props;

		if (ui.hideFooter) return null;

		return (
			<footer className="footer">
				<div className="footer__inner">
					<div className="row">
						<div className="footer__col col-12 col-lg-5">
							<h3 className="footer__nav-title text-uppercase">
								Browse
							</h3>
							<FooterNav />
						</div>
						<div className="footer__col col-6 col-lg-2">
							<h3 className="footer__nav-title text-uppercase">
								Support
							</h3>
							<FooterSupport />
						</div>
						<div className="footer__col col-6 col-lg-2">
							<h3 className="footer__nav-title text-uppercase">
								Quick Links
							</h3>
							<FooterApps />
						</div>
						<div className="footer__col col-md-12 col-lg-3">
							<FooterInstagram></FooterInstagram>
						</div>
					</div>
					<div className="d-flex">
						<FooterSocial />
					</div>
					<FooterCopyright />
					{/* <FooterChat
						isLoading={this.state.isLoadingChat}
						onClick={() => this.handleClickChat()}
					/> */}
				</div>
			</footer>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		ui: store.ui,
	};
};
export default connect(mapStateToProps)(Footer);

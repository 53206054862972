import immer from "immer";

export const deleteConfirmPopup = () => ({
	type: "DELETE_CONFIRM_POPUP",
});

export const initialConfirmPopup = () => ({
	type: "INITIAL_CONFIRM_POPUP",
});
export const langCheckPopup = () => ({
	type: "LANG_CONFIRM_POPUP",
});

export const locationCheckPopup = () => ({
	type: "LOCATION_POPUP",
});

export const termPopup = () => ({
	type: "TERM_POPUP",
});

const initialState = {
	deletePopup: false,
	initialPopup: false,
	langPopup: false,
	termPopup: false
};

export function popup(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			case "DELETE_CONFIRM_POPUP":
				draft.deletePopup = !draft.deletePopup;
				break;
			case "INITIAL_CONFIRM_POPUP":
				draft.initialPopup = !draft.initialPopup;
				break;
			case "LANG_CONFIRM_POPUP":
				draft.langPopup = !draft.langPopup;
				break;
			case "LOCATION_POPUP":
				draft.locationPopup = !draft.locationPopup;
				break;
			case "TERM_POPUP":
				draft.termPopup = !draft.termPopup;
				break;
		}
	});
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import qs from "qs";

import { end, scrollElemTop } from "../../../base/functions";
import { showAuthentication } from "../../../redux/ui";
import { resetPassword } from "../../../redux/authentication";

import Modal from "../../Modal/Modal.jsx";
import ModalFooter from "../../Modal/ModalFooter/ModalFooter.jsx";
import Form from "../../Form/Form.jsx";
import Button from "../../Button/Button.jsx";
import PlainButton from "../../PlainButton/PlainButton.jsx";
import ErrorMessage from "../../Alerts/ErrorMessage/ErrorMessage.jsx";
import "./ResetPasswordModal.scss";

/**
 * Reset password for users who forgot password
 * Linked from email
 */
class ResetPasswordModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			busy: false,
			error: null,
			showNewPassword: false,
			showConfirmPassword: false,
		};
		this.restrictAccess();
	}

	/**
	 * check query string has a recovery token
	 * if not, redirect back to forgot-password modal screen
	 */
	restrictAccess() {
		let queryObj = qs.parse(this.props.location.search, {
			decoder: (str) => str,
			ignoreQueryPrefix: true,
		});
		if (!queryObj.recovery || !queryObj.recovery.length) {
			queryObj.action = "forgot-password";
			delete queryObj.recovery;
			this.props.history.push({
				pathname: this.props.location.pathname,
				search: qs.stringify(queryObj),
			});
		}
	}

	componentDidUpdate(prevProps) {
		let prevAuthProps = prevProps.authentication;

		if (prevAuthProps.status != this.props.authentication.status) {
			switch (this.props.authentication.status) {
				case "RESET_PASSWORD_ERROR":
					this.setState({
						busy: false,
						error: this.props.authentication.resetPasswordError,
					});
					break;
			}
		}
	}

	/**
	 * close authentication modal window
	 */
	handleClose() {
		this.props.showAuthentication(null);
	}

	/**
	 * submit event handler for the form
	 * do further custom validation submit if valid
	 * otherwise show and scroll to error
	 * @param  {object} 	e   	submit event object
	 * @param  {boolean} 	err 	whether <Form /> component found any standard errors
	 */
	handleSubmit(e, err) {
		if (this.password.value.length && this.confirmPassword.value.length) {
			if (this.password.value != this.confirmPassword.value) {
				this.setState({
					error: "Password and confirm password don't match",
				});
			} else if (
				this.password.value.length < 6 ||
				this.password.value.length > 25
			) {
				this.setState({
					error: "Passwords need to be between 6 - 25 characters",
				});
			} else {
				this.setState({ error: null });
			}
		}

		if (!err) {
			let queryObj = qs.parse(this.props.location.search, {
				decoder: (str) => str,
				ignoreQueryPrefix: true,
			});
			this.setState({ busy: true });
			this.props.resetPassword(queryObj.recovery, this.password.value);
		} else {
			let errorFields = ReactDOM.findDOMNode(this.form).querySelectorAll(
				"*:invalid, .form__field--error, .checkbox--error"
			);
			if (errorFields.length > 0) {
				let modal = ReactDOM.findDOMNode(this.modal);
				scrollElemTop(
					modal,
					errorFields[0].getBoundingClientRect().top +
						modal.scrollTop -
						50
				);
			}
		}
	}

	render() {
		return (
			<Modal
				className="resetPasswordModal"
				icon={
					<img
						src="assets/img/ui/logo-yoga-movement.svg"
						alt="Set new password"
					/>
				}
				maxWidth={480}
				title="Reset password"
				onClose={() => this.handleClose()}
				ref={(e) => {
					this.modal = e;
				}}
			>
				<Form
					busy={this.state.busy}
					onSubmit={(e, err) => this.handleSubmit(e, err)}
					ref={(e) => {
						this.form = e;
					}}
				>
					<div className="auth__contents">
						<h3 className="resetPasswordModal__title">
							Reset Password
						</h3>
						{this.state.error != null && (
							<ErrorMessage className="pt-2 pb-3">
								{this.state.error}
							</ErrorMessage>
						)}

						<div className="row">
							<label className="changePasswordModal__col col-12">
								<div className="form__group resetPasswordModal__field">
									<div className="form__label text-uppercase">
										New password
									</div>
									<div className="resetPasswordModal__field">
										<input
											type={
												!this.state.showNewPassword
													? "password"
													: "text"
											}
											className={
												"form__field " +
												(this.state.error != null &&
													" form__field--error form__field--suppress-error ")
											}
											placeholder="Your new password"
											ref={(e) => {
												this.password = e;
											}}
											autoFocus
											required
										/>
										<PlainButton
											className="resetPasswordModal__toggle"
											disableTabFocus={true}
											onClick={() =>
												this.setState({
													showNewPassword: !this.state
														.showNewPassword,
												})
											}
										>
											{!this.state.showNewPassword
												? "Show"
												: "Hide"}
										</PlainButton>
										<div className="form__msg--error">
											Password is required
										</div>
									</div>
								</div>
							</label>
							<label className="changePasswordModal__col col-12 ">
								<div className="form__group resetPasswordModal__field">
									<div className="form__label text-uppercase">
										Confirm password
									</div>
									<div className="resetPasswordModal__field">
										<input
											type={
												!this.state.showConfirmPassword
													? "password"
													: "text"
											}
											className={
												"form__field " +
												(this.state.error != null &&
													" form__field--error form__field--suppress-error ")
											}
											placeholder="Confirm your new password"
											ref={(e) => {
												this.confirmPassword = e;
											}}
											required
										/>
										<PlainButton
											className="resetPasswordModal__toggle"
											disableTabFocus={true}
											onClick={() =>
												this.setState({
													showConfirmPassword: !this
														.state
														.showConfirmPassword,
												})
											}
										>
											{!this.state.showConfirmPassword
												? "Show"
												: "Hide"}
										</PlainButton>
										<div className="form__msg--error">
											Confirm password is required
										</div>
									</div>
								</div>
							</label>
						</div>
					</div>
					<Button className="resetPasswordModal__save auth__btn d-block ">
						{!this.state.busy ? "Reset" : "Submitting"}
					</Button>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
		resetPassword: bindActionCreators(resetPassword, dispatch),
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal)
);

export const getCountryID = ({ lang, data }) => {
	return data?.find((data) => data?.country_code?.toLowerCase() === lang)
		?.country_id;
};

export const getCountryMobileCode = ({ id, data }) => {
	return data?.find((data) => data?.country_id === id)?.mobile_code;
};

export const getCountryCode = ({ id, data }) => {
	return data?.find((data) => data?.country_id === id)?.country_code;
};

export const getCountryCodeByName = ({ name, data }) => {
	return data?.find((data) => data?.country_name === name)?.country_code;
};

export const getCountryName = ({ lang, data }) => {
	return data?.find((data) => data?.country_code?.toLowerCase() === lang)
		?.country_name;
};

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";

import {
	disableMobileScroll,
	showMobileNavigation,
	setActiveSection,
} from "../../redux/ui";

import HeaderNavMain from "./HeaderNavMain/HeaderNavMain.jsx";
import HeaderNavUser from "./HeaderNavUser/HeaderNavUser.jsx";
import HeaderNavIcon from "./HeaderNavIcon/HeaderNavIcon.jsx";
import HeaderBtnPill from "./HeaderBtnPill/HeaderBtnPill.jsx";
import MustReadHeader from "../MustReadHeader/MustReadHeader.jsx";
import "./Header.scss";

/**
 * Main header of the site
 */

export const Header = (props) => {
	const [condenseHeader, setCondenseHeader] = useState(false);

	useEffect(() => {
		let pathsArray = props.location.pathname.split("/").filter((p) => p);
		if (pathsArray[0]) props.setActiveSection(pathsArray[0]);

		const handleScroll = (e) => {
			let scrollPos = window.pageYOffset;

			if (scrollPos >= 200 && !condenseHeader) {
				setCondenseHeader(true);
			} else if (scrollPos <200 && condenseHeader) {
				setCondenseHeader(false);
			}
		};

		const handleResize = (e) => {
			let scrollPos = document.body.scrollTop;
			if (window.innerWidth > 992 && scrollPos <= 0 && condenseHeader) {
				setCondenseHeader(false);
			}
		};

		window.addEventListener("scroll", handleScroll);
		window.addEventListener("resize", handleResize);
		window.dispatchEvent(new Event("scroll", { bubbles: true }));

		return () => {
			window.removeEventListener("scroll", handleScroll);
			window.removeEventListener("resize", handleResize);
		};
	}, [condenseHeader]);

	/**
	 * toggle showing mobile navigation / enable mobile scrollbar
	 * @param  {boolean}  show 	flag to control navigation
	 */
	const toggleMobileNavigation = (show) => {
		props.disableMobileScroll(show);
		props.showMobileNavigation(show);
		props.setActiveSection(null);
	};

	/**
	 * onClick callback for "Book a mat" button
	 */
	const handleBookClick = () => {
		props.showMobileNavigation(false);
		props.disableMobileScroll(false);
		props.history.push("/schedule");
	};

	/**
	 * handle go to the class pack
	 */
	const goToClassPack = () => {
		props.showMobileNavigation(false);
		props.disableMobileScroll(false);
		props.history.push("/classes/buy-class-pack");
	};

	const { ui, masterData } = props;

	
		if (ui.hideHeader) return null;

	// must reader header will show in all the pages
	const hasMustReadHeader =
		masterData.data && masterData.data.announcement.status;


		console.log('here is document.body.scrollTop:', document.body)
	return (
		<header
			id="header"
			className={
				"header " + (condenseHeader && " header--condensed ")
			}
		>
			{hasMustReadHeader && (
				<MustReadHeader
					content={masterData.data.announcement.description}
				/>
			)}
			<div className="d-flex align-items-end  text-uppercase position-relative header__inner">
				<Link
					to="/"
					className="header__logo-link"
					onClick={() => toggleMobileNavigation(false)}
				>
					<img
						src="/assets/img/ui/logo-yoga-movement.svg"
						alt="Yoga Movement"
						className="header__logo"
					/>
				</Link>

				<div
					className={
						"header__navs " +
						(ui.showMobileNavigation &&
							"header__navs--active ") +
						(hasMustReadHeader && " header__navs--must-read")
					}
				>
					<div className="header__navs-group">
						<HeaderNavUser condenseHeader={condenseHeader}>
							<div className="header__btn-pill">
								<HeaderBtnPill
									goToClassPack={goToClassPack}
									handleBookClick={handleBookClick}
									className="header__btn-pill-edge-cases"
								></HeaderBtnPill>
							</div>
						</HeaderNavUser>

						<HeaderNavMain />
						<div className="clearfix"></div>
					</div>
				</div>

				<HeaderBtnPill
					goToClassPack={goToClassPack}
					handleBookClick={handleBookClick}
					className="header__book"
				></HeaderBtnPill>
				<HeaderNavIcon
					active={ui.showMobileNavigation}
					onClick={() =>
						toggleMobileNavigation(
							!ui.showMobileNavigation
						)
					}
				/>
			</div>
		</header>
	);
};

const mapStateToProps = function (store) {
	return {
		ui: store.ui,
		masterData: store.masterData,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		disableMobileScroll: bindActionCreators(disableMobileScroll, dispatch),
		showMobileNavigation: bindActionCreators(
			showMobileNavigation,
			dispatch
		),
		setActiveSection: bindActionCreators(setActiveSection, dispatch),
	};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

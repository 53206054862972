import qs from "qs";
import React from "react";
import moment from "moment";
import "moment-timezone";
import { useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { zendeskChatEmbedKey } from "./config";

/**
 * animate scroll to top in main window
 */
let scrollToTopTimer;
export const scrollToTop = () => {
	if (scrollToTopTimer) clearTimeout(scrollToTopTimer);
	scrollToTopTimer = setTimeout(() => {
		let top = window.pageYOffset || document.documentElement.scrollTop;
		top += -top * 0.3;

		if (top < 5 || isNaN(top)) {
			clearTimeout(scrollToTopTimer);
			scrollToTopTimer = null;
			top = 0;
			window.scrollTo(0, 0);
			return;
		}
		window.scrollTo(0, top);
		scrollToTop();
	}, 1000 / 60);
};

/**
 * stop scrollToTop() animation loop
 * use this if need to scroll elsewhere on page load
 */
export const stopScrollToTop = () => {
	if (scrollToTopTimer) clearTimeout(scrollToTopTimer);
	scrollToTopTimer = null;
};

/**
 * animate scroll to target y position in main window
 * @param {number} 		to  scroll Y position
 * @param {function} 	cb  callback after scrolling to position
 */
let scrollTopTimer;
export const scrollTop = (to, cb) => {
	stopScrollTop();

	let top =
		(window.pageYOffset || document.scrollTop || 0) -
		(document.clientTop || 0);
	if (to < 0) to = 0;
	if (to > document.body.scrollHeight - window.innerHeight) {
		to = document.body.scrollHeight - window.innerHeight;
	}

	scrollTopTimer = setTimeout(() => {
		window.scrollTo(0, top + (to - top) * 0.3);
		if (Math.abs(top - to) < 4) {
			stopScrollTop();
			window.scrollTo(0, to);
			if (cb) cb();
			return;
		}
		scrollTop(to, cb);
	}, 1000 / 60);
};

/**
 * stop scrollTop() animation loop
 */
export const stopScrollTop = () => {
	if (scrollTopTimer) clearTimeout(scrollTopTimer);
	scrollTopTimer = null;
};

/**
 * animate scroll to target y position in target element
 * @param  {DOM}   		elem 	DOM node from querySelector or getElementById
 * @param  {number}   	to 		scroll Y position
 * @param  {Function} 	cb   	callback after scrolling to position
 */
let scrollElemTopTimer;
export const scrollElemTop = (elem, to, cb) => {
	stopScrollTop();

	let top = elem.scrollTop;
	if (to < 0) to = 0;
	if (to > elem.scrollHeight - elem.clientHeight) {
		to = elem.scrollHeight - elem.clientHeight;
	}

	scrollElemTopTimer = setTimeout(() => {
		elem.scrollTop += (to - elem.scrollTop) * 0.3;
		if (Math.abs(top - to) < 4) {
			stopScrollTop();
			elem.scrollTop = to;
			if (cb) cb();
			return;
		}
		scrollElemTop(elem, to, cb);
	}, 1000 / 45);
};

/**
 * stop scrollElemTop() animation loop
 */
export const stopElemScrollTop = () => {
	if (scrollElemTopTimer) clearTimeout(scrollElemTopTimer);
	scrollElemTopTimer = null;
};

//put zeroes in front of string when needed
/**
 * Pad a string to a certain length with another string
 * @param  {string} 	n     	the string to pad
 * @param  {integer} 	width	the string length to achieve
 * @param  {string} 	z    	the string to prepend
 * @return {string}     the padded string
 */
export const pad = (n, width, z) => {
	z = z || "0";
	n = n + "";
	return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

/**
 * set the first character to uppercase
 * @param  {string} string 	the string to manipulate
 * @return {string}        	string with first character uppercased
 */
export const ucfirst = (string) =>
	string.charAt(0).toUpperCase() + string.slice(1);

/**
 * convert string into url-friendly format
 * @param  {string} text 	string to convert
 * @return {string}      	converted string
 */
export const slugify = (text) =>
	text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, "-")
		.replace(/[^\w\-]+/g, "")
		.replace(/\-\-+/g, "-")
		.replace(/^-+/, "")
		.replace(/-+$/, "");

/**
 * convert slug into a title string
 * @param  {string} text 	string to convert
 * @return {string}      	converted string
 */
export const unslugify = (text) =>
	text
		.split("-")
		.join(" ")
		.replace(/\w\S*/g, (text) => {
			return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
		});

/**
 * format a string to display as price
 * adds thousand delimiter and optional 2 decimal places
 * @param  {integer|number|string} 	v       	the value to format
 * @param  {integer} 				decimal 	if defined, will be enforced to follow the decimal places
 *                                				otherwise will show 2 decimal places if needed
 * @return {number}         					formatted value
 */
export const formatPrice = (v, decimal) => {
	if (decimal == undefined) {
		if (v.toString().indexOf(".") < 0) decimal = 0;
		else decimal = 2;
	}
	v = parseInt(v * 100) * 0.01;
	return Number(v).toLocaleString("en-US", {
		minimumFractionDigits: decimal,
	});
};

/**
 * convert thousands to use 'k'
 * @param  {integer} v 	the value to format
 * @return {[type]}   	formatted value
 */
export const formatThousands = (v) => {
	if (v < 999) return v;
	else {
		v /= 1000;
		if (v == v.toFixed(1)) return v + "k";
		else return v.toFixed(1) + "k";
	}
};

/**
 * compare 2 arrays to see if they are different
 * @param  {Array} 	arr1 	first array
 * @param  {Array} 	arr2 	second array
 * @return {boolean}      	whether the arrays are different
 */
export const areArraysDifferent = (arr1, arr2) => {
	if (!arr1 || !arr2) return false;
	if (arr1.length != arr2.length) return true;

	for (var i = 0, l = arr1.length; i < l; i++) {
		// Check if we have nested arrays
		if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
			// recurse into the nested arrays
			if (!arr1[i].equals(arr2[i])) return true;
		} else if (arr1[i] != arr2[i]) {
			// Warning - two different object instances will never be equal: {x:20} != {x:20}
			return true;
		}
	}
	return false;
};

/**
 * attempt to extract xhr error message from a variety of formats
 * @param  {object} 	err 	xhr response object
 * @return {string}     		extracted error message
 */
export const getErrorMessage = (err) => {
	if (err?.response) {
		if (err.response.data) {
			if (err.response.data.message) return err.response.data.message;
			else if (err.response.data) {
				if (err.response.data.error_description)
					return err.response.data.error_description;
				//stripe
				else if (err.response.data.error)
					return err.response.data.error;
				else if (err.response.data.error.message)
					return err.response.data.error.message;
			}
		}
	} else if (err?.data) {
		if (err.data.error.message) return err.data.error.message;
		//codigo backend
		if (err.data.message) return err.data.message;
	} else if (err?.message) return err.message;
	else if (err?.error_description) return err.error_description;
	return "";
};

export const addQueryParams = (queryObj) =>
	new URLSearchParams({ ...queryObj }).toString();

/**
 * simple jwt validation
 * performs base64 decode and validate values
 * @param  {string} 	token 	JWT
 * @return {boolean}       		whether JWT is valid
 */
export const isValidJWT = (token) => {
	if (!token) return null;
	let base64Url;
	let base64;
	let data;

	try {
		base64Url = token.split(".")[1];
		base64 = base64Url.replace("-", "+").replace("_", "/");
		data = JSON.parse(window.atob(base64));
	} catch (err) {
		return false;
	}

	switch (true) {
		// case data.iss != endpointDomain:
		case data.role != "member":
		case data.exp * 1000 < new Date().getTime():
			return false;
			break;
	}

	return true;
};

/**
 * get last item in array
 * @param  {array} 	arr 	array to retrieve value
 * @return {*}     retrieved value
 */
export const end = (arr) => {
	return arr[arr.length - 1];
};

/**
 * produce a single function by composing multiple functions into one.
 * @params {func} function 		functions you want to compose into one
 * @return {func}
 */
export const compose =
	(...fns) =>
	(res) =>
		fns.reduce((accum, next) => next(accum), res);

/**
 * validate an email format by regular expression
 * @params {string} email
 * @return {bool}
 */
export const validateEmail = (email) => {
	const regex =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(email);
};

/**
 * add / remove value to a key in the query string
 * @param  {object}  queryObj query string converted to key-value object
 * @param  {string}  key      query string key to update
 * @param  {string}  id       query string value to add / remove
 * @param  {boolean} isArray  whether item to update is an array
 * @return {string}           updated query string
 */
export function getUpdatedQueryString(queryObj, key, value, isArray = false) {
	let item;
	if (queryObj[key]) item = queryObj[key];
	else if (!isArray) item = null;
	else item = [];

	if (!isArray) {
		item = value;
	} else {
		if (!item.includes(value)) {
			item = [...item, value];
		} else {
			item = item.filter((listItem) => listItem != value);
		}
	}

	queryObj[key] = item;
	if (!value || item.length == 0) delete queryObj[key];

	return qs.stringify(queryObj);
}

/**
 * embed zendesk chat widget by injecting <script> tag
 * not the react way to do things but there is no react library for zendesk chat
 * @param {function} callback  function to run after chat widget has been embedded
 */
export function embedChatWidget(callback) {
	let s = document.createElement("script");
	let e = document.querySelector("body");
	s.async = !0;
	s.setAttribute("id", "ze-snippet");
	s.setAttribute("charset", "utf-8");
	s.src =
		"https://static.zdassets.com/ekr/snippet.js?key=" + zendeskChatEmbedKey;
	s.type = "text/javascript";
	e.parentNode.insertBefore(s, e);

	if (!window.zE) {
		setTimeout(function () {
			window.zE(function () {
				window.zE("webWidget:on", "chat:connected", () => callback());
			});
		}, 500);
	} else {
		callback();
	}
}

// TODO: Move const variable into a constant file
// GST rate
export const GST = 0.07;

// use to retreieve sub total value from grand total without GST
// Eg: 32.10 / 1.07 = 32;
export const reverseGST = 1 + GST;

/**
 * Format number to always show 2 decimal places
 * @param {double} price        price you want to format
 * @return {double}
 */
export const classPackFormatPrice = (price) =>
	parseFloat(price) % 1 === 0
		? parseFloat(price)
		: parseFloat(price).toFixed(2);

/**
 * Prefix price with currency sign
 * @param {double} price
 * @return {string}
 */
export const prefixPrice = (price) => `SGD${price}`;

export const priceWrapperElement = (price) => (
	<span className="price-wrapper">{price}</span>
);

/**
 * Format number and prefix with currency sign at same time
 * @param {functions} [func]
 * @return {string}
 */
export const classPackPrice = compose(
	classPackFormatPrice,
	prefixPrice,
	priceWrapperElement
);

/**
 * Calculate sub total of classPacks without GST value
 * @param {array} classPacks        classPacks lists
 * @return {string}
 */
export const calculateSubTotal = (pack_price) => pack_price / reverseGST;

/**
 * Calculate GST from GrandTotal
 * @param {array} classPacks        classPacks list
 * @return {string}
 */
export const calculateGST = (classPacks) => calculateSubTotal(classPacks) * GST;

/**
 * Calculate GrandTotal = GST + subTotal
 * @param {array} classPacks        classPacks list
 * @return {string}
 */
export const claculateGrandTotal = (classPacks) =>
	calculateGST(classPacks) + calculateSubTotal(classPacks);

/**
 * formats the date into the standard format used which is DD MMM YY
 * @param  {string} date     date that needs to be formatted
 */
export const formatDateToStandard = (date) => {
	return moment(date).format("DD MMM YYYY");
};

/**
 * format any string into title case
 * @param  {string} string 	the string to format
 * @return {string}
 */
export const formatTitleCase = (string) => {
	return string
		.split(" ")
		.map(
			(word) =>
				word && word[0].toUpperCase() + word.substr(1).toLowerCase()
		)
		.join(" ");
};

export const getScheduleTimeWithDuration = ({
	startTime,
	endTime,
	className,
}) => {
	const diffMinute = moment
		.duration(
			moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))
		)
		.asMinutes();
	return (
		<React.Fragment>
			<time className={className}>
				{moment(startTime, "HH:mm:ss").format("h:mma")}
			</time>
			{" to "}
			<time className={className}>
				{moment(endTime, "HH:mm:ss").format("h:mma")}
			</time>
			{" ("}
			{diffMinute}
			{diffMinute === 1 ? "min" : "mins"}
			{")"}
		</React.Fragment>
	);
};

export const getAvatarLetter = ({ member_name = "" }) => {

	const splitted = member_name?.split(" ");
	return `${splitted[0][0]}${splitted[splitted?.length - 1][0]} `;
};

// NOTE: hooks section

export const useIsMobile = (maxWidth = 992) => {
	return useMediaQuery({ maxWidth });
};

export const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

// Helper to render nodes
export const renderJSXNode = (value, forceHTML) =>
	typeof value === "function" ? (
		value()
	) : forceHTML ? (
		<div dangerouslySetInnerHTML={{ __html: value }}></div>
	) : (
		value
	);

// Helper to check image w/h
export const ImgCheck = async (url, options = {}) => {
	const { width, height } = options;

	const Img = new Image();
	Img.src = url;

	return new Promise((resolve, reject) => {
		Img.onload = function () {
			if (width !== undefined) {
				if (Img.width !== width) {
					reject(url);
				}
			}
			if (height !== undefined) {
				if (Img.height !== height) {
					reject(url);
				}
			}

			resolve(url);
		};
	});
};

// HTML stripper
export const stripTags = (html) => {
	// on client side
	// let the browser take care of HTML
	if (process.browser && document) {
		const tmp = document.createElement("DIV");

		tmp.innerHTML = html;

		const plainText = tmp.textContent || tmp.innerText || "";

		tmp.remove();

		return plainText;
	}

	// on SSR
	return html.replace(/<[^>]*>?/gm, "");
};

// shorten when exceeds
export const shortenByCharacters = (
	text,
	length,
	ellipsis,
	ellipsisCharacter
) => {
	if (!ellipsisCharacter) {
		ellipsisCharacter = "...";
	}

	if (text.length > length) {
		return `${text.substring(0, length)}${
			ellipsis ? ellipsisCharacter : ""
		}`;
	}

	return text;
};

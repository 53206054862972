import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import "promise-polyfill/src/polyfill";
import "events-polyfill";
import { store, persistor } from "./base/configureStore";

import App from "./base/App";
// import { facebookAppID } from "./base/config";
import { embedChatWidget } from "./base/functions";

let basename = "/";

// (function () {
// 	window.fbAsyncInit = function () {
// 		FB.init({
// 			appId: facebookAppID,
// 			cookie: true,
// 			xfbml: true,
// 			version: "v14.0",
// 		});

// 		FB.AppEvents.logPageView();
// 	};

// 	(function (d, s, id) {
// 		var js,
// 			fjs = d.getElementsByTagName(s)[0];
// 		if (d.getElementById(id)) {
// 			return;
// 		}
// 		js = d.createElement(s);
// 		js.id = id;
// 		js.src = "https://connect.facebook.net/en_US/sdk.js";
// 		fjs.parentNode.insertBefore(js, fjs);
// 	})(document, "script", "facebook-jssdk");
// })();

embedChatWidget(() => {
	// $zopim?.livechat?.window.show();
	window.zE("webWidget:on", "chat:popout", function () {
		console.log("Live chat widget has been opened in another window.");
	});
});

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter basename={basename}>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>,
	document.body.appendChild(document.createElement("div"))
);

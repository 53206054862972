import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import { end } from "../../base/functions";
import { showNotice } from "../../redux/ui";
import NewToYM from "./NewToYM/NewToYM.jsx";
import SuccessPanel from "../Alerts/SuccessPanel/SuccessPanel.jsx";
import ErrorPanel from "../Alerts/ErrorPanel/ErrorPanel.jsx";

import "./NoticeBar.scss";

/**
 * wrapper component at root level to show various notices
 * stores notice store in local state
 * clears notice when route changes
 */

class NoticeBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: null,
			message: null
		};
	}

	/**
	 * manage notice locally
	 * change local notice state upon UI store change, then empty notice in UI store
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname != this.props.location.pathname) {
			this.setState({ type: null, message: null });
			this.props.showNotice(null);
		}

		let prevUIProps = prevProps.ui;
		const { ui } = this.props;

		if (prevUIProps.notice == null && prevUIProps.notice != ui.notice) {
			this.setState({
				type: ui.notice,
				message: ui.noticeMessage
			});
			this.props.showNotice(null);
		}
	}

	/**
	 * check ui store to see if there are any notices to show
	 * @return {node} 	the component to show
	 */
	showContents() {
		switch (this.state.type) {
			case "new to ym":
				return <NewToYM className="noticeBar" />;
				break;
			case "success":
				return (
					<SuccessPanel
						className="noticeBar"
						onClose={() => this.handleClose()}
					>
						{this.state.message}
					</SuccessPanel>
				);
				break;
			case "error":
				return (
					<ErrorPanel
						className="noticeBar"
						onClose={() => this.handleClose()}
					>
						{this.state.message}
					</ErrorPanel>
				);
				break;
			default:
				return null;
		}
	}

	/**
	 * onClose callback fo SuccessPanel and ErrorPanel
	 */
	handleClose() {
		this.setState({ type: null, message: null });
		this.props.showNotice(null);
	}

	render() {
		return this.showContents();
	}
}
const mapStateToProps = function(store) {
	return {
		ui: store.ui
	};
};
const mapDispatchToProps = function(dispatch) {
	return {
		showNotice: bindActionCreators(showNotice, dispatch)
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(NoticeBar)
);

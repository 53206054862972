import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import qs from "qs";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { showAuthentication } from "../../../redux/ui";
import { endpointCreds, memberEndpointURL } from "../../../base/config";
import Modal from "../../Modal/Modal.jsx";
import Preloader from "../../Preloader/Preloader.jsx";
import ErrorMessage from "../../Alerts/ErrorMessage/ErrorMessage.jsx";
import "./AppleSignInModal.scss";
import {
	appleAuthenticate,
	setAppleId,
	setAuthenticationHook,
} from "../../../redux/authentication";

function AppleSignInModal(props) {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const [error, setError] = useState("");
	const [loading, toggleLoading] = useState(false);

	useEffect(() => {
		getAppleAuthToken();
	}, []);

	function handleClose() {
		dispatch(showAuthentication(null));
		history.push({
			pathname: history.pathname,
		});
	}

	async function getAppleAuthToken() {
		toggleLoading(true);
		const queryObj = qs.parse(location.search, {
			ignoreQueryPrefix: true,
		});

		const payload = jwt_decode(queryObj.id_token);

		const body = {
			apple_id: payload.sub,
			device_type: "website",
			email: queryObj.email,
		};

		axios
			.post(
				memberEndpointURL + "AppleLogin",
				body,

				{ auth: endpointCreds }
			)
			.then((response) => {
				// response?.data;
				const data = response.data;

				toggleLoading(false);
				switch (data.errorCode) {
					case 0:
						dispatch(showAuthentication());
						dispatch(setAuthenticationHook(data.data.member_info));
						break;

					// user login with apple id for first time,
					// redirect user to step 2 to fill the other information
					case 406:
						dispatch(setAppleId(payload.sub));
						dispatch(showAuthentication(null));
						const queryParams = qs.parse(props.location.search, {
							ignoreQueryPrefix: true,
						});
						if (queryParams?.code) {
							props.history.replace(
								`/register/about-you?code=${queryParams?.code}`
							);
						} else {
							history.push("register/about-you");
						}

						break;
					default:
						setError(data.message);
						break;
				}
			})
			.catch((error) => {
				setError(error);
				toggleLoading(false);
			});
	}

	return (
		<Modal
			className="appleSignInModal"
			maxWidth={480}
			title="Welcome to"
			onClose={() => handleClose()}
		>
			<h3 className="font-weight-bold">Processing Apple Sign In</h3>
			{error && (
				<ErrorMessage className="loginForm__message">
					{error}
				</ErrorMessage>
			)}
			{loading && (
				<>
					<p className="description">Please wait for a moment!</p>
					<div className="position-relative loader">
						<Preloader width={100} height={100} />
					</div>
				</>
			)}
		</Modal>
	);
}

export default AppleSignInModal;

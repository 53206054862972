import React, { PureComponent } from "react";
import withRestrictAccess from "./withRestrictAccess.jsx";

/**
 * hoc to transform component to resolve asynchronously
 * this is to support code splitting with webpack
 * usually used in routing
 * @param {object} 		getComponent 	the asynchronously-loaded component to transform
 * @param {boolean} 	restrict 		whether to restrict access to authenticated users
 * @param {string} 		redirectTo 		path to redirect unauthenticated users; default to '/'
 * @return {object}						transformed component
 */
export default function withAsync(getComponent, restrict, redirectTo = "/") {
	class AsyncComponent extends PureComponent {
		static Component = null;
		state = { Component: AsyncComponent.Component };

		componentDidMount() {
			if (!this.state.Component) {
				getComponent()
					.then(module => module.default)
					.then(Component => {
						AsyncComponent.Component = Component;
						this.setState({ Component });
					});
			}
		}
		render() {
			const { Component } = this.state;
			if (Component) {
				return <Component {...this.props} />;
			}
			return null;
		}
	}

	if (restrict) return withRestrictAccess(AsyncComponent, redirectTo);
	else return AsyncComponent;
}

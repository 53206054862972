import React from "react";

export const MalaysiaFlagSVG = () => (
	<svg
		width="28"
		height="20"
		viewBox="0 0 28 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="27.0968" height="20" fill="#F4F4F4" />
		<rect width="27.0968" height="1.5625" fill="#FF4B55" />
		<rect y="7.1875" width="27.0968" height="1.875" fill="#FF4B55" />
		<rect y="14.6875" width="27.0968" height="1.875" fill="#FF4B55" />
		<rect y="3.4375" width="27.0968" height="1.875" fill="#FF4B55" />
		<rect y="10.9375" width="27.0968" height="1.875" fill="#FF4B55" />
		<rect y="18.4375" width="27.0968" height="1.5625" fill="#FF4B55" />
		<rect width="18.4751" height="11.25" fill="#4A50A5" />
		<path
			d="M4.76154 5.78121C4.76154 3.74262 6.37944 2.08894 8.37084 2.08894C8.87192 2.08894 9.34833 2.19384 9.78224 2.38288C9.83854 2.40743 9.88099 2.32726 9.83042 2.29197C9.11251 1.79213 8.23454 1.51828 7.29281 1.56835C5.22243 1.67844 3.52948 3.38402 3.39563 5.49997C3.23999 7.96116 5.14422 10 7.51258 10C8.36845 10 9.16403 9.7303 9.82475 9.27303C9.87562 9.23779 9.83347 9.1575 9.77693 9.18199C9.34445 9.36956 8.86995 9.47361 8.37096 9.47361C6.37944 9.47354 4.76154 7.81987 4.76154 5.78121Z"
			fill="#FFE15A"
		/>
		<path
			d="M12.9117 5.9361L14.4268 5.32572C14.5016 5.29553 14.4761 5.17906 14.3961 5.18573L12.7773 5.32072L13.8897 4.08254C13.9446 4.0215 13.8731 3.92823 13.8037 3.97033L12.3978 4.82433L12.883 3.20866C12.907 3.12891 12.8037 3.07712 12.7589 3.14638L11.8487 4.55028L11.6152 2.87461C11.6037 2.79184 11.4889 2.79178 11.4773 2.87455L11.3282 3.93484L11.2663 4.38357L11.2425 4.55034L10.3323 3.14761C10.2874 3.07836 10.184 3.13021 10.2081 3.20995L10.6952 4.82427L9.28932 3.97026C9.21997 3.92811 9.14848 4.02143 9.20337 4.08248L10.3157 5.32065L8.69654 5.18566C8.61646 5.179 8.59091 5.29547 8.66584 5.32565L10.1822 5.93604L8.66578 6.54655C8.59079 6.57673 8.6164 6.6932 8.69648 6.68654L10.3157 6.55155L9.20331 7.79281C9.14848 7.85398 9.22014 7.94712 9.28944 7.9049L10.6952 7.04786L10.2082 8.66218C10.1841 8.74193 10.2874 8.79384 10.3324 8.72452L11.2425 7.32179L11.2664 7.49252L11.3282 7.9373L11.4774 9.00043C11.4891 9.08326 11.6038 9.08314 11.6153 9.00037L11.8488 7.32192L12.759 8.72582C12.8039 8.79507 12.9072 8.74341 12.8832 8.66354L12.3979 7.04786L13.8037 7.9049C13.873 7.94712 13.9446 7.85398 13.8898 7.79281L12.7774 6.55155L14.3962 6.68654C14.4763 6.6932 14.5018 6.57673 14.4269 6.54655L12.9117 5.9361Z"
			fill="#FFE15A"
		/>
	</svg>
);

export const SingaporeFlagSVG = () => (
	<svg
		width="28"
		height="20"
		viewBox="0 0 28 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="27.0968" height="10" fill="#FF4B55" />
		<rect y="10" width="27.0968" height="10" fill="#F2F2F2" />
		<path
			d="M3.73781 4.99805C3.73781 3.23645 5.02397 1.77615 6.70341 1.50603C6.79459 1.49134 6.79869 1.3653 6.70872 1.34442C6.29292 1.24835 5.85134 1.22098 5.39279 1.28373C3.58142 1.5317 2.18125 3.10712 2.15565 4.94423C2.12648 7.04192 3.80912 8.75 5.88642 8.75C6.1692 8.75 6.44376 8.7168 6.708 8.65569C6.79791 8.63494 6.79338 8.50859 6.70232 8.4939C5.02355 8.22397 3.73781 6.76652 3.73781 4.99805Z"
			fill="#F5F5F5"
		/>
		<path
			d="M6.65653 5.97293L6.50096 6.44864L6.00923 6.45252C5.94558 6.45307 5.91914 6.53588 5.97041 6.57443L6.36598 6.87232L6.21761 7.35041C6.19844 7.41233 6.26757 7.46351 6.3193 7.42574L6.71941 7.13416L7.11951 7.42574C7.1713 7.46351 7.24037 7.41227 7.2212 7.35041L7.07283 6.87232L7.4684 6.57443C7.51967 6.53582 7.49323 6.45307 7.42958 6.45252L6.93785 6.44864L6.78228 5.97293C6.76209 5.91137 6.67666 5.91137 6.65653 5.97293Z"
			fill="#F5F5F5"
		/>
		<path
			d="M9.0872 5.97293L8.93162 6.44864L8.43989 6.45252C8.37624 6.45307 8.34981 6.53588 8.40107 6.57443L8.79665 6.87232L8.64828 7.35041C8.6291 7.41233 8.69823 7.46351 8.74997 7.42574L9.15007 7.13416L9.55017 7.42574C9.60197 7.46351 9.67103 7.41227 9.65186 7.35041L9.50349 6.87232L9.89907 6.57443C9.95033 6.53582 9.92389 6.45307 9.86025 6.45252L9.36852 6.44864L9.21294 5.97293C9.19276 5.91137 9.10738 5.91137 9.0872 5.97293Z"
			fill="#F5F5F5"
		/>
		<path
			d="M7.88212 2.14773L7.72654 2.62344L7.23481 2.62733C7.17117 2.62788 7.14473 2.71068 7.19599 2.74923L7.59157 3.04713L7.4432 3.52521C7.42403 3.58713 7.49315 3.63831 7.54489 3.60055L7.94499 3.30897L8.34509 3.60055C8.39689 3.63831 8.46596 3.58707 8.44678 3.52521L8.29841 3.04713L8.69399 2.74923C8.74525 2.71062 8.71882 2.62788 8.65517 2.62733L8.16344 2.62344L8.00786 2.14773C7.98768 2.08617 7.90224 2.08617 7.88212 2.14773Z"
			fill="#F5F5F5"
		/>
		<path
			d="M5.94462 3.61306L5.78904 4.08878L5.29731 4.09266C5.23367 4.09321 5.20723 4.17601 5.25849 4.21456L5.65407 4.51246L5.5057 4.99054C5.48653 5.05246 5.55565 5.10364 5.60739 5.06588L6.00749 4.7743L6.40759 5.06588C6.45939 5.10364 6.52846 5.0524 6.50928 4.99054L6.36091 4.51246L6.75649 4.21456C6.80775 4.17595 6.78132 4.09321 6.71767 4.09266L6.22594 4.08878L6.07036 3.61306C6.05018 3.5515 5.9648 3.5515 5.94462 3.61306Z"
			fill="#F5F5F5"
		/>
		<path
			d="M9.80399 3.61306L9.64842 4.08878L9.15669 4.09266C9.09304 4.09321 9.06661 4.17601 9.11787 4.21456L9.51344 4.51246L9.36507 4.99054C9.3459 5.05246 9.41503 5.10364 9.46677 5.06588L9.86687 4.7743L10.267 5.06588C10.3188 5.10364 10.3878 5.0524 10.3687 4.99054L10.2203 4.51246L10.6159 4.21456C10.6671 4.17595 10.6407 4.09321 10.577 4.09266L10.0853 4.08878L9.92974 3.61306C9.90956 3.5515 9.82412 3.5515 9.80399 3.61306Z"
			fill="#F5F5F5"
		/>
	</svg>
);

import React from "react";

import "./EmptyListMessage.scss";

function EmptyListMessage({ children }) {
	return (
		<section className="emptyListMessage d-flex align-items-center justify-content-center flex-column">
			<img src="/assets/img/ui/empty-state.svg" alt="Empty friend list" />
			<div className="emptyListMessage__description">{children}</div>
		</section>
	);
}

export default EmptyListMessage;

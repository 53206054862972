import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { setBreakPoint } from "../../redux/ui";

// breakpoints taken from bootstrap 4
const breakpoints = {
	xl: 1200,
	lg: 992,
	md: 768,
	sm: 576,
	xs: 576,
};

/**
 * 1. Renderless component which will only listen for window resize.
 * 2. The purpose of this component is to reproduce CSS's basic media query functionality
 * with react and you can create conditional component rendering depends
 * on how browser width changes.
 * 3. General feature of this component is to dispatch an action everytime activeBreakPoint property
 * of ui reducer is not equal to newBreakPoint(get from comparison between browser width
 * and predefined endpoints).
 */
class MediaQuery extends PureComponent {
	constructor(props) {
		super(props);
		this.handleWindowSize = this.handleWindowReSize.bind(this);
	}

	componentDidMount() {
		// invoke handle window resize after the component did mount
		this.handleWindowReSize();
		// listen to window resize
		window.addEventListener("resize", this.handleWindowReSize.bind(this));
	}

	// eslint-disable-next-line react/no-typos
	componentWillUnMount() {
		
		window.removeEventListener(
			"resize",
			this.handleWindowReSize.bind(this)
		);
	}

	// listen to window resize
	handleWindowReSize() {
		const { setBreakPoint } = this.props;
		const { activeBreakPoint } = this.props.ui;
		let newBreakPoint = activeBreakPoint || "lg";

		const width = document.documentElement.clientWidth;

		// compare browser width and predefined breakpoints
		switch (true) {
			case width >= breakpoints.xl:
				newBreakPoint = "xl";
				break;
			case width >= breakpoints.lg:
				newBreakPoint = "lg";
				break;
			case width >= breakpoints.md:
				newBreakPoint = "md";
				break;
			case width >= breakpoints.sm:
				newBreakPoint = "sm";
				break;
			case width < breakpoints.xs:
				newBreakPoint = "xs";
				break;
			default:
				break;
		}

		// activeBreakPoint and newMediaQuery are not equal
		// It will not dispatch breakPointChange method and ignore.
		if (activeBreakPoint !== newBreakPoint) {
			setBreakPoint(newBreakPoint);
		}
	}

	// it will render nothing
	render() {
		return null;
	}
}

const mapStateToProps = function (store) {
	return {
		ui: store.ui,
	};
};

const mapDispatchToProps = function (dispatch) {
	return {
		setBreakPoint: bindActionCreators(setBreakPoint, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaQuery);

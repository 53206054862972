import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal/Modal.jsx";
import { connect } from "react-redux";
import "./InitialPopup.scss";
import { endpointDomain, endpointVersion } from "../../base/config.jsx";
import { bindActionCreators } from "redux";
import { showAuthentication } from "../../redux/ui.jsx";
import { initialConfirmPopup } from "../../redux/popup.jsx";
const InitialPopup = ({
	due_date,
	onClose = () => {},
	langCheckPopup,
	initialConfirmPopup,
	showAuthentication,
}) => {
	const [popupData, setPopupData] = useState([]);

	const { accessToken } = useSelector(({ authentication }) => authentication);

	const getPopUp = async () => {
		const { data } = await Axios.get(
			endpointDomain +
				endpointVersion +
				"/PopupMessage/GetPopupMessageList",
			{
				params: {
					// page_index: pageIndex,
					// page_size: 10,
				},
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
				},
			}
		);

		setPopupData(data?.data?.popup_msg_list);
	};
	useEffect(() => {
		getPopUp();
	}, []);
	// PopupMessage/GetPopupMessageList

	const PopupContent = (
		<div className={`initialPopup`}>
			<div className="initialPopup__sliderWrapper">
				<div className="slider-container">
					<div className="slider">
						<div className="slides">
							{popupData?.map((data, i) => {
								return (
									<div id={`slides__${i}`} className="slide">
										<div className="slide__text">
											<div className="bgColor">
												<img src={data?.image} />
											</div>
											<div className="initialPopup__upperContent">
												<h2 className="initialPopup__title">
													{data?.title}
												</h2>
												<p className="initialPopup__content">
													{data?.description}
												</p>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);

	// console.log("late cancel state ------------>>", lateCancelState);

	if(popupData?.length < 1) {
     return null
	}
	return (
		<Modal
			hideHeader={true}
			maxWidth={`fit-content`}
			onClose={() => {
				initialConfirmPopup();
				onClose();
				showAuthentication(null);
			}}
			maxWidth={460}
			// height={382}
			className={``}
		>
			{PopupContent}
		</Modal>
	);
};

const mapDispatchToProps = (dispatch) => ({
	showAuthentication: bindActionCreators(showAuthentication, dispatch),
	initialConfirmPopup: bindActionCreators(initialConfirmPopup, dispatch),
});

const mapStateToProps = (store) => ({
	lateCancelState: store.lateCancelReducer,
});
export default connect(mapStateToProps, mapDispatchToProps)(InitialPopup);

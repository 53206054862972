import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useLocation } from "react-router-dom";
// import Cookies from "js-cookie";
import { langCheckPopup, termPopup } from "../../../../redux/popup";
import { currentLanguage } from "../../../../redux/lang";
import Modal from "../../../Modal/Modal.jsx";
// import MYFlagIcon from "../../../Icons/MYFlag";
// import SGFlagIcon from "../../../Icons/SGFlag";

import "./CountryModal.scss";
import RadioCheckedIcon from "../../../Icons/RadioChecked";
import RadioUnCheckIcon from "../../../Icons/RadioUnCheck";
import { useState } from "react";
import { currentCountry } from "../../../../redux/countryId";
import { getProfile } from "../../../../redux/profile.jsx";

const CountryModal = (props) => {
	const [currentLang, setCurrentLang] = useState(props.lang || "sg");
	let history = useHistory();
	const location = useLocation();


	const handleClose = () => {
		props.langCheckPopup();
	};


	const handleChangeLang = (lang) => {

		const _currentCountry = props.masterData?.countries?.find((item) => {
			return item?.country_id === lang
		});

		const profileChecked = props?.profile?.profile?.member_agreement?.find((item) => item.Country_ID === lang)

      if(profileChecked?.Is_Agree !== undefined && !profileChecked?.Is_Agree && props?.authentication?.accessToken) {

		 handleClose()
         props.termCheckPopup();
		 history.push({
			pathname: location.pathname,
			search: `?countryName=${_currentCountry?.country_name}&cId=${_currentCountry?.country_id}`
		})

	  } else {

		setCurrentLang(lang);

		// set language
		props.setCurrentLanguage(lang);
		// get countryId

		props.setCurrentCountry(_currentCountry?.country_id);

		setTimeout(() => {

			handleClose()
            history.push({
				pathname: location.pathname,
				search: `?country=${_currentCountry?.country_code?.toLowerCase()}`
			})
		}, 1000);
		props?.getCurrentProfile();
	  }



	};



	return (
		<Modal
			maxWidth={415}

			onClose={() => handleClose()}
		>
			<div className="countryModal">
			<div className="lang__contents">
				<h2 className="title">Select country</h2>
				<div className="container">
					{props?.masterData?.countries?.map((data) => {

						return (
							<div
								key={data?.country_id}
								className="langBox"
								onClick={(e) => {
									e.preventDefault();
									return handleChangeLang(data?.country_id);
								}}
							>
								<div className="boxLeft">
									<div className="_icon">
										{/* {data?.icon()} */}
										<img
											src={data?.country_logo}
											alt="flag"
										/>
									</div>
									<div className="_desc">
										<span className="title">
											{data?.country_code}
										</span>
										<br />
										<span className="lang">
											{data?.country_name}
										</span>
									</div>
								</div>
								<div className="boxRight">
									{currentLang === 'sg' ? <>
									{data?.country_code?.toLowerCase() === currentLang?.toLowerCase()  ? (
										<RadioCheckedIcon />
									) : (
										<RadioUnCheckIcon />
									)}
									</> : (data?.country_id === currentLang ) ? <RadioCheckedIcon />: 	<RadioUnCheckIcon /> }

								</div>
							</div>
						);
					})}
				</div>
			</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
		lang: store?.lang?.lang,
		masterData: store?.masterData?.data,
		profile: store?.profile,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		langCheckPopup: bindActionCreators(langCheckPopup, dispatch),
		termCheckPopup: bindActionCreators(termPopup,dispatch),
		setCurrentLanguage: bindActionCreators(currentLanguage, dispatch),
		setCurrentCountry: bindActionCreators(currentCountry, dispatch),
		getCurrentProfile: bindActionCreators(getProfile, dispatch),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CountryModal);

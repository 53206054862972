import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isValidJWT } from "../base/functions";
import { setAuthenticationCallback } from "../redux/authentication";

/**
 * hoc to transform component to auto redirect unauthenticated users away
 * @param {object} 		Component 	the component to transform
 * @param {string} 		redirectTo 	path to redirect unauthenticated users; default to '/login'
 * @return {object}					transformed component
 */
export default function withRestrictAccess(Component, redirectTo = "/") {
	class RestrictAccess extends PureComponent {
		constructor(props) {
			super(props);
			this.checkAuth();
		}

		checkAuth() {
			const { authentication } = this.props;

			if (
				!authentication ||
				!authentication.accessToken ||
				!isValidJWT(authentication.accessToken)
			) {
				if (!authentication.callback) {
					this.props.setAuthenticationCallback(() => {
						this.props.history.push(
							this.props.location.pathname +
								this.props.location.search
						);
					});
				}

				this.props.history.push(redirectTo);
			}
		}

		render() {
			const { authentication } = this.props;

			if (authentication && authentication.accessToken) {
				return <Component {...this.props} />;
			} else {
				return null;
			}
		}
	}

	const mapStateToProps = function(store) {
		return {
			authentication: store.authentication
		};
	};

	const mapDispatchToProps = function(dispatch) {
		return {
			setAuthenticationCallback: bindActionCreators(
				setAuthenticationCallback,
				dispatch
			)
		};
	};

	return withRouter(
		connect(mapStateToProps, mapDispatchToProps)(RestrictAccess)
	);
}

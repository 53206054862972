import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import qs from "qs";

import { getMetaTags } from "../../../base/metaTags";

/**
 * set the document.title according to path
 * for titles that require api integration, it will be handled by the child component
 */
class TitleController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: getMetaTags("default").title,
		};
	}

	componentDidMount() {
		this.retrieveMetaTags();
	}

	componentDidUpdate(prevProps) {
		//listen to route changes
		if (
			this.props.location.pathname != prevProps.location.pathname ||
			this.props.location.search != prevProps.location.search
		) {
			this.retrieveMetaTags();
		}
	}

	retrieveMetaTags() {
		let pathsArray = this.props.location.pathname
			.split("/")
			.filter((v) => v);
		let queryObj = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});
		let title = getMetaTags("default").title;
		switch (pathsArray[0]) {
			case "blog":
			case "schedule":
			case "academy":
			case "studios":
			case "the-team":
			case "careers":
			case "own-a-ym":
			case "newbie":
			case "contact-us":
				if (pathsArray.length == 1) {
					title = getMetaTags(pathsArray[0]).title;
					this.setState({ title: getMetaTags(pathsArray[0]).title });
				}
				break;
			case "classes":
				switch (pathsArray[1]) {
					case "class-types":
					case "private-classes":
						title = getMetaTags(pathsArray[1]).title;
						break;
					default:
						title = getMetaTags("classes").title;
				}
				break;
			case "account":
				switch (pathsArray[1]) {
					case "notifications":
					case "edit":
					case "achievements":
					case "favourites":
					case "friends":
					case "referral":
					case "activities-log":
					case "transaction-history":
					case "payment-settings":
					case "adhoc-payments":
						title = getMetaTags("account/" + pathsArray[1]).title;
						break;
					case "classes":
						title = getMetaTags(
							"account/" +
								(queryObj.type != "history"
									? "classes"
									: "classes-history")
						).title;
						break;
					case "class-packs":
						title = getMetaTags(
							"account/" +
								(queryObj.type != "past"
									? "class-packs"
									: "past-class-packs")
						).title;
						break;
				}
				break;
		}
		if (title) this.setState({ title });
	}

	render() {
		return (
			<Helmet>
				<title>{this.state.title}</title>
			</Helmet>
		);
	}
}

export default withRouter(TitleController);

import React from "react";
import PropTypes from "prop-types";

import "./HeaderNavIcon.scss";

const propTypes = {
	/** @type {boolean}		shows cross when active */
	active: PropTypes.bool,
	/** @type {function}	onClick callback */
	onClick: PropTypes.func.isRequired
};
const defaultProps = {
	active: false
};

/**
 * Hamburger icon in the header
 * Only visible in smaller screens
 */
export default function HeaderNavIcon({ active, onClick }) {
	let naviconClassName = "headerNavIcon ";
	if (active) naviconClassName += "headerNavIcon--active";
	return (
		<button type="button" className={naviconClassName} onClick={onClick}>
			<div className="headerNavIcon__top animated" />
			<div className="headerNavIcon__mid animated" />
			<div className="headerNavIcon__btm animated" />
		</button>
	);
}
HeaderNavIcon.propTypes = propTypes;
HeaderNavIcon.defaultProps = defaultProps;

import React, { useState } from "react";
import qs from "qs";
import LateCancelPopup from "../views/Home/LateCancelPopup/LateCancelPopup.jsx";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

const LateCancel = (props) => {
	const [pendingData, setPendingData] = useState(props?.data || null);
	const location = useLocation();

	let queryObj = qs.parse(location?.search, {
		ignoreQueryPrefix: true,
	});

	useEffect(() => {
		if (queryObj?.webview != 1 && !Cookies.get("fine_fee") && props?.data) {
			if (queryObj?.webview != 1) {
				queryObj?.webview != 1 &&
					Cookies.set("fine_fee", JSON.stringify(props?.data));
			}
		}
	}, []);

	return (
		<div>
			{pendingData?.fine_fee ? (
				<LateCancelPopup data={pendingData?.fine_fee_info} />
			) : null}
		</div>
	);
};

export default LateCancel;

import React from "react";
import { Link } from "react-router-dom";

import "./FooterCopyright.scss";
import FooterSocial from "../FooterSocial/FooterSocial.jsx";
/**
 * Bottom copyright line in footer
 */
export default function FooterCopyright() {
	return (
		<div className="footerCopyright">
			<div className="footerCopyright__col">
				&copy; {new Date().getFullYear()} Yoga Movement. All rights
				reserved.
			</div>
			<div className="footerCopyright__col">
				<Link
					to="/terms-of-services"
					className="footerCopyright__link footer__link animated click-efx"
				>
					Terms of Service
				</Link>
				<Link
					to="/privacy-policy"
					className="footerCopyright__link footer__link animated click-efx"
				>
					Privacy Policy
				</Link>
			</div>
		</div>
	);
}

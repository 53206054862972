import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import qs from "qs";

import { isValidJWT, end } from "../../../base/functions";
import { showAuthentication } from "../../../redux/ui";
import { setAuthenticationCallback } from "../../../redux/authentication";
import "./NewToYM.scss";

/**
 * new to YM banner
 */
class NewToYM extends PureComponent {
	static propTypes = {
		/** @type {string} 	react className prop to add to <Link> */
		className: PropTypes.string,
	};
	static defaultProps = {
		className: "",
	};

	constructor(props) {
		super(props);
		this.state = {};
	}

	handleClick(e) {
		if (!isValidJWT(this.props.authentication.accessToken)) {
			e.preventDefault();
			this.props.setAuthenticationCallback(() =>
				this.props.history.push("/schedule")
			);
			this.props.showAuthentication("register");
		}
	}

	render() {
		const { authentication, profile } = this.props;

		let link = !isValidJWT(authentication.accessToken) ? "#" : "/schedule";

		if (profile.profile && profile.profile.mem_tier != "newbie")
			return null;

		return (
			<Link
				to={link}
				onClick={(e) => this.handleClick(e)}
				className={
					"newToYM row click-efx animated " + this.props.className
				}
			>
				<div className="newToYM__title col-md-6 text-uppercase">
					<img
						src="/assets/img/icons/newbie-white.svg"
						alt="newbie"
						className="newToYM__img"
					/>
					NEW TO YM? Join the movement!
				</div>
				<div className="newToYM__register col-md-6 ">
					Register and get a FREE class when you purchase your first
					Class Pack.
				</div>
			</Link>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
		profile: store.profile,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
		setAuthenticationCallback: bindActionCreators(
			setAuthenticationCallback,
			dispatch
		),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(NewToYM)
);

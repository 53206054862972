import axios from "axios";
import immer from "immer";

import { memberEndpointURL, endpointCreds } from "../base/config";
import { end, getErrorMessage } from "../base/functions";
import { setProfile, getProfile, updateClassPack } from "./profile.jsx";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * fetch friends to get friends list
 * can filter by type(all|friend|pending|request)
 * @param { string }  type 			type of the friends
 */
export const getFriendList = (type) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "GET_FRIEND_LIST" });
		return axios
			.get(
				memberEndpointURL +
					`GetFriendList` +
					(type ? "?type=" + type : ""),
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "GET_FRIEND_LIST_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) => {
				return dispatch({
					type: "GET_FRIEND_LIST_ERROR",
					error: err,
				});
			});
	};
};

/**
 * fetch friends to get friends list
 * can filter by type(all|friend|pending|request)
 * @param { string }  type 			type of the friends
 */
export const getFriendPage = (index = 0, limit = 20, edit = false) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "GET_FRIEND_PAGE" });
		return axios
			.get(
				memberEndpointURL +
					`GetYMFriendPage?page_size=${limit}&page_index=${index}`,
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "GET_FRIEND_PAGE_SUCCESS",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_FRIEND_PAGE_ERROR",
					error: err,
				})
			);
	};
};

/**
 * Remove friend from shared class pack
 * @param  {string}  	member_id 			friend id
 * @param  {string} 	mem_pack_id 		member selected class Pack
 */

export const removeShareClass = (member_id, mem_pack_id) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "REMOVE_SHARE_CLASS" });
		return axios
			.post(
				memberEndpointURL + "RemoveShareClass",
				{ member_id, mem_pack_id },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				if (res.data.errorCode === 0) {
					dispatch(updateClassPack(res.data));
				}
				return dispatch({
					type: "REMOVE_SHARE_CLASS_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "REMOVE_SHARE_CLASS_ERROR",
					err: err,
				})
			);
	};
};

/**
 * share class pack with friend
 * @param  {string}  	member_id 			friend id
 * @param  {string} 	mem_pack_id 		member selected class Pack
 */
export const shareClass = (member_id, mem_pack_id) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "SHARE_CLASS" });
		return axios
			.post(
				memberEndpointURL + "ShareClass",
				{ member_id, mem_pack_id },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				if (res.data.errorCode === 0) {
					dispatch(updateClassPack(res.data));
				}
				dispatch({
					type: "SHARE_CLASS_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "SHARE_CLASS_ERROR",
					err: err,
				})
			);
	};
};

/**
 * member accept shared class pack from friends
 * @param {string} mem_pack_id 				member selected class Pack
 */
export const acceptSharedClassPack = (mem_pack_id) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "ACCEPT_SHARED_CLASS_PACK" });
		return axios
			.post(
				memberEndpointURL + "AcceptSharedClassPack",
				{ mem_pack_id },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				if (res.data.errorCode === 0) {
					dispatch(updateClassPack(res.data));
				}
				dispatch({
					type: "ACCEPT_SHARED_CLASS_PACK_RESOLVE",
					res: res.data,
				});

				// load profile again
				dispatch(getProfile());
			})
			.catch((err) =>
				dispatch({
					type: "ACCEPT_SHARED_CLASS_PACK_ERROR",
					err: err,
				})
			);
	};
};

/**
 * member reject shared class pack from friends
 * @param {string} mem_pack_id 				member selected class Pack
 */
export const rejectSharedClassPack = (mem_pack_id) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "REJECT_SHARED_CLASS_PACK" });
		return axios
			.post(
				memberEndpointURL + "RejectSharedClassPack",
				{ mem_pack_id },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				if (res.data.errorCode === 0) {
					dispatch(updateClassPack(res.data));
				}
				dispatch({
					type: "REJECT_SHARED_CLASS_PACK_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "REJECT_SHARED_CLASS_PACK_ERROR",
					err: err,
				})
			);
	};
};

/**
 * member reject shared class pack from friends
 * @param {string} mem_pack_id 				member selected class Pack
 */
export const removeFromFriendList = (member_id, query) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "REMOVE_FROM_FRIEND_LIST" });
		return axios
			.post(
				memberEndpointURL + "RemoveFromFriendList",
				{ member_id },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				if (res.data.errorCode === 0) {
					dispatch(updateClassPack(res.data));
				}
				dispatch({
					type: "REMOVE_FROM_FRIEND_LIST_RESOLVE",
					res: res.data,
				});
				if (query) {
					dispatch(searchMember(query));
				} else {
					dispatch(getFriendList());
				}
			})
			.catch((err) =>
				dispatch({
					type: "REMOVE_FROM_FRIEND_LIST_ERROR",
					err: err,
				})
			);
	};
};

/**
 * add friend to friend list
 * @param {string} member_id 				YM member id
 */
export const addToFriendList = (member_id, query) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "ADD_TO_FRIEND_LIST" });
		return axios
			.post(
				memberEndpointURL + "AddToFriendList",
				{ member_id },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				dispatch({
					type: "ADD_TO_FRIEND_LIST_RESOLVE",
					res: res.data,
				});
				if (query) {
					dispatch(searchMember(query));
				} else {
					dispatch(getFriendList());
				}
			})
			.catch((err) =>
				dispatch({
					type: "ADD_TO_FRIEND_LIST_ERROR",
					err: err,
				})
			);
	};
};

/**
 * member update friend setting
 * @param {bool}  isPrivate 			whether member has a will set account to private
 */
export const updateFriendSetting = (isPrivate) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "UPDATE_FRIEND_SETTING" });
		return axios
			.post(
				memberEndpointURL + "UpdateFriendSetting",
				{ private_account: isPrivate },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				if (res.data.errorCode === 0) {
					dispatch(setProfile(res.data.data.member_info));
				}
				dispatch({
					type: "UPDATE_FRIEND_SETTING_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "UPDATE_FRIEND_SETTING_ERROR",
					err: err,
				})
			);
	};
};

/**
 * search member with email
 * @param {string} keyword 			keyword to filter by email
 */
export const searchMember = (keyword) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "SEARCH_MEMBER" });
		return axios
			.get(memberEndpointURL + `SearchMember?keyword=${keyword}`, {
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
				},
			})
			.then((res) => {
				dispatch({
					type: "SEARCH_MEMBER_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "SEARCH_MEMBER_ERROR",
					err: err,
				})
			);
	};
};

/**
 * member invite friend to class
 * @param {string} class_id 		class id
 * @param {array} selected_friend 	array of friend IDs [{member_id:""}]
 */
export const inviteFriendToClass = (class_id, selected_friend) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "INVITE_FRIEND_TO_CLASS" });
		return axios
			.post(
				memberEndpointURL + "inviteFriendToClass",
				{ class_id, selected_friend },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				dispatch({
					type: "INVITE_FRIEND_TO_CLASS_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "INVITE_FRIEND_TO_CLASS_ERROR",
					err: err,
				})
			);
	};
};

/**
 * search member from facebook's friends
 */
export const searchFacebookMember = () => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "SEARCH_FACEBOOK_MEMBER" });
		return axios
			.get(memberEndpointURL + `SearchFacebookMember`, {
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
				},
			})
			.then((res) => {
				dispatch({
					type: "SEARCH_FACEBOOK_MEMBER_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "SEARCH_FACEBOOK_MEMBER_ERROR",
					err: err,
				})
			);
	};
};

/**
 * invite non-YM member by sending them an email invite
 * @param {string} email  	   email of person to invite
 */
export const inviteNonYm = (email) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		dispatch({ type: "INVITE_NON_YM" });

		return axios
			.post(
				memberEndpointURL + "InviteNonYm",
				{ email },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "INVITE_NON_YM_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "INVITE_NON_YM_ERROR",
					error: err,
				})
			);
	};
};

/**
 * Accept or Reject friends
 * query use for searching friend
 */
export const responseFriendRequest = (body, query) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		dispatch({ type: "RESPONSE_FRIEND_REQUEST" });

		return axios
			.post(memberEndpointURL + "ResponseFriendRequest", body, {
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
				},
			})
			.then((res) => {
				dispatch({
					type: "RESPONSE_FRIEND_REQUEST_RESOLVE",
					res: res.data,
				});
				if (query) {
					dispatch(searchMember(query));
				} else {
					dispatch(getFriendList());
				}
			})
			.catch((err) =>
				dispatch({
					type: "RESPONSE_FRIEND_REQUEST_ERROR",
					error: err,
				})
			);
	};
};

/**
 * Accept or Reject share class pack
 * query use for searching friend
 */
export const responseShareClassPack = (body, query) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		dispatch({ type: "RESPONSE_SHARE_PACK_REQUEST" });

		return axios
			.post(memberEndpointURL + "ResponseFriendRequest", body, {
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
				},
			})
			.then((res) => {
				dispatch({
					type: "RESPONSE_SHARE_PACK_REQUEST_RESOLVE",
					res: res.data,
				});
				if (query) {
					dispatch(searchMember(query));
				} else {
					dispatch(getFriendList());
				}
			})
			.catch((err) =>
				dispatch({
					type: "RESPONSE_SHARE_PACK_REQUEST_ERROR",
					error: err,
				})
			);
	};
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const currentState = {
	status: null,
	error: null,
	errorCode: null,
	friendList: [],
	searchMemberList: [],
	searchFacebookMemberList: [],
};

export function friends(state = currentState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			case "GET_FRIEND_LIST":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_FRIEND_LIST_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_FRIEND_LIST_SUCCESS";
					draft.friendList = action.res.data.friend_list;
				} else {
					draft.status = "GET_FRIEND_LIST_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_FRIEND_LIST_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "RESPONSE_FRIEND_REQUEST_RESOLVE":
				draft.status = action.type;
				break;

			case "SHARE_CLASS":
				draft.status = action.type;
				draft.error = null;
				break;

			case "SHARE_CLASS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "SHARE_CLASS_SUCCESS";
				} else {
					draft.status = "SHARE_CLASS_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "SHARE_CLASS_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "REMOVE_SHARE_CLASS":
				draft.status = action.type;
				draft.error = null;
				break;

			case "REMOVE_SHARE_CLASS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "REMOVE_SHARE_CLASS_SUCCESS";
				} else {
					draft.status = "REMOVE_SHARE_CLASS_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "REMOVE_SHARE_CLASS_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "ACCEPT_SHARED_CLASS_PACK":
				draft.status = action.type;
				draft.error = null;
				break;

			case "ACCEPT_SHARED_CLASS_PACK_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "ACCEPT_SHARED_CLASS_PACK_SUCCESS";
				} else {
					draft.status = "ACCEPT_SHARED_CLASS_PACK_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "ACCEPT_SHARED_CLASS_PACK_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "REJECT_SHARED_CLASS_PACK":
				draft.status = action.type;
				draft.error = null;
				break;

			case "REJECT_SHARED_CLASS_PACK_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "REJECT_SHARED_CLASS_PACK_SUCCESS";
				} else {
					draft.status = "REJECT_SHARED_CLASS_PACK_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "REJECT_SHARED_CLASS_PACK_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "REMOVE_FROM_FRIEND_LIST":
				draft.status = action.type;
				draft.error = null;
				break;

			case "REMOVE_FROM_FRIEND_LIST_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "REMOVE_FROM_FRIEND_LIST_SUCCESS";
					draft.friendList = draft.friendList.filter(
						(x) => x.member_id !== action.res.data.member_id
					);
				} else {
					draft.status = "REMOVE_FROM_FRIEND_LIST_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "REMOVE_FROM_FRIEND_LIST_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "ADD_TO_FRIEND_LIST":
				draft.status = action.type;
				draft.error = null;
				break;

			case "ADD_TO_FRIEND_LIST_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "ADD_TO_FRIEND_LIST_SUCCESS";
				} else {
					draft.status = "ADD_TO_FRIEND_LIST_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "ADD_TO_FRIEND_LIST_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "UPDATE_FRIEND_SETTING":
				draft.status = action.type;
				draft.error = null;
				break;

			case "UPDATE_FRIEND_SETTING":
				if (action.res.errorCode == 0) {
					draft.status = "UPDATE_FRIEND_SETTING_SUCCESS";
				} else {
					draft.status = "UPDATE_FRIEND_SETTING_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "UPDATE_FRIEND_SETTING_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "SEARCH_MEMBER":
				draft.status = action.type;
				draft.error = null;
				draft.errorCode = null;
				break;

			case "SEARCH_MEMBER_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "SEARCH_MEMBER_SUCCESS";
					draft.searchMemberList = action.res.data.member_list;
				} else {
					draft.status = "SEARCH_MEMBER_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "RESPONSE_FRIEND_REQUEST_ERROR":
			case "SEARCH_MEMBER_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "SEARCH_FACEBOOK_MEMBER":
				draft.status = action.type;
				draft.error = null;
				draft.errorCode = null;
				break;

			case "SEARCH_FACEBOOK_MEMBER_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "SEARCH_FACEBOOK_MEMBER_SUCCESS";
					draft.searchFacebookMemberList =
						action.res.data.member_list;
				} else {
					draft.status = "SEARCH_FACEBOOK_MEMBER_ERROR";
					draft.errorCode = action.res.errorCode;
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "SEARCH_FACEBOOK_MEMBER_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "INVITE_FRIEND_TO_CLASS":
				draft.status = action.type;
				draft.error = null;
				break;

			case "INVITE_FRIEND_TO_CLASS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "INVITE_FRIEND_TO_CLASS_SUCCESS";
				} else {
					draft.status = "INVITE_FRIEND_TO_CLASS_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "INVITE_FRIEND_TO_CLASS_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			// invite non-ym
			case "INVITE_NON_YM":
				draft.status = action.type;
				draft.error = null;
				break;

			case "INVITE_NON_YM_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "INVITE_NON_YM_SUCCESS";
				} else {
					draft.status = "INVITE_NON_YM_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "INVITE_NON_YM_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;
		}
	});
}

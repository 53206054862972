import axios from "axios";
import immer from "immer";

import { memberEndpointURL, endpointCreds } from "../base/config";
import { end, getErrorMessage } from "../base/functions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * fetch api to get class types
 */
export const getClassTypes = (getAll = true) => (dispatch, getState) => {
	let store = getState();
	let accessToken = store.authentication.accessToken;

	dispatch({ type: "GET_CLASS_TYPES" });
	return axios
		.get(memberEndpointURL + "GetClassTypes", {
			params: {
				page_index: 0,
				page_size: 0,
				get_all: getAll
			},
			auth: accessToken == null && endpointCreds,
			headers: {
				Authorization: "Bearer " + accessToken,
				"Content-type": "application/json"
			}
		})
		.then(res =>
			dispatch({
				type: "GET_CLASS_TYPES_RESOLVE",
				res: res.data
			})
		)
		.catch(err =>
			dispatch({
				type: "GET_CLASS_TYPES_ERROR",
				error: err
			})
		);
};

/**
 * call api to remove favourited status of class type
 * @param  {string}  classTypeID  class type ID
 */
export const removeFavouriteClassType = classTypeID => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		dispatch({ type: "REMOVE_FAVOURITE_CLASS_TYPE" });

		return axios
			.post(
				memberEndpointURL + "RemoveFavoriteClass",
				{ class_id: classTypeID },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json"
					}
				}
			)
			.then(res =>
				dispatch({
					type: "REMOVE_FAVOURITE_CLASS_TYPE_RESOLVE",
					res: res.data
				})
			)
			.catch(err =>
				dispatch({
					type: "REMOVE_FAVOURITE_CLASS_TYPE_ERROR",
					err: err
				})
			);
	};
};

/**
 * call api to add favourited status of class type
 * @param  {string}  classTypeID  class type ID
 */
export const addFavouriteClassType = classTypeID => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		dispatch({ type: "ADD_FAVOURITE_CLASS_TYPE" });

		return axios
			.post(
				memberEndpointURL + "AddFavoriteClass",
				{ class_id: classTypeID },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json"
					}
				}
			)
			.then(res =>
				dispatch({
					type: "ADD_FAVOURITE_CLASS_TYPE_RESOLVE",
					res: res.data
				})
			)
			.catch(err =>
				dispatch({
					type: "ADD_FAVOURITE_CLASS_TYPE_ERROR",
					err: err
				})
			);
	};
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const currentState = {
	status: null,
	error: null,
	list: []
};
export function classTypes(state = currentState, action) {
	return immer(state, draft => {
		switch (action.type) {
			case "GET_CLASS_TYPES":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_CLASS_TYPES_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_CLASS_TYPES_SUCCESS";
					draft.list = action.res.data.class_list;
				} else {
					draft.status = "GET_CLASS_TYPES_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_CLASS_TYPES_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			// add favourite class type
			case "ADD_FAVOURITE_CLASS_TYPE":
				draft.status = action.type;
				draft.error = null;
				break;

			case "ADD_FAVOURITE_CLASS_TYPE_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "ADD_FAVOURITE_CLASS_TYPE_SUCCESS";
				} else {
					draft.status = "ADD_FAVOURITE_CLASS_TYPE_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "ADD_FAVOURITE_CLASS_TYPE_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			// remove favourite class type
			case "REMOVE_FAVOURITE_CLASS_TYPE":
				draft.status = action.type;
				draft.error = null;
				break;

			case "REMOVE_FAVOURITE_CLASS_TYPE_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "REMOVE_FAVOURITE_CLASS_TYPE_SUCCESS";
				} else {
					draft.status = "REMOVE_FAVOURITE_CLASS_TYPE_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "REMOVE_FAVOURITE_CLASS_TYPE_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;
		}
	});
}

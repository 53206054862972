import axios from "axios";
import immer from "immer";

import { memberEndpointURL, endpointCreds } from "../base/config";
import {  getErrorMessage } from "../base/functions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * fetch api to get teachers
 */
export const getTeachers =
	({ ip, countryId }) =>
	(dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		dispatch({ type: "GET_TEACHERS" });

		return axios
			.get(memberEndpointURL + "GetTeacherList", {
				params: {
					page_index: 0,
					page_size: 0,
				},
				auth: accessToken == null && endpointCreds,
				headers: {
					Authorization: "Bearer " + accessToken,
					IpAddress: ip,
					CountryID: countryId,
					"Content-type": "application/json",
				},
			})
			.then((res) =>
				dispatch({
					type: "GET_TEACHERS_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_TEACHERS_ERROR",
					error: err,
				})
			);
	};

/**
 * fetch api to get teacher detail
 */
export const getTeacherDetail = (instructor_alias) => (dispatch, getState) => {
	let store = getState();
	let accessToken = store.authentication.accessToken;

	dispatch({ type: "GET_TEACHER_DETAIL" });

	return axios
		.get(memberEndpointURL + "GetTeacherDetail", {
			params: {
				instructor_alias: instructor_alias,
			},
			auth: accessToken == null && endpointCreds,
			headers: {
				Authorization: "Bearer " + accessToken,
				"Content-type": "application/json",
			},
		})
		.then((res) =>
			dispatch({
				type: "GET_TEACHER_DETAIL_RESOLVE",
				res: res.data,
			})
		)
		.catch((err) =>
			dispatch({
				type: "GET_TEACHER_DETAIL_ERROR",
				error: err,
			})
		);
};

/**
 * call api to remove favourited status of teacher
 * @param  {string}  teacherAlias  alias of the teacher
 */
export const removeFavouriteTeacher = (teacherAlias) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		dispatch({ type: "REMOVE_FAVOURITE_TEACHER", teacherAlias });

		return axios
			.post(
				memberEndpointURL + "RemoveFavoriteTeacher",
				{ instructor_alias: teacherAlias },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "REMOVE_FAVOURITE_TEACHER_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "REMOVE_FAVOURITE_TEACHER_ERROR",
					err: err,
				})
			);
	};
};

/**
 * call api to add favourited status of teacher
 * @param  {string}  teacherAlias  alias of the teacher
 */
export const addFavouriteTeacher = (teacherAlias) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		dispatch({ type: "ADD_FAVOURITE_TEACHER", teacherAlias });

		return axios
			.post(
				memberEndpointURL + "AddFavoriteTeacher",
				{ instructor_alias: teacherAlias },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "ADD_FAVOURITE_TEACHER_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "ADD_FAVOURITE_TEACHER_ERROR",
					err: err,
				})
			);
	};
};

/**
 * clears selected teacher when Teacher modal is closed
 */
export const clearSelectedTeacher = () => ({
	type: "CLEAR_SELECTED_TEACHER",
});

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const initialState = {
	status: null,
	error: null,
	list: [],
	is_coming_soon: false,
	selectedTeacher: {},
};

export function teachers(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			// get teachers list
			case "GET_TEACHERS":
				draft.status = action.type;
				break;

			case "GET_TEACHERS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_TEACHERS_SUCCESS";
					draft.list = action.res.data.instructor_list;
					draft.is_coming_soon = action.res.data.is_coming_soon
				} else {
					draft.status = "GET_TEACHERS_ERROR";
				}
				break;

			case "GET_TEACHERS_ERROR":
				draft.status = action.type;
				break;

			// get specific teacher detail
			case "GET_TEACHER_DETAIL":
				draft.status = action.type;
				break;

			case "GET_TEACHER_DETAIL_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_TEACHER_DETAIL_SUCCESS";
					draft.selectedTeacher = action.res.data.instructor_info;
				} else {
					draft.status = "GET_TEACHER_DETAIL_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_TEACHER_DETAIL_ERROR":
				draft.status = action.type;
				break;

			// add favourite teacher
			case "ADD_FAVOURITE_TEACHER":
				draft.status = action.type;
				draft.error = null;
				break;

			case "ADD_FAVOURITE_TEACHER_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "ADD_FAVOURITE_TEACHER_SUCCESS";
				} else {
					draft.status = "ADD_FAVOURITE_TEACHER_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "ADD_FAVOURITE_TEACHER_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			// remove favourite teacher
			case "REMOVE_FAVOURITE_TEACHER":
				draft.status = action.type;
				draft.error = null;
				break;

			case "REMOVE_FAVOURITE_TEACHER_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "REMOVE_FAVOURITE_TEACHER_SUCCESS";
				} else {
					draft.status = "REMOVE_FAVOURITE_TEACHER_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "REMOVE_FAVOURITE_TEACHER_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			// clear selected teacher
			case "CLEAR_SELECTED_TEACHER":
				draft.status = action.type;
				draft.selectedTeacher = {};
				draft.is_coming_soon = false
				break;
		}
	});
}

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import DayPicker from "react-day-picker";

import YearMonthSelect from "./YearMonthSelect/YearMonthSelect.jsx";
import "react-day-picker/lib/style.css";
import "./SingleDatePicker.scss";

/**
 * date picker to select a single date
 * with year and month navigation
 */
export default class SingleDatePicker extends PureComponent {
	static propTypes = {
		/** @type {Date}  		the active date to display */
		selectedDate: PropTypes.instanceOf(Date),
		/** @type {function}  	click event handler when click on individual date */
		onDayClick: PropTypes.func,
		/** @type {string}		className prop for button */
		className: PropTypes.string,
	};

	static defaultProps = {
		selectedDate: new Date(),
		onDayClick: () => {},
		className: "",
	};

	constructor(props) {
		super(props);
		this.state = {
			//the date to display (not selected date)
			displayDate: this.props.selectedDate
				? this.props.selectedDate
				: new Date(),
		};
	}

	render() {
		return (
			<DayPicker
				className={"singleDatePicker " + this.props.className}
				selectedDays={this.props.selectedDate}
				fromMonth={this.props.selectedDate}
				month={this.props.selectedDate}
				captionElement={({ date, localeUtils }) => (
					<YearMonthSelect
						date={this.props.selectedDate}
						localeutils={localeUtils}
						onChange={(date) => this.props.onDayClick(date, true)}
					/>
				)}
				onDayClick={(e) => this.props.onDayClick(e)}
			/>
		);
	}
}

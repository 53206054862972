import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import withAsync from "../hocs/withAsync.jsx";

//dev use only
const Sample = withAsync(() => import("../views/Sample/Sample.jsx"));

//homepage
const Home = withAsync(() => import("../views/Home/Home.jsx"));

const GiftClassPurchaseSuccess = withAsync(() =>
	import(
		"../views/Classes/GiftClassPurchaseSuccess/GiftClassPurchaseSuccess.jsx"
	)
);

const GiftBookPackAndPurchaseSuccess = withAsync(() =>
	import(
		"../views/Classes/ClassPacksPurchaseSuccess/GiftBookPackAndPurchaseSuccess.jsx"
	)
);

//YM Life
const YMLife = withAsync(() => import("../views/YMLife/YMLife.jsx"));
const YMLifeDetails = withAsync(() =>
	import("../views/YMLife/YMLifeDetails/YMLifeDetails.jsx")
);

//studio
const Studios = withAsync(() => import("../views/Studios/Studios.jsx"));
const StudiosDetail = withAsync(() =>
	import("../views/Studios/StudiosDetail/StudiosDetail.jsx")
);

const Merchandise = withAsync(() =>
	import("../views/Merchandise/Merchandise.jsx")
);
const MerchandiseDetail = withAsync(() =>
	import("../views/MerchandiseDetail/MerchandiseDetailContainer.jsx")
);

//registration section
const Register = withAsync(() => import("../views/Register/Register.jsx"));
const ConfirmOtp = withAsync(() =>
	import("../views/ConfirmOtp/ConfirmOtp.jsx")
);
const AboutYou = withAsync(() =>
	import("../views/Register/AboutYou/AboutYou.jsx")
);
const Terms = withAsync(() => import("../views/Register/Terms/Terms.jsx"));

//classes section
const ClassPacks = withAsync(() =>
	import("../views/Classes/ClassPacks/ClassPacks.jsx")
);
const ClassTypes = withAsync(() =>
	import("../views/Classes/ClassTypes/ClassTypes.jsx")
);

// ClassPackPurchasePreview will only available for logged in users
const ClassPacksPurchasePreview = withAsync(() =>
	import(
		"../views/Classes/ClassPacksPurchasePreview/ClassPacksPurchasePreview.jsx"
	)
);
const ClassPacksPurchaseBookPreview = withAsync(() =>
	import(
		"../views/Classes/ClassPacksPurchasePreview/ClassPacksPurchaseBookPreview.jsx"
	)
);

// ClassPackPurchasePreview will only available for logged in users
const ClassPacksPurchaseSummary = withAsync(() =>
	import(
		"../views/Classes/ClassPacksPurchaseSummary/ClassPacksPurchaseSummary.jsx"
	)
);
const GiftClassPacksPurchaseSummary = withAsync(() =>
	import(
		"../views/Classes/ClassPacksPurchaseSummary/GiftClassPacksPurchaseSummary.jsx"
	)
);
const GiftClassPacksBookPurchaseSummary = withAsync(() =>
	import(
		"../views/Classes/ClassPacksPurchaseSummary/GiftClassPacksBookPurchaseSum.jsx"
	)
);
const GiftFreePurchaseSummary = withAsync(() =>
	import("../views/Classes/components/FreeClassGift/FreeClassGift.jsx")
);
// Late Cancel Summary Page
const CancelPurchaseSummary = withAsync(() =>
	import("../views/Cancel/CancelPurchaseSummary.jsx")
);
// ClassPacksPurchaseSuccess, redirect unauthenticated user to classses
const ClassPacksPurchaseSuccess = withAsync(
	() =>
		import(
			"../views/Classes/ClassPacksPurchaseSuccess/ClassPacksPurchaseSuccess.jsx"
		),
	true,
	"classes"
);
const ClassPackRollOverSuccess = withAsync(
	() =>
		import(
			"../views/Classes/ClassPacksPurchaseSuccess/RollOverSuccess.jsx"
		),
	true,
	"classes_roll_over"
);

// ClassPacksPurchaseSuccess, redirect unauthenticated user to classses
const MyClasses = withAsync(
	() => import("../views/Classes/MyClasses/MyClasses.jsx"),
	true,
	"classes"
);
const PrivateClassesForm = withAsync(() =>
	import("../views/Classes/PrivateClasses/ContactUs/ContactForm.jsx")
);
const PrivateClasses = withAsync(() =>
	import("../views/Classes/PrivateClasses/PrivateClasses.jsx")
);

//Book a Mat section
const SchedulesRedirect = withAsync(() =>
	import("../views/Schedules/SchedulesRedirect/SchedulesRedirect.jsx")
);
const SchedulesListing = withAsync(() =>
	import("../views/Schedules/SchedulesListing/SchedulesListing.jsx")
);
const SchedulePurchaseConfirmed = withAsync(
	() =>
		import(
			"../views/Schedules/SchedulesPurchaseConfirmed/SchedulesPurchaseConfirmed.jsx"
		),
	true,
	"schedules"
);

const MustRead = withAsync(() => import("../views/MustRead/MustRead.jsx"));

//account section
const Account = withAsync(
	() => import("../views/Account/Account.jsx"),
	true,
	"/account/authenticate"
);

const GiftsClassPacks = withAsync(() =>
	import("../views/Classes/ClassPacks/GiftClassPacks.jsx")
);

const GiftsClassPacksAndBook = withAsync(() =>
	import("../views/Classes/ClassPacks/GiftClassPacksAndBook.jsx")
);

const BuyShareClassPacksAndBook = withAsync(() =>
	import("../views/Classes/ClassPacks/BuyShareClassPacks.jsx")
);

const MyClassesList = withAsync(
	() => import("../views/Account/MyClasses/MyClassesList/MyClassesList.jsx"),
	true
);
const MyClassesDetail = withAsync(
	() =>
		import(
			"../views/Classes/MyClasses/MyClassesDetail/MyClassesDetail.jsx"
		),
	true
);
// const MyClassPacks = withAsync(
// 	() => import("../views/Account/MyClassPacks/MyClassPacks.jsx"),
// 	true
// );
const MyClassPacks = withAsync(
	() => import("../views/Classes/MyClassPacks/MyClassPacks.jsx"),
	true
);
const YMFriends = withAsync(
	() => import("../views/Account/YMFriends/YMFriendsList/YMFriendsList.jsx"),
	true
);
const YMFamPerks = withAsync(
	() => import("../views/Account/YMFamPerks/YMFamPerks.jsx"),
	true
);
const AddNewFriend = withAsync(
	() => import("../views/Account/YMFriends/AddNewFriend/AddNewFriend.jsx"),
	true
);
const YMFriendsSetting = withAsync(
	() =>
		import(
			"../views/Account/YMFriends/YMFriendsSetting/YMFriendsSetting.jsx"
		),
	true
);
const EditProfile = withAsync(
	() => import("../views/Account/EditProfile/EditProfile.jsx"),
	true
);
const PaymentSettings = withAsync(
	() => import("../views/Account/PaymentSettings/PaymentSettings.jsx"),
	true
);

const Authenticate = withAsync(() =>
	import("../views/Authenticate/Authenticate.jsx")
);

const Achievements = withAsync(
	() => import("../views/Account/Achievements/Achievements.jsx"),
	true
);

const Favourites = withAsync(
	() => import("../views/Account/Favourites/Favourites.jsx"),
	true
);
const AllAccessPass = withAsync(
	() => import("../views/Account/AllAccessPass/AllAccessPass.jsx"),
	true
);
const Notifications = withAsync(() =>
	import("../views/Account/Notifications/Notifications.jsx")
);

const TransactionHistory = withAsync(() =>
	import("../views/Account/TransactionHistory/TransactionHistory.jsx")
);

const Activities = withAsync(() =>
	import("../views/Account/Activities/Activities.jsx")
);

const Referral = withAsync(() =>
	import("../views/Account/Referral/Referral.jsx")
);

const DeleteAccount = withAsync(() =>
	import("../views/Account/DeleteAccount/DeleteAccount.jsx")
);

// academy
const Academy = withAsync(() => import("../views/Academy/Academy.jsx"));

const AcademyClasses = withAsync(() =>
	import("../views/Academy/AcademyClasses/AcademyClasses.jsx")
);

const AcademyBookPreview = withAsync(() =>
	import("../views/Academy/AcademyBookPreview/AcademyBookPreview.jsx")
);

const AcademyBookConfirmed = withAsync(() =>
	import("../views/Academy/AcademyBookConfirmed/AcademyBookConfirmed.jsx")
);

// AdhocPayments
const AdhocPayments = withAsync(() =>
	import("../views/Account/AdhocPayments/AdhocPayments/AdhocPayments.jsx")
);

const AdhocPaymentsPreview = withAsync(() =>
	import(
		"../views/Account/AdhocPayments/AdhocPaymentsPreview/AdhocPaymentsPreview.jsx"
	)
);

// AdhocPaymentsSuccess, redirect unauthenticated user to home
const AdhocPaymentsSuccess = withAsync(() =>
	import(
		"../views/Account/AdhocPayments/AdhocPaymentsSuccess/AdhocPaymentsSuccess.jsx"
	)
);
// Careers Details
const CareersDetails = withAsync(() =>
	import("../views/Careers/CareersDetails.jsx")
);
// Careers Page
const Careers = withAsync(() => import("../views/Careers"));
// YMNewbie
const NewToYM = withAsync(() => import("../views/YMNewbie/YMNewbie.jsx"));

// Own A YM

const OwnAYM = withAsync(() => import("../views/OwnAYM/OwnAYM.jsx"));
// contact us
const ContactUs = withAsync(() => import("../views/ContactUs/ContactUs.jsx"));

// faq
const Faq = withAsync(() => import("../views/Faq/Faq.jsx"));

// the team
const OurTeam = withAsync(() => import("../views/OurTeam/OurTeam.jsx"));
const TeacherDetails = withAsync(() =>
	import("../views/OurTeam/TeacherDetails/TeacherDetails.jsx")
);

//misc
const TermsOfServices = withAsync(() =>
	import("../views/TermsOfServices/TermsOfServices.jsx")
);
const PrivacyPolicy = withAsync(() =>
	import("../views/PrivacyPolicy/PrivacyPolicy.jsx")
);
const Indemnity = withAsync(() => import("../views/Indemnity/Indemnity.jsx"));

//not found
const NotFound = withAsync(() => import("../views/NotFound/NotFound.jsx"));

// maintenance
const Maintenance = withAsync(() =>
	import("../views/Maintenance/Maintenance.jsx")
);

// Teacher Training
const TeacherTraining = withAsync(() =>
	import("../views/TeacherTraining/TeacherTraining.jsx")
);

const Routes = withRouter(({ location }) => {
	let pathsArray = location.pathname.split("/").filter((p) => p !== "");
	let path = location.pathname;

	//filter through paths to disable transition animation when necessary
	switch (pathsArray[0]) {
		case "the-team":
			path = "the-team";
			break;
	}

	return (
		<TransitionGroup>
			<CSSTransition
				key={path}
				timeout={300}
				classNames="transition__crossfade"
			>
				<div className="transition__holder">
					<Switch location={location}>
						<Route exact path="/" component={Home} key="home" />
						<Route
							exact
							path="/r/:code"
							component={Home}
							key="ref"
						/>
						<Route
							path="/blog/:year/:slug"
							component={YMLifeDetails}
							key="ym-life-details"
						/>
						<Route path="/blog" component={YMLife} key="ym-life" />
						<Route
							path="/the-team"
							component={OurTeam}
							key="team"
						/>
						<Route
							path="/studios/:slug"
							component={StudiosDetail}
							key="studios-detail"
						/>

						<Route
							path="/studios"
							component={Studios}
							key="studios"
						/>
						<Route
							path="/cancel-summary"
							component={CancelPurchaseSummary}
							key="cancel-summary"
						/>
						<Route
							path="/register"
							component={Register}
							key="register"
						/>
						<Route
							path="/classes/authenticate"
							component={Authenticate}
							key="classes-authenticate"
						/>
						<Route
							path="/classes/*"
							component={ClassesRoutes}
							key="classes-sub"
						/>
						<Route
							path="/classes"
							component={ClassesRoutes}
							key="classes-single"
						/>
						<Route
							path="/merchandise/:id"
							component={MerchandiseDetail}
							key="merchandise-detail"
						/>
						<Route
							path="/merchandise"
							component={Merchandise}
							key="merchandise"
						/>

						<Route
							path="/schedules"
							component={SchedulesRedirect}
							key="schedules-redirect"
						/>
						<Route
							path="/schedules/*"
							component={SchedulesRedirect}
							key="schedules-redirect-sub"
						/>

						<Route
							path="/schedule/authenticate"
							component={Authenticate}
							key="schedules-authenticate"
						/>
						<Route
							path="/schedule/*"
							component={SchedulesRoutes}
							key="schedules-sub"
						/>
						<Route
							path="/schedule"
							component={SchedulesRoutes}
							key="schedules"
						/>
						<Route
							path="/account/authenticate"
							component={Authenticate}
							key="account-authenticate"
						/>
						<Route
							path="/account/:section"
							component={Account}
							key="account-section"
						/>
						<Route
							path="/account"
							component={Account}
							key="account"
						/>

						<Route
							path="/teacher-training/authenticate"
							component={Authenticate}
							key="teacher-training-authenticate"
						/>

						<Route
							path="/own-a-ym"
							component={OwnAYM}
							key="own-a-ym"
						/>
						<Route
							path="/careers/:id"
							component={CareersDetails}
							key="careersDetail"
						/>
						<Route
							path="/careers"
							component={Careers}
							key="careers"
						/>

						<Route
							path="/own-a-ym"
							component={OwnAYM}
							key="own-a-ym"
						/>
						<Route
							path="/teacher-training/*"
							component={TeacherTrainingRoutes}
							key="teacher-training-sub"
						/>
						<Route
							path="/teacher-training"
							component={TeacherTraining}
							key="teacher-training"
						/>

						<Route
							path="/newbies"
							component={NewToYM}
							key="new-to-ym"
						/>

						<Route
							path="/new-to-ym"
							component={NewToYM}
							key="new-to-ym"
						/>

						<Route
							path="/contact-us"
							component={ContactUs}
							key="contact-us"
						/>

						<Route
							path="/contact-us"
							component={ContactUs}
							key="contact-us"
						/>
						<Route path="/faq" component={Faq} key="faq" />
						<Route
							path="/the-team/authenticate"
							component={Authenticate}
							key="the-team-authenticate"
						/>

						<Route
							path="/must-read"
							component={MustRead}
							key="must-read"
						/>

						<Route
							path="/terms-of-services"
							component={TermsOfServices}
							key="terms-of-services"
						/>
						<Route
							path="/privacy-policy"
							component={PrivacyPolicy}
							key="privacy-policy"
						/>
						<Route
							path="/indemnity"
							component={Indemnity}
							key="indemnity"
						/>

						<Route
							path="/maintenance"
							component={Maintenance}
							key="maintenance"
						/>

						{process.env.NODE_ENV != "production" && (
							<Route
								path="/sample"
								component={Sample}
								key="sample"
							/>
						)}

						<Route path="*" component={NotFound} />
					</Switch>
				</div>
			</CSSTransition>
		</TransitionGroup>
	);
});
export default Routes;

export const RegisterRoutes = withRouter(({ location }) => {
	return (
		<TransitionGroup>
			<CSSTransition
				key={location}
				timeout={300}
				classNames="transition__crossfade"
			>
				<div className="transition__holder">
					<Switch location={location}>
						<Route
							path="/register/about-you"
							component={AboutYou}
							key="about-you"
						/>
						<Route
							path="/register/verify-otp"
							component={ConfirmOtp}
							key="verify-otp"
						/>
						<Route
							path="/register/terms-and-conditions"
							component={Terms}
							key="terms"
						/>
					</Switch>
				</div>
			</CSSTransition>
		</TransitionGroup>
	);
});

export const AccountRoutes = withRouter(({ location }) => {
	return (
		<TransitionGroup>
			<CSSTransition
				key={location}
				timeout={300}
				classNames="transition__crossfade"
			>
				<div className="transition__holder">
					<Switch location={location}>
						<Route
							path="/account/edit"
							component={EditProfile}
							key="account/edit"
						/>
						<Route
							path="/account/achievements"
							component={Achievements}
							key="account/achievements"
						/>
						<Route
							path="/account/all-access-pass"
							component={AllAccessPass}
							key="account/all-access-pass"
						/>
						<Route
							path="/account/favourites"
							component={Favourites}
							key="account/favourites"
						/>
						<Route
							path="/account/payment-settings"
							component={PaymentSettings}
							key="account/payment-settings"
						/>
						<Route
							path="/account/class-packs"
							component={MyClassPacks}
							key="account/class-packs"
						/>
						<Route
							path="/account/notifications"
							component={Notifications}
							key="account/notifications"
						/>
						<Route
							path="/account/transaction-history"
							component={TransactionHistory}
							key="account/transaction-history"
						/>
						<Route
							path="/account/activities-log"
							component={Activities}
							key="account/activities-log"
						/>
						<Route
							path="/account/referral"
							component={Referral}
							key="account/get-free-class"
						/>
						<Route
							path="/account/friends/add-new"
							component={AddNewFriend}
							key="account/friends/add-new"
						/>
						<Route
							path="/account/friends/setting"
							component={YMFriendsSetting}
							key="account/friends/setting"
						/>
						<Route
							path="/account/ym-fam-perks"
							component={YMFamPerks}
							key="account/ym-fam-perks"
						/>
						<Route
							path="/account/friends"
							component={YMFriends}
							key="account/friends"
						/>
						<Route
							path="/account/delete-account"
							component={DeleteAccount}
							key="account/delete-account"
						/>
						<Route
							path="/account/adhoc-payments/adhoc-payments-preview"
							component={AdhocPaymentsPreview}
							key="adhoc-payments-preview"
						/>
						<Route
							path="/account/adhoc-payments/adhoc-payments-success"
							component={AdhocPaymentsSuccess}
							key="adhoc-payments-success"
						/>
						<Route
							path="/account/adhoc-payments"
							component={AdhocPayments}
							key="account/adhoc-payments"
						/>
						<Redirect to="/account/notifications" />
					</Switch>
				</div>
			</CSSTransition>
		</TransitionGroup>
	);
});

export const ClassesRoutes = withRouter(({ location }) => {
	return (
		<TransitionGroup>
			<CSSTransition
				key={location}
				timeout={300}
				classNames="transition__crossfade"
			>
				<div className="transition__holder">
					<Switch location={location}>
						<Route
							path="/classes/my-classes/:id"
							component={MyClassesDetail}
							key="classes/my-classes/:id"
						/>
						<Route
							path="/classes/my-classes"
							component={MyClasses}
							key="classes/my-classes"
						/>
						<Route
							path="/classes/gift-class-packs"
							component={GiftsClassPacks}
							key="classes/gift-class-packs"
						/>
						<Route
							path="/classes/buy-share-pack"
							component={BuyShareClassPacksAndBook}
							key="classes/buy-share-pack"
						/>
						<Route
							path="/classes/gift-and-book-purchase-success"
							component={GiftBookPackAndPurchaseSuccess}
							key="classes/gift-and-book-purchase-success"
						/>
						<Route
							path="/classes/gift-class-packs-and-book"
							component={GiftsClassPacksAndBook}
							key="classes/gift-class-packs-and-book"
						/>
						{/* GiftBookPackAndPurchaseSuccess  */}
						<Route
							path="/classes/my-class-packs"
							component={MyClassPacks}
							key="classes/my-class-packs"
						/>
						<Route
							path="/classes/class-types"
							component={ClassTypes}
							key="classes/class-types"
						/>
						<Route
							path="/classes/private-classes-form"
							component={PrivateClassesForm}
							key="classes/private-classes-form"
						/>
						<Route
							path="/classes/private-classes"
							component={PrivateClasses}
							key="classes/private-classes"
						/>
						<Route
							path="/classes/purchase-preview"
							component={ClassPacksPurchasePreview}
							key="classes/purchase-preview"
						/>
						{/* ClassPacksPurchaseBookPreview  */}
						<Route
							path="/classes/purchase-book-preview"
							component={ClassPacksPurchaseBookPreview}
							key="classes/purchase-preview"
						/>
						<Route
							path="/classes/purchase-summary"
							component={ClassPacksPurchaseSummary}
							key="classes/purchase-summary"
						/>
						<Route
							path="/classes/gift-purchase-summary"
							component={GiftClassPacksPurchaseSummary}
							key="classes/gift-purchase-summary"
						/>
						<Route
							path="/classes/gift-purchase-book-summary"
							component={GiftClassPacksBookPurchaseSummary}
							key="classes/gift-purchase-book-summary"
						/>

						<Route
							path="/classes/gift-free-summary"
							component={GiftFreePurchaseSummary}
							key="classes/gift-free-summary"
						/>
						<Route
							path="/classes/purchase-success"
							component={ClassPacksPurchaseSuccess}
							key="classes/purchase-success"
						/>
						<Route
							path="/classes/gift-class-purchase-success"
							component={GiftClassPurchaseSuccess}
							key="classes/gift-class-purchase-success"
						/>

						<Route
							path="/classes/roll-over-success"
							component={ClassPackRollOverSuccess}
							key="classes/roll-over-success"
						/>
						<Route
							path="/classes"
							component={ClassPacks}
							key="classes"
						/>
					</Switch>
				</div>
			</CSSTransition>
		</TransitionGroup>
	);
});

export const SchedulesRoutes = withRouter(({ location }) => {
	return (
		<TransitionGroup>
			<CSSTransition
				key={location}
				timeout={300}
				classNames="transition__crossfade"
			>
				<div className="transition__holder">
					<Switch location={location}>
						<Route
							path="/schedule/purchase-success"
							component={SchedulePurchaseConfirmed}
							key="schedules-purchase-success"
						/>
						<Route
							path="/schedule"
							component={SchedulesListing}
							key="schedules"
						/>
					</Switch>
				</div>
			</CSSTransition>
		</TransitionGroup>
	);
});

export const TeacherDetailRoute = withRouter(({ location }) => {
	return (
		<Switch location={location}>
			<Route
				path="/the-team/:slug"
				component={TeacherDetails}
				key="teachers-detail"
			/>
		</Switch>
	);
});

export const TeacherTrainingRoutes = withRouter(({ location }) => {
	return (
		<TransitionGroup>
			<CSSTransition
				key={location}
				timeout={300}
				classNames="transition__crossfade"
			>
				<div className="transition__holder">
					<Switch location={location}>
						<Route
							path="/teacher-training/booking-confirmed"
							component={AcademyBookConfirmed}
							key="teacher-training-booking-confirmed"
						/>
						<Route
							path="/teacher-training/booking-preview"
							component={AcademyBookPreview}
							key="teacher-training-booking-preview"
						/>
						<Route
							path="/teacher-training/:categoryAlias"
							component={AcademyClasses}
							key="teacher-training-classes"
						/>
						<Route
							path="/teacher-training"
							component={TeacherTraining}
							key="teacher-training"
						/>
						{/* <Route
							path="/academy"
							component={Academy}
							key="academy"
						/> */}
					</Switch>
				</div>
			</CSSTransition>
		</TransitionGroup>
	);
});

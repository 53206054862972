import React from "react";

function RadioUnCheckIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="21"
			fill="none"
			viewBox="0 0 20 21"
		>
			<circle cx="10" cy="10.5" r="9.5" stroke="#8AB0AB"></circle>
		</svg>
	);
}

export default RadioUnCheckIcon;

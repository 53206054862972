import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-timezone";
import {
	getScheduleTimeWithDuration,
	useIsMobile,
} from "../../../base/functions";

import "./SchedulesListRow.scss";

const propTypes = {
	/** @type {string} 		className prop for schedulesListRow */
	className: PropTypes.string,
	/** @type {object} 		schedule object for the columns  */
	schedule: PropTypes.object,
	/** @type {array} 		additional columns other than (date|timing|class|location|instructor)  */
	additionalCols: PropTypes.array,
	/** @type {function}	onClick event for book button */
	onClick: PropTypes.func,
};
const defaultProps = {
	className: "",
	schedule: {},
	additionalCols: [],
	onClick: () => {},
};

/**
 * schedules list entry
 * table layout in desktop
 */
export default function SchedulesListRow({
	className,
	schedule,
	additionalCols,
	onClick,
	onClickName,
	onClickLocation,
	onClickTeacher,
}) {
	const isMobile = useIsMobile(1200);

	if (isMobile) {
		return (
			<div   onClick={() => onClick && onClick()} className={"schedulesListRow__mobile animated " + className}>
				<div className="schedulesListRow__mobile__info">
					<div
						className="schedulesListRow__mobile__title"
						onClick={() => onClickName()}
					>
						{schedule.class_name}
					</div>
					<div className="schedulesListRow__mobile__time">
						<div
							className="schedulesListRow__mobile__icon"
							style={{
								backgroundImage: `url(img/icons/calendar-2.svg)`,
							}}
						/>
						<div className="schedulesListRow__mobile__description">
							{getScheduleTimeWithDuration({
								startTime: schedule.start_time,
								endTime: schedule.end_time,
								className: "",
							})}
						</div>
					</div>
					<div className="schedulesListRow__mobile__place-instructor">
						<div
							className="schedulesListRow__mobile__place"
							onClick={() => onClickLocation()}
						>
							<div
								className="schedulesListRow__mobile__icon"
								style={{
									backgroundImage: `url(img/icons/location-2.svg)`,
								}}
							/>
							<div className="schedulesListRow__mobile__description">
								{schedule.studio_name}
							</div>
						</div>
						<div
							className="schedulesListRow__mobile__instructor"
							onClick={() => onClickTeacher()}
						>
							<div
								className="schedulesListRow__mobile__icon"
								style={{
									backgroundImage: `url(img/icons/instructor-2.svg)`,
								}}
							/>
							<div className="schedulesListRow__mobile__description">
								{schedule.instructor_name}
							</div>
						</div>
					</div>
				</div>
				<div
					className="schedulesListRow__mobile__actions"
					onClick={() => {
						onClick();
					}}
				>
					{additionalCols &&
						additionalCols.map((column, i) => (
							<div
								className="schedulesListRow__col animated"
								key={i}
							>
								{column}
							</div>
						))}
				</div>
			</div>
		);
	} else {
		return (
			<div

		   	    onClick={() => onClick && onClick()}
				className={"schedulesListRow animated " + className}
				style={{
					background: `url(${schedule.triangle_img}) no-repeat #FFF top right / 95px auto`,
				}}
			>
				<time className="schedulesListRow__col schedulesListRow__col-date animated">
					{schedule.study_date &&
						moment(schedule.study_date)
							.utcOffset(schedule.study_date)
							.format("D MMM YYYY")}
				</time>
				<div className="schedulesListRow__col schedulesListRow__col-time animated">
					<div className="schedulesListRow__time">
						{getScheduleTimeWithDuration({
							startTime: schedule.start_time,
							endTime: schedule.end_time,
							className: "schedulesListRow__time",
						})}
					</div>
				</div>
				<div
					onClick={() => onClickName()}
					className="schedulesListRow__col schedulesListRow__col-class text-uppercase animated"
				>
					{schedule.class_name}
				</div>
				<div
					onClick={() => onClickLocation()}
					className="schedulesListRow__col schedulesListRow__col-location animated"
				>
					{schedule.studio_name}
				</div>
				<div className="schedulesListRow__col schedulesListRow__col-room animated">
					{schedule.Room_Name ? schedule.Room_Name : "-"}
				</div>
				<div
					onClick={() => onClickTeacher()}
					className="schedulesListRow__col schedulesListRow__col-teacher animated"
				>
					{schedule.instructor_name}
				</div>
				{additionalCols &&
					additionalCols.map((column, i) => (
						<div
							className="schedulesListRow__col animated"
							onClick={() => onClick()}
							key={i}
						>
							{column}
						</div>
					))}
			</div>
		);
	}
}
SchedulesListRow.propTypes = propTypes;
SchedulesListRow.defaultProps = defaultProps;

{
	/* <div
	className={"schedulesListRow animated " + className}
	style={{
		background: `url(${schedule.triangle_img}) no-repeat #FFF top right / 95px auto`,
	}}
	onClick={() => onClick()}
>
	<time className="schedulesListRow__col schedulesListRow__col-date animated">
		{moment(schedule.study_date).tz("Asia/Singapore").format("DD MMM YYYY")}
	</time>
	<div className="schedulesListRow__col schedulesListRow__col-time animated">
		<div className="schedulesListRow__timing">
			<time className="schedulesListRow__time">
				{moment(schedule.start_time, "HH:mm:ss").format("hh:mmA")}
			</time>
			{" to "}
			<time className="schedulesListRow__time">
				{moment(schedule.end_time, "HH:mm:ss").format("hh:mmA")}
			</time>
			{" ("}
			{moment
				.duration(
					moment(schedule.end_time, "HH:mm:ss").diff(
						moment(schedule.start_time, "HH:mm:ss")
					)
				)
				.asMinutes()}
			{"mins)"}
		</div>
	</div>
	<div className="schedulesListRow__col schedulesListRow__col-class text-uppercase animated">
		{schedule.class_name}
	</div>
	<div className="schedulesListRow__col schedulesListRow__col-location animated">
		{schedule.studio_name}
	</div>
	<div className="schedulesListRow__col schedulesListRow__col-teacher animated">
		{schedule.instructor_name}
	</div>
	{additionalCols &&
		additionalCols.map((column, i) => (
			<div className="schedulesListRow__col animated" key={i}>
				{column}
			</div>
		))}
</div>; */
}

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
	login,
	resetAuthentication,
	facebookAuthenticate,
	setEmail,
	checkLogin,
	setAuthenticationCallback,
} from "../../../../redux/authentication";
import { showAuthentication } from "../../../../redux/ui";
import { locationCheckPopup } from "../../../../redux/popup";
import { resetProfile } from "../../../../redux/profile";

import Button from "../../../Button/Button.jsx";
import SuccessMessage from "../../../Alerts/SuccessMessage/SuccessMessage.jsx";
import { SocialSignIn } from "../../SocialSignIn/SocialSignIn.jsx";
import ErrorMessage from "../../../Alerts/ErrorMessage/ErrorMessage.jsx";
import "./LoginForm.scss";

const authSchema = Yup.object().shape({
	email: Yup.string()
		.email("Invalid email address")
		.required("Email address is required"),
	password: Yup.string().required("Password is required"),
});

class LoginModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			busy: false,
			showPassword: false,
			showClose: false,
			credentials: {},
		};
	}

	componentDidMount() {
		if (this.email) this.email.focus();
		else if (this.password) this.password.focus();
	}

	componentDidUpdate(prevProps) {
		const prevAuthProps = prevProps.authentication;
		const { authentication } = this.props;

		if (prevAuthProps.loginError !== authentication.loginError) {
			this.setState({
				busy: false,
				error: authentication.loginError,
			});
		}

		if (prevAuthProps.status != authentication.status) {
			switch (authentication.status) {
				case "CHECK_ACCOUNT_STATUS_SUCCESS":
					this.setState({ busy: false });
					break;
				case "CHECK_ACCOUNT_STATUS_ERROR":
					//only run if form is busy to ensure it doesnt show register errors in authenticate screen
					if (this.state.busy) {
						if (authentication.accountStatus == 406) {
							//error 406 can also mean user has not completed the 3-step registration
							//checkLogin to determine user's registration status
							this.props.checkLogin(
								this.state.email.value,
								this.state.password
							);
						} else if (authentication.accountStatus == 416) {
							//for migration users, just disable busy state and dont show error message
							this.setState({
								busy: false,
							});
						} else {
							//otherwise show error message
							this.setState({
								busy: false,
								error: authentication.loginError,
							});
						}
					}
					break;
				case "LOGIN_ERROR":
				case "CHECK_LOGIN_ERROR":
				case "FACEBOOK_AUTHENTICATE_ERROR":
					this.setState({
						busy: false,
						error: authentication.loginError,
					});
					break;
				case "FACEBOOK_AUTHENTICATE":
					this.setState({ busy: true });
					break;
			}
		}
	}

	/**
	 * click callback for "Not me" button, for returning users
	 * clears all data in profile / authentication store
	 * will show email field as a result
	 */
	handleClickNotMe() {
		this.props.resetProfile();
		this.props.resetAuthentication();
	}

	/**
	 * onSubmit event handler for <Form /> component
	 * @param  {object} 	e   	submit event object
	 * @param  {boolean} 	err 	whether there are errors
	 */
	handleSubmit(values) {
		const { authentication, profile } = this.props;

		this.setState({ busy: true });

		if (
			!authentication.callback &&
			((this.props.location.pathname != "/" &&
				this.props.location.pathname.match(/authenticate$/) == null) ||
				this.props.location.search)
		) {
			this.props.setAuthenticationCallback(() =>
				this.props.history.push(
					this.props.location.pathname + this.props.location.search
				)
			);
		}

		this.props.setEmail(values.email);
		this.props.login(values.email, values.password, "password", (data) => {
			const _check = this.props?.masterData?.data?.countries?.filter(
				(data) =>
					data?.country_name ===
					this.props?.masterData?.data?.dedect_country
			);
			if (
				this.props?.masterData?.data?.dedect_country !=
					this.props?.profile?.country_name &&
				_check?.length >= 1
			) {
				return this.props.showLocationPopup();
			}
		});

		this.setState({
			credentials: {
				email: values.email,
				password: values.password,
			},
		});
	}

	/**
	 * FacebookProvider <Login /> onResponse event handler
	 * @param  {object} res  response object
	 */
	handleFacebookSuccess(res) {
		this.props.facebookAuthenticate(res.tokenDetail.accessToken);
	}

	/**
	 * FacebookProvider <Login /> onError event handler
	 * @param  {object} res  response object
	 */
	handleFacebookFail(err) {
		this.setState({ busy: false });
	}

	handleClear() {
		// this.email.value = "";
		this.setState({
			showClose: false,
		});
	}

	render() {
		const { authentication, profile } = this.props;

		let hasSavedProfile =
			profile.profile &&
			profile.profile.mem_type === "email" &&
			profile.lastEmail !== null;
		let getProfile = profile.profile || {};

		return (
			<div className="loginForm">
				<Formik
					initialValues={{
						email:
							profile.profile &&
							profile.profile.mem_type === "email" &&
							profile.lastEmail
								? profile.lastEmail
								: "",
						password: "",
					}}
					validationSchema={authSchema}
					onSubmit={(values) => this.handleSubmit(values)}
				>
					{({
						values,
						errors,
						touched,
						isValid,
						handleBlur,
						handleChange,
						handleSubmit,
						dirty,
						setFieldValue,
						setErrors,
					}) => (
						<Form
							className="form--check-disable"
							noValidate={true}
							onBlur={handleBlur}
							onSubmit={handleSubmit}
							autoComplete={"off"}
						>
							{this.state.error && (
								<ErrorMessage className="loginForm__message">
									{this.state.error}
								</ErrorMessage>
							)}
							{authentication.status ==
								"RESET_PASSWORD_SUCCESS" && (
								<SuccessMessage
									className="loginForm__message"
									title="Your password has been reset!"
								>
									You may now try to log in your account with
									your new password.
								</SuccessMessage>
							)}
							{authentication.status ==
								"CREATE_PASSWORD_SUCCESS" && (
								<SuccessMessage
									className="loginForm__message"
									title="Your new password has been set!"
								>
									You may now try to log in your account with
									your new password.
								</SuccessMessage>
							)}
							{!hasSavedProfile && (
								<h3 className="loginForm__title auth__title">
									SIGN IN
								</h3>
							)}
							{hasSavedProfile ? (
								[1].map((foo) => {
									return (
										<div
											className="loginForm__user d-flex align-items-center justify-content-between"
											key="user"
										>
											<div className="loginForm__user-details">
												<div>
													Signed in as{" "}
													{getProfile.first_name}
												</div>
											</div>
											<button
												type="button"
												className="loginForm__user-change click-efx pr-0"
												onClick={() => {
													this.handleClickNotMe();
													setFieldValue("email", "");
												}}
											>
												Not me
											</button>
										</div>
									);
								})
							) : (
								<label className="form__group d-block">
									<div className="form__label text-uppercase">
										Email Address
									</div>
									<div className="position-relative">
										<input
											type="email"
											className="form__field"
											onChange={(e) => {
												this.setState({
													showClose:
														e.target.value.length >
														0,
												});
												handleChange(e);
											}}
											onBlur={handleBlur}
											value={values.email}
											name="email"
										/>
										{errors.email && touched.email && (
											<div className="form__field--error">
												{errors.email}
											</div>
										)}

										{this.state.showClose && (
											<img
												src="/assets/img/icons/input-close.svg"
												className="input__close"
												onClick={() => {
													this.handleClear();
													setFieldValue("email", "");
													setErrors({
														email: "",
														password: "",
													});
												}}
											/>
										)}
									</div>
								</label>
							)}
							<Fragment>
								<label className="form__group d-block">
									<div className="form__label text-uppercase">
										Password
									</div>
									<div className="loginForm__password-wrapper auth__password-wrapper position-relative">
										<input
											type={
												!this.state.showPassword
													? "password"
													: "text"
											}
											className="form__field"
											onChange={(e) => {
												handleChange(e);
											}}
											onBlur={handleBlur}
											value={values.password}
											name="password"
										/>
										{errors.password &&
											touched.password && (
												<div className="form__field--error">
													{errors.password}
												</div>
											)}

										<button
											type="button"
											className="loginForm__password-toggle auth__password-toggle loginForm__link click-efx"
											onClick={() =>
												this.setState({
													showPassword:
														!this.state
															.showPassword,
												})
											}
										>
											{!this.state.showPassword
												? "Show"
												: "Hide"}
										</button>
									</div>
								</label>
								<div className="d-flex align-items-center justify-content-between loginForm__submit-wrapper">
									<div
										className="loginForm__forgot auth__link d-inline-block animated click-efx loginForm__link"
										onClick={() =>
											this.props.showAuthentication(
												"forgot-password"
											)
										}
									>
										Forgot password?
									</div>
									<Button
										className="loginForm__btn auth__btn"
										type="submit"
										disabled={
											!(isValid && dirty) ||
											this.props.authentication.status ===
												"LOGIN"
										}
									>
										{this.props.authentication.status ===
										"LOGIN"
											? "Loading..."
											: "Sign in"}
									</Button>
								</div>
							</Fragment>
						</Form>
					)}
				</Formik>
				{getProfile.mem_type != "facebook" && (
					<div className="loginForm__or auth__or text-uppercase">
						or
					</div>
				)}

				<SocialSignIn />
			</div>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
		profile: store.profile,
		masterData: store.masterData,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		login: bindActionCreators(login, dispatch),
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
		resetAuthentication: bindActionCreators(resetAuthentication, dispatch),
		resetProfile: bindActionCreators(resetProfile, dispatch),
		facebookAuthenticate: bindActionCreators(
			facebookAuthenticate,
			dispatch
		),
		showLocationPopup: bindActionCreators(locationCheckPopup, dispatch),
		setEmail: bindActionCreators(setEmail, dispatch),
		checkLogin: bindActionCreators(checkLogin, dispatch),
		setAuthenticationCallback: bindActionCreators(
			setAuthenticationCallback,
			dispatch
		),
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LoginModal)
);

import axios from "axios";
import immer from "immer";
import _ from "lodash";

import { memberEndpointURL } from "../base/config";
import { end, getErrorMessage } from "../base/functions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * fetch api to get notifications
 * @params {number} pageIndex  	        current page number
 * @params {boolean} showInitialList    whether to render the initial list
 * @params {boolean} hasLoader          whether to render loader while fetching notifications
 *                                 		(e.g. "Read All" needs to fetch notifications but shouldn't trigger the loader)
 */
export const getNotifications =
	(pageIndex, showInitialList = true, hasLoader = true) =>
	(dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		hasLoader
			? dispatch({ type: "GET_NOTIFICATIONS" })
			: dispatch({ type: "GET_NOTIFICATIONS_SILENTLY" });

		return axios
			.get(memberEndpointURL + "GetNotifications", {
				params: {
					page_index: pageIndex,
					page_size: 10,
				},
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
				},
			})
			.then((res) =>
				dispatch({
					type: "GET_NOTIFICATIONS_RESOLVE",
					res: res.data,
					showInitialList,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_NOTIFICATIONS_ERROR",
					error: err,
				})
			);
	};

/**
 * call api to set notification as read
 * @params {string} notificationId    id of notification that was clicked / selected
 */
export const setNotificationAsRead =
	(notificationId) => (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		dispatch({ type: "SET_NOTIFICATION_AS_READ" });

		return axios
			.post(
				memberEndpointURL + "SetNotificationAsRead",
				{ notification_id: notificationId },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "SET_NOTIFICATION_AS_READ_RESOLVE",
					res: res.data,
					notificationId,
				})
			)
			.catch((err) =>
				dispatch({
					type: "SET_NOTIFICATION_AS_READ_ERROR",
					error: err,
				})
			);
	};

/**
 * call api to set ALL notification as read
 */
export const setAllNotificationAsRead = () => (dispatch, getState) => {
	let store = getState();
	let accessToken = store.authentication.accessToken;

	dispatch({ type: "SET_ALL_NOTIFICATION_AS_READ" });

	return axios
		.post(
			memberEndpointURL + "SetAllNotificationAsRead",
			{},
			{
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
				},
			}
		)
		.then((res) =>
			dispatch({
				type: "SET_ALL_NOTIFICATION_AS_READ_RESOLVE",
				res: res.data,
			})
		)
		.catch((err) =>
			dispatch({
				type: "SET_ALL_NOTIFICATION_AS_READ_ERROR",
				error: err,
			})
		);
};

/**
 * revert notification in store to initial state
 * called when notification unmounts
 */
export const resetNotifications = () => ({ type: "RESET_NOTIFICATIONS" });

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const currentState = {
	status: null,
	error: null,
	list: [],
	totalPages: null,
};

export function notifications(state = currentState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			// get notifications list
			case "GET_NOTIFICATIONS":
			case "GET_NOTIFICATIONS_SILENTLY":
				draft.status = action.type;
				break;

			case "GET_NOTIFICATIONS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_NOTIFICATIONS_SUCCESS";
					draft.totalPages = action.res.data.total_page;

					if (action.showInitialList) {
						draft.list = action.res.data.notification_list;
					} else {
						draft.list = draft.list.concat(
							action.res.data.notification_list
						);
					}
				} else {
					draft.status = "GET_NOTIFICATIONS_ERROR";
				}
				break;

			case "GET_NOTIFICATIONS_ERROR":
				draft.status = action.type;
				break;

			// set notifications as read
			case "SET_NOTIFICATION_AS_READ":
				draft.status = action.type;
				break;

			case "SET_NOTIFICATION_AS_READ_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "SET_NOTIFICATION_AS_READ_SUCCESS";

					draft.list = draft.list.map((notification) =>
						notification.notification_id === action.notificationId
							? { ...notification, is_read: true }
							: notification
					);
				} else {
					draft.status = "SET_NOTIFICATION_AS_READ_ERROR";
				}
				break;

			case "SET_NOTIFICATION_AS_READ_ERROR":
				draft.status = action.type;
				break;

			// set ALL notifications as read
			case "SET_ALL_NOTIFICATION_AS_READ":
				draft.status = action.type;
				break;

			case "SET_ALL_NOTIFICATION_AS_READ_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "SET_ALL_NOTIFICATION_AS_READ_SUCCESS";
				} else {
					draft.status = "SET_ALL_NOTIFICATION_AS_READ_ERROR";
				}
				break;

			case "SET_ALL_NOTIFICATION_AS_READ_ERROR":
				draft.status = action.type;
				break;

			case "RESET_NOTIFICATIONS":
				draft.status = action.type;
				draft.list = [];
				draft.totalPages = null;
				break;
		}
	});
}

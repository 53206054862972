import axios from "axios";
import immer from "immer";
import { memberEndpointURL } from "../base/config";
import { end, getErrorMessage } from "../base/functions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * get member's activity list
 * @params {number}		pageIndex  	  		current page number
 * @params {boolean} 	showInitialList    	determines whether to render the initial list or more pages list
 */
export const getActivities = (
	pageIndex,
	showInitialList = true,
	activityTypes = "class"
) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "GET_ACTIVITIES" });
		return axios
			.get(memberEndpointURL + "GetMyActivities", {
				params: {
					page_index: pageIndex,
					page_size: 10,
					activity_type: activityTypes
				},
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json"
				}
			})
			.then(res =>
				dispatch({
					type: "GET_ACTIVITIES_RESOLVE",
					res: res.data,
					showInitialList
				})
			)
			.catch(err =>
				dispatch({
					type: "GET_ACTIVITIES_ERROR",
					error: err
				})
			);
	};
};

/**
 * resets activities list
 */
export const resetActivities = () => ({
	type: "RESET_ACTIVITIES"
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const currentState = {
	status: null,
	error: null,

	list: [],
	totalPages: []
};
export function activitiesLog(state = currentState, action) {
	return immer(state, draft => {
		switch (action.type) {
			//get activities list
			case "GET_ACTIVITIES":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_ACTIVITIES_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_ACTIVITIES_SUCCESS";
					draft.totalPages = action.res.data.total_page;

					if (action.showInitialList) {
						draft.list = action.res.data.activity_list;
					} else {
						draft.list = draft.list.concat(
							action.res.data.activity_list
						);
					}
				} else {
					draft.status = "GET_ACTIVITIES_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_ACTIVITIES_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "RESET_ACTIVITIES":
				draft.list = [];
				break;
		}
	});
}

import axios from "axios";
import immer from "immer";

import { memberEndpointURL, endpointCreds } from "../base/config";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * fetch api to get studios list
 *
 */
export const getStudios =
	(getAll = false, { ip, countryId }) =>
	(dispatch, getState) => {
		dispatch({ type: "GET_STUDIOS" });
		return axios
			.get(memberEndpointURL + "GetStudioList", {
				params: {
					page_index: 0,
					page_size: 0,
					get_all: getAll,
				},
				auth: endpointCreds,
				headers: {
					IpAddress: ip,
					CountryID: countryId,
					"Content-type": "application/json",
				},
			})
			.then((res) =>
				dispatch({
					type: "GET_STUDIOS_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_STUDIOS_ERROR",
					error: err,
				})
			);
	};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const initialState = {
	status: null,
	list: [],
};

export function studios(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			case "GET_STUDIOS":
				draft.status = action.type;
				break;

			case "GET_STUDIOS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_STUDIOS_SUCCESS";
					draft.list = action.res.data.studio_list;
				} else {
					draft.status = "GET_STUDIOS_ERROR";
				}
				break;

			case "RESET_STUDIO_LIST":
				draft.list = [];
				draft.status = "RESET_STUDIO_LIST";
				break;

			case "GET_STUDIOS_ERROR":
				draft.status = action.type;
				break;
		}
	});
}

import React from "react";
import PropTypes from "prop-types";

import "./SuccessMessage.scss";

const propTypes = {
	/** @type {string} 				title of error message */
	title: PropTypes.string,
	/** @type {string} 				react className prop */
	className: PropTypes.string,
	/** @type {string|number|node} 	children elements */
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.node
	]).isRequired
};

const defaultProps = {
	className: "",
	title: null
};

/** Generic component to display success messages */
export default function SuccessMessage({ title, children, className }) {
	return (
		<div
			className={"successMessage d-flex align-items-center " + className}
		>
			<div className="successMessage__icon" />
			<div className="successMessage__contents">
				{title && <div className="text-bold">{title}</div>}
				{children}
			</div>
		</div>
	);
}

SuccessMessage.propTypes = propTypes;
SuccessMessage.defaultProps = defaultProps;

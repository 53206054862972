import React from "react";
import { Link } from "react-router-dom";

import "./FooterApps.scss";

/**
 * App links in footer
 */
export default function FooterApps() {
	return (
		<nav className="footerApps">
			<span className="footer__link-wrap">
				<Link
					to="/classes/buy-class-pack"
					className="footer__link animated click-efx"
				>
					Buy a Class Pack
				</Link>
			</span>
			<span className="footer__link-wrap">
				<Link
					to="/schedule"
					className="footer__link animated click-efx"
				>
					Book A Mat
				</Link>
			</span>
		</nav>
	);
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { connectFacebook } from "../../../redux/authentication";
import { disableScroll, showAuthentication } from "../../../redux/ui";

import Modal from "../../Modal/Modal.jsx";
import PlainButton from "../../PlainButton/PlainButton.jsx";
import Button from "../../Button/Button.jsx";
import ErrorMessage from "../../Alerts/ErrorMessage/ErrorMessage.jsx";
import "./ConnectModal.scss";

class ConnectModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
		};
	}

	componentDidUpdate(prevProps) {}

	/**
	 * FacebookProvider <Login /> onResponse event handler
	 * @param  {object} res  response object
	 */
	handleFacebookSuccess(res) {
		this.props.connectFacebook(res.accessToken);
		// this.props.connectFacebook(res.tokenDetail.accessToken);
	}

	/**
	 * FacebookProvider <Login /> onError event handler
	 * @param  {object} res  response object
	 */
	handleFacebookFail(err) {}

	handleClose() {
		this.props.showAuthentication(null);
	}

	handleFacebookLogin() {
		window?.FB.login(function (response) {
			if (response.authResponse) {
				this.handleFacebookSuccess(response.authResponse);
			} else {
			}
		});
	}

	render() {
		const { authentication } = this.props;

		return (
			<Modal
				className="ConnectModal"
				icon={
					<img
						src="assets/img/ui/logo-yoga-movement.svg"
						alt="Welcome to our new YM platform"
					/>
				}
				maxWidth={600}
				title={
					<span>
						Welcome to our
						<br />
						new YM platform
					</span>
				}
				onClose={() => this.handleClose()}
			>
				<div className="auth__contents">
					{authentication.facebookError != null && (
						<ErrorMessage className="text-center pt-3 mb-4">
							{authentication.facebookError}
						</ErrorMessage>
					)}
					<p>
						Now you can link your account with Facebook for faster
						log in. At the same time, you can also find out who is a
						YM Fam in your friend list.
					</p>

					<Button
						className="auth__btn--fb auth__btn text-uppercase"
						onClick={this.handleFacebookLogin}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 20 20"
							className="auth__btn-icon--fb auth__btn-icon animated"
						>
							<path d="M1.104 0C.494 0 0 .494 0 1.104v17.792C0 19.506.494 20 1.104 20h9.579v-7.745H8.076V9.237h2.607V7.01c0-2.584 1.577-3.99 3.882-3.99 1.104 0 2.052.082 2.329.119v2.7h-1.598c-1.254 0-1.496.595-1.496 1.47v1.927h2.989l-.39 3.018h-2.6V20h5.097c.61 0 1.104-.494 1.104-1.104V1.104C20 .494 19.506 0 18.896 0H1.104z" />
						</svg>
						Log in with Facebook
					</Button>
					{/* <FacebookProvider appId={facebookAppID}>
						<Login
							scope="email"
							onCompleted={res => this.handleFacebookSuccess(res)}
							onError={err => this.handleFacebookFail(err)}
						>
							{({ handleClick }) => (
								<Button
									className="auth__btn--fb auth__btn text-uppercase"
									onClick={handleClick}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										className="auth__btn-icon--fb auth__btn-icon animated"
									>
										<path d="M1.104 0C.494 0 0 .494 0 1.104v17.792C0 19.506.494 20 1.104 20h9.579v-7.745H8.076V9.237h2.607V7.01c0-2.584 1.577-3.99 3.882-3.99 1.104 0 2.052.082 2.329.119v2.7h-1.598c-1.254 0-1.496.595-1.496 1.47v1.927h2.989l-.39 3.018h-2.6V20h5.097c.61 0 1.104-.494 1.104-1.104V1.104C20 .494 19.506 0 18.896 0H1.104z" />
									</svg>
									Log in with Facebook
								</Button>
							)}
						</Login>
					</FacebookProvider> */}

					<div className="connectModal__or auth__or text-uppercase">
						or
					</div>

					<div className="row">
						<div className="col-9">
							Set up later in your profile page, under YM Fam.
						</div>
						<div className="col-3 text-right">
							<PlainButton
								className="connectModal__skip"
								onClick={() =>
									this.props.showAuthentication(null)
								}
							>
								Skip
							</PlainButton>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		disableScroll: bindActionCreators(disableScroll, dispatch),
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
		connectFacebook: bindActionCreators(connectFacebook, dispatch),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(ConnectModal);

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import qs from "qs";

import { scrollElemTop } from "../../../base/functions";
import { showAuthentication } from "../../../redux/ui";
import { createPassword } from "../../../redux/authentication";

import Modal from "../../Modal/Modal.jsx";
import ModalFooter from "../../Modal/ModalFooter/ModalFooter.jsx";
import Form from "../../Form/Form.jsx";
import Checkbox from "../../Form/Checkbox/Checkbox.jsx";
import Button from "../../Button/Button.jsx";
import ErrorMessage from "../../Alerts/ErrorMessage/ErrorMessage.jsx";
import "./NewPasswordModal.scss";

/**
 * Set password for migrated users
 */

class NewPasswordModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			busy: false,
			error: false,
			passwordErrorMessage: null,
			acknowledged: false
		};
		this.restrictAccess();
	}

	/**
	 * check query string has a token
	 * if not, redirect back to migration modal screen
	 */
	restrictAccess() {
		let queryObj = qs.parse(this.props.location.search, {
			decoder: str => str
		});
		if (!queryObj.token || !queryObj.token.length) {
			queryObj.action = "migration";
			delete queryObj.token;
			this.props.history.push({
				pathname: this.props.location.pathname,
				search: qs.stringify(queryObj)
			});
		}
	}

	componentDidUpdate(prevProps) {
		let prevAuthProps = prevProps.authentication;

		if (prevAuthProps.status != this.props.authentication.status) {
			switch (this.props.authentication.status) {
				case "NEW_PASSWORD_ERROR":
					this.setState({
						busy: false,
						error: this.props.authentication.createPasswordError
					});
					break;
			}
		}
	}

	/**
	 * close authentication modal window
	 */
	handleClose() {
		this.props.showAuthentication(null);
	}

	/**
	 * submit event handler for the form
	 * do further custom validation submit if valid
	 * otherwise show and scroll to error
	 * @param  {object} 	e   	submit event object
	 * @param  {boolean} 	err 	whether <Form /> component found any standard errors
	 */
	handleSubmit(e, err) {
		if (!this.state.acknowledged) err = true;
		if (this.password.value.length && this.confirmPassword.value.length) {
			if (this.password.value != this.confirmPassword.value) {
				err = true;
				this.setState({
					passwordErrorMessage:
						"Password and confirm password don't match"
				});
			} else if (
				this.password.value.length < 6 ||
				this.password.value.length > 25
			) {
				err = true;
				this.setState({
					passwordErrorMessage:
						"Passwords need to be between 6 - 25 characters"
				});
			} else {
				this.setState({ passwordErrorMessage: null });
			}
		}

		if (!err) {
			let queryObj = qs.parse(this.props.location.search, {
				decoder: str => str
			});
			this.setState({ busy: true });

			this.props.createPassword(queryObj.token, this.password.value);
		} else {
			let errorFields = ReactDOM.findDOMNode(this.form).querySelectorAll(
				"*:invalid, .form__field--error, .checkbox--error"
			);
			if (errorFields.length > 0) {
				let modal = ReactDOM.findDOMNode(this.modal);
				scrollElemTop(
					modal,
					errorFields[0].getBoundingClientRect().top +
						modal.scrollTop -
						50
				);
			}
		}
	}

	render() {
		return (
			<Modal
				className="newPasswordModal"
				icon={
					<img
						src="assets/img/ui/logo-yoga-movement.svg"
						alt="Set new password"
					/>
				}
				maxWidth={900}
				title="Set new password"
				onClose={() => this.handleClose()}
				ref={e => {
					this.modal = e;
				}}
			>
				<Form
					busy={this.state.busy}
					onSubmit={(e, err) => this.handleSubmit(e, err)}
					ref={e => {
						this.form = e;
					}}
				>
					<div className="newPasswordModal__contents auth__contents">
						{this.state.passwordErrorMessage != null && (
							<ErrorMessage className="pt-2 pb-3">
								{this.state.passwordErrorMessage}
							</ErrorMessage>
						)}
						<small className="newPasswordModal__tip d-block">
							* All fields are mandatory
						</small>

						<div className="row">
							<label className="col-12 col-md-6">
								<div className="form__label text-uppercase">
									New password
								</div>
								<input
									type="password"
									className={
										"form__field " +
										(this.state.passwordErrorMessage !=
											null &&
											" form__field--error form__field--suppress-error ")
									}
									placeholder="Your new password"
									ref={e => {
										this.password = e;
									}}
									autoFocus
									required
								/>
								<div className="form__msg--error">
									Password is required
								</div>
							</label>
							<label className="col-12 col-md-6">
								<div className="form__label text-uppercase">
									Confirm password
								</div>
								<input
									type="password"
									className={
										"form__field " +
										(this.state.passwordErrorMessage !=
											null &&
											" form__field--error form__field--suppress-error ")
									}
									placeholder="Confirm your new password"
									ref={e => {
										this.confirmPassword = e;
									}}
									required
								/>
								<div className="form__msg--error">
									Confirm password is required
								</div>
							</label>
						</div>
						<div className="row mt-5">
							<div className="col-12">
								<Checkbox
									type="tick"
									className={
										"indemnity__checkbox " +
										(!this.state.acknowledged &&
											" checkbox--form-error ")
									}
									selected={this.state.acknowledged}
									onChange={() =>
										this.setState({
											acknowledged: !this.state
												.acknowledged
										})
									}
								>
									I acknowledge that I have read and agreed to
									Yoga Movement’s new{" "}
									<a
										href="/terms-of-services"
										target="_blank"
										rel="noopener"
										title="Opens in a new window"
									>
										Terms and Conditions
									</a>{" "}
									and{" "}
									<a
										href="/privacy-policy"
										target="_blank"
										rel="noopener"
										title="Opens in a new window"
									>
										Privacy Policy
									</a>
									.
								</Checkbox>
							</div>
						</div>
					</div>
					<ModalFooter>
						<Button className="newPasswordModal__save auth__btn d-block text-uppercase">
							Save
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		);
	}
}

const mapStateToProps = function(store) {
	return {
		authentication: store.authentication
	};
};
const mapDispatchToProps = function(dispatch) {
	return {
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
		createPassword: bindActionCreators(createPassword, dispatch)
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(NewPasswordModal)
);

import axios from "axios";
import immer from "immer";

import {
	memberEndpointURL,
	endpointCreds,
	globalMemberEndpoint,
	endpointDomain,
	endpointVersion,
} from "../base/config";
import { getErrorMessage } from "../base/functions";
import { getProfile } from "./profile";

const classPacksURL = "GetClassPackList";
const purchaseClassPackURL = "PurchaseClassPack";
const giftClassPackURL = "PurchaseClassPackGift";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * revert store to initial state
 */
export const resetClassPacks = () => ({ type: "RESET_CLASS_PACKS" });
/**
 * revert store to initial state
 */
export const resetRollOverClassPack = () => ({
	type: "RESET_ROLL_OVER_CLASS_PACK",
});
/**
 *
 * fetch api to get class packs
 * use both baic auth and bearer token for authentication to fetch classPack
 * because listing can differ according to account type
 * @param {string} filter   filter results for other uses
 *                          [pending = filter class packs for newbie payment]
 */

export const replaceWithV3 = (endPoint) =>
	endPoint.replace(/[/][v]\d{1}/gi, "/global");

export const getGiftClassPacks =
	(filter = null, countryId) =>
	(dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		let endPoint = !!countryId ? globalMemberEndpoint : memberEndpointURL;
		let headerConfig = {
			"Content-type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + accessToken,
			CountryID: countryId,
			// ...(!!countryId ? { CountryID: countryId } : {}),
		};

		dispatch({ type: "GET_GIFT_CLASS_PACKS" });
		return axios
			.get(
				endpointDomain +
					endpointVersion +
					"/Member/GetClassPackList?is_gift=1",
				{
					params: {
						type: filter,
					},
					auth: accessToken === null && endpointCreds,
					headers: headerConfig,
				}
			)
			.then((res) =>
				dispatch({
					type: "GET_GIFT_CLASS_PACKS_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_CLASS_PACKS_ERROR",
					error: err,
				})
			);
	};

export const getClassPacks =
	(filter = null, countryId = 1) =>
	(dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		let endPoint = !!countryId ? globalMemberEndpoint : memberEndpointURL;
		let headerConfig = {
			"Content-type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + accessToken,
			CountryID: countryId,
			// ...(!!countryId ? { CountryID: countryId } : {}),
		};

		dispatch({ type: "GET_CLASS_PACKS" });
		return axios
			.get(globalMemberEndpoint + classPacksURL, {
				params: {
					type: filter,
				},
				auth: accessToken === null && endpointCreds,
				headers: headerConfig,
			})
			.then((res) =>
				dispatch({
					type: "GET_CLASS_PACKS_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_CLASS_PACKS_ERROR",
					error: err,
				})
			);
	};

export const getClassPacksWithV3EndPoint =
	(filter = null) =>
	(dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		let endPoint = memberEndpointURL.replace(/[/][v]\d{1}/gi, "/global"); // replace v3 with global value in member endpoint

		dispatch({ type: "GET_CLASS_PACKS" });
		return axios
			.get(endPoint + classPacksURL, {
				params: {
					type: filter,
				},
				auth: accessToken == null && endpointCreds,
				headers: {
					"Content-type": "application/x-www-form-urlencoded",
					Authorization: "Bearer " + accessToken,
				},
			})
			.then((res) =>
				dispatch({
					type: "GET_CLASS_PACKS_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_CLASS_PACKS_ERROR",
					error: err,
				})
			);
	};

export const resetFriendGiftInfo = () => {
	return {
		type: "RESET_FRIEND_GIFT_INFO",
	};
};
export const onChangeFriendGift = (values = {}) => {
	return {
		type: "ON_CHANGE_GIFT_FRIEND",
		payload: {
			...values,
		},
	};
};

/**
 * Purchase a class pack
 * @param {object} cardInfo 		Information about card
 * @param {object} classPack 		classPack object
 * @param {string} promoCode 		promo code
 */

export const purchaseClassPackGiftAndBook =
	(
		{ stripe_token, stripe_card_id },
		classPack,
		friend,
		promoCode,
		schedule_id,
		schedule_date
	) =>
	(dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "PURCHASE_CLASS_PACK_AND_BOOK" });
		const endpointUrl =
			endpointDomain +
			endpointVersion +
			"/Guest/PurchaseClassPackGiftAndBook";
		return axios
			.post(
				endpointUrl,
				{
					stripe_token,
					stripe_card_id,
					friend_email: friend?.friend_email || null,
					friend_id: friend?.friend_id || "",
					message: friend?.message || "",
					pack_id: classPack.pack_id,
					promotion_code: promoCode,
					schedule_id,
					study_date: schedule_date,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				dispatch({
					type: "PURCHASE_CLASS_PACK_AND_BOOK_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "PURCHASE_CLASS_PACK_AND_BOOK_ERROR",
					error: err,
				})
			);
	};

/**
 * Purchase a class pack
 * @param {object} cardInfo 		Information about card
 * @param {object} classPack 		classPack object
 * @param {string} promoCode 		promo code
 */
export const purchaseClassPack =
	(
		{ stripe_token, stripe_card_id },
		classPack,
		friend,
		promoCode,
		future_rolled_pack_id
	) =>
	(dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "PURCHASE_CLASS_PACK" });
		const endpointUrl = friend.friend_email
			? memberEndpointURL + giftClassPackURL
			: memberEndpointURL + purchaseClassPackURL;

		return axios
			.post(
				endpointUrl,
				{
					stripe_token,
					stripe_card_id,
					friend_email: friend?.friend_email || null,
					friend_id: friend?.friend_id || "",
					message: friend?.message || "",
					pack_id: classPack.pack_id,
					promotion_code: promoCode,
					future_rolled_pack_id,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				if (res?.data?.data?.roll_info) {
					dispatch({
						type: "ROLL_OVER_CLASS_PACK",
						res: res?.data?.data?.roll_info,
					});
				}
				dispatch({
					type: "PURCHASE_CLASS_PACK_RESOLVE",
					// response will be { payment_info: {bill_amount, bill_no } }
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "PURCHASE_CLASS_PACK_ERROR",
					error: err,
				})
			);
	};
/**
 * For newbie to pay the unpaid first class
 * @param {object} cardInfo 		Information about card
 */
export const purchasePendingBilling =
	(bill_id, { stripe_token, stripe_card_id }, promotion_code) =>
	(dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "PURCHASE_PENDING_BILLING" });
		return axios
			.post(
				memberEndpointURL + "PurchasePendingBilling",
				{
					bill_id,
					stripe_token,
					stripe_card_id,
					promotion_code,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				dispatch({
					type: "PURCHASE_PENDING_BILLING_RESOLVE",
					res: res.data,
				});

				// get Profile data for for newbie -> member
				dispatch(getProfile());
			})
			.catch((err) =>
				dispatch({
					type: "PURCHASE_PENDING_BILLING_ERROR",
					error: err,
				})
			);
	};

/**
 * select classpacks and store in classPackReducer as a selectedClassPacks property.
 * selectClassPacks will be stored in memory.
 * selectedClassPacks state will lost when the browser is refreshed.
 */
export const selectClassPacks = (classPacks) => ({
	type: "SELECT_CLASS_PACKS",
	classPacks,
});

/**
 *
 * @param {*} classPacks
 * @returns
 */
export const rollOverClassPack = (classPack) => ({
	type: "ROLL_OVER_CLASS_PACK",
	rollOverClassPack: classPack,
});

export const selectedRollOverClassPack = (classPack) => ({
	type: "SELECTED_ROLL_OVER_CLASS_PACK",
	selectedRollOverClassPack: classPack,
});
/**
 * Save purcahsed Class pack inside redux store.
 * We might need to save the card info which is used to satisfy the classPack payment,
 * Coz the last 4 number of purchased card will use to show in `ClassPackPurchaseSuccess`.
 */
export const setPurchasedClassPacks = (classPacks, card) => ({
	type: "SET_PURCHASED_CLASS_PACKS",
	classPacks,
	card,
});

/**
 * Validate promo code with class pack
 * @param {string} classPackId 		class pack id
 * @param {string} promoCode 		entered promo code
 */
export const checkPromoCode =
	(classPackId, promoCode) => (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch(resetPromoCode());
		dispatch({ type: "CHECK_PROMO_CODE" });
		return axios
			.post(
				memberEndpointURL + "CheckPromotion",
				{
					pack_id: classPackId,
					promotion_code: promoCode,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				dispatch({
					type: "CHECK_PROMO_CODE_RESOLVE",
					promoCode,
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "CHECK_PROMO_CODE_ERROR",
					error: err,
				})
			);
	};

export const resetPromoCode = () => ({ type: "RESET_PROMO_CODE" });

export const getGlobalCoutriesMasterData = () => (dispatch, getState) => {
	let store = getState();
	let accessToken = store.authentication.accessToken;
	dispatch({ type: "GET_COUNTRIES" });
	// console.log("globalMemberEndpoint", globalMemberEndpoint);
	const masterDataUrl = globalMemberEndpoint + "GetMasterData";
	return axios
		.get(masterDataUrl, {
			auth: accessToken === null && endpointCreds,
			headers: {
				"Content-type": "application/x-www-form-urlencoded",
				Authorization: "Bearer " + accessToken,
			},
		})
		.then((res) => {
			dispatch({
				type: "GET_COUNTRIES_RESOLVE",
				res: res.data?.data?.master_data,
			});
		})
		.catch((err) => {
			dispatch({
				type: "GET_COUNTRIES_DATA_ERROR",
				error: err,
			});
		});
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const initialState = {
	status: null,
	classPacks: [],
	classGiftPacks: [],
	pendingBilling: null,
	is_coming_soon: false,
	selectedClassPacks: [],
	selectedRollOverClassPack: null,
	rollOverClassPack: null, // roll over class pack
	purchasedClassPacks: [], // purchased class pack
	purchasedWithCard: {}, // the last 4 number of Card that used to satisfy purchased class pack's payment
	purchasedResults: null, // api response after purchase
	error: null,
	promoCode: null, // entered promo code
	promoCodeDetails: null, // promo code details from api
	promoCodeValid: null, // whether promo code is valid
	promoCodeError: null, // error message from checkPromoCode
	countries: null,
	friend: {}, // fiend info for gift class pack -> email, message
};
export function classPack(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			case "RESET_CLASS_PACK":
				draft = Object.assign(draft, initialState);
				break;
			case "RESET_ROLL_OVER_CLASS_PACK":
				draft.rollOverClassPack = null;
				break;
			case "GET_CLASS_PACKS":
			case "PURCHASE_CLASS_PACK":
				draft.status = action.type;
				break;
			case "ROLL_OVER_CLASS_PACK":
				draft.rollOverClassPack =
					action.rollOverClassPack || action.res;
				break;
			case "SELECTED_ROLL_OVER_CLASS_PACK":
				draft.selectedRollOverClassPack =
					action.selectedRollOverClassPack;
				break;
			case "GET_CLASS_PACKS_ERROR":
				draft.status = action.type;
				break;
			case "GET_GIFT_CLASS_PACKS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_GIFT_CLASS_PACKS_SUCCESS";
					draft.classPacks = action.res.data.pack_list_by_category || [];
					draft.pendingBilling = action.res.data.pending_billing;
				}
				break;
			case "GET_CLASS_PACKS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_CLASS_PACKS_SUCCESS";
					draft.classPacks = action.res.data.pack_list_by_category || [];
					draft.pendingBilling = action.res.data.pending_billing;
					draft.is_coming_soon = action.res.data.is_coming_soon
				} else {
					draft.status = "GET_CLASS_PACKS_ERROR";
					draft.error = action.res; // getErrorMessage(action.res)
				}
				break;

			case "SELECT_CLASS_PACKS":
				draft.selectedClassPacks = [action.classPacks];
				break;

			case "SET_PURCHASED_CLASS_PACKS":
				draft.purchasedClassPacks = [action.classPacks];
				draft.purchasedWithCard = action.card;
				break;

			case "PURCHASE_CLASS_PACK_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "PURCHASE_CLASS_PACK_SUCCESS";
					draft.purchasedResults = action.res.data.payment_info;
				} else {
					draft.status = "PURCHASE_CLASS_PACK_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "PURCHASE_CLASS_PACK_AND_BOOK_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "PURCHASE_CLASS_PACK_AND_BOOK_SUCCESS";
					draft.purchasedResults = action.res.data.payment_info;
				} else {
					draft.status = "PURCHASE_CLASS_PACK_AND_BOOK_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;
			case "PURCHASE_CLASS_PACK_ERROR":
				draft.status = action.type;
				break;

			case "SELECT_CLASS_PACKS":
				draft.selectedClassPacks = [action.classPacks];
				break;

			case "PURCHASE_PENDING_BILLING":
				draft.status = action.type;
				break;

			case "PURCHASE_PENDING_BILLING_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "PURCHASE_PENDING_BILLING_SUCCESS";
					draft.purchasedResults = action.res.data.payment_info;
				} else {
					draft.status = "PURCHASE_PENDING_BILLING_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "PURCHASE_PENDING_BILLING_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.res);
				break;

			case "CHECK_PROMO_CODE_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "CHECK_PROMO_CODE_SUCCESS";
					draft.promoCode = action.promoCode;
					draft.promoCodeDetails = action.res.data;
					draft.promoCodeValid = true;
					draft.promoCodeError = null;
				} else {
					draft.status = "CHECK_PROMO_CODE_ERROR";
					draft.promoCodeValid = false;
					draft.promoCodeError = getErrorMessage(action.res);
				}
				break;

			case "CHECK_PROMO_CODE_ERROR":
				draft.status = action.type;
				draft.promoCodeValid = false;
				draft.promoCodeError = getErrorMessage(action.res);
				break;

			case "RESET_PROMO_CODE":
				draft.status = action.type;
				draft.promoCode = null;
				draft.promoCodeDetails = null;
				draft.promoCodeValid = null;
				draft.promoCodeError = null;
				break;
			case "RESET_FRIEND_GIFT_INFO":
				draft.friend = {};
				break;

			case "ON_CHANGE_GIFT_FRIEND":
				draft.friend.friend_email = action.payload?.email || "";
				draft.friend.friend_id = action.payload?.friend_id || "";
				draft.friend.message = action.payload?.message || "";
				draft.friend.isError = action.payload?.isError || false;

			case "GET_COUNTRIES_RESOLVE":
				draft.status = "GET_COUNTRIES_SUCCESS";
				draft.countries = action.res?.countries;

			case "GET_COUNTRIES_DATA_ERROR":
				draft.status = "GET_COUNTRIES_DATA_ERROR";
				draft.error = getErrorMessage(action.res);
		}
	});
}

import axios from "axios";
import immer from "immer";

import { memberEndpointURL } from "../base/config";
import { end, getErrorMessage } from "../base/functions";

/** ACTIONS SET UP */

export const proceedLateCancelPayment = (classPackId = null) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "PROCEED_LATE_CANCEL_PAYMENT" });
		return axios
			.get(memberEndpointURL + "ChargePendingLateCancel", {
				params: {
					id: classPackId,
				},
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
				},
			})
			.then((res) =>
				dispatch({
					type: "PROCEED_LATE_CANCEL_PAYMENT_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "PROCEED_LATE_CANCEL_PAYMENT_ERROR",
					error: err,
				})
			);
	};
};

export const proceed_to_payment_action = "PROCEED_TO_PAYMENT";
export const show_popup_modal_action = "SHOW_LATECANCEL_POPUP_MODAL";
export const reset_late_cancel_state_action = "RESET_LATECANCEL_STATE";

export const proccedToLatePaymentPage = () => {
	return (dispatch) => {
		dispatch({ type: proceed_to_payment_action });
	};
};

export const showLateCancelPopupModal = () => {
	return (dispatch) => {
		dispatch({ type: show_popup_modal_action });
	};
};

export const resetLateCancelState = () => {
	return (dispatch) => {
		dispatch({ type: reset_late_cancel_state_action });
	};
};

/** REDUCER SETUP */

const initialState = {
	paymentSuccessData: null,
	paymentError: null,
	hideLateCancelPopup: false,
};

export function lateCancelReducer(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			// get transaction history list

			case "PROCEED_LATE_CANCEL_PAYMENT":
			case "PROCEED_LATE_CANCEL_PAYMENT_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "PROCEED_LATE_CANCEL_PAYMENT_SUCCESS";
					draft.paymentSuccessData = action.res;

					draft.hideLateCancelPopup = true;
				} else {
					draft.status = "PROCEED_LATE_CANCEL_PAYMENT_FAIL";
					draft.paymentError = getErrorMessage(action.res);
				}
				break;

			case "PROCEED_LATE_CANCEL_PAYMENT_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case show_popup_modal_action:
				draft.status = action.type;
				draft.hideLateCancelPopup = false;
				break;

			case proceed_to_payment_action:
				draft.status = action.type;
				draft.hideLateCancelPopup = true;
				break;

			case reset_late_cancel_state_action:
				draft.status = action.type;

				draft.paymentError = initialState.paymentError;
				draft.hideLateCancelPopup = initialState.hideLateCancelPopup;
				draft.paymentSuccessData = initialState.paymentSuccessData;
				break;
		}
	});
}

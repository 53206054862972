import React from "react";
import PropTypes from "prop-types";

import "./ErrorMessage.scss";

const propTypes = {
	/** @type {string} 				title of error message */
	title: PropTypes.string,
	/** @type {string} 				react className prop */
	className: PropTypes.string,
	/** @type {string|number|node} 	children elements */
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.node,
	]).isRequired,
};

const defaultProps = {
	className: "",
	title: null,
	showIcon: false,
};

/** Generic component to display error messages */
export default function ErrorMessage({ title, children, className, showIcon }) {
	return (
		<div className={"errorMessage d-flex align-items-center " + className}>
			{showIcon && <div className="errorMessage__icon" />}
			<div className="errorMessage__contents">
				{title && <div className="text-bold">{title}</div>}
				{children}
			</div>
		</div>
	);
}

ErrorMessage.propTypes = propTypes;
ErrorMessage.defaultProps = defaultProps;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "react-google-login";
// dev/app/base/config
import { googleClientID } from "../../../base/config.jsx";
import { googleAuthenticate } from "../../../redux/authentication.jsx";
import AppleSignin from "react-apple-signin-auth";

import ErrorMessage from "../../Alerts/ErrorMessage/ErrorMessage.jsx";
import { appleID, redirectURI } from "../../../base/config";

import Button from "../../Button/Button.jsx";
import { getErrorMessage } from "../../../base/functions";
import "./SocialSignIn.scss";

export const SocialSignIn = ({ props }) => {
	const [error, setError] = useState("");
	const dispatch = useDispatch();

	const { signIn } = useGoogleLogin({
		onSuccess: async (codeResponse) => {
			dispatch(googleAuthenticate(codeResponse?.tokenId));
		},
		clientId: googleClientID,
		onFailure: (errorResponse) => console.log(errorResponse),
	});

	function handleGoogleLogin() {
		signIn();
	}

	return (
		<>
			{error && (
				<ErrorMessage className="loginForm__message">
					{getErrorMessage(error)}
				</ErrorMessage>
			)}

			<Button
				className="auth__btn--social auth__btn--social-gg"
				onClick={() => handleGoogleLogin()}
			>
				<img
					src="/assets/img/icons/google-ico.svg"
					alt="sign in with Google"
					className="loginForm__btn-icon--gg auth__btn-icon--gg animated"
				/>
				Sign in with Google
			</Button>

			{/* <FacebookProvider appId={facebookAppID}>
				<Login
					scope="email"
					onCompleted={(res) => handleFacebookSuccess(res)}
					onError={(err) => handleFacebookFail(err)}
				>
					{({ handleClick }) => (
						<Button
							className="auth__btn--social auth__btn--social-fb"
							onClick={handleClick}
						>
							<img
								src="img/icons/fb-ico.svg"
								alt="sign in with facebook"
								className="loginForm__btn-icon--fb auth__btn-icon--fb animated"
							/>
							Sign in with Facebook
						</Button>
					)}
				</Login>
			</FacebookProvider> */}

			<Button className="auth__btn--social">
				{/* <AppleLogin
					clientId={appleID}
					// usePopup={true}
					// autoLoad={true}
					redirectURI={redirectURI}
					// responseType="code id_token"
					responseMode="form_post"
					callback={(data) => console.log("here is callback", data)}
					scope="name&email"
				/> */}
				<AppleSignin
					className="apple-signin-auth-btn"
					authOptions={{
						clientId: appleID,
						scope: "email name",
						redirectURI: redirectURI,
						state: "state",
					}}
					buttonExtraChildren=""
					noDefaultStyle={true}
					onSuccess={(res) => console.log("Apple Res", res)}
					onError={(error) => console.log(error)}
				/>
				<img
					style={{
						position: "absolute",
						top: "-2px",
					}}
					src="/assets/img/icons/apple-ico.svg"
					alt="sign in with facebook"
					className="loginForm__btn-icon--fb auth__btn-icon--fb animated"
				/>
				<p
					style={{
						marginBottom: "-2px",
					}}
				>
					Sign in with Apple
				</p>
			</Button>
		</>
	);
};

import axios from "axios";
import immer from "immer";

import {
	memberEndpointURL,
	endpointCreds,
	endpointDomain,
	endpointVersion,
} from "../../base/config";
import { end, getErrorMessage } from "../../base/functions";
import {
	ADD_MYPACKS_TO_STORE,
	ADD_SELECTED_PACK_MYSELF,
	ADD_SELECTED_PACK_OTHERS,
	ADD_SELECTED_PACK_TO_SHARE_PACKS,
	ADD_SELECTED_SHARE_PACK,
	ADD_SELECTPACK_TO_MYPACKS,
	ADD_SHAREPACKS_TO_STORE,
	BIND_SELECTED_USER_TO_A_SHARE_PACK,
	REMOVE_SHARE_PACK_FROM_LIST,
	REMOVE_USERS_FROM_SHARE_PACK,
	SELECTED_MYSELF_PACK,
	SELECT_CURRENT_SHAREPACK,
	UPDATE_SELECTED_MYPACK,
	UPDATE_SHARE_PACK,
} from "./SelectedPacks.actions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * revert store to initial state
 */
export const resetSchedules = () => ({ type: "RESET_SCHEDULES" });
export const resetAllSharePacks = () => ({
	type: "RESET_ALL_SHARE_PACK",
});
export const resetMySelfPack = () => ({ type: "RESET_MYSELF_PACK" });
export const resetBookForOther = () => ({
	type: "RESET_BOOK_FOR_OTHER_PACK",
});
export const addSelectedFriMember = (data) => {
	return (dispatch) => {
		dispatch({
			type: "SELECTED_FRI_MEMBER_RESOLVE",
			res: data,
		});
	};
};

/**
 * fetch api to get schedules
 * use both basic auth and bearer token for authentication
 * because schedules will have more details for logged in users
 * @param {string} 	date 			YYYY/MM/DD
 * @param {array} 	classTypeIDs 	array of class type IDs
 * @param {array} 	teacherIDs 		array of teacher IDs
 * @param {array} 	studioIDs 		array of studio IDs
 * @param {number} 	pageIndex 		current page number
 * @param {number} 	pageSize 		number of results per page
 */

const defaultConfig = {
	date: null,
	classTypeIDs: null,
	teacherIDs: null,
	studioIDs: null,
	pageIndex: 0,
	pageSize: 0,
	useV3EndPoint: false,
	countryId: null,
};
export const getSchedules =
	(config = defaultConfig) =>
	(dispatch, getState) => {
		let store = getState();
		const {
			date,
			classTypeIDs,
			teacherIDs,
			studioIDs,
			pageIndex,
			pageSize,
			useV3EndPoint,
			countryId,
		} = config;

		// clear old request (if there is)
		if (store.schedules.cancelGetSchedules) {
			// take note axios.catch is checking based on the msg here
			store.schedules.cancelGetSchedules.cancel("Manual cancel");
		}

		let accessToken = store.authentication.accessToken;

		const CancelToken = axios.CancelToken;
		const source = CancelToken.source();

		dispatch({ type: "GET_SCHEDULES", cancelGetSchedules: source });

		const endPoint = useV3EndPoint
			? memberEndpointURL.replace(/[/][v]\d{1}/gi, "/global")
			: memberEndpointURL;

		return axios
			.post(
				endPoint + "GetScheduleWithBookStatus",
				{
					schedule_date: date,
					class_type_list:
						classTypeIDs != null
							? classTypeIDs.map((id) => {
									return { class_id: id };
							  })
							: null,
					instructor_list:
						teacherIDs != null
							? teacherIDs.map((id) => {
									return { instructor_id: id };
							  })
							: null,
					studio_list:
						studioIDs != null
							? studioIDs.map((id) => {
									return { studio_id: id };
							  })
							: null,
					page_index: pageIndex,
					page_size: pageSize,
				},
				{
					auth: accessToken == null && endpointCreds,
					headers: {
						Authorization: "Bearer " + accessToken,
						CountryID: countryId,
					},
					cancelToken: source.token,
				}
			)
			.then((res) =>
				dispatch({
					type: "GET_SCHEDULES_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) => {
				// manual cancel from cancel token
				if (err.message !== "Manual cancel") {
					dispatch({
						type: "GET_SCHEDULES_ERROR",
						error: err,
					});
				}
			});
	};

/**
 * cancel get schedules api call
 */
export const cancelGetSchedules = () => (dispatch, getState) => {
	let store = getState();
	if (store.schedules.cancelGetSchedules) {
		store.schedules.cancelGetSchedules.cancel();
	}
	dispatch({ type: "CANCEL_GET_SCHEDULES" });
};

/**
 * get waiting time options for use when making a waitlist
 */
export const getWaitlistTiming = () => (dispatch, getState) => {
	let store = getState();
	let accessToken = store.authentication.accessToken;
	dispatch({ type: "GET_WAITLIST_TIMING" });
	return axios
		.get(memberEndpointURL + "GetWaitlistTiming", {
			headers: {
				Authorization: "Bearer " + accessToken,
			},
		})
		.then((res) =>
			dispatch({
				type: "GET_WAITLIST_TIMING_RESOLVE",
				res: res.data,
			})
		)
		.catch((err) =>
			dispatch({
				type: "GET_WAITLIST_TIMING_ERROR",
				error: err,
			})
		);
};

/**
 * book a class
 * @param  {string} date        YYYY/MM/DD
 * @param  {string} packID      class pack ID
 * @param  {string} scheduleID 	schedule ID
 */
export const guestBookClass = (body) => (dispatch, getState) => {
	let store = getState();
	let accessToken = store.authentication.accessToken;
	dispatch({ type: "GUEST_BOOK_CLASS" });
	return axios
		.post(
			endpointDomain + endpointVersion + "/Guest/BookClass",

			body,
			{
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			}
		)
		.then((res) => {
			if (res?.errorCode > 0 || res?.data?.errorCode > 0) {
				return dispatch({
					type: "GUEST_BOOK_CLASS_ERROR",
					error: res?.data?.message,
				});
			}
			return dispatch({
				type: "GUEST_BOOK_CLASS_RESOLVE",
				res: res.data,
			});
		})
		.catch((err) =>
			dispatch({
				type: "GUEST_BOOK_CLASS_ERROR",
				error: err,
			})
		);
};

/**
 * book a class
 * @param  {string} date        YYYY/MM/DD
 * @param  {string} packID      class pack ID
 * @param  {string} scheduleID 	schedule ID
 */
export const bookClass = (date, packID, scheduleID) => (dispatch, getState) => {
	let store = getState();
	let accessToken = store.authentication.accessToken;
	dispatch({ type: "BOOK_CLASS" });

	return axios
		.post(
			memberEndpointURL + "BookClass",
			{
				study_date: date,
				mem_pack_id: packID,
				schedule_id: scheduleID,
			},
			{
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			}
		)
		.then((res) =>
			dispatch({
				type: "BOOK_CLASS_RESOLVE",
				res: res.data,
			})
		)
		.catch((err) =>
			dispatch({
				type: "BOOK_CLASS_ERROR",
				error: err,
			})
		);
};

/**
 * book a class as a newbie
 * newbie do not have class packs and will book directly with a saved card ID
 * @param  {string} date        YYYY/MM/DD
 * @param  {string} scheduleID 	schedule ID
 * @param  {string} cardID 		stripe card ID
 */
export const newbieBookClass =
	(date, scheduleID, cardID) => (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "NEWBIE_BOOK_CLASS" });
		return axios
			.post(
				memberEndpointURL + "NewbieBookClass",
				{
					study_date: date,
					schedule_id: scheduleID,
					stripe_card_id: cardID,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "NEWBIE_BOOK_CLASS_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "NEWBIE_BOOK_CLASS_ERROR",
					error: err,
				})
			);
	};

/**
 * add a class to waitlist
 * @param  {string} 	date        YYYY/MM/DD
 * @param  {string} 	packID      class pack ID
 * @param  {string}		scheduleID 	schedule ID
 * @param  {integer}	bufferTime 	minutes to notify user if there is a slot
 */
export const addToWaitlist =
	(date, packID, scheduleID, bufferTime) => (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "ADD_TO_WAITLIST" });
		return axios
			.post(
				memberEndpointURL + "AddClassToWaitlist",
				{
					study_date: date,
					mem_pack_id: packID,
					schedule_id: scheduleID,
					ready_min: bufferTime,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "ADD_TO_WAITLIST_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "ADD_TO_WAITLIST_ERROR",
					error: err,
				})
			);
	};

/**
 * reset error message
 */
export const resetError = () => ({
	type: "RESET_ERROR",
});

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const initialState = {
	status: null,
	error: null,
	list: [],
	cancelGetSchedules: null,
	waitlistTimingOptions: [],
	selectedSchedule: null,
	bookedSchedule: null,
	totalPages: 0,
	memberInfo: {},
	is_coming_soon: false,
	newbieErrorStatusCode: 0,
	selectedMyPack: [],
	allMyPacks: [],
	allSharePacks: [],
	selectedFriMember: [],
	currentSelectedSharePack: null,
	selectedUsersToSharePack: [],
};

export function schedules(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			case "RESET_SCHEDULES":
				draft = Object.assign(draft, initialState);
				break;
			case "RESET_MYSELF_PACK":
				draft.selectedMyPack = [];
				break;
			case "RESET_ALL_SHARE_PACK":
				draft.allSharePacks = [];
				draft.selectedFriMember = [];
				break;
			case "RESET_BOOK_FOR_OTHER_PACK":
				draft.allMyPacks = [];
				draft.allSharePacks = [];
				draft.selectedFriMember = [];
				draft.currentSelectedSharePack = null;

				break;
			case "RESET_ERROR":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_SCHEDULES":
				draft.status = action.type;
				draft.cancelGetSchedules = action.cancelGetSchedules;
				draft.list = [];
				break;
			case "SELECTED_FRI_MEMBER_RESOLVE":
				draft.status = action.type;
				draft.selectedFriMember = action?.res;
				// const hasAllElem = draft.selectedFriMember.every((elem) =>
				// 	action?.includes(elem)
				// );
				// if (!hasAllElem) draft.selectedFriMember = action?.res;
				break;

			case "GET_SCHEDULES_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_SCHEDULES_SUCCESS";
					draft.cancelGetSchedules = null;
					draft.list = action.res.data.schedule_list || [];
					draft.is_coming_soon = action.res.data.is_coming_soon;
					draft.memberInfo = action.res.data.member_info;
					draft.totalPages = action.res.data.total_page;
				} else {
					draft.status = "GET_SCHEDULES_ERROR";
					draft.cancelGetSchedules = null;
					draft.is_coming_soon = false;
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_SCHEDULES_ERROR":
				draft.status = action.type;
				break;

			case "GET_WAITLIST_TIMING":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_WAITLIST_TIMING_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_WAITLIST_TIMING_SUCCESS";
					draft.waitlistTimingOptions =
						action.res.data.waitlist_timing;
				} else {
					draft.status = "GET_WAITLIST_TIMING_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_WAITLIST_TIMING_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.error);
				break;

			case "BOOK_CLASS":
				draft.status = action.type;
				draft.error = null;
				break;

			case "BOOK_CLASS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "BOOK_CLASS_SUCCESS";
					draft.bookedSchedule = action.res.data.schedule_class_info;
				} else {
					draft.status = "BOOK_CLASS_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;
			case "GUEST_BOOK_CLASS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GUEST_BOOK_CLASS_SUCCESS";
					draft.bookedSchedule = action.res.data;
				} else {
					draft.status = "GUEST_BOOK_CLASS_ERROR";

					draft.error =
						action?.res?.data?.message ||
						getErrorMessage(action.res);
				}
				break;

			case "BOOK_CLASS_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.error);
				break;
			case "GUEST_BOOK_CLASS_ERROR":
				draft.status = action.type;

				draft.error = action?.error || getErrorMessage(action);
				break;
			case "NEWBIE_BOOK_CLASS":
				draft.status = action.type;
				draft.error = null;
				draft.newbieErrorStatusCode = 0;
				break;

			case "NEWBIE_BOOK_CLASS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "NEWBIE_BOOK_CLASS_SUCCESS";
					draft.bookedSchedule = action.res.data.schedule_class_info;
					draft.newbieErrorStatusCode = 0;
				} else {
					draft.status = "NEWBIE_BOOK_CLASS_ERROR";
					draft.error = getErrorMessage(action.res);
					draft.newbieErrorStatusCode = action.res.errorCode;
				}
				break;

			case "NEWBIE_BOOK_CLASS_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.error);
				draft.newbieErrorStatusCode = action.res.errorCode;
				break;

			case "ADD_TO_WAITLIST":
				draft.status = action.type;
				draft.error = null;
				break;

			case "ADD_TO_WAITLIST_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "ADD_TO_WAITLIST_SUCCESS";
					draft.bookedSchedule = action.res.data.schedule_class_info;
				} else {
					draft.status = "ADD_TO_WAITLIST_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "ADD_TO_WAITLIST_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.error);
				break;
			case ADD_MYPACKS_TO_STORE:
				draft.status = action.type;
				draft.allMyPacks = action.payload?.map((pack) => ({
					packInfo: pack,
					isSelected: false,
				}));
				break;

			case UPDATE_SELECTED_MYPACK:
				draft.status = action.type;
				const toUpdateMyPack = draft.allMyPacks?.find(
					(p) =>
						p.packInfo?.pack_id ===
						action.payload?.packInfo?.pack_id
				);
				const toUpdateMyPackIndex =
					draft.allMyPacks?.indexOf(toUpdateMyPack);
				toUpdateMyPack["isSelected"] = !action.payload.isSelected;
				draft.allMyPacks[toUpdateMyPackIndex] = toUpdateMyPack;
				break;
			case ADD_SHAREPACKS_TO_STORE:
				draft.status = action.type;
				// draft.allSharePacks = action?.payload?.map((c) => ({
				// 	classPack: c?.classPack,
				// 	members: [],
				// 	guests: [],
				// }));
				// draft.allSharePacks = [
				// 	{
				// 		classPack: action?.payload?.classPack || {},
				// 		members: [],
				// 		guests: [],
				// 	},
				// ];
				break;

			case SELECT_CURRENT_SHAREPACK:
				draft.status = action.type;
				draft.currentSelectedSharePack = action.payload;
				break;
			case SELECTED_MYSELF_PACK:
				draft.status = action.type;
				draft.selectedMyPack = [action.payload];
				break;
			case REMOVE_USERS_FROM_SHARE_PACK:
				draft.status = action.type;
				const toRemoveSharePack = draft.allSharePacks?.find(
					(p) => p.classPack?.pack_id === action.payload?.pack_id
				);
				const toRemovePackIndex =
					draft.allSharePacks?.indexOf(toRemoveSharePack);
				toRemoveSharePack.members = [];
				toRemoveSharePack.guests = [];
				draft.allSharePacks[toRemovePackIndex] = toRemoveSharePack;
				break;

			case UPDATE_SHARE_PACK:
				draft.status = action.type;
				draft.allSharePacks = action.payload;
				break;
		}
	});
}

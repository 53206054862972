import React from "react";

function SGFlagIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="16"
			fill="none"
			viewBox="0 0 20 16"
		>
			<path fill="#FF4B55" d="M0 0.5H20V8H0z"></path>
			<path fill="#F2F2F2" d="M0 8H20V15.5H0z"></path>
			<path
				fill="#F5F5F5"
				d="M2.759 4.249c0-1.322.95-2.417 2.189-2.62.067-.01.07-.105.004-.12a2.638 2.638 0 00-.972-.046c-1.337.186-2.37 1.367-2.389 2.745-.021 1.573 1.22 2.854 2.754 2.854.208 0 .411-.024.606-.07.067-.016.063-.11-.004-.122-1.24-.202-2.188-1.295-2.188-2.621z"
			></path>
			<path
				fill="#F5F5F5"
				d="M4.913 4.98l-.115.356-.363.003c-.047 0-.066.063-.028.092l.292.223-.11.359c-.014.046.037.085.075.056l.296-.218.295.218c.038.029.089-.01.075-.056l-.11-.359.292-.223c.038-.03.019-.091-.028-.092l-.363-.003-.115-.356a.048.048 0 00-.093 0zM6.707 4.98l-.115.356-.363.003c-.047 0-.066.063-.028.092l.292.223-.11.359c-.014.046.037.085.075.056l.295-.218.296.218c.038.029.089-.01.075-.056l-.11-.359.292-.223c.038-.03.019-.091-.028-.092l-.363-.003L6.8 4.98a.048.048 0 00-.093 0zM5.818 2.11l-.114.358-.363.002c-.047 0-.067.063-.029.092l.292.223-.11.359c-.014.046.037.085.075.056l.296-.218.295.218c.038.029.09-.01.075-.056l-.11-.359.293-.223c.037-.029.018-.091-.029-.091l-.363-.003-.115-.357a.048.048 0 00-.093 0zM4.388 3.21l-.115.357-.363.003c-.047 0-.067.062-.029.091l.292.223-.11.359c-.013.046.038.085.076.057l.295-.22.295.22c.039.028.09-.01.075-.057l-.109-.359.292-.223c.038-.029.018-.091-.029-.091l-.363-.003-.114-.357a.048.048 0 00-.093 0zM7.236 3.21l-.114.357-.363.003c-.047 0-.067.062-.03.091l.293.223-.11.359c-.014.046.037.085.075.057l.296-.22.295.22c.038.028.09-.01.075-.057l-.11-.359.293-.223c.037-.029.018-.091-.03-.091l-.362-.003-.115-.357a.048.048 0 00-.093 0z"
			></path>
		</svg>
	);
}

export default SGFlagIcon;

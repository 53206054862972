import axios from "axios";
import immer from "immer";
import qs from "qs";

import { stripeAPIURL, stripeAPIKey, stripeEndpointURL } from "../base/config";
import { end, getErrorMessage } from "../base/functions";
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * revert store to initial state
 */
export const resetPayment = () => ({ type: "RESET_PAYMENT" });

/**
 * get all credit cards in account
 */
export const getAllCards = () => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "GET_ALL_CARDS" });
		return axios
			.get(stripeEndpointURL + "GetAllCard", {
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
				},
			})
			.then((res) =>
				dispatch({
					type: "GET_ALL_CARDS_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_ALL_CARDS_ERROR",
					err: err,
				})
			);
	};
};

/**
 * add credit card to account
 * @param  {string}  	token     	JWT returned from stripe
 */
export const addCard = (token) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		let profileProps = store.profile;

		dispatch({ type: "ADD_CARD" });
		return axios
			.post(
				stripeEndpointURL + "AddCard",
				{
					stripe_token: token,
					primary_card: true,
					card_holder_name: `${profileProps.profile.first_name} ${
						profileProps.profile.last_name
					} - ${
						profileProps.profile?.mem_email ||
						profileProps?.lastEmail
					}`,
					email:
						profileProps.profile?.mem_email ||
						profileProps?.lastEmail,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "ADD_CARD_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "ADD_CARD_ERROR",
					err: err,
				})
			);
	};
};

export const setStripeToken = (token) => (dispatch) => {
	return dispatch({
		type: "SET_STRIPE_TOKEN",
		token,
	});
};

/**
 * update credit card in account
 * @param  {string}  	token     	JWT returned from stripe
 */
export const updateCard = (token) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		let profileProps = store.profile;
		let card = store.payment.cards[0];

		console.log(
			"profileProps.profile:",
			profileProps.profile,
			profileProps
		);

		dispatch({ type: "UPDATE_CARD" });
		return axios
			.post(
				stripeEndpointURL + "UpdateCard",
				{
					stripe_token: token,
					primary_card: true,
					stripe_card_id: card.id,
					email:
						profileProps.profile?.mem_email ||
						profileProps?.lastEmail,
					card_holder_name: `${profileProps.profile.first_name} ${
						profileProps.profile.last_name
					} - ${
						profileProps.profile?.mem_email ||
						profileProps?.lastEmail
					}`,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "UPDATE_CARD_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "UPDATE_CARD_ERROR",
					err: err,
				})
			);
	};
};

/**
 * delete credit card from account
 * @param  {string} 	cardID 	credit card ID
 */
export const deleteCard = (cardID) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "DELETE_CARD" });
		return axios
			.post(
				stripeEndpointURL + "DeleteCard",
				{
					stripe_card_ids: [{ stripe_card_id: cardID }],
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "DELETE_CARD_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "DELETE_CARD_ERROR",
					err: err,
				})
			);
	};
};

/**
 * set credit card as default card
 * @param  {string} cardID  credit card ID
 */
export const setPrimaryCard = (cardID) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "SET_PRIMARY_CARD" });
		return axios
			.post(
				stripeEndpointURL + "UpdateCardDefault",
				{
					stripe_card_id: cardID,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "SET_PRIMARY_CARD_RESOLVE",
					res: res.data,
					cardID: cardID,
				})
			)
			.catch((err) =>
				dispatch({
					type: "SET_PRIMARY_CARD_ERROR",
					err: err,
				})
			);
	};
};

/**
 * pass credit card credentials to stripe to get stripe token
 * @param  {string} cardID  	credit card ID
 * @param  {string} cardExpiry  credit card expiry date MM/YYYY
 * @param  {number} cardCVV  	3-digit CVV number
 */
export const getStripeToken = (cardNumber, cardExpiry, cardCVV) => {
	return (dispatch, getState) => {
		let store = getState();

		let profileProps = store.profile;

		let expiryArray = cardExpiry.split("/").map((v) => v.trim());
		dispatch({ type: "GET_STRIPE_TOKEN" });
		return axios
			.post(
				stripeAPIURL + "tokens",
				qs.stringify({
					["card[number]"]: cardNumber,
					["card[exp_month]"]: expiryArray[0],
					name: `${profileProps.profile.first_name} ${
						profileProps.profile.last_name
					} - ${
						profileProps.profile?.mem_email ||
						profileProps?.lastEmail
					}`,
					email:
						profileProps.profile?.mem_email ||
						profileProps?.lastEmail,
					["card[exp_year]"]: expiryArray[1],
					["card[cvc]"]: cardCVV,
				}),
				{
					headers: {
						Authorization: "Bearer " + stripeAPIKey,
						"Content-type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "GET_STRIPE_TOKEN_RESOLVE",
					res: res,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_STRIPE_TOKEN_ERROR",
					err: err,
				})
			);
	};
};

/**
 * clear status in store
 * for use with triggerPaymentSubmit
 */
export const clearPaymentStatus = () => ({ type: "CLEAR_PAYMENT_STATUS" });

/**
 * trigger submit in payment forms
 */
export const triggerPaymentSubmit = () => (dispatch) => {
	dispatch(clearPaymentStatus());
	setTimeout(() => dispatch({ type: "TRIGGER_PAYMENT_SUBMIT" }));
};

/**
 * update payment submission trigger as complete
 * this is fired when local form validation has passed so as to allow
 * - parent components to know local form validation has passed and disable UI
 * - subsequent payment submit triggers to run
 */
export const triggerPaymentSubmitComplete = () => ({
	type: "TRIGGER_PAYMENT_SUBMIT_COMPLETE",
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// STORE
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const initialState = {
	status: null,
	success: null,
	error: null,

	cards: [],
	primaryCardID: null,
	stripeToken: null,
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function payment(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			case "RESET_PAYMENT":
				draft = Object.assign(draft, initialState);
				break;

			case "GET_ALL_CARDS":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_ALL_CARDS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_ALL_CARDS_SUCCESS";
					draft.cards = action.res.data.card_list;
					draft.primaryCardID = action.res.data.default_source_id;
				} else {
					draft.status = "GET_ALL_CARDS_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_ALL_CARDS_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "ADD_CARD":
				draft.status = action.type;
				draft.error = null;
				break;

			case "ADD_CARD_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "ADD_CARD_SUCCESS";
				} else {
					draft.status = "ADD_CARD_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "ADD_CARD_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "UPDATE_CARD":
				draft.status = action.type;
				draft.error = null;
				break;

			case "UPDATE_CARD_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "UPDATE_CARD_SUCCESS";
					draft.card = action.res.data.card_list;
					draft.primaryCardID = action.res.data.default_source_id;
				} else {
					draft.status = "UPDATE_CARD_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "UPDATE_CARD_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "DELETE_CARD":
				draft.status = action.type;
				draft.error = null;
				break;

			case "DELETE_CARD_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "DELETE_CARD_SUCCESS";
				} else {
					draft.status = "DELETE_CARD_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "DELETE_CARD_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "SET_PRIMARY_CARD":
				draft.status = action.type;
				draft.error = null;
				break;

			case "SET_PRIMARY_CARD_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "SET_PRIMARY_CARD_SUCCESS";
					//manually update primary card ID without calling backend
					//because backend will always return primary card first, will cause confusion
					draft.primaryCardID = action.cardID;
				} else {
					draft.status = "SET_PRIMARY_CARD_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "SET_PRIMARY_CARD_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "GET_STRIPE_TOKEN":
				draft.status = action.type;
				draft.error = null;
				break;

			case "SET_STRIPE_TOKEN":
				draft.status = "GET_STRIPE_TOKEN_SUCCESS";
				draft.stripeToken = action.token;
				break;

			case "GET_STRIPE_TOKEN_RESOLVE":
				if (action.res.status == 200) {
					draft.status = "GET_STRIPE_TOKEN_SUCCESS";
					draft.stripeToken = action.res.data.id;
				} else {
					draft.status = "GET_STRIPE_TOKEN_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_STRIPE_TOKEN_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "CLEAR_PAYMENT_STATUS":
				draft.status = "";
				break;

			case "TRIGGER_PAYMENT_SUBMIT":
			case "TRIGGER_PAYMENT_SUBMIT_COMPLETE":
				draft.status = action.type;
				break;
		}
	});
}

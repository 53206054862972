import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, withRouter, useHistory } from "react-router-dom";
import qs from "qs";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Routes from "./Routes";
import "./api";
import Authentication from "../components/Authentication/Authentication.jsx";
import MediaQuery from "../components/MediaQuery/MediaQuery.jsx";
import UIController from "../components/Controllers/UIController/UIController.jsx";
import TitleController from "../components/Controllers/TitleController/TitleController.jsx";
import FirebaseController from "../components/Controllers/FirebaseController/FirebaseController.jsx";
import AnalyticsController from "../components/Controllers/AnalyticsController/AnalyticsController.jsx";
import Header from "../components/Header/Header.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NoticeBar from "../components/NoticeBar/NoticeBar.jsx";
import RatingPurchaseWrapper from "../components/Modals/RatingPurchaseWrapper/RatingPurchaseWrapper.jsx";
import MyYMFriends from "../components/Friends/MyYMFriends/MyYMFriends.jsx";
import AppDownload from "../components/AppDownload/AppDownload.jsx";
// import MustReadHeader from "../components/MustReadHeader/MustReadHeader.jsx"
import { getMasterData, masterData } from "../redux/masterData";
import { currentLanguage } from "../redux/lang";
import { currentCountry } from "../redux/countryId";

import DeleteConfirmPopup from "../views/Account/DeleteAccount/DeleteConfirmPopup.jsx";
import { getUnlimitedPendingData } from "../redux/unlimitedPending.jsx";
import { initialConfirmPopup, termPopup } from "../redux/popup.jsx";
import Axios from "axios";
import { memberEndpointURL, endpointDomain, endpointVersion } from "./config";
import LateCancel from "./LateCancel.jsx";
import InitialPopup from "../views/InitialPopup/InitialPopup.jsx";
import { showAuthentication } from "../redux/ui.jsx";

// const callUnlimitedDataApi = (accessToken) => {
// 	return Axios.get(endpointDomain + "v4/Member/GetUnlimitedPendingPayment", {
// 		headers: {
// 			Authorization: "Bearer " + accessToken,
// 		},
// 	});
// };


import CountryModal from "../components/Header/CountrySelect/CountryModal/CountryModal.jsx";
import LocationModal from "../components/Header/CountrySelect/LocationModal/LocationModal.jsx";
import "./styles/index.scss";
import { getCountryID } from "../hooks/getCountryId";
import TermAndConditionModal from "../components/Header/CountrySelect/TermAndConditionModal/TermAndConditionModal.jsx";
//import SelectSearchDropDown from "../components/Form/SelecteDropDown/SelectSearchDropDown.jsx";

const App = (props) => {

	const [pendingData, setPendingData] = useState(null);
	const [currentProfile, setCurrentMyProfile] = useState(null);
	const [ip, setIP] = useState("");

	const location = useLocation();

	const history = useHistory();

	const query = new URLSearchParams(location.search);
	const country = query.get("country");

	const detectLang = props.masterData?.data?.countries?.find(
		(item) => item.country_code?.toLowerCase() === country?.toLowerCase()
	);

	let queryObj = qs.parse(props.location.search, {
		ignoreQueryPrefix: true,
	});
	
	const _profileChecked = currentProfile?.member_agreement?.find(
		(item) => item.Country_ID === props?.lang
	);




const callProfileDataApi =  async (accessToken, country_id) => {

		const { data } = await Axios.get(
			endpointDomain + endpointVersion + "/Member/GetProfile",
			{
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
					CountryID: country_id,
				},
			}
		);

		setCurrentMyProfile(data?.data?.member_info)


};

	const handleChangeLang = (lang) => {
		const checkCurrentProfile = props?.profile?.member_agreement?.find(
			(item) => item.Country_ID === lang
		);

		if (
			(_profileChecked?.Is_Agree !== undefined &&
				!_profileChecked?.Is_Agree &&
				props?.authentication?.accessToken) ||
			(!checkCurrentProfile?.Is_Agree && props?.authentication?.accessToken)
		) {
			props.termCheckPopup();
			history.push({
				pathname: location.pathname,
				search: `?countryName=${detectLang?.country_name}&cId=${detectLang?.country_id}`,
			});
		} else {
			if (checkCurrentProfile?.Is_Agree) {
				props.setCurrentLanguage(lang);
				props.setCurrentCountry(detectLang?.country_id);
			}
		}
	};

	useEffect(() => {

		if (
			_profileChecked?.Is_Agree !== undefined &&
			!_profileChecked?.Is_Agree &&
			props?.authentication?.accessToken
		) {
			props.termCheckPopup();
			history.push({
				pathname: location.pathname,
				search: `?countryName=${_profileChecked?.Country_Name}&cId=${_profileChecked?.Country_ID}`
			})
		}
		if(props?.authentication?.accessToken) {
			callProfileDataApi(props?.authentication?.accessToken, props?.lang);
		}

	}, []);

	const readyLangs = props.masterData?.data?.countries?.map((item) =>
		item.country_code?.toLowerCase()
	);

	useEffect(() => {
		if (
			detectLang?.country_code &&
			readyLangs?.includes(country?.toLocaleLowerCase())
		) {
			handleChangeLang(detectLang?.country_id);
		}
	}, [detectLang, detectLang?.country_id, readyLangs, country]);


	const callUnlimitedDataApi = (accessToken) => {
		return Axios.get(memberEndpointURL + "GetUnlimitedPendingPayment", {
			headers: {
				Authorization: "Bearer " + accessToken,
				IpAddress: Cookies.get("ipAddress"),
				CountryID:
					getCountryID({ lang: props.lang, data: masterData?.data?.countries }) ||
					masterData?.data?.countries.find(
						(data) => data?.country_code?.toLowerCase() === props?.lang
					)?.country_id,
			},
		});
	};



	const getData = async () => {
		const res = await Axios.get("https://geolocation-db.com/json/");

		console.log('IPv4;', res)
		if (res?.data?.IPv4) {
			Cookies.set("ipAddress", res?.data?.IPv4);
		}
		Cookies.set("ipAddress", res?.data?.IPv4);
		setIP(res?.data?.IPv4);
	};

	useEffect(() => {
		props.getMasterData({ ip });
		getData();

		if (Cookies.get("showPopUp") === undefined && queryObj?.webview != 1) {
			props?.showInitialPopup();
			Cookies.set("showPopUp", true);
		}
	}, [ip]);

	useEffect(() => {
		if (queryObj?.webview != 1) {
			if (Cookies.get("fine_fee") === "true") {
				return getPendingData(props.authentication?.accessToken);
			}
		}
	}, [queryObj?.webview != 1 && Cookies.get("fine_fee")]);

	const getPendingData = (token) => {
		callUnlimitedDataApi(token)
			.then((res) => {
				setPendingData(res?.data?.data);
			})
			.catch((err) => console.log("an error occur ", err));
	};

	useEffect(() => {
		if (
			props?.authentication?.accessToken &&
			props?.profile?.rating_display_class?.Class_ID
		) {
			if (queryObj?.webview != 1) {
				history.push(
					`/classes/my-classes/${props?.profile?.rating_display_class?.Class_ID}?type=history&&class-id=${props?.profile?.rating_display_class?.Class_ID}&&action=rate-class&&r=1`
				);
			}
		}
	}, [
		props?.profile?.rating_display_class?.Class_ID,
		props.authentication?.accessToken,
	]);

	const { ui } = props;


	const cookieValue = Cookies.get("fine_fee");

	if (
		queryObj?.webview != 1 &&
		queryObj?.webview != 1 &&
		cookieValue &&
		JSON.parse(cookieValue)?.fine_fee &&
		location.pathname !== "/cancel-summary"
	) {
		return (
			<LateCancel
				data={
					queryObj?.webview != 1 &&
					queryObj?.webview != 1 &&
					JSON.parse(cookieValue)
				}
			/>
		);
	}

	return (
		<div
			className={
				"app " +
				(ui.hideHeader && " app--no-header ") +
				(queryObj.webview == 1 && " app--webview ")
			}
		>
			<Header />

			<LateCancel data={pendingData} />

			<div
				className={
					"app__main " +
					(ui.useFullWidthBody && " app__main--fullWidth ") +
					(ui.condenseHeader && " app__main--condensed ") +
					(props.location.pathname === "/contact-us" &&
						" app__main--height-auto ")
				}
			>
				<NoticeBar />
				<div className="app__main-inner">
					<Routes />
				</div>
			</div>
			{props?.popup?.termPopup && <TermAndConditionModal />}
			{props?.popup?.langPopup && <CountryModal />}
			{props?.popup?.locationPopup && <LocationModal />}
			{props.location.pathname === "/" && queryObj.webview != 1 && (
				<AppDownload />
			)}
			{props.popup.deletePopup && <DeleteConfirmPopup />}
			{props.popup.initialPopup && <InitialPopup />}
			<Footer />
			<Authentication />
			<MyYMFriends />
			<MediaQuery />
			<UIController />
			<TitleController />
			<FirebaseController />
			<AnalyticsController />
			<RatingPurchaseWrapper />
		</div>
	);
};

const mapStateToProps = function (store) {
	return {
		ui: store.ui,
		popup: store.popup,
		masterData: store.masterData,
		profile: store?.profile?.profile,
		lang: store?.lang?.lang,
		unlimitedPendingData: store.unlimitedPendingData,
		authentication: store.authentication,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		getMasterData: bindActionCreators(getMasterData, dispatch),
		getUnlimitedPendingData: bindActionCreators(
			getUnlimitedPendingData,
			dispatch
		),
		termCheckPopup: bindActionCreators(termPopup, dispatch),
		setCurrentLanguage: bindActionCreators(currentLanguage, dispatch),
		setCurrentCountry: bindActionCreators(currentCountry, dispatch),
		showInitialPopup: bindActionCreators(initialConfirmPopup, dispatch),
		showAuthentication: bindActionCreators(showAuthentication, dispatch),

	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

import React, { Component } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";

import { googleAnalyticsKey } from "../../../base/config";

class AnalyticsController extends Component {
	componentDidMount() {
		// console.log(location.pathname);
		ReactGA.initialize(googleAnalyticsKey);
		this.props.history.listen((location) =>
			ReactGA.pageview(location.pathname)
		);
	}

	render() {
		return null;
	}
}

export default withRouter(AnalyticsController);

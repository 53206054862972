import axios from "axios";
import immer from "immer";
import _ from "lodash";
import { REHYDRATE } from "redux-persist";
import {
	memberEndpointURL,
	endpointDomain,
	endpointVersion,
} from "../base/config";
import { end, getErrorMessage } from "../base/functions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * call api to get profile details
 */
export const getProfile = (check, cb, country_id = 1) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		if (!accessToken) {
			return;
		}
		dispatch({ type: "GET_PROFILE" });
		return axios
			.get(endpointDomain + endpointVersion + "/Member/GetProfile", {
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
					CountryID: country_id,
				},
			})
			.then((res) => {
				if (
					res.data.errorCode === 0 &&
					check &&
					res.data &&
					!res.data.data.member_info.Is_Verify
				) {
					cb();
				}
				dispatch({
					type: "GET_PROFILE_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "GET_PROFILE_ERROR",
					error: err,
				})
			);
	};
};

/**
 * call api to change email address
 * @param  {string}  email  email address to change to
 */
export const changeEmail = (email) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "CHANGE_EMAIL" });
		return axios
			.post(
				memberEndpointURL + "ChangeEmail",
				{ email },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "CHANGE_EMAIL_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "CHANGE_EMAIL_ERROR",
					err: err,
				})
			);
	};
};

/**
 * call api to update profile
 * @param  {object}  params  parameters to pass to UpdateProfile
 */
export const updateProfile = (params, isComplete, country_id = 1) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "UPDATE_PROFILE" });
		return axios
			.post(
				memberEndpointURL +
					(isComplete ? "UpdateProfileComplete" : "UpdateProfile"),
				params,
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
						IpAddress: params?.ipAddress,
						CountryID: country_id,
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "UPDATE_PROFILE_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "UPDATE_PROFILE_ERROR",
					err: err,
				})
			);
	};
};

/**
 * call api to update profile image
 * @param  {string}  base64 	image in base64 format
 */
export const updateAvatar = (base64) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "UPDATE_AVATAR" });
		return axios
			.post(
				memberEndpointURL + "UpdateAvatar",
				{ image_value: base64 },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "UPDATE_AVATAR_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "UPDATE_AVATAR_ERROR",
					error: err,
				})
			);
	};
};

export const updateVarificationImage = (base64) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "UPDATE_VARIFY_IMAGE" });
		return axios
			.post(
				memberEndpointURL + "UploadFaceImage",
				{ image_value: base64 },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "UPDATE_VARIFY_IMAGE_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "UPDATE_VARIFY_IMAGE_ERROR",
					error: err,
				})
			);
	};
};

/**
 * get member's class packs
 * @params {string} type 			filter classPack by type(available, expired, finished)
 */
export const getMemberClassPacks = (type = "available", config = {}) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "GET_MEMBER_CLASS_PACKS" });
		// /global/Member/GetMemberClassPack
		return axios
			.get(
				endpointDomain + endpointVersion + "/Member/GetMemberClassPack",
				{
					params: {
						type,
						page_index: 0,
						page_size: 0,
					},
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
						...(config?.countryId
							? { CountryID: config?.countryId }
							: {}),
						...(config?.ip ? { IpAddress: config?.ip } : {}),
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "GET_MEMBER_CLASS_PACKS_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_MEMBER_CLASS_PACKS_ERROR",
					error: err,
				})
			);
	};
};

/**
 * member extend expiring class pack
 * @params {string}	mem_pack_id 		id of the member class pack
 */
export const extendClassPack = (mem_pack_id) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "EXTEND_CLASS_PACK" });
		return axios
			.post(
				memberEndpointURL + "ExtendClassPack",
				{ mem_pack_id },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				if (res.data.errorCode == 0) {
					dispatch(getMemberClassPacks());
				}
				dispatch({
					type: "EXTEND_CLASS_PACK_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "EXTEND_CLASS_PACK_ERROR",
					error: err,
				})
			);
	};
};

/**
 * cancel unlimited pack subscription
 * @params {string}	mem_pack_id 		id of the member class pack
 */
export const cancelSubscription = (mem_pack_id) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "CANCEL_CLASS_PACK_SUBSCRIPTION" });
		return axios
			.post(
				memberEndpointURL + "CancelRecurring",
				{ mem_pack_id },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				if (res.data.errorCode == 0) {
					dispatch(getMemberClassPacks());
				}
				dispatch({
					type: "CANCEL_CLASS_PACK_SUBSCRIPTION_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "CANCEL_CLASS_PACK_SUBSCRIPTION_ERROR",
					error: err,
				})
			);
	};
};

/**
 * get member's classes
 * @params {string} type  	   [available|all]
 * @params {number} pageIndex  current page number
 * @params {number} pageSize   number of classes per page
 */
export const getMemberClasses = (
	type = "available",
	pageIndex = 0,
	pageSize = 0,
	country_id
) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "GET_MEMBER_CLASSES" });
		if (!type) type = "available";
		return axios
			.get(memberEndpointURL + "GetMyClasses", {
				params: {
					type,
					page_index: pageIndex,
					page_size: pageSize,
				},
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
					CountryID: country_id,
				},
			})
			.then((res) =>
				dispatch({
					type: "GET_MEMBER_CLASSES_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_MEMBER_CLASSES_ERROR",
					error: err,
				})
			);
	};
};

/**
 * get member's booked class details
 * @param {string} id  class ID
 */
export const getBookedClassDetail = (id) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "GET_BOOKED_CLASS_DETAIL" });
		return axios
			.get(memberEndpointURL + "GetClassDetail", {
				params: {
					class_id: id,
				},
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
				},
			})
			.then((res) =>
				dispatch({
					type: "GET_BOOKED_CLASS_DETAIL_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_BOOKED_CLASS_DETAIL_ERROR",
					error: err,
				})
			);
	};
};

/**
 * manually set profile details
 * this is used during authentication
 * @param  {object}  profile 	profile details
 */
export const setProfile = (profile) => ({
	type: "SET_PROFILE",
	profile: profile,
});

/**
 * manually update member class pack
 * @param {object}	class pack 		member class pack's details
 */
export const updateClassPack = (classPack) => ({
	type: "UPDATE_MEMBER_CLASS_PACK",
	classPack,
});

export const removeClassPack = (classPack) => ({
	type: "REMOVE_MEMBER_CLASS_PACK",
	classPack,
});

/**
 * revert store to initial state
 * called when user logout
 * @return {[type]} [description]
 */
export const resetProfile = () => ({ type: "RESET_PROFILE" });

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const initialState = {
	status: null,
	error: null,
	profile: null,
	lastEmail: null,
	memberClassPacks: [],
	availableClassPacks: [],

	activities: [],
	activitiesTotalPages: [],

	memberClasses: [],
	memberBookedClass: null,
	memberClassesTotalPages: null,
};

export function profile(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			//get profile
			case "GET_PROFILE":
			case "GET_PROFILE_ERROR":
				draft.status = action.type;
				break;

			case "GET_PROFILE_RESOLVE":
				draft.status = "GET_PROFILE_SUCCESS";
				draft.profile = action.res.data.member_info;
				draft.lastEmail = action.res.data.member_info.mem_email;
				break;

			//change email
			case "CHANGE_EMAIL":
				draft.status = action.type;
				break;

			case "CHANGE_EMAIL_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "CHANGE_EMAIL_SUCCESS";
					draft.profile = action.res.data.member_info;
				} else {
					draft.status = "CHANGE_EMAIL_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "CHANGE_EMAIL_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			//update profile
			case "UPDATE_PROFILE":
				draft.status = action.type;
				break;

			case "UPDATE_PROFILE_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "UPDATE_PROFILE_SUCCESS";
					draft.profile = action.res.data.member_info;
				} else {
					draft.status = "UPDATE_PROFILE_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "UPDATE_PROFILE_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			//update avatar
			case "UPDATE_AVATAR":
				draft.status = action.type;
				draft.error = null;
				break;

			case "UPDATE_AVATAR_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "UPDATE_AVATAR_SUCCESS";
					draft.profile = action.res.data.member_info;
				} else {
					draft.status = "UPDATE_AVATAR_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "UPDATE_AVATAR_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			//update varification image

			case "UPDATE_VARIFY_IMAGE":
				draft.status = action.type;
				draft.error = null;
				break;

			case "UPDATE_VARIFY_IMAGE_RESOLVE":
				if (action.res.errorCode == 0) {
					console.log("UPDATE_VARIFY_IMAGE_RESOLVE", action.res);
					draft.status = "UPDATE_VARIFY_IMAGE_SUCCESS";
					draft.profile = action.res.data.member_info; // open this line when Backend add member_info to response
				} else {
					draft.status = "UPDATE_VARIFY_IMAGE_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "UPDATE_VARIFY_IMAGE_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			//get user's lists of classPack
			case "GET_MEMBER_CLASS_PACKS":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_MEMBER_CLASS_PACKS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_MEMBER_CLASS_PACKS_SUCCESS";
					draft.memberClassPacks = action.res.data.pack_list;
				} else {
					draft.status = "GET_MEMBER_CLASS_PACKS_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_MEMBER_CLASS_PACKS_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			//get user's available class packs
			case "GET_AVAILABLE_CLASS_PACKS":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_AVAILABLE_CLASS_PACKS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_AVAILABLE_CLASS_PACKS_SUCCESS";
					draft.availableClassPacks = action.res.data.pack_list;
				} else {
					draft.status = "GET_AVAILABLE_CLASS_PACKS_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_AVAILABLE_CLASS_PACKS_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "RATE_CLASS_SUCCESS":
				draft.status = action.type;
				break;

			// member extend expiring class pack
			case "EXTEND_CLASS_PACK":
				draft.status = action.type;
				draft.error = null;
				break;

			case "EXTEND_CLASS_PACK_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "EXTEND_CLASS_PACK_SUCCESS";
				} else {
					draft.status = "EXTEND_CLASS_PACK_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "EXTEND_CLASS_PACK_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			//cancel subscription
			case "CANCEL_CLASS_PACK_SUBSCRIPTION":
				draft.status = action.type;
				draft.error = null;
				break;

			case "CANCEL_CLASS_PACK_SUBSCRIPTION_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "CANCEL_CLASS_PACK_SUBSCRIPTION_SUCCESS";
				} else {
					draft.status = "CANCEL_CLASS_PACK_SUBSCRIPTION_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "CANCEL_CLASS_PACK_SUBSCRIPTION_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			//get user's lists of classes
			case "GET_MEMBER_CLASSES":
				draft.status = action.type;
				draft.memberClasses = [];
				draft.error = null;
				break;

			case "GET_MEMBER_CLASSES_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_MEMBER_CLASS_PACKS_SUCCESS";
					draft.memberClassesTotalPages = action.res.data.total_page;
					draft.memberClasses = action.res.data.schedule_list;
				} else {
					draft.status = "GET_MEMBER_CLASS_PACKS_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_MEMBER_CLASSES_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			//get a booked class by user
			case "GET_BOOKED_CLASS_DETAIL":
				draft.status = action.type;
				draft.memberBookedClass = null;
				draft.error = null;
				break;

			case "GET_BOOKED_CLASS_DETAIL_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_BOOKED_CLASS_DETAIL_SUCCESS";
					draft.memberBookedClass =
						action.res.data.schedule_class_info;
				} else {
					draft.status = "GET_BOOKED_CLASS_DETAIL_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_BOOKED_CLASS_DETAIL_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "UPDATE_MEMBER_CLASS_PACK":
				draft.status = action.type;
				draft.memberClassPacks = _.values(
					_.merge(
						_.keyBy(draft.memberClassPacks, "mem_pack_id"),
						_.keyBy(
							[action.classPack.data.class_pack_info],
							"mem_pack_id"
						)
					)
				);
				break;

			case "REMOVE_MEMBER_CLASS_PACK":
				draft.status = action.type;
				draft.memberClassPacks = draft.memberClassPacks.filter(
					(x) =>
						x.mem_pack_id !==
						action.classPack.data.class_pack_info.mem_pack_id
				);
				break;

			case "SET_PROFILE":
				draft.status = action.type;
				draft.profile = action.profile;
				draft.lastEmail = action.profile.mem_email;
				break;

			case "RESET_PROFILE":
				draft = Object.assign(draft, initialState);
				break;
		}
	});
}

import axios from "axios";
import { Service } from "axios-middleware";
import { store } from "./configureStore";

// custom middleware to catch authorisation error (for expired token)
const service = new Service(axios);
service.register({
	onResponseError(err) {

		const data = err?.response?.data? JSON.parse(err?.response?.data): {};
		switch (true) {
			case err?.response?.status === 401:
			case data && data?.errorCode === 408:
				store.dispatch({ type: "LOGOUT" });
				store.dispatch({
					type: "SHOW_AUTHENTICATION",
					section: "log-in"
				});
				store.dispatch({
					type: "SET_LOGIN_ERROR",
					error: data?.message
				});
				break;
			default:
				/**
				 * to support return format of various api responses
				 * e.g. getToken / checkAccountStatus responses do not have data object
				 */
				return {
					...err,
					status: err?.response?.status,
					data: err?.response?.data,
					response: { ...err?.response, data: err?.response }
				};
		}
	}
});

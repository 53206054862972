import immer from "immer";

export const currentCountry = (id) => ({
	type: "COUNTRY_ID",
	res: id,
});

export const scheduleSelectedCountry = (id) => ({
	type: "SCHEDULE_COUNTRY_ID",
	res: id,
});

const initialState = {
	countryId: 1,
	scheduleCountryId: 1,
};

export function country(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			case "COUNTRY_ID":
				draft.countryId = action.res;
				break;
			case "SCHEDULE_COUNTRY_ID":
				draft.scheduleCountryId = action.res;
				break;
		}
	});
}

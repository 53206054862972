import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./ErrorPanel.scss";

/** Generic component to display error messages */
export default class ErrorPanel extends PureComponent {
	static propTypes = {
		/** @type {string} 				react className prop */
		className: PropTypes.string,
		/** @type {string|number|node} 	children elements */
		children: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
			PropTypes.node
		]).isRequired,
		/** @type {function} 			triggers when panel closes */
		onClose: PropTypes.func.isRequired
	};

	static defaultProps = {
		className: "",
		title: null
	};

	constructor(props) {
		super(props);
		this.state = {
			closeFlag: false
		};
		this.closeTimer = null;
	}

	handleClose(e) {
		e.preventDefault();
		if (!this.closeTimer) {
			this.setState({ closeFlag: true });
			this.closeTimer = setTimeout(() => {
				this.props.onClose(e);
			}, 300);
		}
	}

	render() {
		return (
			<div
				className={
					"ErrorPanel " +
					this.props.className +
					(this.state.closeFlag ? " errorPanel--closing " : "")
				}
			>
				<div className="errorPanel__inner d-flex align-items-center justify-content-between">
					<div className="errorPanel__icon" />
					<div className="errorPanel__contents">
						{this.props.children}
					</div>
					<button
						className="errorPanel__close click-efx circle"
						onClick={e => this.handleClose(e)}
					/>
				</div>
			</div>
		);
	}
}

import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "react-day-picker/lib/style.css";
import "./YearMonthSelect.scss";

/**
 * year + month select menus for datepicker
 * requires react-day-picker to work
 * PureComponent instead of stateless because need to use refs
 */

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 99, 0);
const toMonth = new Date(currentYear, 0);
let years = [];
for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i++) {
	years.push(i);
}

export default class YearMonthSelect extends PureComponent {
	static propTypes = {
		/** @type {date} 	To display active year and month */
		date: PropTypes.instanceOf(Date),
		/** @type {date} 	To display active year and month */
		localeutils: PropTypes.object.isRequired,
		/** @type {date} 	onChange event handler */
		onChange: PropTypes.func,
	};
	static defaultProps = {
		date: new Date(),
		onChange: () => {},
	};

	constructor(props) {
		super(props);
		this.months = this.props.localeutils.getMonths();
	}

	render() {
		const dayOfMonth = this.props.date.getDate() || 1;

		return (
			<div className="yearMonthSelect DayPicker-Caption">
				<select
					className="yearMonthSelect__select form__select"
					onChange={() =>
						this.props.onChange(
							new Date(
								this.year.value,
								this.month.value,
								dayOfMonth
							)
						)
					}
					value={this.props.date.getMonth()}
					ref={(e) => {
						this.month = e;
					}}
				>
					{this.months.map((month, i) => (
						<option key={month} value={i}>
							{month}
						</option>
					))}
				</select>
				<select
					className="yearMonthSelect__select form__select"
					onChange={() =>
						this.props.onChange(
							new Date(
								this.year.value,
								this.month.value,
								dayOfMonth
							)
						)
					}
					value={this.props.date.getFullYear()}
					ref={(e) => {
						this.year = e;
					}}
				>
					{years.map((year) => (
						<option key={year} value={year}>
							{year}
						</option>
					))}
				</select>
			</div>
		);
	}
}

import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Select, { components } from "react-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { bindActionCreators } from "redux";
import moment from "moment";

import { endpointCreds, memberEndpointURL } from "../../../base/config";
import { scrollTop, end, isValidJWT } from "../../../base/functions";
import {
	updateProfile,
	updateAvatar,
	getProfile,
} from "../../../redux/profile";
import { disconnectFacebook } from "../../../redux/authentication";
import { showAuthentication } from "../../../redux/ui";

import Modal from "../../Modal/Modal.jsx";
import ErrorMessage from "../../Alerts/ErrorMessage/ErrorMessage.jsx";
import ErrorPanel from "../../Alerts/ErrorPanel/ErrorPanel.jsx";
import SuccessPanel from "../../Alerts/SuccessPanel/SuccessPanel.jsx";
import Checkbox from "../../Form/Checkbox/Checkbox.jsx";
import Panel from "../../Panel/Panel.jsx";
import SingleDatePicker from "../../SingleDatePicker/SingleDatePicker.jsx";
import Button from "../../Button/Button.jsx";

import "./CompleteProfileModal.scss";
import { withRouter } from "react-router-dom";
import SelectDropDown from "../../Form/SelecteDropDown/SelectDropDown.jsx";
import {
	customFormatShape,
	formatWithSpecificAttributes,
	getCountryWithCustomAttribute,
} from "../../CountrySelect/CountrySelect.config";
import { mobileCodeSelectStyle } from "../../Form/SelecteDropDown/SelectDropDown.config";

const authSchema = Yup.object().shape({
	email: Yup.string()
		.email("Invalid email address")
		.required("Email address is required"),
	mobile: Yup.string()

		// .matches(/(8|9)\d{7}$/, "Invalid mobile number") // Singapore's Mobile Number
		// international mobile number with or without country code
		.matches(/\+?\d{7,20}$/, "Invalid mobile number")
		.required("Mobile number is required"),
	dateofbirth: Yup.string().required("Date of birth is required"),
	gender: Yup.string().required("Gender is required"),
	district: Yup.string().required("District is required"),
	emergency_contact_no: Yup.string()
		.matches(/\+?\d{7,20}$/, "Invalid mobile number")
		.required("Emergency contact number is required"),
	emergency_contact_name: Yup.string().required(
		"Emergency contact name is required"
	),
});

/**
 * Form for user to update profile
 */
class CompleteProfileModal extends Component {
	constructor(props) {
		super(props);

		let latestProfileProps = this.props.profile;
		this.state = {
			busy: false,
			success: null,
			error: null,

			uploadError: null,
			uploadingAvatar: false,

			showDatePicker: false,
			gender: latestProfileProps.profile?.mem_gender,
			dob: latestProfileProps.profile?.mem_birthday
				? moment(latestProfileProps.profile?.mem_birthday).toDate()
				: "",

			districtList: [],
		};
		this.getDistricts();
	}

	componentDidUpdate(prevProps) {
		let prevProfileProps = prevProps.profile;
		let latestProfileProps = this.props.profile;

		if (prevProfileProps.status != latestProfileProps.status) {
			switch (latestProfileProps.status) {
				case "UPDATE_PROFILE_SUCCESS":
					this.setState({ busy: false, success: true });
					this.props.showAuthentication(null);
					this.props.history.push("/");
					this.props.getProfile();
					scrollTop(10);
					break;
				case "UPDATE_PROFILE_ERROR":
					this.setState({
						busy: false,
						error: latestProfileProps.error,
					});
					break;
				case "GET_PROFILE_RESOLVE":
				case "GET_PROFILE_SUCCESS":
					this.setState({
						gender: latestProfileProps.profile?.mem_gender,
						dob: latestProfileProps.profile?.mem_birthday
							? moment(
									latestProfileProps.profile?.mem_birthday
							  ).toDate()
							: "",
					});
					break;
			}
		}
	}

	checkProfileIsVerified() {
		if (
			isValidJWT(this.props.authentication.accessToken) &&
			!this.props.profile?.profile?.Is_Verify
		) {
			return true;
		}
	}

	getDistricts() {
		axios
			.get(memberEndpointURL + "GetPostalDistrict", {
				auth: endpointCreds,
			})
			.then((response) => {
				this.setState({
					districtList: response.data.data.postal_list,
				});
			})
			.catch((e) => e);
	}

	/**
	 * convert image blob to base64 format
	 * @param  {Blob}   	raw       data from file input
	 * @param  {Function} 	callback  callback function upon converting to base64
	 */
	getBase64Image(raw, callback) {
		let nameParts = raw.name.split(".");
		if (
			["jpg", "jpeg", "gif", "png"].indexOf(
				nameParts[nameParts.length - 1]
			) < 0
		) {
			this.setState({ uploadError: "Please upload a valid image" });
			return;
		}

		let reader = new window.FileReader();
		reader.readAsDataURL(raw);
		reader.onloadend = () => {
			let image = new Image();
			image.onload = () => {
				callback(reader.result.split(",")[1]);
			};
			image.src = reader.result;
		};
	}
	/**
	 * onSubmit event handler for edit profile <Form /> component
	 * will call updateProfile action in profile redux
	 * @param  {object} 	e   	submit event object
	 * @param  {boolean} 	err 	whether there are errors
	 */
	handleSubmit(values) {
		this.setState({
			busy: true,
			success: null,
			error: null,
			uploadError: null,
		});

		// console.log("values", values);
		let latestProfileProps = this.props.profile;
		this.props.updateProfile(
			{
				first_name: latestProfileProps.profile.first_name,
				last_name: latestProfileProps.profile.last_name,
				contact_no: values.mobile,
				mem_gender: values.gender,
				email: values.email,
				mem_birthday: values.dateofbirth
					? moment(values.dateofbirth, "DD/MM/YYYY").format(
							"YYYY/MM/DD"
					  )
					: "",
				address: "",
				unit_no: "",
				postal_code: "",
				postal_district_id: values.district,
				note_instructor: values.note_instructor,
				emergency_contact_name: values.emergency_contact_name,
				emergency_contact_no: parseInt(values.emergency_contact_no),
				mobile_code:
					values.mobile_code ||
					getCountryWithCustomAttribute(
						this.props.countryId,
						this.props.countries,
						"mobile_code"
					)?.value,
				country_id: this.props.countryId,
			},
			true,
			this.props.countryId
		);
	}

	/**
	 * close authentication modal window
	 */
	handleClose() {
		this.props.showAuthentication(null);
	}

	render() {
		let latestProfileProps = this.props.profile;

		const globalSelectMobileCode = getCountryWithCustomAttribute(
			this.props.countryId,
			this.props.countries,
			"mobile_code"
		);

		return (
			<Modal
				className="completeProfile"
				maxWidth={884}
				onClose={() => this.handleClose()}
			>
				<div className="auth__contents">
					<h2 className="account-content-title text-uppercase completeProfile__title">
						Complete your profile
					</h2>
					{this.state.error && (
						<ErrorPanel
							onClose={() => this.setState({ error: null })}
						>
							{this.state.error}
						</ErrorPanel>
					)}
					<p className="completeProfile__description">
						Please verify and complete all fields below before we
						proceed to the new YM experience.
					</p>
					{this.state.districtList.length > 0 && (
						<Formik
							initialValues={{
								email:
									latestProfileProps.profile?.mem_email || "",
								mobile:
									latestProfileProps.profile?.mem_phone || "",

								gender:
									latestProfileProps.profile?.mem_gender ||
									"",
								dateofbirth: latestProfileProps.profile
									?.mem_birthday
									? moment(
											latestProfileProps.profile
												.mem_birthday
									  ).format("DD/MM/YYYY")
									: "",
								district: latestProfileProps.profile
									?.postal_districtid
									? parseInt(
											latestProfileProps.profile
												?.postal_districtid
									  )
									: null,
								note_instructor:
									latestProfileProps.profile?.note_instructor,
								emergency_contact_name:
									latestProfileProps.profile
										?.emergency_contact_name,
								emergency_contact_no:
									latestProfileProps.profile
										?.emergency_contact_no,
							}}
							enablereinitialize="true"
							validationSchema={authSchema}
							onSubmit={(values) => this.handleSubmit(values)}
						>
							{({
								values,
								errors,
								touched,
								isValid,
								handleBlur,
								handleChange,
								handleSubmit,
								dirty,
								setFieldValue,
								setErrors,
							}) => (
								<Form
									noValidate={true}
									onBlur={handleBlur}
									onSubmit={handleSubmit}
									className="completeProfile__form"
								>
									<div className="completeProfile__group">
										<div className="row no-gutters">
											<div className="col-12 col-md-6 completeProfile__form-left-column">
												<label className="form__group w-100 mb-0 ">
													<div className="form__label text-uppercase form__label-disabled">
														Email Address *
													</div>
													<input
														type="email"
														className="form__field"
														onBlur={handleBlur}
														value={values.email}
														name="email"
														onChange={handleChange}
														disabled={true}
													/>
													{errors.email &&
														touched.email && (
															<div className="form__field--error">
																{errors.email}
															</div>
														)}
												</label>
											</div>
											<div className="col-12 col-md-6">
												<label
													className="form__group w-100 d-flex mb-0"
													style={{
														gap: "6px",
														height: "fit-content",
														position: "relative",
														zIndex: 200,
													}}
												>
													{this.props.countries
														?.length > 0 && (
														<div
															className="mobile-code-select__container"
															style={{
																marginBottom:
																	"5px",
															}}
														>
															<SelectDropDown
																onSelect={(
																	val
																) => {
																	setFieldValue(
																		"mobile_code",
																		val?.value
																	);
																}}
																label="COUNTRY"
																selectOptions={this.props?.countries.map(
																	(country) =>
																		formatWithSpecificAttributes(
																			country,
																			customFormatShape(
																				"mobile_code"
																			)
																		)
																)}
																selectStyle={
																	mobileCodeSelectStyle
																}
																selectHeight={
																	"fit-content"
																}
																defaltValue={
																	globalSelectMobileCode
																}
															/>
														</div>
													)}
													<div
														className="d-flex flex-column"
														style={{
															width: "100%",
														}}
													>
														<div className="form__label text-uppercase ">
															Mobile number*
														</div>
														<input
															type="text"
															className="form__field"
															onChange={
																handleChange
															}
															onBlur={handleBlur}
															value={
																values.mobile
															}
															name="mobile"
														/>
														{errors.mobile &&
															touched.mobile && (
																<div className="form__field--error">
																	{
																		errors.mobile
																	}
																</div>
															)}
													</div>
												</label>
											</div>
										</div>
										<div className="row no-gutters">
											<div className="col-12 col-md-6 completeProfile__form-left-column d-flex">
												<label className="form__group w-100    d-flex flex-column">
													<div className="form__label text-uppercase ">
														Date of birth*
													</div>
													<input
														id="dob"
														type="text"
														className={
															"form__field " +
															(this.state
																.dobError &&
																" form__field--error ")
														}
														style={{
															flexGrow: 1,
															marginBottom: 0,
														}}
														placeholder="DD/MM/YYYY"
														readOnly
														value={
															this.state.dob !=
															null
																? moment(
																		this
																			.state
																			.dob
																  ).format(
																		"DD/MM/YYYY"
																  )
																: ""
														}
														onClick={() =>
															this.setState({
																showDatePicker: true,
															})
														}
														onBlur={handleBlur}
														name="dateofbirth"
													/>
													{errors.mobile &&
														touched.dateofbirth && (
															<div className="form__field--error">
																{
																	errors.dateofbirth
																}
															</div>
														)}
												</label>
												{this.state.showDatePicker && (
													<SingleDatePicker
														className="completeProfile__datepicker"
														selectedDate={
															this.state.dob ||
															moment().toDate()
														}
														onDayClick={(
															day,
															restrict
														) => {
															if (!restrict) {
																this.setState({
																	showDatePicker: false,
																});
															}
															setFieldValue(
																"dateofbirth",
																day
																	? moment(
																			day
																	  ).format(
																			"DD/MM/YYYY"
																	  )
																	: ""
															);
															this.setState({
																dob: day,
																dobError: false,
															});
														}}
													/>
												)}
												<div className="form__msg--error">
													Date of birth is not valid
												</div>
											</div>
											{this.state.showDatePicker && (
												<div
													className="cover"
													onClick={() =>
														this.setState({
															showDatePicker: false,
														})
													}
												/>
											)}
											<div
												className="col-12 col-md-6"
												style={{ zIndex: 100 }}
											>
												<label className="w-100 bg-white">
													<div className="form__label text-uppercase">
														District*
													</div>
													<Select
														closeMenuOnSelect={true}
														styles={{
															option: (base) => ({
																...base,
																height: "100%",
															}),
														}}
														placeholder="Select"
														defaultValue={
															latestProfileProps &&
															this.state
																.districtList
																.length > 0 &&
															this.state?.districtList.find(
																(x) =>
																	x.Postal_DistrictID ===
																	latestProfileProps
																		?.profile
																		?.postal_districtid
															)
														}
														onChange={(val) => {
															setFieldValue(
																"district",
																val?.Postal_DistrictID
															);
														}}
														components={{
															Control: (
																props
															) => (
																<div className="district-select">
																	<components.Control
																		{...props}
																	/>
																</div>
															),
															IndicatorSeparator:
																(props) => (
																	<components.IndicatorSeparator
																		{...props}
																		className="d-none"
																	/>
																),
															ValueContainer: (
																props
															) => (
																<components.ValueContainer
																	{...props}
																	className="p-0 pl-2"
																/>
															),
															IndicatorsContainer:
																(props) => (
																	<components.IndicatorsContainer
																		{...props}
																		className="district-select-indicator"
																	/>
																),
															Option: (props) => {
																return (
																	<components.Option
																		{...props}
																		className={`district-select-option ${
																			props.isSelected &&
																			"active"
																		}`}
																	/>
																);
															},
														}}
														getOptionLabel={(
															option
														) =>
															option.General_Location
														}
														getOptionValue={(
															option
														) =>
															option.Postal_DistrictID
														}
														options={
															this.state
																.districtList
														}
													/>
													<input
														type="text"
														className="form__field d-none"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.district}
														name="district"
													/>
													{errors.district &&
														touched.district && (
															<div className="form__field--error">
																{
																	errors.district
																}
															</div>
														)}
												</label>
											</div>
										</div>

										<div className="row no-gutters">
											<div className="col-12 col-md-6 completeProfile__form-left-column">
												<div className="form__group ">
													<div className="form__label text-uppercase ">
														I Identify as *
													</div>
													<div
														className={
															"d-flex  pl-4 form__input completeProfile__gender bg-white " +
															(this.state
																.genderError &&
																" form__group--error ")
														}
													>
														<div className="row w-100 completeProfile__gender-selection mx-0">
															<div className="col-sm-5 pl-sm-0">
																<Checkbox
																	type="default"
																	className="completeProfile__checkbox d-block checkbox--tick"
																	selected={
																		this
																			.state
																			.gender ==
																		"male"
																	}
																	error={
																		this
																			.state
																			.genderError
																	}
																	onChange={() => {
																		setFieldValue(
																			"gender",
																			"male"
																		);
																		this.setState(
																			{
																				gender: "male",
																				genderError: false,
																			}
																		);
																	}}
																>
																	Male
																</Checkbox>
															</div>
															<div className="col-sm-7 pl-sm-0">
																<Checkbox
																	type="default"
																	className="completeProfile__checkbox checkbox--tick"
																	selected={
																		this
																			.state
																			.gender ==
																		"female"
																	}
																	error={
																		this
																			.state
																			.genderError
																	}
																	onChange={() => {
																		setFieldValue(
																			"gender",
																			"female"
																		);
																		this.setState(
																			{
																				gender: "female",
																				genderError: false,
																			}
																		);
																	}}
																>
																	Female
																</Checkbox>
															</div>
															<div className="col-sm-5 pl-sm-0">
																<Checkbox
																	type="default"
																	className="completeProfile__checkbox checkbox--tick"
																	selected={
																		this
																			.state
																			.gender ==
																		"non_binary"
																	}
																	error={
																		this
																			.state
																			.genderError
																	}
																	onChange={() => {
																		setFieldValue(
																			"gender",
																			"non_binary"
																		);
																		this.setState(
																			{
																				gender: "non_binary",
																				genderError: false,
																			}
																		);
																	}}
																>
																	Non-binary
																</Checkbox>
															</div>
															<div className="col-sm-7 pl-sm-0">
																<Checkbox
																	type="default"
																	className="completeProfile__checkbox checkbox--tick"
																	selected={
																		this
																			.state
																			.gender ==
																		"disclose"
																	}
																	error={
																		this
																			.state
																			.genderError
																	}
																	onChange={() => {
																		setFieldValue(
																			"gender",
																			"disclose"
																		);
																		this.setState(
																			{
																				gender: "disclose",
																				genderError: false,
																			}
																		);
																	}}
																>
																	Prefer not
																	to disclose
																</Checkbox>
															</div>
														</div>
													</div>

													<input
														type="hidden"
														name="gender"
													/>
													{errors.gender &&
														touched.gender && (
															<div className="form__field--error">
																{errors.gender}
															</div>
														)}
												</div>
											</div>
											<div className="col-12 col-md-6 d-flex">
												<label className="form__group w-100    d-flex flex-column">
													<div className="form__label text-uppercase">
														NOTES TO INSTRUCTORS
													</div>
													<textarea
														wrap={false}
														type="text"
														className="form__field"
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder="Injuries or medical issues(if any)"
														style={{
															resize: "none",
															flexGrow: 1,
														}}
														value={
															values.note_instructor
														}
														name="note_instructor"
													/>
													{errors.note_instructor &&
														touched.note_instructor && (
															<div className="form__field--error">
																{
																	errors.note_instructor
																}
															</div>
														)}
												</label>
											</div>
										</div>
									</div>
									<div className="completeProfile__group">
										<h2 className="completeProfile__subtitle ">
											Emergency contact
										</h2>
										<div className="row no-gutters">
											<div className="col-12 col-md-6 completeProfile__form-left-column">
												<label className="form__group w-100">
													<div className="form__label text-uppercase">
														Contact name *
													</div>
													<input
														type="text"
														className="form__field"
														name="emergency_contact_name"
														onChange={handleChange}
														onBlur={handleBlur}
														value={
															values.emergency_contact_name
														}
													/>
													{errors.emergency_contact_name &&
														touched.emergency_contact_name && (
															<div className="form__field--error">
																{
																	errors.emergency_contact_name
																}
															</div>
														)}
												</label>
											</div>
											<div className="col-12 col-md-6">
												<label className="form__group w-100">
													<div className="form__label text-uppercase">
														Contact No. *
													</div>
													<input
														type="text"
														className="form__field"
														name="emergency_contact_no"
														onChange={handleChange}
														onBlur={handleBlur}
														value={
															values.emergency_contact_no
														}
													/>
													{errors.emergency_contact_no &&
														touched.emergency_contact_no && (
															<div className="form__field--error">
																{
																	errors.emergency_contact_no
																}
															</div>
														)}
												</label>
											</div>
										</div>
									</div>
									<div className="clearfix">
										<Button
											type="submit"
											className="completeProfile__btn"
										>
											{!this.state.busy
												? "Save"
												: "Saving..."}
										</Button>
									</div>
								</Form>
							)}
						</Formik>
					)}
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		profile: store.profile,
		authentication: store.authentication,
		countries: store.masterData?.data?.countries,
		countryId: store.country?.countryId,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		updateProfile: bindActionCreators(updateProfile, dispatch),
		updateAvatar: bindActionCreators(updateAvatar, dispatch),
		disconnectFacebook: bindActionCreators(disconnectFacebook, dispatch),
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
		getProfile: bindActionCreators(getProfile, dispatch),
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(CompleteProfileModal)
);

import React from "react";
import { Link } from "react-router-dom";

/**
 * Social links in footer
 */
export default function FooterSupport() {
	return (
		<nav className="">
			<span className="footer__link-wrap">
				<Link
					to="/new-to-ym"
					className="footer__link animated click-efx"
				>
					Newbie Guide
				</Link>
			</span>
			<span className="footer__link-wrap">
				<Link to="/faq" className="footer__link animated click-efx">
					FAQ
				</Link>
			</span>
			<span className="footer__link-wrap">
				<Link
					to="/contact-us"
					className="footer__link animated click-efx"
				>
					Contact Us
				</Link>
			</span>
		</nav>
	);
}

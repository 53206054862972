import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import qs from "qs";

import { memberEndpointURL } from "../../../base/config";
import { getErrorMessage } from "../../../base/functions";

import { disableScroll } from "../../../redux/ui";
import { getClassPacks, selectClassPacks } from "../../../redux/classPack";
import { getBookedClassDetail } from "../../../redux/profile";

import RateClassModal from "../RateClassModal/RateClassModal.jsx";
import NewbiePaymentModal from "../NewbiePaymentModal/NewbiePaymentModal.jsx";
import Cookies from "js-cookie";

/**
 * wrapper component to hold RateClassModal and NewbiePaymentModal
 * because these modals are presentation components
 * RatingPurchaseWrapper will make the necessary calls and feed the necessary values
 */
class RatingPurchaseWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			busy: false,
			error: null,
			showNewBiePaymentModal: false,
		};

		let queryObj = qs.parse(this.props?.location?.search, {
			ignoreQueryPrefix: true,
		});
		if (queryObj["class-id"]) {
			this.props.getBookedClassDetail(queryObj["class-id"]);
		}
		if (queryObj.action == "newbie-buy-class-pack") {
			this.props.getClassPacks(
				"pending",
				this.props?.profile?.profile?.country_id
			); // PENDING:
		}
		this.props.getClassPacks("pending");
	}

	componentDidUpdate(prevProps) {
		let prevProfileProps = prevProps.profile;
		let latestProfileProps = this.props.profile;
		let prevQueryObj = qs.parse(prevProps.location.search, {
			ignoreQueryPrefix: true,
		});

		let queryObj = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});

		if (prevProfileProps.status != latestProfileProps.status) {
			switch (latestProfileProps.status) {
				case "GET_BOOKED_CLASS_DETAIL_ERROR":
					this.handleModalClose();
					break;
			}
		}
		if (
			prevProfileProps.status !== latestProfileProps.status &&
			latestProfileProps?.profile?.pending_billing?.bill_id
		) {
			this.setState({
				showNewBiePaymentModal: true,
			});
		}

		//get class packs for newbie if action updated to newbie-buy-class-pack
		if (
			prevQueryObj.action != queryObj?.action &&
			queryObj.action == "newbie-buy-class-pack"
		) {
			this.props.getClassPacks("pending"); // PENDING:
		}

		//get booked class details if class-id is updated and populated
		if (
			prevQueryObj["class-id"] != queryObj["class-id"] &&
			queryObj["class-id"]
		) {
			this.props.getBookedClassDetail(queryObj["class-id"]);
		}
	}

	/**
	 * callback when user closes modal window
	 */
	handleModalClose() {
		let queryObj = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});

		this.setState({
			showNewBiePaymentModal: false,
		});

		this.props.disableScroll(false);

		// if (queryObj["r"]) {
		// 	this.props.history.push({
		// 		pathname: "/",
		// 	});
		// }

		delete queryObj.action;
		delete queryObj["class-id"];
		delete queryObj["r"];
		this.props.history.push({
			pathname: this.props.location.pathname,
			search: qs.stringify(queryObj),
		});
	}

	/**
	 * pass ratings to api
	 * if this is the first class, show payment modal after it resolves
	 * otherwise close modal and update booking detail
	 * @param {number} mood 			class mood rating [1-5]
	 * @param {number} teacher_rating 	teacher rating [1-5]
	 * @param {number} class_id 		id of the class
	 */
	rateClass(mood, teacher_rating, feedback, class_id) {
		const queryObj = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});

		const { authentication, profile } = this.props;

		this.setState({ success: null, error: null, busy: true });

		axios
			.post(
				memberEndpointURL + "RateClass",
				{
					class_id,
					class_rating: mood,
					comment: feedback,
					teacher_rating,
				},
				{
					headers: {
						Authorization: "Bearer " + authentication.accessToken,
					},
				}
			)
			.then((res) => {
				this.setState({
					busy: false,
				});
				if (res.data.errorCode == 0) {
					this.props.getBookedClassDetail(queryObj["class-id"]);
					this.props.history.push({
						pathname: "/",
					});
					if (profile.profile.mem_tier != "newbie") {
						this.handleModalClose();
					} else {
						queryObj.action = "newbie-buy-class-pack";
						queryObj.rateSuccess = "true";
						this.props.history.push({
							pathname: this.props.location.pathname,
							search: qs.stringify(queryObj),
						});
					}
				} else {
					this.setState({
						error: getErrorMessage(res.data),
					});
				}
			})
			.catch((err) => {
				this.setState({
					error: getErrorMessage(err),
					busy: false,
				});
				this.props.disableScroll(false);
			});
	}

	/**
	 * onClick callback for MyClassesDetailNewbiePayment Proceed to pay button
	 * @param {object} classPack  selected classpack
	 */
	handleProceedToPayment(classPack) {
		this.props.selectClassPacks(classPack);
		this.props.disableScroll(false);
		this.props.history.push("/classes/purchase-preview");
	}

	render() {
		let queryObj = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});
		const { ui, profile, classPack } = this.props;

		// if (!profile.memberBookedClass) return null; // NOTE: this the old logic define by prev dev!
		let bookedClass = profile?.memberBookedClass;

		switch (true) {
			case (queryObj.action == "rate-class" &&
				!bookedClass?.class_rating) ||
				queryObj.r === "1":
				return (
					<>
						<RateClassModal
							onModalClose={() => this.handleModalClose()}
							onClickCTA={(mood, rating, feedback) =>
								this.rateClass(
									mood,
									rating,
									feedback,
									profile?.memberBookedClass?.class_id
								)
							}
							isNewbie={profile.profile.mem_tier == "newbie"}
							bookedClass={profile?.memberBookedClass}
							busy={this.state.busy}
							error={this.state.error}
						/>
					</>
				);

			case profile?.profile?.mem_tier === "newbie" &&
				this.props.location.pathname === "/" &&
				this.state.showNewBiePaymentModal:
				return (
					<NewbiePaymentModal
						onModalClose={() => this.handleModalClose()}
						onClickNewbiePaid={() => window.location.reload()}
						onProceed={(classPack) =>
							this.handleProceedToPayment(classPack)
						}
						classPacks={classPack?.classPacks?.map((pack) => {
							return pack?.pack_list;
						})}
						pendingBilling={classPack?.pendingBilling}
						busy={this.state.busy}
						error={this.state.error}
						activeBreakPoint={ui.activeBreakPoint}
					/>
				);

			default:
				return null;
		}
	}
}

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
		ui: store.ui,
		classPack: store.classPack,
		profile: store.profile,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		disableScroll: bindActionCreators(disableScroll, dispatch),
		getClassPacks: bindActionCreators(getClassPacks, dispatch),
		selectClassPacks: bindActionCreators(selectClassPacks, dispatch),
		getBookedClassDetail: bindActionCreators(
			getBookedClassDetail,
			dispatch
		),

		setRatingSuccess: bindActionCreators(
			(dispatch) =>
				dispatch({
					type: "RATE_CLASS_SUCCESS",
				}),
			dispatch
		),
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(RatingPurchaseWrapper)
);

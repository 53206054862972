import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { showAuthentication } from "../../../redux/ui";

import Modal from "../../Modal/Modal.jsx";
import RegisterForm from "./RegisterForm/RegisterForm.jsx";
import "./RegisterModal.scss";

class RegisterModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const { authentication } = this.props;
		// if user is already login, dismiss the modal,
		if (authentication.accessToken) {
			this.props.showAuthentication(null);
		}
	}

	handleClose() {
		this.props.showAuthentication(null);
	}

	render() {
		const cssPrefix = "Auth-header";

		return (
			<Modal
				className="registerModal"
				maxWidth={480}
				title="Welcome to"
				onClose={() => this.handleClose()}
			>
				<div className="auth__content">
					<div className="auth__contents">
						<div
							className={`${cssPrefix} d-flex align-items-center justify-content-between`}
						>
							<div className="position-relative">
								<img
									src="/assets/img/ui/logo-yoga-movement.svg"
									alt="Welcome to Yoga Movement"
									className={`${cssPrefix}__icon`}
								/>
								<div
									className="loginForm__user-pic circle position-absolute"
									style={{
										background: `url('img/icons/newbie-icon.svg') no-repeat #fff center / 30px auto`,
									}}
								></div>
							</div>

							<div
								className="d-flex align-items-center cursor-pointer"
								onClick={() =>
									this.props.showAuthentication("log-in")
								}
							>
								<span
									className={`${cssPrefix}__text text-capitalize`}
								>
									SIGN IN
								</span>
							</div>
						</div>
						<RegisterForm />
					</div>
				</div>
			</Modal>
		);
	}
}
const mapStateToProps = function (store) {
	return {
		profile: store.profile,
		authentication: store.authentication,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterModal);

import React from "react";

import { formatPrice } from "../../../../base/functions";
import ClassPackIcon from "../../../ClassPack/ClassPackIcon/ClassPackIcon.jsx";
import "./NewbiePaymentOption.scss";

/** individual class pack option in newbie payment modal */
export default function NewbiePaymentOptionLoading() {
	return (
		<li className="newbiePaymentOption mb-3 d-flex justify-content-between click-efx animated">
			<ClassPackIcon
				className="isLoading isLoading--animated"
				classPack={{ pack_type: "", total_credit: 0 }}
			/>
			<div className="newbiePaymentOption__detail">
				<h3 className="newbiePaymentOption__name isLoading">&nbsp;</h3>
				<div className="newbiePaymentOption__note mb-2 isLoading">
					&nbsp;
				</div>
				<div className="isLoading">&nbsp;</div>
			</div>
			<div className="newbiePaymentOption__pricing text-right">
				<div className="newbiePaymentOption__totalPrice isLoading">
					&nbsp;
				</div>
				<div className="newbiePaymentOption__unitPrice isLoading">
					&nbsp;
				</div>
			</div>
		</li>
	);
}

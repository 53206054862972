import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { disableScroll } from "../../redux/ui";
import "./Overlay.scss";

/**
 * hoc to transform component into an overlay
 * @param  {object} 	getComponent 	the component to transform
 * @return {object}						transformed component
 */
export default function withOverlay(Component) {
	class Overlay extends PureComponent {
		static propTypes = {
			/** @type {function} 	onBeforeClose	triggers before overlay closes */
			onBeforeClose: PropTypes.func,
			/** @type {function} 	onClose			triggers when overlay closes */
			onClose: PropTypes.func,
			/** @type {string} 		className		react className prop */
			className: PropTypes.string,
			/** @type {number} 		closeDuration	buffer time in ticks for close animation to complete */
			closeDuration: PropTypes.number
		};

		static defaultProps = {
			onBeforeClose: () => {},
			onClose: () => {},
			className: null,
			closeDuration: 400
		};

		constructor(props) {
			super(props);
			this.state = {
				closing: false
			};
			this.props.disableScroll(true);
		}

		componentWillUnmount() {
			this.props.disableScroll(false);
		}

		triggerClose(e) {
			this.setState({ closing: true });
			this.props.onBeforeClose(e);
			this.props.disableScroll(false);
			setTimeout(() => this.props.onClose(e), this.props.closeDuration);
		}

		render() {
			let overlayClassName = `overlay d-flex h-full align-items-center ${
				this.props.className
			} `;
			if (this.state.closing) overlayClassName += " overlay--closing ";

			return (
				<aside style={{
					height: '100% !important;'
				}} className={overlayClassName}>
					<div className="overlay__inner">
						<div
							className="overlay__backing"
							onClick={e => this.triggerClose(e)}
						/>
						<Component
							{...this.props}
							triggerClose={() => this.triggerClose()}
						/>
					</div>
				</aside>
			);
		}
	}

	const mapStateToProps = function(store) {
		return {};
	};
	const mapDispatchToProps = function(dispatch) {
		return {
			disableScroll: bindActionCreators(disableScroll, dispatch)
		};
	};

	return connect(
		mapStateToProps,
		mapDispatchToProps
	)(Overlay);
}

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NavLink, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { isValidJWT } from "../../../base/functions";
import {
	showAuthentication,
	showMobileNavigation,
	setActiveSection,
	disableMobileScroll,
} from "../../../redux/ui";
import { logout } from "../../../redux/authentication";
import { resetClassPacks, resetPromoCode } from "../../../redux/classPack";
import { resetPayment } from "../../../redux/payment";
import { resetProfile, getProfile } from "../../../redux/profile";
import { resetSchedules } from "../../../redux/schedules/schedules.jsx";
import { resetAdhocPayments } from "../../../redux/adhocPayments";
import HeaderNavItem from "../HeaderNavItem/HeaderNavItem.jsx";
import CountrySelector from "../CountrySelect/CountrySelector.jsx";
import "./HeaderNavUser.scss";
/**
 * user navigation in header
 */
class HeaderNavUser extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		const { authentication, profile } = this.props;

		if (isValidJWT(authentication.accessToken) && !profile.profile) {
			this.props.getProfile();
		}
	}

	/**
	 * standard click event for navigation item
	 * hides mobile menu and enable mobile scroll
	 */
	handleClick() {
		this.props.showMobileNavigation(false);
		this.props.disableMobileScroll(false);
	}

	/**
	 * click callback for the authentication items (login / register)
	 * triggers authentication modal windows
	 * @param  {string} section  authentication modal window to show
	 */
	handleAuthClick(section) {
		this.props.showAuthentication(section);
		this.props.showMobileNavigation(false);
		this.props.disableMobileScroll(false);
	}

	/**
	 * logs user out and reset various stores
	 */
	handleLogout() {
		setTimeout(() => {
			this.props.logout();
			this.props.resetClassPacks();
			this.props.resetPromoCode();
			this.props.resetPayment();
			this.props.resetSchedules();
			this.props.resetAdhocPayments();
			//this.props.resetProfile();
			this.props.history.push("/");
		}, 100);
	}

	render() {
		const { condenseHeader } = this.props;

		const { ui, profile, authentication } = this.props;

		if (!isValidJWT(authentication.accessToken) || !profile.profile) {
			return (
				<nav
					className={
						"headerNavUser headerNavUser--unauthenticated " +
						(condenseHeader && " headerNavUser--condensed ")
					}
				>
					<ul className="headerNavUser__list _authList">
						<HeaderNavItem
							to="#"
							name="Register"
							className="headerNavUser__item headerNavUser__item--w-divider"
							onClick={() => this.handleAuthClick("register")}
						/>
						<HeaderNavItem
							to="#"
							name="Sign In"
							className="headerNavUser__item headerNavUser__item--w-divider"
							onClick={() => this.handleAuthClick("log-in")}
						/>
					</ul>
					{this.props.children}
					<CountrySelector />
				</nav>
			);
		} else {
			return (
				<nav
					className={
						"headerNavUser " +
						(condenseHeader && " headerNavUser--condensed ")
					}
				>
					<ul className="flex-wrap headerNavUser__list d-flex d-md-block flex-column-reverse">
						{/*<HeaderNavItem
							name="My classes"
							to="/account/classes"
							className="headerNavUser__item"
							onClick={() => this.handleClick()}
						/>*/}

						<HeaderNavItem
							name={
								<span className="headerNavUser__profile-name">
									Hi {profile.profile.first_name}!{" "}
									<img
										src={
											this.props.ui.activeSection ===
											"account"
												? "img/icons/chevron-up-teal.svg"
												: "img/icons/chevron-down-teal.svg"
										}
										alt="dropdown"
										className="headerNavUser__down headerNavUser__down-right"
									/>
									{profile.profile.avatar_img ? (
										<div
											className="headerNavUser__pic circle"
											style={{
												background: `url(${profile.profile.avatar_img}) no-repeat center / cover`,
											}}
										/>
									) : (
										<div className="headerNavUser__pic circle">
											{profile.profile.first_name.substr(
												0,
												1
											)}
											{profile.profile.last_name.substr(
												0,
												1
											)}
										</div>
									)}
								</span>
							}
							to="/account"
							className="headerNavUser__item headerNavUser__item--user"
							active={this.props.ui.activeSection === "account"}
							activeClassName="skip"
							onClick={() =>
								this.props.setActiveSection("account")
							}
							ignoreChildren={true}
						>
							<ul className="headerNavUser__sub headerNavMain__sub">
								<li className="headerNavMain__sub-item">
									<NavLink
										to="/account/edit"
										className="headerNavMain__sub-link animated d-block click-efx"
										activeClassName="headerNavUser__sub-link--active"
										onClick={() => this.handleClick()}
									>
										Edit profile
									</NavLink>
								</li>

								<li className="headerNavMain__sub-item">
									<div
										to="#"
										className="headerNavMain__sub-link animated d-block click-efx"
										onClick={() => {
											this.handleLogout();
											Cookies.remove("fine_fee");
											Cookies.remove("showPopUp");
										}}
									>
										Logout
									</div>
								</li>
							</ul>
						</HeaderNavItem>
						<HeaderNavItem
							name={<img src="img/icons/notifications.svg" />}
							to="/account/notifications"
							className="headerNavUser__item headerNavUser__notification"
							badge={profile.profile.has_new_notification}
							onClick={() => this.handleClick()}
						/>
					</ul>

					{this.props.children}
					<CountrySelector />
				</nav>
			);
		}
	}
}

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
		profile: store.profile,
		ui: store.ui,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
		getProfile: bindActionCreators(getProfile, dispatch),
		showMobileNavigation: bindActionCreators(
			showMobileNavigation,
			dispatch
		),
		disableMobileScroll: bindActionCreators(disableMobileScroll, dispatch),
		setActiveSection: bindActionCreators(setActiveSection, dispatch),
		resetClassPacks: bindActionCreators(resetClassPacks, dispatch),
		resetPromoCode: bindActionCreators(resetPromoCode, dispatch),
		resetPayment: bindActionCreators(resetPayment, dispatch),
		resetProfile: bindActionCreators(resetProfile, dispatch),
		resetSchedules: bindActionCreators(resetSchedules, dispatch),
		resetAdhocPayments: bindActionCreators(resetAdhocPayments, dispatch),
		logout: bindActionCreators(logout, dispatch),
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(HeaderNavUser)
);

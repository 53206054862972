import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";

import * as anim from "./featured.json";
import "./ClassPackIcon.scss";

const packSize = (totalCredit) => {
	switch (true) {
		case totalCredit === 1:
			return <span>S</span>;
		// case totalCredit === 2:
		// 	return <span>LU</span>;
		case totalCredit === 0:
			return "";
		default:
			return <span>{totalCredit}</span>;
	}
};

const propTypes = {
	/** @type {object}   	class packs prop */
	classPack: PropTypes.object.isRequired,
	/** @type {className}   className */
	className: PropTypes.string,
	/** @type {boolean}   	show loading state */
	loading: PropTypes.bool,
	/** @type {boolean}   	whether animation is enabled */
	animated: PropTypes.bool,
};

const defaultPropTypes = {
	classPack: {},
	loading: false,
	animated: true,
};

const defaultOptions = {
	renderer: "canvas",
	loop: true,
	autoplay: true,
	animationData: anim.default,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};

/**
 * Class pack icon
 * Each class pack icon has pack_type(non_shareable, shareable or unlimited)
 * and will differ in background color according to the pack_type.
 * Non shareable follow 10 Class Pack.
 * Shareable follow their own respective Share Pack.
 * Unlimited will use infinity_circle.svg.
 */
export default function ClassPackIcon({
	classPack,
	className,
	loading,
	animated,
}) {
	const { total_credit, pack_type, pack_icon, is_animation } = classPack;

	let classNameProp = `ClassPackIcon circle-badge ${pack_type} ${
		pack_icon ? "ClassPackIcon--no-bg" : ""
	}`;
	if (className) classNameProp += ` ${className} `;
	if (loading) classNameProp += ` isLoading isLoading--animated `;

	return (
		<div className={classNameProp}>
			{!pack_icon && packSize(total_credit)}
			{pack_icon && (
				<div
					className="ClassPackIcon__img"
					style={{
						background: `url(${pack_icon}) no-repeat center / cover`,
					}}
				/>
			)}
			{animated !== false && is_animation && (
				<div className="ClassPackIcon__animation">
					<Lottie options={defaultOptions} height={56} width={56} />
				</div>
			)}
		</div>
	);
}
ClassPackIcon.propTypes = propTypes;
ClassPackIcon.defaultPropTypes = defaultPropTypes;

import React from "react";
import PropTypes from "prop-types";
import Button from "../../Button/Button.jsx";
import "./HeaderBtnPill.scss";

const propTypes = {
	className: PropTypes.string,
};

const defaultProps = {
	classNam: "",
};

export default function HeaderBtnPill({
	handleBookClick,
	goToClassPack,
	className,
}) {
	return (
		<a className="no-click-efx headerBtnPill">
			<div className={`button__btn-tab ${className}`}>
				<Button
					onClick={() => goToClassPack()}
					className="d-flex-inline align-items-center button__splitter button__btn-tab-content left text-uppercase"
				>
					<img src="/assets/img/icons/classpack.svg" />
					Buy a class pack
				</Button>

				<Button
					onClick={() => handleBookClick()}
					className="d-flex-inline align-items-center button__btn-tab-content right text-uppercase"
				>
					<img src="/assets/img/icons/book.svg" />
					Book a mat
				</Button>
			</div>
		</a>
	);
}

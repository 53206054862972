import React from "react";
import PropTypes from "prop-types";
import "./Checkbox.scss";

/**
 * stylised checkbox component that replaces the native one
 */

const propTypes = {
	/** @type {string} 		checkbox type (default|tick|big-tick) */
	type: PropTypes.string,
	/** @type {boolean} 	show selected state */
	selected: PropTypes.bool,
	/** @type {boolean} 	show error state */
	error: PropTypes.bool,
	/** @type {boolean} 	show disabled state */
	disabled: PropTypes.bool,
	/** @type {string} 		react className prop */
	className: PropTypes.string,
	/** @type {function} 	onChange event of the <input> */
	onChange: PropTypes.func,
	/** @type {string} 		children elements */
	children: PropTypes.node,
};
const defaultProps = {
	type: "default",
	selected: false,
	error: false,
	disabled: false,
	className: null,
	onChange: () => {},
	children: null,
};

export default function Checkbox({
	type,
	selected,
	error,
	disabled,
	className,
	onChange,
	children,
	...props
}) {
	let typeClass = "checkbox--" + type;
	let selectedClass = selected && " checkbox--selected ";
	let errorClass = error && " checkbox--error ";
	let disabledClass = disabled && " checkbox--disabled ";
	return (
		<label
			className={`checkbox animated d-flex align-items-center ${typeClass} ${selectedClass} ${errorClass} ${disabledClass} ${className}`}
		>
			<div className="checkbox__wrapper">
				<div className="checkbox__icon animated d-flex justify-content-center align-items-center circle" />
				<input
					type="checkbox"
					className="checkbox__input d-none"
					onChange={() => onChange()}
					checked={selectedClass}
					{...props}
				/>
			</div>
			<span className="checkbox__text">{children}</span>
		</label>
	);
}
Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

import React from "react";
import { Link } from "react-router-dom";

import "./MustReadHeader.scss";

export default function MustReadHeader({ content }) {
	return (
		<div
			className="mustReadHeader"
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	);
}

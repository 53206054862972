import React from "react";
import { SingaporeFlagSVG, MalaysiaFlagSVG } from "../Icons/CountriesFlagIcons";

export const getCustomStyles = (selectWidth, selectHeight) => ({
	option: (provided, state) => ({
		display: "flex",
		alignItems: "center",
		padding: "10px 15px",
		justifyContent: "space-between",
		border: "none",
		outline: "none",
		cursor: "pointer",
		...(state?.isSelected && {
			backgroundColor: "#28445B",
			color: "#fff",
		}),
	}),
	clearIndicator: (provided, state) => ({
		...provided,
		display: "none",
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: "none",
	}),

	singleValue: (provided, state) => ({
		...provided,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		fontFamily: "Inter, sans-serif",
		fontSize: "16px",
		fontWeight: "400",
	}),
	control: (provided, state) => ({
		...provided,
		width: selectWidth,
		"&:focus": { borderColor: "none" },
		boxShadow: "none",
		"&:hover": { borderColor: "#1E90FF" },
		height: selectHeight,
		paddingLeft: "10px",
		paddingRight: "8px",
		borderRadius: "0px",
		border: "none",
	}),
	valueContainer: (provided, state) => ({
		...provided,
		padding: "0px",
	}),
	menu: (provided, state) => ({
		...provided,
		width: selectWidth,
		marginTop: "0px",
		paddingTop: "0px",
		borderRadius: "0px",
		border: "none",
		boxShadow: "none",
	}),
	menuList: (provided, state) => ({
		...provided,
		paddingTop: "0px",
		borderRadius: "0px",
		paddingBottom: "0px",
		border: "none",
		boxShadow: "none",
	}),
});

export const formatCountryObj = (countryObj) => {
	return {
		label: countryObj?.country_name,
		value: countryObj?.country_id,
		icon: countryObj?.country_logo,
	};
};

export const formatWithCountryCode = (countryObj) => ({
	label: countryObj?.country_name,
	value: countryObj?.country_code,
	icon: countryObj?.country_logo,
});

export const formatWithSpecificAttributes = (countryObj, formatObj) => {
	let obj = {
		label: countryObj[formatObj.label],
		value: countryObj[formatObj.value],
		icon: countryObj[formatObj.icon],
	};
	return obj;
};

export const formatCountryArray = (countryArray, formatFunction) => {
	return countryArray.map((country) => formatFunction(country));
};

export const customFormatShape = (attribute = "mobile_code") => {
	const staticAttributes = ["mobile_code"];
	return {
		label: staticAttributes?.includes(attribute)
			? attribute
			: "country_name",
		value: attribute,
		icon: "country_logo",
	};
};

const flagIconStyle = {
	width: "27px",
	height: "20px",
};

export const renderFlagIcon = (
	url,
	alt = "country-flag",
	style = flagIconStyle
) => {
	return <img src={url} alt={alt} style={style} />;
};

export const getCountry = (searchVal, allCountries, attributeName) => {
	const matchCountry = allCountries.find(
		(country) => country[attributeName] === searchVal
	);
	return matchCountry ? matchCountry : allCountries[0];
};

export const getCountryByCountryCode = (countryCode, allCountries) => {
	const country = getCountry(countryCode, allCountries, "country_code");
	return formatWithCountryCode(country);
};

export const getCountryByCountryID = (countryID, allCountries) => {
	const country = getCountry(countryID, allCountries, "country_id");
	return formatCountryObj(country);
};

export const getCountryWithCustomAttribute = (
	countryId,
	allCountries,
	attributeName
) => {
	const country = getCountry(countryId, allCountries, "country_id");
	return formatWithSpecificAttributes(
		country,
		customFormatShape(attributeName)
	);
};

export const getCountryByCountryMobileCode = (mobileCode, allCountries) => {
	const country = getCountry(mobileCode, allCountries, "mobile_code");
	return formatWithSpecificAttributes(
		country,
		customFormatShape("mobile_code")
	);
};

import React from "react";
import { Link } from "react-router-dom";

/**
 * Main footer navigation
 */
export default function FooterNav() {
	return (
		<nav className="footerNav">
			<div className="row">
				<div className="col-lg-5 col-6">
					<span className="footer__link-wrap">
						<Link
							to="/"
							className="footer__link animated click-efx"
						>
							YM Life
						</Link>
					</span>
					<span className="footer__link-wrap">
						<Link
							to="/account/friends"
							className="footer__link animated click-efx"
						>
							YM Friends
						</Link>
					</span>
					<span className="footer__link-wrap">
						<Link
							to="/studios"
							className="footer__link animated click-efx"
						>
							Studios
						</Link>
					</span>
					<span className="footer__link-wrap">
						<Link
							to="/classes/class-types"
							className="footer__link animated click-efx"
						>
							Class Types
						</Link>
					</span>
				</div>
				<div className="col-lg-5 col-6">
					<span className="footer__link-wrap">
						<Link
							to="/merchandise"
							className="footer__link animated click-efx"
						>
							Merchandise
						</Link>
					</span>
					<span className="footer__link-wrap">
						<Link
							to="/the-team"
							className="footer__link animated click-efx"
						>
							The Team
						</Link>
					</span>
					<span className="footer__link-wrap">
						<Link
							to="/teacher-training"
							className="footer__link animated click-efx"
						>
							Academy
						</Link>
					</span>
					<span className="footer__link-wrap">
						<Link
							to="/account/ym-fam-perks"
							className="footer__link animated click-efx"
						>
							YM FAM Perks
						</Link>
					</span>
				</div>
			</div>
		</nav>
	);
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import Fuse from "fuse.js";
import qs from "qs";
import {
	getFriendList,
	responseFriendRequest,
	removeFromFriendList,
	searchMember,
	addToFriendList,
	shareClass,
} from "../../../redux/friends";

import { end, scrollElemTop } from "../../../base/functions";
import Modal from "../../Modal/Modal.jsx";

import FriendList from "./FriendList/FriendList.jsx";

import "./AddFriendsModal.scss";

/**
 * wrapper modal window for friends list
 *
 */
class AddFriendsModal extends Component {
	static propTypes = {
		/** @type {function} 	runs when close modal  */
		onClose: PropTypes.func.isRequired,
		/** @type {string} 	 	class ID to invite to */
	};

	constructor(props) {
		super(props);
		this.state = {
			//true by default to show loading state
			loading: this.props.isYmFriends,
			busy: false,
			screen: "friends-list",
			//store in local state to support search
			friendsList: [],
			//error message
			errorMessage: null,
			selectedFriendIDs: [],
		};
		if (this.props.isYmFriends) {
			this.props.getFriendList();
		}
	}

	componentDidUpdate(prevProps) {
		let prevFriendsProps = prevProps.friends;
		let latestFriendsProps = this.props.friends;
		if (prevFriendsProps.status != latestFriendsProps.status) {
			switch (latestFriendsProps.status) {
				case "GET_FRIEND_LIST":
				case "SEARCH_MEMBER":
					this.setState({
						loading: true,
					});
					break;

				case "GET_FRIEND_LIST_SUCCESS":
					this.setState({
						loading: false,
						friendList: this.props.friends.friendList,
					});
					break;

				case "SEARCH_MEMBER_SUCCESS":
					this.setState({
						loading: false,
						friendList: this.props.friends.searchMemberList,
					});
					break;

				case "REMOVE_FROM_FRIEND_LIST_ERROR":
				case "RESPONSE_FRIEND_REQUEST_ERROR":
				case "SEARCH_MEMBER_ERROR":
					this.setState({
						busy: false,
						errorMessage: latestFriendsProps.error,
					});
					break;
			}
		}
	}

	/**
	 * onClick callback for each friend in FriendsModalList
	 * @param  {object} friend friend object
	 */
	handleClickFriend(friend) {
		let list = this.state.selectedFriendIDs;
		if (!list.includes(friend.member_id)) {
			list = [...list, friend.member_id];
		} else {
			list = list.filter((id) => id !== friend.member_id);
		}
		this.setState({
			selectedFriendIDs: list,
			errorMessage: null,
		});
	}

	/**
	 * search friends list
	 * @return {string}  the term used to search
	 */
	handleSearch(searchTerm) {
		return this.props.searchMember(searchTerm);
	}

	/**
	 * callback when click "Send invites now"
	 * validate if any friends is selected first
	 */
	handleClickSendInvite() {
		if (!this.state.selectedFriendIDs.length) {
			this.setState({
				errorMessage: "Please select some friends to invite",
			});
			scrollElemTop(
				document.querySelector(".friendsModal .overlay__inner"),
				0
			);
		} else {
			this.setState({
				busy: true,
				errorMessage: null,
			});
		}
	}

	handleSharePack(member_id) {
		const queryObj = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});

		// this.props?.shareClass(member_id, queryObj?.pack_id);
	}

	handleFriendsReset() {
		this.setState({
			friendList: this.props.friends.friendList,
		});
	}

	handleMemberSearchReset() {
		this.setState({
			friendList: [],
		});
	}

	render() {
		return (
			<Modal
				className="friendsModal"
				onClose={() => this.props.onClose()}
				maxWidth={480}
			>
				<h3 className="friendsModal__title">Your YM Friends List</h3>
				<div className="friendsModal__content">
					<FriendList
						loading={this.state.loading}
						busy={this.state.busy}
						list={this.state.friendList}
						isYmFriends={this.props.isYmFriends}
						errorMessage={this.state.errorMessage}
						selectedIDs={this.state.selectedFriendIDs}
						onClickFriend={(friend) =>
							this.handleClickFriend(friend)
						}
						friendsReset={() => this.handleFriendsReset()}
						memberSearchReset={() => this.handleMemberSearchReset()}
						onSearch={(searchTerm) => this.handleSearch(searchTerm)}
						onRemove={this.props.removeFromFriendList}
						onAddFriend={this.props.addToFriendList}
						onResponseFriendRequest={
							this.props.responseFriendRequest
						}
					/>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		friends: store.friends,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getFriendList: bindActionCreators(getFriendList, dispatch),
		responseFriendRequest: bindActionCreators(
			responseFriendRequest,
			dispatch
		),
		removeFromFriendList: bindActionCreators(
			removeFromFriendList,
			dispatch
		),
		shareClass: bindActionCreators(shareClass, dispatch),
		addToFriendList: bindActionCreators(addToFriendList, dispatch),
		searchMember: bindActionCreators(searchMember, dispatch),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AddFriendsModal);

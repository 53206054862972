import immer from "immer";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * toggles friend feed visibility
 */
export const toggleFriendFeedVisibility = () => ({
	type: "TOGGLE_FRIEND_FEED_VISIBILITY"
});

/**
 * resets friend feed visibility status
 */
export const resetFriendFeedVisibility = () => ({
	type: "RESET_FRIEND_FEED_VISIBILITY"
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const initialState = {
	status: null,
	showFriendsFeed: true
};

export function ymLife(state = initialState, action) {
	return immer(state, draft => {
		switch (action.type) {
			case "TOGGLE_FRIEND_FEED_VISIBILITY":
				draft.status = action.type;
				draft.showFriendsFeed = !draft.showFriendsFeed;
				break;

			case "RESET_FRIEND_FEED_VISIBILITY":
				draft.status = action.type;
				draft.showFriendsFeed = true;
				break;
		}
	});
}

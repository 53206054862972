import React from "react";
import "./FooterSocial.scss";

/**
 * Social links in footer
 */
export default function FooterSocial() {
	return (
		<nav className="footerSocial">
			<span className="footer__link-wrap pr-3 mb-0">
				<a
					href="https://www.facebook.com/YogaMovementSingapore"
					target="_blank"
					rel="noopener"
					className="footer__link animated click-efx"
					title="Opens in a new window"
				>
					<img src="/assets/img/icons/fb.svg" alt="Share on Facebook" />
				</a>
			</span>
			<span className="footer__link-wrap mb-0">
				<a
					href="https://www.instagram.com/yogamovement"
					target="_blank"
					rel="noopener"
					className="footer__link animated click-efx"
					title="Opens in a new window"
				>
					<img src="/assets/img/icons/ig.svg" alt="Share on Instagram" />
				</a>
			</span>
		</nav>
	);
}

import axios from "axios";
import immer from "immer";
import qs from "qs";
import { REHYDRATE } from "redux-persist";
import {
	baseEndpointURL,
	memberEndpointURL,
	webEndpointURL,
	endpointCreds,
	endpointDomain,
	globalMemberEndpoint,
	globalWebEndpointURL,
	endpointVersion,
} from "../base/config";
import { getProfile } from "./profile.jsx";
import { getErrorMessage, end } from "../base/functions";
import { getFriendList } from "./friends";
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * sign user into account
 * @param  {string} email  		email address
 * @param  {string} password  	account password
 * @param  {string} grant_type  password|refresh_token
 */
export const login = (
	email,
	password,
	grant_type = "password",
	popUpCallBack = () => {}
) => {
	return (dispatch) => {
		dispatch({ type: "LOGIN" });
		// check email existence first
		axios
			.post(
				globalMemberEndpoint + "CheckAccountStatus",
				{ email, password },
				{ auth: endpointCreds }
			)
			.then((res) => {
				// if email is not registered, throw the error
				if (res.data.errorCode === 406) {
					return dispatch({
						type: "LOGIN_ERROR",
						err: {
							response: {
								data: {
									error: "Invalid email and password",
								},
							},
						},
					});
				}

				axios
					.post(
						// v2 login endpoint has some problem,
						// use v1 login endpoint
						// endpointDomain + "global/" + "getToken",
						baseEndpointURL + "getToken",
						qs.stringify({
							email: email,
							password: password,
							grant_type: grant_type,
							device_type: "website",
						}),
						{
							auth: endpointCreds,
							header: {
								"Content-type":
									"application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						dispatch({
							type: "LOGIN_RESOLVE",
							email: email,
							res: res.data,
						});

						if (res?.data?.access_token) {
							popUpCallBack(res?.data);
						}
					})
					.catch((err) =>
						dispatch({
							type: "LOGIN_ERROR",
							err: err,
						})
					);
			})
			.catch((e) => {
				dispatch({
					type: "LOGIN_ERROR",
					err: {
						response: {
							data: {
								error: "Invalid email and password",
							},
						},
					},
				});
			});
	};
};

/**
 * registration step 1
 * @param  {string} email  		user email address
 * @param  {string} password  	user password
 */
export const registerStep1 = (email, password) => {
	return (dispatch) => {
		dispatch({ type: "REGISTER_STEP_1" });
		return axios
			.post(
				webEndpointURL + "RegisterStep1",
				{
					password: password,
					email: email,
				},
				{
					auth: endpointCreds,
				}
			)
			.then((res) =>
				dispatch({
					type: "REGISTER_STEP_1_RESOLVE",
					email: email,
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "REGISTER_STEP_1_ERROR",
					err: "Something went wrong. Please try again later.",
				})
			);
	};
};

/**
 * registration step 2, user fills in "About you" form
 * @param  {string} firstName user first name
 * @param  {string} lastName  user last name
 * @param  {string} contact   contact number
 * @param  {string} gender    male|female
 * @param  {string} birthday  YYYY/MM/DD
 * @param  {string} referral  referral code (optional)
 *
 */

export const registerStep2 = (body, headerConfig = {}) => {
	return (dispatch, getState) => {
		let store = getState();
		dispatch({ type: "REGISTER_STEP_2" });

		return axios
			.post(
				globalWebEndpointURL + "RegisterStep2",
				{
					mem_id: store.authentication.profile.mem_id,
					...body,
				},
				{
					auth: endpointCreds,
					headers: {
						...headerConfig,
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "REGISTER_STEP_2_RESOLVE",
					res: { ...res.data, CountryID: headerConfig.CountryID },
				})
			)
			.catch((err) =>
				dispatch({
					type: "REGISTER_STEP_2_ERROR",
					err: err,
				})
			);
	};
};

/**
 * registration step 3, user agrees to T&C and add emergency contact info
 * @param  {string} name 	 emergency contact name
 * @param  {string} contact  emergency contact number
 */

const defaultConfig = {
	name: "",
	contact: "",
	countryId: 1,
};
export const registerStep3 = (config = defaultConfig) => {
	const { name, contact, countryId, popUpCallBack = () => {} } = config;
	return (dispatch, getState) => {
		let store = getState();
		dispatch({ type: "REGISTER_STEP_3" });
		return axios
			.post(
				webEndpointURL + "RegisterStep3",
				{
					mem_id: store.authentication.profile.mem_id,
					emergency_contact_name: name,
					emergency_contact_no: contact,
				},
				{
					auth: endpointCreds,
					headers: {
						CountryID: countryId,
					},
				}
			)
			.then((res) => {
				return dispatch({
					type: "REGISTER_STEP_3_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "REGISTER_STEP_3_ERROR",
					err: err,
				})
			);
	};
};

/**
 * set authentication from other async hook rather than from redux store
 */
export const setAuthenticationHook = (res) => ({
	type: "VERIFY_SUCCESS",
	res: {
		...res,
		statusCode: 0,
		error: 0,
	},
});

/**
 * send Facebook token to backend after Facebook authentication dialog
 * @param  {string} token  facebook token
 */
export const googleAuthenticate = (token) => {
	return (dispatch) => {
		dispatch({ type: "GOOGLE_AUTHENTICATE" });
		// "push_notification_token": "",
		// "device_type": "android"
		return axios
			.post(
				memberEndpointURL + "RegisterGoogle",
				{
					token: token,
					device_type: "website",
					type: "request_connect",
				},
				{ auth: endpointCreds }
			)
			.then((res) =>
				dispatch({
					type: "GOOGLE_AUTHENTICATE_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GOOGLE_AUTHENTICATE_ERROR",
					err: err,
				})
			);
	};
};

/**
 * send Facebook token to backend after Facebook authentication dialog
 * @param  {string} token  facebook token
 */
export const facebookAuthenticate = (token) => {
	return (dispatch) => {
		dispatch({ type: "FACEBOOK_AUTHENTICATE" });
		return axios
			.post(
				memberEndpointURL + "RegisterFacebook",
				{ token: token, device_type: "website" },
				{ auth: endpointCreds }
			)
			.then((res) =>
				dispatch({
					type: "FACEBOOK_AUTHENTICATE_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "FACEBOOK_AUTHENTICATE_ERROR",
					err: err,
				})
			);
	};
};

export const appleAuthenticate = (token,action) => {
	return (dispatch) => {
		dispatch({ type: "APPLE_AUTHENTICATE_LOADING" });
		return axios
			.post(
				memberEndpointURL + "RegisterApple",
				{ token: token, device_type: "website" },
				{ auth: endpointCreds }
			)
			.then((res) => {
				if (action.res.errorCode == 406) {
					dispatch({
						type: "APPLE_AUTHENTICATE_RESOLVE",
						res: { ...res.data, must_fill: true },
					});
				} else if (action.res.errorCode == 0) {
					dispatch({
						type: "APPLE_AUTHENTICATE_RESOLVE",
						res: res.data,
					});
				} else {
					dispatch({
						type: "APPLE_AUTHENTICATE_ERROR",
						err: res.data,
					});
				}
			})
			.catch((err) =>
				dispatch({
					type: "APPLE_AUTHENTICATE_ERROR",
					err: err,
				})
			);
	};
};

/**
 * link member account with facebook
 * this is also used when requesting for additional permissions on Facebook
 * @param	{string} token	facebook token
 * @param	{string} type 	request_connect - when link account to Facebook account
 *                        	request_permission - when request for additional permissions; this suppresses the "already connected" error message
 *
 */
export const connectFacebook = (token, type = "request_connect") => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "CONNECT_FACEBOOK" });
		return axios
			.post(
				memberEndpointURL + "ConnectFacebook",
				{ token: token, type },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "CONNECT_FACEBOOK_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "CONNECT_FACEBOOK_ERROR",
					err: err,
				})
			);
	};
};

/**
 * for new users who have not connected to facebook
 * @param  {string} token  facebook token
 */
export const disconnectFacebook = (token) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "DISCONNECT_FACEBOOK" });
		return axios
			.post(
				memberEndpointURL + "DisconnectFacebook",
				{ token: token, device_type: "website" },
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				if (res.data.errorCode === 0) {
					dispatch(getProfile());
				}
				dispatch({
					type: "DISCONNECT_FACEBOOK_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "DISCONNECT_FACEBOOK_ERROR",
					err: err,
				})
			);
	};
};

/*
 * registration step 2 for Apple, user fills in "About you" form
 * @param  {string} firstName user first name
 * @param  {string} lastName  user last name
 * @param  {string} contact   contact number
 * @param  {string} gender    male|female
 * @param  {string} birthday  YYYY/MM/DD
 * @param  {string}	appleId
 * @param  {string} referral  referral code (optional)
 */
export const registerWithApple = (payload) => {
	return (dispatch, getState) => {
		let store = getState();
		dispatch({ type: "REGISTER_STEP_3" });

		return axios
			.post(
				memberEndpointURL + "RegisterApple",
				{
					...payload,
					apple_id: store.authentication.apple_id,
				},
				{ auth: endpointCreds }
			)
			.then((res) => {
				if (res.data.errorCode !== 0) {
					return dispatch({
						type: "REGISTER_STEP_3_ERROR",
						err: res.data,
					});
				}
				dispatch({
					type: "REGISTER_STEP_3_SUCCESS",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "REGISTER_STEP_3_ERROR",
					err: err,
				})
			);
	};
};

/**
 * registration step 2 for Facebook, user fills in "About you" form
 * @param  {string} firstName user first name
 * @param  {string} lastName  user last name
 * @param  {string} contact   contact number
 * @param  {string} gender    male|female
 * @param  {string} birthday  YYYY/MM/DD
 * @param  {string} referral  referral code (optional)
 */
export const registerStep2Facebook = (body) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "REGISTER_STEP_2_FACEBOOK" });

		return axios
			.post(
				memberEndpointURL + "UpdateProfileFacebookStep1",
				{
					mem_id: store.authentication.profile.mem_id,
					...body,
				},
				{ auth: endpointCreds }
			)
			.then((res) =>
				dispatch({
					type: "REGISTER_STEP_2_FACEBOOK_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "REGISTER_STEP_2_FACEBOOK_ERROR",
					err: err,
				})
			);
	};
};

/**
 * registration step 3 facebook, user agrees to T&C and add emergency contact info
 * @param  {string} name 	 emergency contact name
 * @param  {string} contact  emergency contact number
 */

export const registerStep3Facebook = (name, contact) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "REGISTER_STEP_3_FACEBOOK" });
		return axios
			.post(
				memberEndpointURL + "UpdateProfileFacebookStep2",
				{
					mem_id: store.authentication.profile.mem_id,
					emergency_contact_name: name,
					emergency_contact_no: contact,
				},
				{ auth: endpointCreds }
			)
			.then((res) =>
				dispatch({
					type: "REGISTER_STEP_3_FACEBOOK_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "REGISTER_STEP_3_FACEBOOK_ERROR",
					err: err,
				})
			);
	};
};

/**
 * set new password for migrated users
 * @param  {string} token  		token to validate the set password request
 * @param  {string} password  	the new password
 */
export const createPassword = (token, password) => {
	return (dispatch) => {
		dispatch({ type: "CREATE_PASSWORD" });
		return axios
			.post(
				memberEndpointURL + "CreatePassword",
				{ create_token: token, new_password: password },
				{
					auth: endpointCreds,
				}
			)
			.then((res) =>
				dispatch({
					type: "CREATE_PASSWORD_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "CREATE_PASSWORD_ERROR",
					err: err,
				})
			);
	};
};

/**
 * request to reset password
 * @param  {string} email  email address of account
 */
export const forgotPassword = (email) => {
	return (dispatch) => {
		dispatch({ type: "FORGOT_PASSWORD" });
		return axios
			.post(
				memberEndpointURL + "ForgotPassword",
				{ email: email },
				{
					auth: endpointCreds,
				}
			)
			.then((res) =>
				dispatch({
					type: "FORGOT_PASSWORD_RESOLVE",
					email: email,
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "FORGOT_PASSWORD_ERROR",
					err: err,
				})
			);
	};
};

/**
 * reset password for users who forgot password
 * @param  {string} token  		token to validate the reset password request
 * @param  {string} password  	the new password
 */
export const resetPassword = (token, password) => {
	return (dispatch) => {
		dispatch({ type: "RESET_PASSWORD" });
		return axios
			.post(
				memberEndpointURL + "ResetPassword",
				{ recovery_token: token, new_password: password },
				{
					auth: endpointCreds,
				}
			)
			.then((res) =>
				dispatch({
					type: "RESET_PASSWORD_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "RESET_PASSWORD_ERROR",
					err: err,
				})
			);
	};
};

/**
 * check if account exists / locked / link
 */
export const checkAccountStatus = () => {
	return (dispatch, getState) => {
		dispatch({ type: "CHECK_ACCOUNT_STATUS" });
		let store = getState();
		let email = store.authentication.email;
		return axios
			.post(
				memberEndpointURL + "CheckAccountStatus",
				{ email: email },
				{ auth: endpointCreds }
			)
			.then((res) =>
				dispatch({
					type: "CHECK_ACCOUNT_STATUS_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "CHECK_ACCOUNT_STATUS_ERROR",
					err: err,
				})
			);
	};
};

/**
 * for users who did not complete the 3-step registration process
 * they will not be able to login
 * need this special endpoint to check and redirect according to their progress
 */
export const checkLogin = (email, password) => {
	return (dispatch, getState) => {
		dispatch({ type: "CHECK_LOGIN" });
		let store = getState();
		let email = store.authentication.email;
		return axios
			.post(
				memberEndpointURL + "CheckLogin",
				{ email, password },
				{ auth: endpointCreds }
			)
			.then((res) =>
				dispatch({
					type: "CHECK_LOGIN_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "CHECK_LOGIN_ERROR",
					err: err,
				})
			);
	};
};

/**
 * manually set log in error
 * for expired token
 */
export const setLoginError = (error) => ({ type: "SET_LOGIN_ERROR", error });

/**
 * set email address
 * email is needed during login fail and need to check account status
 * @param  {string} 	email 	email address
 */
export const setEmail = (email) => ({ type: "SET_EMAIL", email: email });

/**
 * set apple id
 * apple id will be used in registration step 2 after the apple login
 * @param  {string} 	apple_id apple_id
 */
export const setAppleId = (apple_id) => ({ type: "SET_APPLE_ID", apple_id });

export const setApplePayload = (payload) => ({
	type: "SET_APPLE_REGISTRATION_PAYLOAD",
	payload,
});

/**
 * set function to run after log in / register
 * @param  {function} 	callback 	the function to run
 */
export const setAuthenticationCallback = (callback) => ({
	type: "SET_AUTHENTICATION_CALLBACK",
	callback: callback,
});

/**
 * revert store to initial state
 */
export const resetAuthentication = () => ({ type: "RESET_AUTHENTICATION" });

/**
 * log user out
 * revert entire store except email to initial state
 */
export const logout = () => ({ type: "LOGOUT" });
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const initialState = {
	status: null,
	CountryID: 1,
	loginError: null,
	facebookError: null,

	registerStatusCode: 0,
	registerError: null,

	forgotPasswordError: null,
	createPasswordError: null,
	resetPasswordError: null,
	accountStatus: null,

	accessToken: null,
	email: null,

	// set value in store once apple id was decoded from the backend response
	// this will be used in registering for step2 with apple id
	apple_id: null,

	// apple registration step will be completely different with facebook
	// both registration step 2 and step 3 will be called inside one endpoint
	// so we need to save the registration payload in each step
	apple_registration_payload: {},

	/**
	 * populated from register or facebook authentication
	 * for registration purposes only
	 * not for displaying of user profile and data is NOT persisted
	 * as such for profile details, pls use profile redux instead
	 * there is a separate logic to populate profile redux after fb authentication in Register.jsx
	 */
	profile: null,

	callback: null,
};

export function authentication(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			//register
			case "REGISTER_STEP_1":
				draft.status = action.type;
			case "REGISTER_STEP_3":
				draft.status = action.type;
				draft.loginError = null;
				draft.registerError = null;
				draft.registerStatusCode = 0;
				break;
			case "REGISTER_STEP_1_RESOLVE":
				draft.email = action.email;
				if (action.res.errorCode == 0) {
					draft.status = "REGISTER_STEP_1_SUCCESS";
					draft.profile = action.res.data.member_info;
				} else {
					draft.status = "REGISTER_STEP_1_ERROR";
					draft.registerStatusCode = action.res.errorCode;
					draft.registerError = getErrorMessage(action.res);
				}
				break;
			case "REGISTER_STEP_2":
				draft.status = "REGISTER_STEP_2";
				break;
			case "REGISTER_STEP_2_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "REGISTER_STEP_2_SUCCESS";
					draft.profile = action.res.data.member_info;
					draft.CountryID = action.res.CountryID;
					draft.registerError = "";
				} else {
					draft.status = "REGISTER_STEP_2_ERROR";
					draft.registerStatusCode = action.res.errorCode;
					draft.registerError = getErrorMessage(action.res);
				}
				break;
			case "REGISTER_STEP_3_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "REGISTER_STEP_3_SUCCESS";
					draft.profile = action.res.data.member_info;
					draft.accessToken =
						action.res.data.member_info.access_token;
				} else {
					draft.status = "REGISTER_STEP_3_ERROR";
					draft.registerStatusCode = action.res.errorCode;
					draft.registerError = getErrorMessage(action.res);
				}
				break;
			case "REGISTER_STEP_1_ERROR":
			case "REGISTER_STEP_2_ERROR":
			case "REGISTER_STEP_3_ERROR":
				draft.status = action.type;
				if (action.err) {
					draft.registerStatusCode = action.err.errorCode;
				}
				draft.registerError = getErrorMessage(action.err);
				break;

			//login
			case "LOGIN":
				draft.status = action.type;
				draft.accountStatus = null;
				draft.loginError = null;
				draft.profile = null;
				break;
			case "VERIFY_SUCCESS":
				draft.email = action.email;
				if (action.res.error == 0) {
					draft.status = "LOGIN_SUCCESS";
					draft.profile = action.res;
					draft.accessToken = action.res.access_token;
				} else {
					draft.status = "LOGIN_ERROR";
					draft.accountStatus = action.res.error;
					draft.loginError = getErrorMessage(action.res);
				}
				break;

			case "LOGIN_RESOLVE":
				draft.email = action.email;
				if (action.res.error == 0) {
					draft.status = "LOGIN_SUCCESS";
					draft.accessToken = action.res.access_token;
				} else {
					draft.status = "LOGIN_ERROR";
					draft.accountStatus = action.res.error;
					draft.loginError = getErrorMessage(action.res);
				}
				break;
			case "LOGIN_ERROR":
				draft.status = action.type;
				draft.accountStatus = action.err.response.data.error;
				draft.loginError = getErrorMessage(action.err);
				break;

			//facebook authenticate
			case "FACEBOOK_AUTHENTICATE":
				draft.status = action.type;
				draft.facebookError = null;
				break;
			case "FACEBOOK_AUTHENTICATE_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "FACEBOOK_AUTHENTICATE_SUCCESS";
					draft.accessToken =
						action.res.data.member_info.access_token;
					draft.profile = action.res.data.member_info;
					draft.facebookError = null;
				} else {
					draft.status = "FACEBOOK_AUTHENTICATE_ERROR";
					draft.facebookError = getErrorMessage(action.res);
				}
				break;
			case "FACEBOOK_AUTHENTICATE_ERROR":
				draft.status = action.type;
				draft.facebookError = getErrorMessage(action.err);
				break;

			// google login

			case "GOOGLE_AUTHENTICATE_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GOOGLE_AUTHENTICATE_SUCCESS";
					draft.accessToken =
						action.res.data.member_info.access_token;
					draft.profile = action.res.data.member_info;
					draft.googleError = null;
				} else {
					draft.status = "GOOGLE_AUTHENTICATE_ERROR";
					draft.googleError = getErrorMessage(action.res);
				}
				break;
			case "GOOGLE_AUTHENTICATE_ERROR":
				draft.status = action.type;
				draft.googleError = getErrorMessage(action.err);
				break;
			// apple login
			case "APPLE_AUTHENTICATE_LOADING":
				draft.status = action.type;
				draft.error = null;
				break;

			case "APPLE_AUTHENTICATE_RESOLVE":
				draft.status = "APPLE_AUTHENTICATE_RESOLVE";
				draft.accessToken = action.res.data.member_info.access_token;
				draft.profile = action.res.data.member_info;
				draft.error = null;
				break;

			case "APPLE_AUTHENTICATE_ERROR":
				draft.status = "APPLE_AUTHENTICATE_ERROR";
				draft.error = getErrorMessage(action.err);
				break;

			//facebook connect
			case "CONNECT_FACEBOOK":
				draft.status = action.type;
				draft.facebookError = null;
				break;
			case "CONNECT_FACEBOOK_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "CONNECT_FACEBOOK_SUCCESS";
					draft.accessToken =
						action.res.data.member_info.access_token;
					draft.profile = action.res.data.member_info;
					draft.facebookError = null;
				} else {
					draft.status = "CONNECT_FACEBOOK_ERROR";
					draft.facebookError = getErrorMessage(action.res);
				}
				break;
			case "CONNECT_FACEBOOK_ERROR":
				draft.status = action.type;
				draft.facebookError = getErrorMessage(action.err);
				break;

			// disconnect from facebook
			case "DISCONNECT_FACEBOOK":
				draft.status = action.type;
				draft.facebookError = null;
				break;
			case "DISCONNECT_FACEBOOK":
				if (action.res.errorCode == 0) {
					draft.status = "DISCONNECT_FACEBOOK_SUCCESS";
				} else {
					draft.status = "DISCONNECT_FACEBOOK_ERROR";
				}
				break;
			case "DISCONNECT_FACEBOOK_ERROR":
				draft.status = action.type;
				break;

			// register via facebook
			case "REGISTER_STEP_2_FACEBOOK":
			case "REGISTER_STEP_3_FACEBOOK":
				draft.status = action.type;
				draft.registerError = null;
				draft.registerStatusCode = 0;
				break;
			case "REGISTER_STEP_2_FACEBOOK_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "REGISTER_STEP_2_FACEBOOK_SUCCESS";
					draft.profile = action.res.data.member_info;
				} else {
					draft.status = "REGISTER_STEP_2_FACEBOOK_ERROR";
					draft.registerStatusCode = action.res.errorCode;
					draft.registerError = getErrorMessage(action.res);
				}
				break;
			case "REGISTER_STEP_3_FACEBOOK_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "REGISTER_STEP_3_FACEBOOK_SUCCESS";
					draft.profile = action.res.data.member_info;
					draft.accessToken =
						action.res.data.member_info.access_token;
				} else {
					draft.status = "REGISTER_STEP_3_FACEBOOK_ERROR";
					draft.registerStatusCode = action.res.errorCode;
					draft.registerError = getErrorMessage(action.res);
				}
				break;
			case "REGISTER_STEP_2_FACEBOOK_ERROR":
			case "REGISTER_STEP_3_FACEBOOK_ERROR":
				draft.status = action.type;

				draft.registerError = getErrorMessage(action.err);
				break;

			//create password
			case "CREATE_PASSWORD":
				draft.status = action.type;
				draft.createPasswordError = null;
				break;
			case "CREATE_PASSWORD_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "CREATE_PASSWORD_SUCCESS";
				} else {
					draft.status = "CREATE_PASSWORD_ERROR";
					draft.createPasswordError = getErrorMessage(action.res);
				}
				break;
			case "CREATE_PASSWORD_ERROR":
				draft.status = action.type;
				draft.createPasswordError = getErrorMessage(action.err);
				break;

			//forgot password
			case "FORGOT_PASSWORD":
				draft.status = action.type;
				draft.forgotPasswordError = null;
				break;
			case "FORGOT_PASSWORD_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "FORGOT_PASSWORD_SUCCESS";
					draft.email = action.email;
				} else {
					draft.status = "FORGOT_PASSWORD_ERROR";
					draft.forgotPasswordError = getErrorMessage(action.res);
				}
				break;
			case "FORGOT_PASSWORD_ERROR":
				draft.status = action.type;
				draft.forgotPasswordError = getErrorMessage(action.err);
				break;

			//reset password
			case "RESET_PASSWORD":
				draft.status = action.type;
				draft.resetPasswordError = null;
				break;
			case "RESET_PASSWORD_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "RESET_PASSWORD_SUCCESS";
				} else {
					draft.status = "RESET_PASSWORD_ERROR";
					draft.resetPasswordError = getErrorMessage(action.res);
				}
				break;
			case "RESET_PASSWORD_ERROR":
				draft.status = action.type;
				draft.resetPasswordError = getErrorMessage(action.err);
				break;

			//check account status
			case "CHECK_ACCOUNT_STATUS":
				draft.status = action.type;
				draft.accountStatus = null;
				break;
			case "CHECK_ACCOUNT_STATUS_RESOLVE":
				if (action.res.errorCode != 0) {
					draft.status = "CHECK_ACCOUNT_STATUS_ERROR";
					draft.accountStatus = action.res.errorCode;
					draft.loginError = action.res.message;
				} else {
					draft.status = "CHECK_ACCOUNT_STATUS_SUCCESS";
				}
				break;
			case "CHECK_ACCOUNT_STATUS_ERROR":
				draft.status = action.type;
				draft.loginError = getErrorMessage(action.err);
				break;

			//check login
			case "CHECK_LOGIN":
				draft.status = action.type;
				draft.accountStatus = null;
				break;
			case "CHECK_LOGIN_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "CHECK_LOGIN_SUCCESS";
					draft.profile = action.res.data.member_info;
					draft.loginError = null;
				} else {
					draft.status = "CHECK_LOGIN_ERROR";
					draft.accountStatus = action.res.errorCode;
					draft.loginError = getErrorMessage(action.res);
				}
				break;
			case "CHECK_LOGIN_ERROR":
				draft.status = action.type;
				break;

			case "SET_LOGIN_ERROR":
				draft.loginError = action.error;
				break;

			case "SET_EMAIL":
				draft.email = action.email;
				break;

			case "SET_AUTHENTICATION_CALLBACK":
				draft.callback = action.callback;
				break;

			case "LOGOUT":
				draft = Object.assign(draft, {
					...initialState,
					email: draft.email,
					status: action.type,
				});
				break;

			case "SET_APPLE_ID":
				draft.apple_id = action.apple_id;
				break;

			case "SET_APPLE_REGISTRATION_PAYLOAD":
				draft.apple_registration_payload = action.payload;
				draft.email = action.payload.email;
				break;

			case "RESET_AUTHENTICATION":
				draft = Object.assign(draft, initialState);
				break;
		}
	});
}

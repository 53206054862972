import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";

import { end, getErrorMessage } from "../../../base/functions";
import { memberEndpointURL, endpointCreds } from "../../../base/config";
import { disableScroll, showAuthentication } from "../../../redux/ui";

import Modal from "../../Modal/Modal.jsx";
import ModalFooter from "../../Modal/ModalFooter/ModalFooter.jsx";
import Button from "../../Button/Button.jsx";
import ErrorMessage from "../../Alerts/ErrorMessage/ErrorMessage.jsx";
import SuccessMessage from "../../Alerts/SuccessMessage/SuccessMessage.jsx";
import "./MigrationModal.scss";

class MigrationModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			busy: false,
			success: null,
			error: null
		};
	}

	handleClose() {
		this.props.showAuthentication(null);
	}

	handleClickResend() {
		this.setState({ busy: true });

		axios
			.post(
				memberEndpointURL + "RequestCreatePassword",
				{ email: this.props.authentication.email },
				{ auth: endpointCreds }
			)
			.then(res => {
				if (res.data.errorCode == 0) {
					this.setState({ busy: false, success: res.data.message });
				} else {
					this.setState({
						busy: false,
						error: getErrorMessage(res.data)
					});
				}
			})
			.catch(err => {
				this.setState({ busy: false, error: getErrorMessage(err) });
			});
	}

	render() {
		return (
			<Modal
				className="migrationModal"
				icon={
					<img
						src="assets/img/ui/logo-yoga-movement.svg"
						alt="Migration to our new YM platform"
					/>
				}
				maxWidth={600}
				title={
					<span>
						Migration to our
						<br />
						new YM platform
					</span>
				}
				onClose={() => this.handleClose()}
			>
				<div className="auth__contents">
					{this.state.success && (
						<SuccessMessage className="text-center mb-5">
							{this.state.success}
						</SuccessMessage>
					)}
					{this.state.error && (
						<ErrorMessage className="text-center mb-5">
							{this.state.error}
						</ErrorMessage>
					)}
					<p>
						We have an awesome new Yoga Movement online platform. To
						ensure a proper migration and lock up security, we would
						like you to help by setting a new password for your
						account. Please kindly check your email{" "}
						<strong>{this.props.authentication.email}</strong> for
						the instructions.
					</p>
					<p>
						Didn’t receive any email? Don’t worry, you can request
						it again and it’ll be sent to the email address{" "}
						<strong>{this.props.authentication.email}</strong>{" "}
						shortly.
					</p>
				</div>
				<ModalFooter>
					<Button
						className="migrationModal__btn auth__btn text-uppercase"
						onClick={() => this.handleClickResend()}
						disabled={this.state.busy}
					>
						{!this.state.busy ? "Resend email" : "Sending..."}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}

const mapStateToProps = function(store) {
	return {
		authentication: store.authentication
	};
};

const mapDispatchToProps = function(dispatch) {
	return {
		disableScroll: bindActionCreators(disableScroll, dispatch),
		showAuthentication: bindActionCreators(showAuthentication, dispatch)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MigrationModal);

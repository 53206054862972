import React from "react";
import PropTypes from "prop-types";

import withOverlay from "../../hocs/withOverlay/withOverlay";
import "./Modal.scss";

const propTypes = {
	/** @type {function} 			triggers when overlay closes */
	onClose: PropTypes.func.isRequired,
	/** @type {function} 			close function from withOverlay hoc */
	triggerClose: PropTypes.func.isRequired,
	/** @type {string} 				react className prop */
	className: PropTypes.string,
	/** @type {number} 				max width of overlay window */
	maxWidth: PropTypes.number,
	/** @type {string|node} 		title in the header */
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	/** @type {string|number|node} 	icon in the header */
	icon: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.node,
	]),
	/** @type {string} 				bg color of icon in the header */
	iconColor: PropTypes.string,
	/** @type {string} 				children elements */
	children: PropTypes.node.isRequired,
	/** @type {string} 				prop to render <header> or not */
	hideHeader: PropTypes.bool,
};

const defaultProps = {
	className: "",
	maxWidth: 600,
	title: null,
	icon: null,
	iconColor: "#1f455f",
	hideHeader: true,
};

/**
 * Standard modal window with header and close button provided, transformed into an overlay via the withOverlay hoc
 * In most cases, this modal window will be displayed via a state variable in its parent
 * The modal window can be closed via the inbuilt close button or triggerClose prop
 * However that close behaviour is just a css opacity animation; the modal window still retains the block box-model and block interactions
 * As such, please always use the onClose prop to clear that state when the modal window closes to remove the modal window from the DOM
 */
function Modal({
	onClose,
	triggerClose,
	className,
	maxWidth,
	height,
	title,
	icon,
	iconColor,
	children,
	hideHeader,
}) {
	/**
	 * onClick event of close button
	 * @param  {object} e onClick event object
	 */
	const handleCloseCallback = (e) => {
		e.preventDefault();

		triggerClose();

	};

	let headerClassName =
		"modal__header d-flex justify-content-center align-items-center text-center ";

	return (
		<div

			className={"modal " + className}
			style={{ maxWidth: maxWidth, height: height?? '100% !important;' }}
		>
			<button
				className="modal__btn-close click-efx circle animated"
				onClick={(e) => handleCloseCallback(e)}
			/>
			{hideHeader === false && (
				<header className={headerClassName}>
					<h2 className="modal__title text-uppercase">{title}</h2>
					<div
						className="modal__icon circle d-flex justify-content-center align-items-center"
						style={{ backgroundColor: iconColor }}
					>
						{icon}
					</div>
				</header>
			)}
			{children}
		</div>
	);
}
Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default withOverlay(Modal);

import { compose, formatPrice } from "./../../base/functions.jsx";

// TODO: Move const variable into a constant file
// GST rate
export const GST = 0.07;

// use to retreieve sub total value from grand total without GST
// Eg: 32.10 / 1.07 = 32;
export const reverseGST = 1 + GST;

/**
 * Prefix price with currency sign
 * @param {double} price
 * @return {string}
 */
export const prefixPrice = (price) => `$${price}`;

/**
 * Format number and prefix with currency sign at same time
 * @param {functions} [func]
 * @return {string}
 */
export const classPackPrice = compose(formatPrice, prefixPrice);

export const parseClassPackPrice = (price = 0, currencySymbol) => {
	const isDecimal = price % 1 !== 0;
	// const currencySymbol = getCurrencySymbol(countryCode);
	const formattedPrice = formatPrice(price);
	return isDecimal
		? `${price} ${currencySymbol}`
		: `${formattedPrice} ${currencySymbol}`;
};

/**
 * Calculate sub total of classPacks with GST value
 * @param {array} classPacks        classPacks lists
 * @return {string}
 */
export const calculateSubTotal = (classPacks) =>
	classPacks
		.map((x) => x.pack_price - x.gst_amount)
		.reduce((x, y) => x + y, 0);

/**
 * Calculate sub total of classPacks without GST value
 * @param {array} classPacks        classPacks lists
 * @return {string}
 */
export const calculateTotal = (classPacks) =>
	classPacks
		.map((x) => {
			if (x?.pack_type === "unlimited") {
				return x.tier_price;
			}
			return x.pack_price;
		})
		.reduce((x, y) => x + y, 0);

/**
 * Calculate GST from GrandTotal
 * @param {array} classPacks        classPacks list
 * @return {string}
 */
export const calculateGST = (classPacks) =>
	classPacks.map((x) => x.gst_amount).reduce((x, y) => x + y, 0);

/**
 * Calculate GrandTotal = GST + subTotal
 * @param {array} classPacks        classPacks list
 * @return {string}
 */
export const calculateGrandTotal = (classPacks) => {
	return classPacks
		.map((x) => {
			if (x?.pack_type === "unlimited") {
				return x.tier_price;
			} else {
				return x.pack_price;
			}
		})
		.reduce((x, y) => x + y, 0);
};

export const findAllAccessPackInClassPacks = (classPacks) => {
	return classPacks.find((classPack) =>
		classPack?.pack_list?.some((pack) => pack.pack_type === "unlimited")
	);
};

export const isClassPacksIsEmpty = (classPacks) => {
	return classPacks.every(
		(classPack) =>
			Array.isArray(classPack?.pack_list) ? !classPack?.pack_list : false // check if pack_list of class pack is empty
	);
};

export const checkIfClassPackIsAllAccess = (classPack) => {
	return classPack?.pack_list?.some((pack) => pack.pack_type === "unlimited");
};

export const categorizeClassPacksList = (classPacks) => {
	const filterKey = "pack_category_id";
	function filterBySpecificKey(key, value) {
		return classPacks.find((classPack) => classPack[key] === value);
	}
	const newBiePacks = filterBySpecificKey(filterKey, 1);

	const allAccessPacks = filterBySpecificKey(filterKey, 2);
	const classesPacks = filterBySpecificKey(filterKey, 3);
	const sharePacks = filterBySpecificKey(filterKey, 4);
	const dropInsPacks = filterBySpecificKey(filterKey, 5);

	// filter logic for single packs is different,
	const singlePacks = classPacks.find(
		(classPack) => classPack.total_credit === 1
	);

	return {
		newBiePacks,
		classesPacks,
		allAccessPacks,
		sharePacks,
		dropInsPacks,
		singlePacks,
	};
};

export const categorizeClassPacks = ({ classPacks }) => {
	const singleClassPacks = classPacks.filter(
		(classPack) => classPack.total_credit === 1
	);
	const nonShareableClassPacks = classPacks.filter(
		(classPack) =>
			classPack.total_credit !== 1 &&
			classPack.pack_type === "non_shareable"
	);
	const shareableClassPacks = classPacks.filter(
		(classPack) =>
			classPack.total_credit !== 1 && classPack.pack_type === "shareable"
	);
	return { singleClassPacks, nonShareableClassPacks, shareableClassPacks };
};

export const getClassPackTheme = (classPack) => {
	if (classPack.total_credit === 1) {
		return "single";
	} else if (
		classPack.pack_type === "non_shareable" ||
		classPack.pack_type === "unlimited"
	) {
		return "non-shareable";
	} else return "shareable";
};

export const sortUnlimitedClassPacksByPrice = (classPacks) =>
	classPacks.sort((a, b) => {
		if (a?.tier_price < b?.tier_price) {
			return 1;
		} else {
			return -1;
		}
	});

export const calculateRank = (unlimitedPacks) => {
	let currentRank = 1;
	unlimitedPacks?.forEach((c, i) => {
		if (c?.tier_id === 1 && c?.is_unlocked) {
			currentRank = 1;
		} else if (c?.tier_id === 2 && c?.is_unlocked) {
			currentRank = 2;
		} else if (c?.tier_id === 3 && c?.is_unlocked) {
			currentRank = 3;
		}
	});
	return currentRank;
};

export const getUnlockedPacks = (unlimitedPacks, classPack) => {
	const rank = calculateRank(unlimitedPacks);

	// console.log("Currrent rank in geTcurrentTire func ", currentRank);
	// console.log("all unlimited packs", unlimitedPacks);

	switch (rank) {
		case 1:
			return unlimitedPacks.find(
				(c) => c?.tier_id === 1 && c?.pack_name === classPack?.pack_name
			);

		case 2:
			return unlimitedPacks.find(
				(c) => c?.tier_id === 2 && c?.pack_name === classPack?.pack_name
			);

		case 3:
			return unlimitedPacks.find(
				(c) => c?.tier_id === 3 && c?.pack_name === classPack?.pack_name
			);
	}
};

export const getUnlockedPacksFromTiers = (unlimitedPacks) => {
	const rank = calculateRank(unlimitedPacks);
	return unlimitedPacks.find((c) => c?.tier_id === rank);
};

export const checkTierRankAndShowInfo = (unlimitedPacks, classPack) => {
	const rank = calculateRank(unlimitedPacks);

	// console.log("unlimit pack", unlimitedPacks);
	// console.log("current rank ", currentRank);
	// console.log("class pack in check rank =======><>>", classPack);

	switch (true) {
		case classPack?.pack_type !== "unlimited":
			return true;
		case classPack?.tier_id === 1 && rank === 1:
			return true;
		case classPack?.tier_id === 2 && rank === 2:
			return true;
		case classPack?.tier_id === 3 && rank === 3:
			return true;
		default:
			return false;
	}
};

/** old rank calculation logic 
 * // let currentRank = 1;
	// unlimitedPacks?.forEach((c, i) => {
	// 	if (c?.tier_name === "Rookie" && c?.is_unlocked) {
	// 		currentRank = 1;
	// 	} else if (c?.tier_name === "Pro" && c?.is_unlocked) {
	// 		currentRank = 2;
	// 	} else if (c?.tier_name === "Elite" && c?.is_unlocked) {
	// 		currentRank = 3;
	// 	}
	// });
 */

import React from "react";

function RadioCheckedIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="21"
			fill="none"
			viewBox="0 0 20 21"
		>
			<circle cx="10" cy="10.5" r="10" fill="#3DCCC7"></circle>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M13.807 8.099l-5.439 5.44-2.176-2.176"
			></path>
		</svg>
	);
}

export default RadioCheckedIcon;

import React from "react";
// import PropTypes from "prop-types";
import Lottie from "react-lottie";
import * as anim from "./preloader-animation.json";

import "./Preloader.scss";

// const propTypes = {
// 	/** @type {[string}		react className prop */
// 	className: PropTypes.string,
// 	width: PropTypes.string,
// 	height: PropTypes.string,
// };

// const defaultProps = {
// 	className: "",
// 	width: "300px",
// 	height: "300px",
// };

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: anim.default,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};

/**
 * Generic preloader
 * auto center via absolute positioning + translate
 */
export default function Preloader({ className, width, height }) {
	return (
		<div className={"preloader " + className}>
			<Lottie options={defaultOptions} height={height} width={width} />
		</div>
	);
}
// Preloader.propTypes = propTypes;
// Preloader.defaultProps = defaultProps;

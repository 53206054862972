import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import qs from "qs";

import { scrollToTop, end } from "../../../base/functions";
import { hideHeader, hideFooter, hideTitle } from "../../../redux/ui";

/**
 * logical component to manage top level ui states
 */
class UIController extends Component {
	constructor(props) {
		super(props);

		let queryObj = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});

		if (
			queryObj.webview == 1 ||
			this.props.location.pathname === "/maintenance"
		) {
			this.props.hideHeader(true);
			this.props.hideFooter(true);
			this.props.hideTitle(true);
		}
	}

	componentDidUpdate(prevProps) {
		//listen to route changes
		if (this.props.location.pathname != prevProps.location.pathname) {
			scrollToTop();
			let queryObj = qs.parse(this.props.location.search, {
				ignoreQueryPrefix: true,
			});

			if (
				this.props.location.pathname === "/maintenance" ||
				queryObj.webview == 1
			) {
				this.props.hideHeader(true);
				this.props.hideFooter(true);
			} else {
				this.props.hideHeader(false);
				this.props.hideFooter(false);
			}
		}

		//listen to UI updates
		let prevUIProps = prevProps.ui;
		const { ui } = this.props;

		if (ui) {
			if (prevUIProps.disableScroll !== ui.disableScroll) {
				if (ui.disableScroll)
					document
						.querySelector("html")
						.classList.add("disable-scroll");
				else
					document
						.querySelector("html")
						.classList.remove("disable-scroll");
			}

			if (prevUIProps.disableMobileScroll !== ui.disableMobileScroll) {
				if (ui.disableMobileScroll) {
					document
						.querySelector("html")
						.classList.add("disable-scroll-mobile");
				} else {
					document
						.querySelector("html")
						.classList.remove("disable-scroll-mobile");
				}
			}
		}
	}

	render() {
		//nothing to render
		return null;
	}
}

const mapStateToProps = function (store) {
	return {
		ui: store.ui,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		hideHeader: bindActionCreators(hideHeader, dispatch),
		hideFooter: bindActionCreators(hideFooter, dispatch),
		hideTitle: bindActionCreators(hideTitle, dispatch),
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(UIController)
);

import immer from "immer";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * expand / compact header
 * @param  {boolean}  hide  show / hide main header
 */
export const setCondensedHeader = (condense) => ({
	type: "SET_CONDENSED_HEADER",
	condense: condense,
});
/**
 * show / hide main header
 * @param  {boolean}  hide  show / hide main header
 */
export const hideHeader = (hide) => ({ type: "HIDE_HEADER", value: hide });
/**
 * show / hide main footer
 * @param  {boolean}  hide  show / hide main footer
 */
export const hideFooter = (hide) => ({ type: "HIDE_FOOTER", value: hide });
/**
 * show / hide page title
 * @param  {boolean}  hide  show / hide page title
 */
export const hideTitle = (hide) => ({ type: "HIDE_TITLE", value: hide });

/**
 * toggle full width body content in desktop
 * by default, body content has 1200px width
 * @param  {boolean} fullWidth  whether to use fullWidth body
 */
export const useFullWidthBody = (fullWidth) => ({
	type: "USE_FULL_WIDTH_BODY",
	fullWidth,
});

/**
 * show / hide browser scrollbar
 * @param  {boolean} disable 	flag to show / hide browser scrollbar
 */
export const disableScroll = (disable) => ({
	type: "DISABLE_SCROLL",
	disable: disable,
});
/**
 * show / hide browser scrollbar in smaller screens
 * used to hide mobile scrollbar when navicon is active
 * @param  {boolean} disable 	flag to show / hide browser scrollbar
 */
export const disableMobileScroll = (disable) => ({
	type: "DISABLE_MOBILE_SCROLL",
	disable: disable,
});
/**
 * show various authentication modal windows
 * @param  {string} section 	authentication modal window to show
 */
export const showAuthentication = (section) => ({
	type: "SHOW_AUTHENTICATION",
	section: section,
});
/**
 * show / hide navigation in mobile
 * @param  {boolean} show 	flag to show / hide navigation in mobile
 */
export const showMobileNavigation = (show) => ({
	type: "SHOW_MOBILE_NAVIGATION",
	show: show,
});
/**
 * set active section in mobile navigation
 * the active section will keep the navigation group expanded in mobile
 * @param  {string} section 	navigation group
 */
export const setActiveSection = (section) => ({
	type: "SET_ACTIVE_SECTION",
	section: section,
});
/**
 * set notice to show at root level
 * notices are wider (1200px) and are above page titles
 * @param  {string} notice 		the notice to show
 * @param  {string} msg 		message to display in the notice (only applicable to some)
 */
export const showNotice = (notice, msg) => ({
	type: "SHOW_NOTICE",
	notice: notice,
	msg: msg,
});
/**
 * set break point for conditional components rendering based on breakPoint
 * the breakPoint will help you dynamic rendering of your components
 * @param  {string}  breakPoint
 */
export const setBreakPoint = (breakPoint) => ({
	type: "SET_BREAK_POINT",
	breakPoint,
});
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const initialState = {
	condenseHeader: false,
	hideHeader: false,
	hideFooter: false,
	hideTitle: false,
	useFullWidthBody: false,
	disableScroll: false,
	disableMobileScroll: false,
	showAuthentication: null,
	showMobileNavigation: false,
	activeSection: null,
	notice: null,
	noticeMessage: null,
	activeBreakPoint: "lg",
};

export function ui(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			case "SET_CONDENSED_HEADER":
				draft.condenseHeader = action.condense;
				break;

			case "HIDE_HEADER":
				draft.hideHeader = action.value;
				break;

			case "HIDE_FOOTER":
				draft.hideFooter = action.value;
				break;

			case "HIDE_TITLE":
				draft.hideTitle = action.value;
				break;

			case "USE_FULL_WIDTH_BODY":
				draft.useFullWidthBody = action.fullWidth;
				break;

			case "DISABLE_SCROLL":
				draft.disableScroll = action.disable;
				break;

			case "DISABLE_MOBILE_SCROLL":
				draft.disableMobileScroll = action.disable;
				break;

			case "SHOW_AUTHENTICATION":
				draft.showAuthentication = action.section;
				break;

			case "SHOW_MOBILE_NAVIGATION":
				draft.showMobileNavigation = action.show;
				break;

			case "SET_ACTIVE_SECTION":
				draft.activeSection =
					// toggle if the value is inside the store
					draft.activeSection === action.section
						? null
						: action.section;
				break;

			case "SHOW_NOTICE":
				draft.notice = action.notice;
				draft.noticeMessage = action.msg;
				break;

			case "SET_BREAK_POINT":
				draft.activeBreakPoint = action.breakPoint;
				break;
		}
	});
}

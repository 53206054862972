import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import { locationCheckPopup, termPopup } from "../../../../redux/popup.jsx";
import { showAuthentication } from "../../../../redux/ui.jsx";
import {
	getProfile,
	setProfile,
	
} from "../../../../redux/profile";
import { currentLanguage } from "../../../../redux/lang.jsx";
import Axios from "axios";
import { baseEndpointURL } from "../../../../base/config.jsx";
import Modal from "../../../Modal/Modal.jsx";
import Form from "../../../Form/Form.jsx";
import Button from "../../../Button/Button.jsx";
import Checkbox from "../../../Form/Checkbox/Checkbox.jsx";
import "./termAndConditionModal.scss";
import LocationIcon from "../../../Icons/Location.jsx";
import { getCountryCodeByName } from "../../../../hooks/getCountryId.js";

const termAndConditionSchema = Yup.object().shape({
	acknowledge: Yup.boolean().oneOf([true], "Field must be checked"),
	acknowledge2: Yup.boolean().oneOf(
		[true],
		"Must Accept Terms and Conditions"
	),
	acknowledge3: Yup.boolean().oneOf(
		[true],
		"Must Accept Terms and Conditions"
	),
});
const TermAndConditionModal = (props) => {

	const [acknowledged, setAcknowledged] = useState(false);

	const [acknowledged2, setAcknowledged2] = useState(false);
	const [acknowledged3, setAcknowledged3] = useState(false);

	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const countryName = query.get("countryName");
	const countryID = query.get("cId");

	const _currentCountry = props.masterData?.countries?.find((item) => {
		return item?.country_id === props?.lang;
	});

	const handleSetLanguage = (country) => {
		const _lang = getCountryCodeByName({
			name: country,
			data: props?.masterData?.countries,
		});

		props.setCurrentLanguage(Number(countryID) || _lang);
		props.termCheckPopup();
		// need to change here
		props.history.push({
			pathname: location.pathname,
			search: `?country=${_lang?.country_code?.toLowerCase()}`,
		});
	};

	const handleSubmit = async () => {
		const accessToken = props?.authentication?.accessToken;

		try {
			const { data } = await Axios.post(
				baseEndpointURL + "Member/MemberAgreement",
				{},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
						CountryID: Number(
							countryID !== "undefined"
								? countryID
								: _currentCountry?.country_id
						),
					},
				}
			);

			handleSetLanguage(countryID);

			props?.getCurrentProfile();
		} catch (error) {}
	};

	const handleClose = () => {
		// props.showAuthentication(null)
		props.termCheckPopup(null);

		const prevLang = props?.profile?.member_agreement?.find(
			(item) => item.Is_Agree === true
		);
		props.setCurrentLanguage(prevLang?.country_id || 1);
		props.setCurrentLanguage(prevLang?.country_id || 1);
		window.location.reload();
	};

	return (
		<Modal
			className="termAndConditionModal"
			maxWidth={415}
			height={603}
			onClose={() => handleClose()}
		>
			<div className="location__contents">
				<div className="location__contents__icon">
					<LocationIcon />
				</div>
				<h2 className="title">
					GOIN' PLACES?
					{/*
					{getCountryName({
						lang: props?.lang,
						data: props?.masterData?.countries,
					})} */}
					!
				</h2>
				<p>
					You have selected a new country! Changing your country will
					update the terms and conditions and privacy policy
					applicable.
				</p>

				<Formik
					initialValues={{
						acknowledged: false,
						acknowledged2: false,
						acknowledged3: false,
					}}
					enablereinitialize="true"
					validationSchema={termAndConditionSchema}
					onSubmit={(values) => {
						handleSubmit();
					}}
				>
					{({
						values,
						isSubmitting,
						handleSubmit,
						setFieldValue,
					}) => {
						function checkAllFieldsTrue(values) {
							// Iterate over the object's properties
							for (let key in values) {
								// If any property is false, return false
								if (values[key] !== true) {
									return true;
								}
							}
							// If all properties are true, return true
							return false;
						}

						return (
							<Form
								validationSchema={termAndConditionSchema}
								onSubmit={() => {
									handleSubmit();
								}}
							>
								<div className="row mt-5">
									<div className="col-12">
										<Checkbox
											type={"tick"}
											name="acknowledged"
											className={
												"term__checkbox align-items-start checkbox--square " +
												(!acknowledged &&
													" checkbox--form-error ")
											}
											selected={values.acknowledged}
											onChange={() => {
												setFieldValue(
													"acknowledged",
													!values.acknowledged
												);
												setAcknowledged(
													(prev) => !prev
												);
											}}
										>
											<p>
												I consent to my personal data
												being transferred, processed and
												used within and outside my
												country of primary residence,
												including for direct marketing
												purposes.
											</p>
										</Checkbox>
									</div>
									<div className="col-12">
										<Checkbox
											type="tick"
											name="acknowledged2"
											className={
												"term__checkbox checkbox--square " +
												(!acknowledged2 &&
													" checkbox--form-error ")
											}
											selected={values.acknowledged2}
											onChange={() => {
												setFieldValue(
													"acknowledged2",
													!values.acknowledged2
												);
												setAcknowledged2(
													(prev) => !prev
												);
											}}
										>
											I have read and agree to the{" "}
											<a
												href="/terms-of-services"
												target="_blank"
												rel="noopener"
												title="Opens in a new window"
											>
												Terms & Conditions{" "}
												{countryName !== "undefined"
													? countryName
													: _currentCountry?.country_name}
												.
											</a>{" "}
										</Checkbox>
									</div>
									<div className="col-12">
										<Checkbox
											type="tick"
											name="acknowledged3"
											className={
												"term__checkbox checkbox--square " +
												(!acknowledged3 &&
													" checkbox--form-error ")
											}
											selected={values.acknowledged3}
											onChange={() => {
												setFieldValue(
													"acknowledged3",
													!values.acknowledged3
												);
												setAcknowledged3(
													(prev) => !prev
												);
											}}
										>
											I have read and agree to the{" "}
											<a
												href="/terms-of-services"
												target="_blank"
												rel="noopener"
												title="Opens in a new window"
											>
												Privacy Policy{" "}
												{countryName !== "undefined"
													? countryName
													: _currentCountry?.country_name}
												.
											</a>{" "}
										</Checkbox>
									</div>
								</div>

								<div className="row">
									<div className="col-lg-10 mx-auto mt-20 col-md-12 d-flex justify-content-end">
										<Button
											className="aboutYou__next d-inline-block  ml-auto"
											type="submit"
											disabled={
												checkAllFieldsTrue(values) ||
												isSubmitting
											}
										>
											{!isSubmitting
												? "Continue"
												: "Submitting..."}
										</Button>
									</div>
								</div>
							</Form>
						);
					}}
				</Formik>
				{/* <div
					className="location__contents__btn"
					onClick={() =>
						handleSetLanguage(props?.masterData?.dedect_country)
					}
				>
					Explore YM {props?.masterData?.dedect_country}

				</div> */}
			</div>
		</Modal>
	);
};

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
		lang: store?.lang?.lang,
		masterData: store?.masterData?.data,
		authentication: store.authentication,
		profile: store.profile?.profile,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		showLocationPopup: bindActionCreators(locationCheckPopup, dispatch),
		termCheckPopup: bindActionCreators(termPopup, dispatch),
		setCurrentLanguage: bindActionCreators(currentLanguage, dispatch),
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
		setMyProfile: bindActionCreators(setProfile, dispatch),
		getCurrentProfile: bindActionCreators(getProfile, dispatch),
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TermAndConditionModal);

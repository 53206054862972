import React from "react";
import "./AppDownload.scss";

function AppDownload() {
	return (
		<section className="AppDownload">
			<div className="AppDownload__inner">
				<div>
					<h2 className="AppDownload__title">Get the app</h2>
					<p className="AppDownload__description">
						Download the Yoga Movement app for iPhone and Android.
					</p>
					<div>
						<a
							href="https://apps.apple.com/sg/app/yoga-movement/id1457680413"
							target="_blank"
							className="mr-2"
						>
							<img
								src="assets/img/ui/app-store.svg"
								alt="Install YM App from the Apple store for IOS user"
								className=" AppDownload__store-link"
							/>
						</a>
						<a
							href="https://play.google.com/store/apps/details?id=com.yogamovement.android"
							target="_blank"
						>
							<img
								src="assets/img/ui/play-store.svg"
								alt="Install YM App from the Google Play for Android user"
								className="AppDownload__store-link"
							/>
						</a>
					</div>
				</div>

				<div className="AppDownload__wrapper">
					<img
						src="/assets/img/home/get-the-app"
						alt="Download Yoga Movement app for iPhone and Android"
						srcSet="
									/assets/img/home/get-the-app.png 560w,
									/assets/img/home/get-the-app@2x.png 1120w,
									/assets/img/home/get-the-app@3x.png 1680w
								"
					/>
				</div>
			</div>
		</section>
	);
}

export default AppDownload;

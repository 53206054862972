import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import qs from "qs";

import { end, isValidJWT } from "../../base/functions";
import { disableScroll, showAuthentication } from "../../redux/ui";
import {
	checkAccountStatus,
	setEmail,
	setAuthenticationCallback,
} from "../../redux/authentication";
import { resetProfile, setProfile, getProfile } from "../../redux/profile";

import LoginModal from "./LoginModal/LoginModal.jsx";
import RegisterModal from "./RegisterModal/RegisterModal.jsx";
import MigrationModal from "./MigrationModal/MigrationModal.jsx";
import ConnectModal from "./ConnectModal/ConnectModal.jsx";
import ForgotPasswordModal from "./ForgotPasswordModal/ForgotPasswordModal.jsx";
import ResetPasswordModal from "./ResetPasswordModal/ResetPasswordModal.jsx";
import AppleSignInModal from "./AppleSignInModal/AppleSignInModal.jsx";
import CheckEmailModal from "./CheckEmailModal/CheckEmailModal.jsx";
import NewPasswordModal from "./NewPasswordModal/NewPasswordModal.jsx";
import EmailUpdatedModal from "./EmailUpdatedModal/EmailUpdatedModal.jsx";
import "./Authentication.scss";
import CompleteProfileModal from "./CompleteProfileModal/CompleteProfileModal.jsx";

/**
 * wrapper component at root level for various authentication component
 * controls the authentication flow
 * listens for changes in ui.showAuthentication / authentication.status
 *
 * the authentication flow is complex because we are using the same endpoints for multiple platforms;
 * certain flows can be have a series of api calls, please refer to documentation for more info
 */
class Authentication extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.readQueryString();
	}

	componentDidMount() {
		//for logged-in users, refresh profile on site load
		//do not rely on cache data because there might be changes in user status
		if (isValidJWT(this.props.authentication.accessToken)) {
			this.props.getProfile();
		}
	}

	componentDidUpdate(prevProps) {
		const prevUIProps = prevProps.ui;
		const prevAuthProps = prevProps.authentication;
		const prevProfileProps = prevProps.profile;

		const { ui, authentication, profile } = this.props;

		if (ui) {
			switch (true) {
				case prevUIProps.showAuthentication != ui.showAuthentication:
					/**
					 * listen for changes in showAuthentication in ui store
					 * update the action query string if necessary
					 */
					switch (ui.showAuthentication) {
						case "log-in":
						case "register":
						case "forgot-password":
						case "reset-password":
						case "migration":
						case "connect":
						case "forgot-password":
						case "check-email":
						case "complete-profile":
						case "new-password":
						case "":
						case null:
							let queryObj = qs.parse(
								this.props.location.search,
								{
									ignoreQueryPrefix: true,
								}
							);
							if (queryObj.action != ui.showAuthentication) {
								queryObj.action = ui.showAuthentication;
								if (!queryObj.action || queryObj.action == "")
									delete queryObj.action;

								this.props.history.push({
									pathname: this.props.location.pathname,
									search: "?" + qs.stringify(queryObj),
								});
							}
							break;
					}
					break;
			}
		}

		/**
		 * listen for updates in authentication status
		 * control which screen to redirect to
		 */
		if (prevAuthProps.status != authentication.status) {
			switch (authentication.status) {
				//facebook authenticate can run for first time / returning users
				// case "FACEBOOK_AUTHENTICATE_SUCCESS":
				case "GOOGLE_AUTHENTICATE_SUCCESS":
				case "CONNECT_FACEBOOK_SUCCESS":
					this.props.resetProfile();
					this.props.setProfile(authentication.profile);
					this.props.setEmail(authentication.profile.mem_email || "");
					this.checkRegistrationProgress();
					this.props.showAuthentication(null);
					this.props.disableScroll(false);
					break;
				//after registration, go to /register/about-you page
				case "REGISTER_STEP_1_SUCCESS":
				case "CHECK_LOGIN_SUCCESS":
					this.props.resetProfile();
					this.checkRegistrationProgress();
					this.props.showAuthentication(null);
					this.props.disableScroll(false);
					break;
				case "REGISTER_STEP_3_FACEBOOK_SUCCESS":
					this.checkRegistrationProgress();
					break;
				case "LOGIN_SUCCESS":
					this.checkRegistrationProgress();
					this.props.showAuthentication(null);
					this.props.disableScroll(false);
					if (this.props.authentication.callback != null) {
						this.props.authentication.callback();
						this.props.setAuthenticationCallback(null);
					} else {
						this.props.history.push("/");
					}
					// check profile is verified, if it is verified
					this.props.getProfile("check", () => {
						this.props.showAuthentication("complete-profile");
						this.props.history.push("/?action=complete-profile");
					});
					break;
				case "LOGIN_ERROR":
					break;
				case "CHECK_ACCOUNT_STATUS_ERROR":
					if (authentication.accountStatus == 416) {
						this.props.showAuthentication("migration");
					} else if (authentication.accountStatus == 407) {
						//account locked
					} else if (authentication.accountStatus == 406) {
						//error 406 can also mean user has not completed the 3-step registration
						//need to check via Member/CheckLogin
						//api call is made in the child LoginForm which has the password
					}
					break;
				case "CREATE_PASSWORD_SUCCESS":
					this.props.resetProfile();
					this.props.showAuthentication("log-in");
					break;
				case "FORGOT_PASSWORD_SUCCESS":
					this.props.resetProfile();
					this.props.showAuthentication("check-email");
					break;
				case "RESET_PASSWORD_SUCCESS":
					this.props.resetProfile();
					this.props.showAuthentication("log-in");
					break;
			}
		}

		/**
		 * when profile just loaded
		 */
		if (!prevProfileProps.profile && profile.profile) {
			this.checkRegistrationProgress();
		}
	}

	/**
	 * read profile object and check whether user has completed registration
	 * redirect to register section to let <Register /> component redirect accordingly
	 */
	checkRegistrationProgress() {
		const { authentication, profile } = this.props;

		const queryParams = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});

		let profileObj = authentication.profile || profile.profile;
		if (!profileObj) return;

		if (!profileObj.mem_phone || !profileObj.emergency_contact_no) {
			if (this.props.location.pathname != "/register") {
				if (queryParams?.code) {
					this.props.history.push(
						`/register?code=${queryParams?.code}`
					);
				} else {
					this.props.history.push("/register");
				}
			}
		} else {
			this.checkAuthCallback();
		}
	}

	/**
	 * check and run authentication callback (if any)
	 */
	checkAuthCallback() {
		if (this.props.authentication.callback != null) {
			this.props.authentication.callback();
			this.props.setAuthenticationCallback(null);
		} else {
			this.props.history.push("/");
		}
	}

	/**
	 * read the query string during initialisation
	 * update showAuthentication in ui store if applicable
	 */
	readQueryString() {
		let queryObj = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});
		switch (queryObj.action) {
			case "log-in":
			case "register":
			case "forgot-password":
			case "reset-password":
			case "migration":
			case "connect":
			case "forgot-password":
			case "check-email":
			case "new-password":
			case "email-updated":
			case "complete-profile":
			case "apple-signin":
				this.props.showAuthentication(queryObj.action);
				break;
		}
	}

	render() {
		const { ui } = this.props;

		switch (ui.showAuthentication) {
			case "log-in":
				return <LoginModal />;
				break;
			case "register":
				return <RegisterModal />;
				break;
			case "migration":
				return <MigrationModal />;
				break;
			case "connect":
				return <ConnectModal />;
				break;
			case "forgot-password":
				return <ForgotPasswordModal />;
				break;
			case "reset-password":
				return <ResetPasswordModal />;
				break;
			case "check-email":
				return <CheckEmailModal />;
				break;
			case "new-password":
				return <NewPasswordModal />;
				break;
			case "complete-profile":
				return <CompleteProfileModal />;
				break;
			case "email-updated":
				return <EmailUpdatedModal />;
				break;
			case "apple-signin":
				return <AppleSignInModal />;
			default:
				return null;
		}
	}
}
const mapStateToProps = function (store) {
	return {
		ui: store.ui,
		authentication: store.authentication,
		profile: store.profile,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		disableScroll: bindActionCreators(disableScroll, dispatch),
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
		checkAccountStatus: bindActionCreators(checkAccountStatus, dispatch),
		resetProfile: bindActionCreators(resetProfile, dispatch),
		setEmail: bindActionCreators(setEmail, dispatch),
		getProfile: bindActionCreators(getProfile, dispatch),
		setProfile: bindActionCreators(setProfile, dispatch),
		setAuthenticationCallback: bindActionCreators(
			setAuthenticationCallback,
			dispatch
		),
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Authentication)
);

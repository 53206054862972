import React from "react";
import moment from "moment";
import "./LateCancelPopup.scss";
import EmptyWalletSVG from "../../../components/Icons/EmptyWallet";
import Modal from "../../../components/Modal/Modal.jsx";
import { bindActionCreators } from "redux";
import {
	proccedToLatePaymentPage,
	resetLateCancelState,
} from "../../../redux/lateCancel";
import { connect } from "react-redux";

const LateCancelPopup = ({
	due_date,
	onClose = () => {},
	proccedToLatePaymentPage,
	data,
}) => {
	const generalDateFormat = "DD MMM YYYY";
	const formatDate = (date) => moment(date).format(generalDateFormat);
	const preFixWithSymbol = (text, symbol = "$") => `${symbol}${text}`;

	const _total = data
		?.map((_d) => _d?.fine_amount)
		?.reduce((partialSum, a) => partialSum + a, 0);

	const PopupContent = (
		<div className={`lateCancelPopup`}>
			{" "}
			<div className="lateCancelPopup__upperContent">
				<div className="iconWrapper">
					<EmptyWalletSVG />
				</div>
				<h3>{`YOU HAVE AN OUTSTANDING BALANCE.`}</h3>
				<p>Please check and update your payment.</p>
			</div>{" "}
			<div className="lateCancelPopup__lowerContent">
				<div className="inner">
					{data?.map((_data) => {
						return (
							<div className="inner__info">
								<div className="left__side">
									<h3>{_data?.class_status}</h3>
									<p className="date">{`${
										_data?.class_name
									} on ${formatDate(_data?.class_date)}`}</p>
								</div>
								<div className="price">
									{preFixWithSymbol(_data?.fine_amount)}
								</div>
							</div>
						);
					})}
					<div className="inner__total">
						<h2>YOU PAY</h2>
						<div className="price">{preFixWithSymbol(_total)}</div>
					</div>
				</div>
			</div>
			<div className="lateCancelPopup__bottom">
				<button
					className="lateCancelPopup__bottom__btn"
					onClick={() => {
						// onClose();
						proccedToLatePaymentPage();

						return window.location.replace(`/cancel-summary`);
					}}
				>
					Proceed to pay
				</button>
			</div>
			{/* <div className="lateCancelPopup__bottom">
				<button
					className="lateCancelPopup__bottom__btn"
					onClick={() => {
						history.push("/classes/buy-class-pack");
						resetLateCancelState();
					}}
				>
					reset
				</button>
			</div> */}
		</div>
	);

	// console.log("late cancel state ------------>>", lateCancelState);

	return (
		<Modal
			hideHeader={true}
			maxWidth={`fit-content`}
			onClose={() => onClose()}
			className={``}
		>
			{PopupContent}
		</Modal>
	);
};

const mapDispatchToProps = (dispatch) => ({
	proccedToLatePaymentPage: bindActionCreators(
		proccedToLatePaymentPage,
		dispatch
	),
	resetLateCancelState: bindActionCreators(resetLateCancelState, dispatch),
});

const mapStateToProps = (store) => ({
	lateCancelState: store.lateCancelReducer,
});
export default connect(mapStateToProps, mapDispatchToProps)(LateCancelPopup);

import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/database";

import {
	firebaseAPIKey,
	firebaseAuthDomain,
	firebaseDatabaseURL,
	firebaseProjectId,
	firebaseStorageBucket,
	firebaseMessagingSenderId,
} from "../../../base/config";

class FirebaseController extends PureComponent {
	componentDidMount() {
		firebase.initializeApp({
			apiKey: firebaseAPIKey,
			authDomain: firebaseAuthDomain,
			databaseURL: firebaseDatabaseURL,
			projectId: firebaseProjectId,
			storageBucket: firebaseStorageBucket,
			messagingSenderId: firebaseMessagingSenderId,
		});

		let settings = firebase.database().ref("master_data");
		settings.on("value", (snapshot) => {
			const {
				web_maintenance,
				web_maintenance_title,
				web_maintenance_message,
			} = snapshot.val();
			if (web_maintenance) {
				this.props.history.push({
					pathname: "/maintenance",
					state: {
						message: web_maintenance_message,
						title: web_maintenance_title,
					},
				});
			} else if (
				!web_maintenance &&
				this.props.location.pathname === "/maintenance"
			) {
				this.props.history.push({
					pathname: "/",
				});
			}
		});
	}

	render() {
		return null;
	}
}

export default withRouter(FirebaseController);

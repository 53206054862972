import immer from "immer";

/**  ACTIONS LIST */
export const ADD_MYPACKS_TO_STORE = "ADD_MYPACKS_TO_STORE";
export const UPDATE_SELECTED_MYPACK = "UPDATE_SELECTED_MYPACK";
export const ADD_SHAREPACKS_TO_STORE = "ADD_SHAREPACKS_TO_STORE";
export const REMOVE_USERS_FROM_SHARE_PACK = "REMOVE_SHARE_PACK_FROM_LIST";
export const UPDATE_SHARE_PACK = "UPDATE_SHARE_PACK";
export const SELECT_CURRENT_SHAREPACK = "SELECT_CURRENT_SHAREPACK";
export const SELECTED_MYSELF_PACK = "SELECTED_MYSELF_PACK";
/** ACTIONS CREATORS */
export const actionGenerator = (type) => (payload) => {
	return (dispatch, getState) => {
		dispatch({ type, payload });
	};
};

export const addMyPacksToStore = actionGenerator(ADD_MYPACKS_TO_STORE);
export const updateSelectedMyPack = actionGenerator(UPDATE_SELECTED_MYPACK);
export const addAllSharePacksToStore = actionGenerator(ADD_SHAREPACKS_TO_STORE);
export const removeSharedUsersFromPack = actionGenerator(
	REMOVE_USERS_FROM_SHARE_PACK
);
export const updateSharePackFromList = actionGenerator(UPDATE_SHARE_PACK);
export const selectCurrentSharePack = actionGenerator(SELECT_CURRENT_SHAREPACK);
export const selectedMySelfPack = actionGenerator(SELECTED_MYSELF_PACK);

import React from "react";
import PropTypes from "prop-types";

import "./ModalFooter.scss";

const propTypes = {
	/** @type {string}	children elements */
	children: PropTypes.node.isRequired
};

/**
 * standard footer section of modal window
 */
export default function ModalFooter({ children }) {
	return <footer className="modalFooter">{children}</footer>;
}
ModalFooter.propTypes = propTypes;

export const getCustomStyles = (selectWidth, selectHeight) => ({
	option: (provided, state) => ({
		display: "flex",
		alignItems: "center",
		padding: "10px 15px",
		justifyContent: "space-between",
		border: "none",
		outline: "none",
		cursor: "pointer",
		...(state?.isSelected && {
			backgroundColor: "#28445B",
			color: "#fff",
		}),
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		padding: "8px",
		marginTop: "20px",
	}),
	clearIndicator: (provided, state) => ({
		...provided,
		display: "none",
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: "none",
	}),

	singleValue: (provided, state) => ({
		...provided,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		fontFamily: "Inter, sans-serif",
		fontSize: "16px",
		fontWeight: "400",
	}),
	control: (provided, state) => ({
		...provided,
		width: selectWidth,
		"&:focus": { borderColor: "none" },
		boxShadow: "none",
		"&:hover": { borderColor: "#1E90FF" },
		height: selectHeight,
		borderRadius: "4px",
		paddingLeft: "10px",
		paddingRight: "8px",
		borderRadius: "0px",
		border: "none",
	}),
	valueContainer: (provided, state) => ({
		...provided,
		padding: "0px",
		marginTop: "20px",
	}),
	menu: (provided, state) => ({
		...provided,
		width: selectWidth,
		marginTop: "0px",
		paddingTop: "0px",
		borderRadius: "0px",
		border: "none",
		boxShadow: "none",
	}),
	menuList: (provided, state) => ({
		...provided,
		paddingTop: "0px",
		borderRadius: "0px",
		paddingBottom: "0px",
		border: "none",
		boxShadow: "none",
	}),
});

export const mobileCodeSelectStyle = {
	option: (provided, state) => ({
		display: "flex",
		alignItems: "center",
		padding: "10px 15px",
		justifyContent: "space-between",
		border: "none",
		outline: "none",
		cursor: "pointer",
		...(state?.isSelected && {
			backgroundColor: "#28445B",
			color: "#fff",
		}),
	}),
	container: (provided, state) => ({
		...provided,
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		marginTop: "15px",
	}),
	clearIndicator: (provided, state) => ({
		...provided,
		display: "none",
	}),
	indicatorSeparator: (provided) => ({
		...provided,
		display: "none",
	}),

	singleValue: (provided, state) => ({
		...provided,
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		fontFamily: "Inter, sans-serif",
		fontSize: "16px",
		fontWeight: "400",
	}),

	control: (provided, state) => ({
		...provided,
		width: "125px",
		"&:focus": { borderColor: "none" },
		boxShadow: "none",
		"&:hover": { borderColor: "#1E90FF" },
		height: "inherit",
		borderRadius: "4px",
		height: "100%",
		maxHeight: "70px",
		paddingLeft: "10px",
		paddingRight: "8px",
		borderRadius: "0px",
		border: "none",
	}),
	valueContainer: (provided, state) => ({
		...provided,
		padding: "0px",
		marginTop: "15px",
	}),
	menu: (provided, state) => ({
		...provided,
		width: "112px",
		marginTop: "0px",
		paddingTop: "0px",
		borderRadius: "0px",
		border: "none",
		boxShadow: "none",
	}),

	menuPlacer: (provided, state) => ({
		...provided,
	}),
	menuList: (provided, state) => ({
		...provided,
		paddingTop: "0px",
		borderRadius: "0px",
		paddingBottom: "0px",
		border: "none",
		boxShadow: "none",
	}),
};

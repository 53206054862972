import axios from "axios";
import immer from "immer";

import {
	memberEndpointURL,
	endpointCreds,
	globalMemberEndpoint,
} from "../base/config";

let defaultMasterDataConfig = {
	useGlobalEndpoint: false,
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getMasterData = (config = defaultMasterDataConfig) => {
	return (dispatch, getState) => {
		dispatch({ type: "GET_MASTER_DATA" });

		let store = getState();
		let accessToken = store.authentication.accessToken;
		let endPoint = config.useGlobalEndpoint
			? globalMemberEndpoint
			: memberEndpointURL;
		return axios
			.get(endPoint + "GetMasterData", {
				headers: {
					Authorization: "Bearer " + accessToken,
					IpAddress: config?.ip,
				},
				auth: endpointCreds,
			})
			.then((res) => {
				return dispatch({
					type: "GET_MASTER_DATA_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) => {
				return dispatch({
					type: "GET_MASTER_DATA_ERROR",
					err: err,
				});
			});
	};
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const currentState = {
	status: null,
	data: null,
};

export function masterData(state = currentState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			case "GET_MASTER_DATA":
			case "GET_MASTER_DATA_ERROR":
				draft.status = action.type;
				break;

			case "GET_MASTER_DATA_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_MASTER_DATA_SUCCESS";
					draft.data = action.res.data.master_data;
				} else {
					draft.status = "GET_MASTER_DATA_ERROR";
				}
				break;
		}
	});
}

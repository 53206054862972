//manually defined meta tags for pages that do not need dynamic data
//file is in `.js` and uses `modules.export` because it is shared with lambda@edge implementation

/**
 * attempt to get meta tags info by page
 * use default if no data available
 * @param  {string} page  page to get the meta tags from
 * @return {object}	formatted object
 */
function getMetaTags(page) {
	if (!metaTags[page]) {
		return metaTags.default;
	} else {
		let defaultMetaData = metaTags.default;
		let metaData = metaTags[page];
		return {
			title: metaData.title || defaultMetaData.title,
			description: metaData.description || defaultMetaData.description,
			image: metaData.image || defaultMetaData.image,
			keywords: metaData.keywords || defaultMetaData.keywords,
		};
	}
}

const ogImage =
	"https://s3-ap-southeast-1.amazonaws.com/m.yogamovement.com/webfront/yoga-og-image.jpg";

const metaTags = {
	default: {
		title: "Yoga Movement",
		description:
			"More than just a bunch of yoga studios around the hottest spots in Singapore, we're a lifestyle brand that’s accessible to beginners, experienced practitioners... and everyone in-between. Come join the YM FAM!",
		image: ogImage,
		keywords: "yoga, fitness, health, singapore",
	},
	blog: {
		title: "YM Life | Yoga Movement",
		description: "",
		image: "",
		keywords: "",
	},
	classes: {
		title: "Classes | Yoga Movement",
		description: "classes",
		image: ogImage,
		keywords: "",
	},
	careers: {
		title: "Careers | Yoga Movement",
		description: "careers",
		image: ogImage,
		keywords: "",
	},
	"own-a-ym": {
		title: "Own a YM | Yoga Movement",
		description: "Own a YM",
		image: ogImage,
		keywords: "",
	},
	"class-types": {
		title: "Class Types | Yoga Movement",
		description: "",
		image: ogImage,
		keywords: "",
	},
	"private-classes": {
		title: "Private and Corporate Classes | Yoga Movement",
		description: "",
		image: ogImage,
		keywords: "",
	},
	schedule: {
		title: "Schedule | Yoga Movement",
		description: "",
		image: ogImage,
		keywords: "",
	},
	academy: {
		title: "Academy | Yoga Movement",
		description: "",
		image: ogImage,
		keywords: "",
	},
	studios: {
		title: "Studios | Yoga Movement",
		description: "",
		image: ogImage,
		keywords: "",
	},
	"our-team": {
		title: "Our Team | Yoga Movement",
		description: "",
		image: ogImage,
		keywords: "",
	},
	newbie: {
		title: "Newbie | Yoga Movement",
		description: "",
		image: ogImage,
		keywords: "",
	},
	"contact-us": {
		title: "Contact Us | Yoga Movement",
		description: "",
		image: ogImage,
		keywords: "",
	},
	"account/classes": {
		title: "My Schedule | Yoga Movement",
	},
	"account/classes-history": {
		title: "My Classes Visit History | Yoga Movement",
	},
	"account/notifications": {
		title: "Notifications | Yoga Movement",
	},
	"account/edit": {
		title: "Edit Profile | Yoga Movement",
	},
	"account/class-packs": {
		title: "My Class Packs | Yoga Movement",
	},
	"account/past-class-packs": {
		title: "Past Class Packs | Yoga Movement",
	},
	"account/achievements": {
		title: "Achievements | Yoga Movement",
	},
	"account/favourites": {
		title: "Favourites | Yoga Movement",
	},
	"account/friends": {
		title: "YM Friends | Yoga Movement",
	},
	"account/referral": {
		title: "Share and Get a Free Class | Yoga Movement",
	},
	"account/activities-log": {
		title: "Activities Log | Yoga Movement",
	},
	"account/transaction-history": {
		title: "Transaction History | Yoga Movement",
	},
	"account/payment-settings": {
		title: "Payment Settings | Yoga Movement",
	},
	"account/adhoc-payments": {
		title: "Ad-Hoc Payments | Yoga Movement",
	},
};

module.exports = { metaTags, getMetaTags };

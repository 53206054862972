import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { end } from "../../../base/functions";
import { showAuthentication } from "../../../redux/ui";

import Button from "../../Button/Button.jsx";
import Modal from "../../Modal/Modal.jsx";
import ModalFooter from "../../Modal/ModalFooter/ModalFooter.jsx";
import "./CheckEmailModal.scss";

class CheckEmailModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	/**
	 * close authentication modal window
	 */
	handleClose() {
		this.props.showAuthentication(null);
	}

	render() {
		return (
			<Modal
				className="checkEmailModal"
				icon={
					<img
						src="/assets/img/ui/logo-yoga-movement.svg"
						alt="Welcome to Yoga Movement"
					/>
				}
				maxWidth={480}
				title="Forgot password"
				onClose={() => this.handleClose()}
			>
				<div className="auth__contents">
					<h3 className="checkEmailModal__heading">
						Forgot Password
					</h3>
					<div className="checkEmailModal__message">
						An email was sent to you at{" "}
						{this.props.authentication.email}
					</div>
					Please follow the instructions to reset your password.
					<br />
					Didn’t receive the email?{" "}
					<button
						className="checkEmailModal__resend click-efx"
						onClick={() =>
							this.props.showAuthentication("forgot-password")
						}
					>
						Resend here
					</button>
					<Button
						className="checkEmailModal__btn"
						onClick={() => this.props.showAuthentication(null)}
					>
						Okay
					</Button>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckEmailModal);

import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { showAuthentication } from "../../../redux/ui";
import Modal from "../../Modal/Modal.jsx";
import LoginForm from "./LoginForm/LoginForm.jsx";

import "../Authentication.scss";

/**
 * login modal window
 */
class LoginModal extends PureComponent {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const { authentication } = this.props;
		// if user is already login, dismiss the modal,
		if (authentication.accessToken) {
			this.props.showAuthentication(null);
		}
	}

	/**
	 * close authentication modal window
	 */
	handleClose() {
		this.props.showAuthentication(null);
	}

	render() {
		const cssPrefix = "Auth-header",
			profileDetail = this.props.profile.profile;

		const userPicStyle =
			profileDetail &&
			profileDetail.avatar_img != null &&
			profileDetail.avatar_img.length > 0
				? {
						background: `url(${profileDetail.avatar_img}) no-repeat center / cover`,
				  }
				: {};

		return (
			<Modal
				className="loginModal"
				icon={
					<img
						src="/assets/img/ui/logo-yoga-movement.svg"
						alt="Welcome to Yoga Movement"
					/>
				}
				maxWidth={480}
				title="Welcome to"
				onClose={() => this.handleClose()}
			>
				<div className="auth__content">
					<div className="auth__contents">
						<div
							className={`${cssPrefix} d-flex align-items-center justify-content-between`}
						>
							<div className="position-relative">
								<img
									src="/assets/img/ui/logo-yoga-movement.svg"
									alt="Welcome to Yoga Movement"
									className={`${cssPrefix}__icon`}
								/>
								{this.props.profile.profile &&
									this.props.profile.profile.mem_type ===
										"email" &&
									this.props.profile.lastEmail != null && (
										<>
											{userPicStyle.background ? (
												<div
													className="loginForm__user-pic circle position-absolute"
													style={userPicStyle}
												></div>
											) : (
												<div className="loginForm__user-pic circle position-absolute">
													{profileDetail.first_name.substr(
														0,
														1
													)}
													{profileDetail.last_name.substr(
														0,
														1
													)}
												</div>
											)}
										</>
									)}
							</div>

							<div
								className="d-flex align-items-center cursor-pointer"
								onClick={() =>
									this.props.showAuthentication("register")
								}
							>
								<img
									src="/assets/img/icons/newbie-icon.svg"
									alt="newbie"
									className={`${cssPrefix}__newbie-icon`}
								/>

								<span
									className={`${cssPrefix}__text text-capitalize ${cssPrefix}__text-sign-in`}
								>
									NEWBIE SIGNUPS
								</span>

								<img
									src="/assets/img/icons/forward-icon.svg"
									alt="newbie"
									className={`${cssPrefix}__forward-icon`}
								/>
							</div>
						</div>
						<LoginForm />
					</div>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		profile: store.profile,
		authentication: store.authentication,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);

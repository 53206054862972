import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import Search from "../../../Search/Search.jsx";
import ErrorMessage from "../../../Alerts/ErrorMessage/ErrorMessage.jsx";
import FriendsModalItemLoading from "../../FriendsModal/FriendsModalList/FriendsModalItemLoading/FriendsModalItemLoading.jsx";
import "./FriendList.scss";
import EmptyListMessage from "../../../EmptyListMessage/EmptyListMessage.jsx";
import YMFriendDetail from "../../../../views/Account/YMFriends/YMFriendsList/YMFriendDetail/YMFriendDetail.jsx";

const propTypes = {
	/** @type {boolean}   show preloading state while api is loading for the first time */
	loading: PropTypes.bool,
	/** @type {boolean}   disables form when sending invitation */
	busy: PropTypes.bool,
	/** @type {array}  	  list of friends */
	list: PropTypes.array,
	/** @type {boolean}   check it is for ym friends */
	isYmFriends: PropTypes.bool,
	/** @type {function}  onSubmit prop from Search */
	onSearch: PropTypes.func.isRequired,
	/** @type {function}  onRemove prop from Delete */
	onRemove: PropTypes.func.isRequired,
	/** @type {function}  onAddFriend prop from Add */
	onAddFriend: PropTypes.func.isRequired,
	/** @type {function}  Reset friends search */
	friendsReset: PropTypes.func.isRequired,
	/** @type {function}  Reset member search */
	memberSearchReset: PropTypes.func.isRequired,
	/** @type {function}  handle Response Friend Request */
	onResponseFriendRequest: PropTypes.func.isRequired,
	/** @type {string}    show error message */
	errorMessage: PropTypes.string,
};
const defaultProps = {
	loading: false,
	isYmFriends: false,
	busy: false,
	list: [],
	errorMessage: null,
};

/**
 * list of friends with search field
 */
export default function FriendList({
	loading,
	busy,
	list,
	isYmFriends,
	onSearch,
	errorMessage,
	onRemove,
	onAddFriend,
	onResponseFriendRequest,
	friendsReset,
	onSharePack,
	is_share = false,
	memberSearchReset,
}) {
	const [searchQuery, setSearchQuery] = useState("");
	function handleRespondToFriendRequest(friend, action) {
		const confirmAction = window.confirm(
			`Are you sure want to ${action} ${friend.member_name} ${
				action === "accept" ? "to" : "from"
			} friend list?`
		);
		if (confirmAction) {
			const body = {
				action: action === "accept" ? 1 : 2,
				member_id: friend.member_id,
				friend_id: friend.friend_id,
			};
			onResponseFriendRequest(body);
			// dispatch remove friend from friend list
		}
	}

	function handleSearch(s) {
		setSearchQuery(s);
		if (s === "") {
			if (isYmFriends) return friendsReset();
			return memberSearchReset();
		}
		onSearch(s);
	}

	return (
		<div
			className={
				"friendsModalList " + (busy && " friendsModalList--busy ")
			}
		>
			{errorMessage && (
				<ErrorMessage className="mb-5">{errorMessage}</ErrorMessage>
			)}
			<Search
				placeholder="Find or add friends by email add or mobile no."
				className="friendsModalList__search"
				onSubmit={(s) => handleSearch(s)}
				allowNull={true}
			/>

			{!searchQuery ? (
				isYmFriends && (
					<div className="friendsModalList__heading">
						Your current friend(s)
					</div>
				)
			) : (
				<div className="friendsModalList__heading">Results</div>
			)}

			<ul className="friendsModalList__list list-unstyled">
				{loading && (
					<Fragment>
						<FriendsModalItemLoading />
						<FriendsModalItemLoading />
						<FriendsModalItemLoading />
					</Fragment>
				)}
				{!loading &&
					list.length > 0 &&
					list.map((friend) => (
						<YMFriendDetail
							key={friend.member_id}
							friend={friend}
							is_share={is_share}
							onRemove={onRemove}
							onAddFriend={onAddFriend}
							onResponseFriendRequest={onResponseFriendRequest}
						/>
					))}
				{searchQuery && !loading && list.length == 0 && (
					<li className="friendsModalList__empty d-flex flex-column align-items-center justify-content-center">
						<EmptyListMessage>
							<p>
								We can’t find who you’re looking for. Please try
								again.
							</p>
						</EmptyListMessage>
					</li>
				)}
			</ul>
		</div>
	);
}

FriendList.propTypes = propTypes;
FriendList.defaultProps = defaultProps;

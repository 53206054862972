import immer from "immer";

export const currentLanguage = (lang) => ({
	type: "CURRENT_LANG",
	res: lang,
});

const initialState = {
	lang: "sg",
};

export function lang(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			case "CURRENT_LANG":
				draft.lang = action.res;
				break;
		}
	});
}

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import "./Form.scss";

/**
 * provides standard form styles and basic form functionality like validation
 */
export default class Form extends PureComponent {
	static propTypes = {
		/** @type {boolean} 	disable form if true */
		busy: PropTypes.bool,
		/** @type {function} 	onSubmit event of <form> */
		onSubmit: PropTypes.func,
		/** @type {string} 		react className prop for <form> */
		className: PropTypes.string,
		/** @type {node} 		children elements */
		children: PropTypes.node
	};

	static defaultProps = {
		busy: false,
		onSubmit: () => {},
		className: null,
		children: null
	};

	constructor(props) {
		super(props);
		this.state = {
			isValidated: false
		};
	}

	reset() {
		this.setState({ isValidated: false });
	}

	/**
	 * form submit event
	 * perform simple validation
	 * @param  {object} 	e submit event object
	 */
	handleSubmit(e) {
		e.preventDefault();
		this.setState({ isValidated: true });

		let errors =
			ReactDOM.findDOMNode(this.form).querySelectorAll("*:invalid")
				.length > 0;
		this.props.onSubmit(e, errors);
	}

	render() {
		let className = "form " + this.props.className;
		if (this.props.busy) className += " form--busy ";
		if (this.state.isValidated) className += " form--do-validate ";

		return (
			<form
				className={className}
				noValidate
				onSubmit={e => this.handleSubmit(e)}
				ref={e => {
					this.form = e;
				}}
			>
				{this.props.children}
			</form>
		);
	}
}

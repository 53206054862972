import React from "react";
import "./FriendsModalItemLoading.scss";

export default function FriendModalItemLoading() {
	return (
		<div className="friendsModalItemLoading friendsModalList__item ">
			<div className="friendsModalItemLoading__item-wrapper d-flex align-items-center">
				<div className="friendsModalList__item-pic circle isLoading isLoading--animated friendsModalItemLoading__content" />
				<div className="friendsModalItemLoading__item-name isLoading isLoading--animated">
					&nbsp;
				</div>
			</div>
		</div>
	);
}

import axios from "axios";
import immer from "immer";

import {
	memberEndpointURL,
	endpointDomain,
	endpointCreds,
} from "../base/config";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getUnlimitedPendingData = () => {
	return (dispatch, getState) => {
		dispatch({ type: "GET_UNLIMITED_DATA" });

		let store = getState();
		let accessToken = store.authentication.accessToken;

		return axios
			.get(memberEndpointURL + "GetUnlimitedPendingPayment", {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
				// auth: endpointCreds,
			})
			.then((res) => {
				return dispatch({
					type: "GET_UNLIMITED_DATA_RESOLVE",
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "GET_UNLIMITED_DATA_ERROR",
					err: err,
				})
			);
	};
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const currentState = {
	status: null,
	data: null,
};

export function unlimitedPendingData(state = currentState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			case "GET_UNLIMITED_DATA":
			case "GET_UNLIMITED_DATA_ERROR":
				draft.status = action.type;
				break;

			case "GET_UNLIMITED_DATA_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_UNLIMITED_DATA_SUCCESS";
					draft.data = action.res.data;
				} else {
					draft.status = "GET_UNLIMITED_DATA_ERROR";
				}
				break;
		}
	});
}

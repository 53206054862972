import React from "react";
import { NavLink } from "react-router-dom";
// import PropTypes from "prop-types";

import "./HeaderNavItem.scss";

// const propTypes = {
// 	/** @type {string}		name to display */
// 	name: PropTypes.oneOfType([
// 		PropTypes.string,
// 		PropTypes.number,
// 		PropTypes.node,
// 	]).isRequired,
// 	/** @type {string}		where this nav item links to */
// 	to: PropTypes.string.isRequired,
// 	/** @type {boolean}		active state */
// 	active: PropTypes.bool,
// 	/** @type {node}		children elements */
// 	children: PropTypes.node,
// 	/** @type {function}	nav item click callback */
// 	onClick: PropTypes.func,
// 	/** @type {string} 		react className prop */
// 	className: PropTypes.string,
// 	/** @type {string} 		react className prop for active*/
// 	activeClassName: PropTypes.string,

// 	/** @type {boolean} 	show notification badge as red dot */
// 	badge: PropTypes.bool,

// 	/** @type {boolean} 	remove has-children class */
// 	ignoreChildren: PropTypes.bool,
// };

const defaultProps = {
	active: false,
	children: null,
	onClick: () => {},
	className: null,
	badge: false,
	ignoreChildren: false,
	activeClassName: "HeaderNavItem__link--active",
};

/**
 * top level nav item
 */
export default function HeaderNavItem({
	name,
	to,
	active,
	children,
	onClick,
	className,
	badge,
	activeClassName,
	ignoreChildren,
	...props
}) {
	let classes = "HeaderNavItem list-inline-item " + className + " ";
	if (children && !ignoreChildren) classes += " HeaderNavItem--has-children ";
	if (active) classes += " HeaderNavItem__link--active ";

	return (
		<li className={classes} onClick={() => onClick()}>
			<NavLink
				to={to}
				className={`HeaderNavItem__link animated d-flex ${
					active ? " HeaderNavItem__link--active" : ""
				}`}
				activeClassName={activeClassName}
				{...props}
			>
				{name}
				{badge ? <div className="HeaderNavItem__badge circle" /> : null}
			</NavLink>
			<div
				className={
					"HeaderNavItem__sub " +
					(active && " HeaderNavItem__sub--active ")
				}
			>
				{children}
			</div>
		</li>
	);
}
// HeaderNavItem.propTypes = propTypes;
HeaderNavItem.defaultProps = defaultProps;

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { locationCheckPopup, langCheckPopup } from "../../../../redux/popup";
import { currentLanguage } from "../../../../redux/lang";
import Modal from "../../../Modal/Modal.jsx";

import "./LocationModal.scss";
import LocationIcon from "../../../Icons/Location.jsx";
import { getCountryCodeByName } from "../../../../hooks/getCountryId";
const LocationModal = (props) => {
	const handleClose = () => {
		props.showLocationPopup();
	};

	const handleSetLanguage = (country) => {
		const _lang = getCountryCodeByName({
			name: country,
			data: props?.masterData?.countries,
		});

		props.setCurrentLanguage(_lang?.toLowerCase());
		handleClose();
		setTimeout(() => {
			// handleClose();
			window.location.reload();
		}, 1000);
	};

	return (
		<Modal
			className="locationModal"
			maxWidth={415}
			height={339}
			onClose={() => handleClose()}
		>
			<div className="location__contents">
				<div className="location__contents__icon">
					<LocationIcon />
				</div>
				<h2 className="title">
					{" "}
					Looks like you’re in {props?.masterData?.dedect_country}
					{/*
					{getCountryName({
						lang: props?.lang,
						data: props?.masterData?.countries,
					})} */}
					!
				</h2>
				<div className="location__contents__container">
					<p>
						Check out our studios to join classes! Buy a Class Pack
						for this country and book your mat.
					</p>
				</div>
				<div
					className="location__contents__btn"
					onClick={() =>
						handleSetLanguage(props?.masterData?.dedect_country)
					}
				>
					Explore YM {props?.masterData?.dedect_country}
					{/* {getCountryName({
						lang: props?.lang,
						data: props?.masterData?.countries,
					})} */}
				</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
		lang: store?.lang?.lang,
		masterData: store?.masterData?.data,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		showLocationPopup: bindActionCreators(locationCheckPopup, dispatch),
		setCurrentLanguage: bindActionCreators(currentLanguage, dispatch),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(LocationModal);


import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { langCheckPopup } from "../../../redux/popup";
import MYFlagIcon from "../../Icons/MYFlag";
import SGFlagIcon from "../../Icons/SGFlag";
import "./Style.scss";

export const languages = {
	my: {
		code: "MY",
		name: "Malaysia",
		icon: () => <MYFlagIcon />,
	},
	sg: {
		code: "SG",
		name: "Singapore",
		icon: () => <SGFlagIcon />,
	},
};

const CountrySelector = ({ langCheckPopup, lang, masterData, profile }) => {

	// const findCountry = masterData?.data?.countries?.filter(
	// 	(data) => data?.country_name === masterData?.data?.dedect_country
	// );


	const [currentLang, setCurrentLang] = useState(
		(masterData?.data?.countries[0] &&
			masterData?.data?.countries[0]?.country_id) ||
			1
	);


	const handleShowPopUp = () => {
		langCheckPopup();
	};

	const filterLang = (currentLang) => {
		if(Number(currentLang)) {
			return masterData?.data?.countries?.filter(
				(data) => data?.country_id === currentLang
			);
		} else {
			return masterData?.data?.countries?.filter(
				(data) => data?.country_code?.toLowerCase() === currentLang?.toLowerCase()
			);
		}
	};

	useEffect(() => {
		setCurrentLang(lang);
	}, [lang]);


	return (
		<>
			<div className="countrySelect" onClick={() => handleShowPopUp()}>
				<div className="countrySelect__icon">
					{filterLang(currentLang)&&filterLang(currentLang)[0]?.country_logo ? <img
						src={filterLang(currentLang)&&filterLang(currentLang)[0]?.country_logo}
						alt={"flag logo"}
					/>: null}

				</div>
				<div className="countrySelect__lang">
					{filterLang(currentLang) &&
						filterLang(currentLang)[0]?.country_code}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
		masterData: store?.masterData,
		profile: store?.profile,
		lang: store.lang?.lang,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		langCheckPopup: bindActionCreators(langCheckPopup, dispatch),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(CountrySelector);

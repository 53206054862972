import React from "react";

function LocationIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="58"
			height="59"
			fill="none"
			viewBox="0 0 58 59"
		>
			<path
				stroke="#8AB0AB"
				d="M29.052 58.31c-3.543-.563-10.627-5.17-10.627-21.06 0-15.89 7.217-21.65 10.825-22.544"
			></path>
			<path
				stroke="#8AB0AB"
				d="M29.25 58.31c3.542-.563 10.627-5.17 10.627-21.06 0-15.89-7.217-21.65-10.825-22.544M29.15 14.706v44.096"
			></path>
			<circle
				cx="29.151"
				cy="36.754"
				r="21.747"
				stroke="#8AB0AB"
			></circle>
			<path
				stroke="#8AB0AB"
				d="M8.976 30.69h40.738M8.285 43.81h41.429"
			></path>
			<path
				fill="#F4F4F4"
				fillRule="evenodd"
				stroke="#8AB0AB"
				d="M36.677 4.2c4.266 4.266 4.266 11.183 0 15.45l-7.725 7.725-7.725-7.725c-4.266-4.267-4.266-11.184 0-15.45 4.266-4.267 11.184-4.267 15.45 0z"
				clipRule="evenodd"
			></path>
			<ellipse
				cx="28.952"
				cy="12.365"
				fill="#F4F4F4"
				stroke="#8AB0AB"
				rx="3.973"
				ry="3.973"
			></ellipse>
		</svg>
	);
}

export default LocationIcon;

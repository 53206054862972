import React from "react";
// import PropTypes from "prop-types";

import "./Button.scss";

// const propTypes = {
// 	/** @type {string}		button|submit */
// 	type: PropTypes.string,
// 	/** @type {function}	onClick event of button */
// 	onClick: PropTypes.func,
// 	/** @type {string}		className prop for button */
// 	className: PropTypes.string,
// 	/** @type {boolean} 	disable button if true */
// 	disabled: PropTypes.bool,
// 	/** @type {string} 		button colour scheme (default|black|outline|black-outline) */
// 	theme: PropTypes.string,
// 	/** @type {string} 		button css border radius */
// 	borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
// 	/** @type {string} 		children elements */
// 	children: PropTypes.node,
// 	/** @type {boolean}		if true will disable tab focus and add the tabIndex=-1 attribute */
// 	disableTabFocus: PropTypes.bool,
// };

const defaultProps = {
	type: "submit",
	disabled: false,
	onClick: () => {},
	className: null,
	theme: "default",
	borderRadius: 25,
	children: null,
	disableTabFocus: false,
};

export default function Button({
	type,
	disabled,
	onClick,
	className,
	theme,
	borderRadius,
	children,
	disableTabFocus,
}) {
	let classNameProp = `button ${className} button--${theme} animated `;
	if (disabled) classNameProp += "button--disabled";

	let styles = { borderRadius: borderRadius };

	return (
		<button
			type={type}
			tabIndex={!disableTabFocus ? null : -1}
			className={classNameProp}
			style={styles}
			onClick={onClick}
		>
			<div className="button-inner">{children}</div>
		</button>
	);
}
// Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

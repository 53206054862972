import { connect, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { DownArrowSVG } from "../../Icons/Arrows";
import { getCustomStyles, mobileCodeStyle } from "./SelectDropDown.config";
import {
	getCountryByCountryCode,
	renderFlagIcon,
} from "../../CountrySelect/CountrySelect.config";

import "./SelectDropDown.scss";
import { defaultCountries } from "../../CountrySelect/CountrySelect";

/** utils func */

const checkIfNumber = (value) => value?.match(/\d+/g);

const SelectDropDown = ({
	onSelect = () => {},
	label = "label",
	selectStyle = null,
	selectWidth = "100%",
	selectHeight = "70px",
	selectOptions = [],
	customSelectComponents = null,
	defaltValue = null,
}) => {
	const [selectValue, setSelectValue] = useState(null);

	const DropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<DownArrowSVG />
			</components.DropdownIndicator>
		);
	};

	return (
		<div className="selectDropDown">
			<label className="selectDropDown__label">{label}</label>
			<Select
				value={selectValue || defaltValue || selectOptions[0]}
				options={selectOptions}
				components={{
					DropdownIndicator,
					...customSelectComponents,
				}}
				onChange={(val) => {
					onSelect(val);
					setSelectValue(val);
				}}
				styles={
					selectStyle || getCustomStyles(selectWidth, selectHeight)
				}
				isSearchable={false}
				getOptionLabel={(option) => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							gap: "10px",
						}}
						key={option.value}
					>
						{renderFlagIcon(option?.icon)}
						<span>
							{checkIfNumber(option.label)
								? `+${option.label}`
								: option.label}
						</span>
					</div>
				)}
				getOptionValue={(option) => option.value}
				placeholder="Select a country"
			/>
		</div>
	);
};

const memorizedSelectDropDown = React.memo(SelectDropDown);

const mapStateToProps = (state) => ({
	allCountries: state?.classPack?.countries,
});

export default connect(mapStateToProps)(memorizedSelectDropDown);

import React from "react";
import PropTypes from "prop-types";

import "./PlainButton.scss";

const propTypes = {
	/** @type {string}		button|submit */
	type: PropTypes.string,
	/** @type {function}	onClick event of button */
	onClick: PropTypes.func,
	/** @type {string}		className prop for button */
	className: PropTypes.string,
	/** @type {boolean} 	disable button if true */
	disabled: PropTypes.bool,
	/** @type {string} 		children elements */
	children: PropTypes.node,
	/** @type {boolean}		if true will disable tab focus and add the tabIndex=-1 attribute */
	disableTabFocus: PropTypes.bool
};
const defaultProps = {
	type: "button",
	disabled: false,
	onClick: () => {},
	className: null,
	children: null,
	disableTabFocus: false
};

export default function PlainButton({
	type,
	disabled,
	onClick,
	className,
	children,
	disableTabFocus
}) {
	let classNameProp = `plainButton ${className} animated click-efx `;
	if (disabled) classNameProp += "plainButton--disabled";

	return (
		<button
			type={type}
			tabIndex={!disableTabFocus ? null : -1}
			className={classNameProp}
			onClick={onClick}
		>
			{children}
		</button>
	);
}
PlainButton.propTypes = propTypes;
PlainButton.defaultProps = defaultProps;

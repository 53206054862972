import axios from "axios";
import immer from "immer";
import { memberEndpointURL, endpointCreds } from "../base/config";
import { end, getErrorMessage } from "../base/functions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * get academy category
 */
export const getAcademyCategories = () => {
	return (dispatch) => {
		dispatch({ type: "GET_ACADEMY_CATEGORIES" });
		return axios
			.get(memberEndpointURL + "GetAcademyCategory", {
				params: {
					page_index: 0,
					page_size: 0,
				},
				auth: endpointCreds,
			})
			.then((res) =>
				dispatch({
					type: "GET_ACADEMY_CATEGORIES_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_ACADEMY_CATEGORIES_ERROR",
					error: err,
				})
			);
	};
};

/**
 * get academy class list
 */
export const getAcademyClassList = (categoryAlias, countryId = 1) => {
	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		dispatch({ type: "GET_ACADEMY_CLASS_LIST" });
		return axios
			.get(
				memberEndpointURL +
					"GetAcademyClass?category_alias=" +
					categoryAlias,
				{
					params: {
						page_index: 0,
						page_size: 0,
					},
					auth: accessToken == null && endpointCreds,
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
						CountryID: countryId,
					},
				}
			)
			.then((res) =>
				dispatch({
					type: "GET_ACADEMY_CLASS_LIST_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_ACADEMY_CLASS_LIST_ERROR",
					error: err,
				})
			);
	};
};

/**
 * get academy class detail
 */
export const getAcademyClassDetail = (classId) => {
	return (dispatch, getState) => {
		let store = getState();
		dispatch({ type: "GET_ACADEMY_CLASS_DETAIL" });
		return axios
			.get(
				memberEndpointURL + "GetAcademyClassDetail?class_id=" + classId,
				{
					auth: endpointCreds,
				}
			)
			.then((res) =>
				dispatch({
					type: "GET_ACADEMY_CLASS_DETAIL_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_ACADEMY_CLASS_DETAIL_ERROR",
					error: err,
				})
			);
	};
};

/**
 * select an academy class to book
 * @param {object} selectedClass 	selected class to book
 */
export const bookAcademyClass = (selectedClass) => {
	return {
		type: "BOOK_ACADEMY_CLASS",
		selectedClass,
	};
};

/**
 * purchase an academy class
 * @param {object} cardInfo 			information about card
 * @param {object} academyClassPack 	detail about academyClassPack
 */
export const purchaseAcademyClass =
	({ stripe_token, stripe_card_id, partial_payment }, academyClassPack) =>
	(dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;

		dispatch({ type: "PURCHASE_ACADEMY_PACK" });
		return axios
			.post(
				memberEndpointURL + "PurchaseAcademyClass",
				{
					class_id: academyClassPack.class_id,
					partial_payment,
					platform: "web",
					stripe_token,
					stripe_card_id,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				dispatch({
					type: "PURCHASE_ACADEMY_PACK_RESOLVE",
					// response will be { payment_info: {bill_amount, bill_no } }
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "PURCHASE_ACADEMY_PACK_ERROR",
					error: err,
				})
			);
	};

/**
 * saves purchased academy class inside redux store
 * we might need to save the card info which is used to satisfy the academy class payment,
 * coz the last 4 number of purchased card will be shown in the academy purchase success page
 */
export const setPurchasedAcademyClass = (
	academyClass,
	card,
	isPartiallyPaid
) => ({
	type: "SET_PURCHASED_ACADEMY_CLASS",
	academyClass,
	card,
	isPartiallyPaid,
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const currentState = {
	status: null,
	error: null,

	categoryList: null,
	categoryTotalPages: null,

	// academy class detail
	academyClass: {},

	// get list of academy classes by category alias
	academyClassList: null,

	// selected academy class to book
	selectedAcademyClass: {},

	isPartiallyPaid: false,

	purchasedAcademyClass: {},

	purchasedWithCard: {},
};

export function academy(state = currentState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			// get academy categories
			case "GET_ACADEMY_CATEGORIES":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_ACADEMY_CATEGORIES_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_ACADEMY_CATEGORIES_SUCCESS";
					draft.categoryTotalPages = action.res.data.total_page;
					draft.categoryList = action.res.data.category_list;
				} else {
					draft.status = "GET_ACADEMY_CATEGORIES_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_ACADEMY_CATEGORIES_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "GET_ACADEMY_CLASS_DETAIL":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_ACADEMY_CLASS_DETAIL_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_ACADEMY_CLASS_DETAIL_SUCCESS";
					draft.academyClass = action.res.data.class_info;
				} else {
					draft.status = "GET_ACADEMY_CLASS_DETAIL_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_ACADEMY_CLASS_DETAIL_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			// get academy class list
			case "GET_ACADEMY_CLASS_LIST":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_ACADEMY_CLASS_LIST_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_ACADEMY_CLASS_LIST_SUCCESS";
					draft.academyClassList = action.res.data.class_list;
				} else {
					draft.status = "GET_ACADEMY_CLASS_LIST_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_ACADEMY_CLASS_LIST_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			// select academy class
			case "BOOK_ACADEMY_CLASS":
				draft.status = action.type;
				draft.error = null;
				draft.selectedAcademyClass = action.selectedClass;
				break;

			// set purchased academy class
			case "SET_PURCHASED_ACADEMY_CLASS":
				draft.purchasedAcademyClass = action.academyClass;
				draft.purchasedWithCard = action.card;
				draft.isPartiallyPaid = action.isPartiallyPaid;
				break;

			case "PURCHASE_ACADEMY_PACK":
				draft.status = action.type;
				break;

			case "PURCHASE_ACADEMY_PACK_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "PURCHASE_ACADEMY_PACK_SUCCESS";
				} else {
					draft.status = "PURCHASE_ACADEMY_PACK_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "PURCHASE_ACADEMY_PACK_ERROR":
				draft.status = action.type;
				break;
		}
	});
}

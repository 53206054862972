import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import qs from "qs";
import { facebookAppID } from "../../../../base/config";
import {
	registerStep1,
	login,
	facebookAuthenticate,
	setAuthenticationCallback,
} from "../../../../redux/authentication";

import Button from "../../../Button/Button.jsx";
import { SocialSignIn } from "../../SocialSignIn/SocialSignIn.jsx";
import ErrorMessage from "../../../Alerts/ErrorMessage/ErrorMessage.jsx";
import "./RegisterForm.scss";
const authSchema = Yup.object().shape({
	email: Yup.string()
		.email("Invalid email address")
		.required("Email address is required"),
	password: Yup.string()
		.min(
			6,
			"Password must be at least 6 characters long and can not exceed 25 characters"
		)
		.max(
			25,
			"Password must be at least 6 characters long and can not exceed 25 characters"
		)
		.required("Password is required"),
});

class RegisterForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			busy: false,
			error: null,
			showPassword: false,
			showClose: false,
			email: null,
		};
	}

	componentDidUpdate(prevProps) {
		const prevAuthProps = prevProps.authentication;

		const { authentication } = this.props;

		if (prevAuthProps.status != authentication.status) {
			switch (authentication.status) {
				case "REGISTER_STEP_1_SUCCESS":
				case "FACEBOOK_AUTHENTICATE_SUCCESS":
					this.setState({
						busy: false,
						error: authentication.facebookError,
					});
					break;
				// register failed
				// if email exists, attempt to login with the password provided
				case "REGISTER_STEP_1_ERROR":
					// if (authentication.registerStatusCode == 412) {
					// this.props.login(this.email.value, this.password.value);
					// } else {
					this.setState({
						busy: false,
						error: authentication.registerError,
					});
					// }
					break;
				// when user registers with an existing email
				// registration will fail with error 412
				// we will try to log user in
				// if it fails, we will check account status
				// if that also fails we will show registration error
				case "CHECK_ACCOUNT_STATUS_SUCCESS":
					this.setState({
						busy: false,
						error: authentication.registerError,
					});
					break;
				case "CHECK_ACCOUNT_STATUS_ERROR":
					// only run if form is busy to ensure it doesnt show login errors in authenticate screen
					if (this.state.busy) {
						if (authentication.accountStatus == 416) {
							// for migration users, just disable busy state and dont show error message
							this.setState({
								busy: false,
							});
						} else {
							// otherwise show error message
							this.setState({
								busy: false,
								error: authentication.registerError,
							});
						}
					}

					break;
				case "FACEBOOK_AUTHENTICATE_ERROR":
					this.setState({
						busy: false,
						error: authentication.facebookError,
					});
					break;

				case "REGISTER_STEP_1":
					this.setState({ busy: true });
					break;
			}
		}
	}

	/**
	 * onSubmit event handler for <Form /> component
	 * @param  {object} 	e   	submit event object
	 * @param  {boolean} 	err 	whether there are errors
	 */
	handleSubmit(values) {
		this.setState({ error: null });
		this.props.registerStep1(values.email, values.password);

		if (
			!this.props.authentication.callback &&
			((this.props.location.pathname != "/" &&
				this.props.location.pathname.match(/authenticate$/) == null) ||
				this.props.location.search)
		) {
			this.props.setAuthenticationCallback(() =>
				this.props.history.push(
					this.props.location.pathname + this.props.location.search
				)
			);
		}
	}

	/**
	 * FacebookProvider <Login /> onResponse event handler
	 * @param  {object} res  response object
	 */
	handleFacebookSuccess(res) {
		this.props.facebookAuthenticate(res.tokenDetail.accessToken);
	}

	/**
	 * FacebookProvider <Login /> onError event handler
	 * @param  {object} res  response object
	 */
	handleFacebookFail(err) {
		this.setState({ busy: false });
	}

	render() {
		return (
			<div className="registerForm">
				<h3 className="registerForm__title auth__title text-uppercase">
					Newbie sign up
				</h3>
				<SocialSignIn />
				<div className="auth__or text-uppercase">or</div>
				<Formik
					initialValues={{
						email: "",
						password: "",
					}}
					validationSchema={authSchema}
					onSubmit={(values) => this.handleSubmit(values)}
				>
					{({
						values,
						errors,
						touched,
						isValid,
						handleBlur,
						handleChange,
						handleSubmit,
						dirty,
						setFieldValue,
						setErrors,
					}) => (
						<Form
							noValidate={true}
							onBlur={handleBlur}
							onSubmit={handleSubmit}
							autoComplete="skip-auto-complete"
						>
							<div className="registerForm__form">
								{this.state.error != null && (
									<ErrorMessage className="RegisterForm__error">
										{this.state.error}
									</ErrorMessage>
								)}

								<label className="form__group d-block">
									<div className="form__label text-uppercase">
										Email Address
									</div>
									<div className="position-relative">
										<input
											type="email"
											className="form__field"
											onChange={(e) => {
												this.setState({
													showClose:
														e.target.value.length >
														0,
												});
												handleChange(e);
											}}
											onBlur={handleBlur}
											value={values.email}
											name="email"
											autoComplete="new-password"
										/>
										{errors.email && touched.email && (
											<div className="form__field--error">
												{errors.email}
											</div>
										)}

										{this.state.showClose && (
											<img
												src="/assets/img/icons/input-close.svg"
												className="input__close"
												onClick={() => {
													setFieldValue("email", "");
													setErrors({ email: "" });
												}}
											/>
										)}
									</div>
								</label>
								<label className="form__group d-block">
									<div className="form__label text-uppercase">
										Create Password
									</div>
									<div className="auth__password-wrapper">
										<input
											type={
												!this.state.showPassword
													? "password"
													: "text"
											}
											className="form__field"
											onChange={(e) => {
												handleChange(e);
											}}
											onBlur={handleBlur}
											autoComplete="new-password"
											value={values.password}
											name="password"
										/>
										{errors.password &&
											touched.password && (
												<div className="form__field--error">
													{errors.password}
												</div>
											)}

										<button
											type="button"
											className="auth__password-toggle RegisterForm__link click-efx"
											onClick={() =>
												this.setState({
													showPassword:
														!this.state
															.showPassword,
												})
											}
										>
											{!this.state.showPassword
												? "Show"
												: "Hide"}
										</button>
									</div>
								</label>
								<Button
									className="registerForm__btn auth__btn "
									type="submit"
									disabled={
										!(isValid && dirty) ||
										this.props.authentication.status ===
											"REGISTER_STEP_1"
									}
								>
									{this.props.authentication.status ===
									"REGISTER_STEP_1"
										? "Loading..."
										: "Continue"}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		registerStep1: bindActionCreators(registerStep1, dispatch),
		login: bindActionCreators(login, dispatch),
		facebookAuthenticate: bindActionCreators(
			facebookAuthenticate,
			dispatch
		),
		setAuthenticationCallback: bindActionCreators(
			setAuthenticationCallback,
			dispatch
		),
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
);

import React from "react";
import PropTypes from "prop-types";

import "./Panel.scss";

const propTypes = {
	/** @type {node}    children elements */
	children: PropTypes.node.isRequired,
	/** @type {string} 	className */
	className: PropTypes.string
};

const defaultProps = {
	className: ""
};

/**
 * Standard white panel for various contents
 */
export default function Panel({ children, className }) {
	return <section className={"panel " + className}>{children}</section>;
}

Panel.propTypes = propTypes;
Panel.defaultProps = defaultProps;

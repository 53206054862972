import React from "react";

const EmptyWalletSVG = () => {
	return (
		<svg
			width="55"
			height="42"
			viewBox="0 0 55 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				y="11.1724"
				width="44"
				height="28.6"
				rx="3"
				transform="rotate(-14.7094 0 11.1724)"
				fill="#F4F4F4"
				stroke="#8AB0AB"
				stroke-linejoin="round"
			/>
			<rect
				x="9.91016"
				y="12.1177"
				width="44"
				height="28.6"
				rx="3"
				fill="#F4F4F4"
				stroke="#8AB0AB"
				stroke-linejoin="round"
			/>
			<rect
				x="9.91016"
				y="17.1177"
				width="44"
				height="5"
				rx="1"
				fill="#8AB0AB"
				stroke="#8AB0AB"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default EmptyWalletSVG;

import axios from "axios";
import immer from "immer";
import { memberEndpointURL } from "../base/config";
import { end, getErrorMessage } from "../base/functions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * get achievements list
 */
export const getAchievements = ({ ip, countryId }) => {

	return (dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "GET_ACHIEVEMENTS" });
		return axios
			.get(memberEndpointURL + "GetMyAchievement", {
				params: {
					page_index: 0,
					page_size: 0,
				},
				headers: {
					Authorization: "Bearer " + accessToken,
					"Content-type": "application/json",
					IpAddress: ip,
					CountryID: countryId,
				},
			})
			.then((res) =>
				dispatch({
					type: "GET_ACHIEVEMENTS_RESOLVE",
					res: res.data,
				})
			)
			.catch((err) =>
				dispatch({
					type: "GET_ACHIEVEMENTS_ERROR",
					error: err,
				})
			);
	};
};

/**
 * revert store to initial state
 * called when user logout
 * @return {[type]} [description]
 */
export const resetAchievements = () => ({ type: "RESET_ACHIEVEMENTS" });

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const initialState = {
	status: null,
	error: null,
	list: [],
	totalPages: null,
	featured: {},
};
export function achievements(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			// get achievements
			case "GET_ACHIEVEMENTS":
				draft.status = action.type;
				draft.error = null;
				break;

			case "GET_ACHIEVEMENTS_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "GET_ACHIEVEMENTS_SUCCESS";
					draft.totalPages = action.res.data.total_page;
					draft.list = action.res.data.achievement_list;
					draft.featured = action.res.data.featured_achievement;
				} else {
					draft.status = "GET_ACHIEVEMENTS_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			case "GET_ACHIEVEMENTS_ERROR":
				draft.status = action.type;
				draft.error = getErrorMessage(action.err);
				break;

			case "RESET_ACHIEVEMENTS":
				draft = Object.assign(draft, initialState);
				break;
		}
	});
}

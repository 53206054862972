import { combineReducers } from "redux";
import { persistReducer, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { masterData } from "./masterData";
import { ui } from "./ui";
import { lang } from "./lang";
import { country } from "./countryId";
import { authentication } from "./authentication";
import { profile } from "./profile";
import { payment } from "./payment";
import { classPack } from "./classPack";
// import { schedules } from "./schedules";
import { schedules } from "./schedules/schedules.jsx";
import { teachers } from "./teachers";
import { studios } from "./studios";
import { classTypes } from "./classTypes";
import { achievements } from "./achievements";
import { friends } from "./friends";
import { academy } from "./academy";
import { notifications } from "./notifications";
import { activitiesLog } from "./activitiesLog";
import { transactionHistory } from "./transactionHistory";
import { adhocPayments } from "./adhocPayments";
import { ymLife } from "./ymLife";
import { popup } from "./popup";
import { unlimitedPendingData } from "./unlimitedPending";

const storageConfig = {
	key: "primary",
	storage,
	whitelist: ["profile", "ymLife", "lang", "country"],
};

const authPersistConfig = {
	key: "auth",
	storage: storage,
	blacklist: ["status", "registerError"],
};

const reducers = persistCombineReducers(storageConfig, {
	masterData,
	ui,
	authentication: persistReducer(authPersistConfig, authentication),
	lang,
	country,
	profile,
	payment,
	classPack,
	schedules,
	teachers,
	studios,
	classTypes,
	achievements,
	friends,
	academy,
	notifications,
	activitiesLog,
	transactionHistory,
	adhocPayments,
	ymLife,
	popup,
	unlimitedPendingData,
});

export default reducers;

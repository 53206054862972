import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { end } from "../../../base/functions";
import { showAuthentication } from "../../../redux/ui";
import { forgotPassword } from "../../../redux/authentication";

import Modal from "../../Modal/Modal.jsx";
import ModalFooter from "../../Modal/ModalFooter/ModalFooter.jsx";
import Form from "../../Form/Form.jsx";
import Button from "../../Button/Button.jsx";
import ErrorMessage from "../../Alerts/ErrorMessage/ErrorMessage.jsx";
import "./ForgotPasswordModal.scss";
import AuthHeader from "../AuthHeader/AuthHeader.jsx";

class ForgotPasswordModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			busy: false,
			error: null,
		};
	}

	componentDidMount() {
		this.email.focus();
	}

	componentDidUpdate(prevProps) {
		const prevAuthProps = prevProps.authentication;
		const { authentication } = this.props;

		if (prevAuthProps.status != authentication.status) {
			switch (authentication.status) {
				case "FORGOT_PASSWORD_SUCCESS":
					this.setState({ busy: false });
					break;
				case "FORGOT_PASSWORD_ERROR":
					this.setState({
						busy: false,
						error: authentication.forgotPasswordError,
					});
					break;
			}
		}
	}

	/**
	 * close authentication modal window
	 */
	handleClose() {
		this.props.showAuthentication(null);
	}

	/**
	 * onSubmit event handler for <Form /> component
	 * @param  {object} 	e   	submit event object
	 * @param  {boolean} 	err 	whether there are errors
	 */
	handleSubmit(e, err) {
		if (!err) {
			this.setState({ busy: true, error: null });
			this.props.forgotPassword(this.email.value);
		}
	}

	render() {
		const { authentication } = this.props;

		return (
			<Modal
				className="forgotPasswordModal"
				icon={
					<img
						src="/assets/img/ui/logo-yoga-movement.svg"
						alt="Welcome to Yoga Movement"
					/>
				}
				maxWidth={480}
				title="Forgot password"
				onClose={() => this.handleClose()}
			>
				<div className="auth__contents">
					<div
						className={`d-flex align-items-center justify-content-between`}
					>
						<div className="position-relative">
							<img
								src="assets/img/ui/logo-yoga-movement.svg"
								alt="Welcome to Yoga Movement"
								className={`Auth-header__icon`}
							/>
						</div>
					</div>
					<Form
						busy={this.state.busy}
						onSubmit={(e, err) => this.handleSubmit(e, err)}
						className="forgotPasswordModal__form form--check-disable"
					>
						<div className="forgotPasswordModal__contents">
							<h3 className="auth__title">FORGOT PASSWORD</h3>
							{authentication.forgotPasswordError && (
								<ErrorMessage className="forgotPasswordModal__error">
									{authentication.forgotPasswordError}
								</ErrorMessage>
							)}
							<label className="form__group d-block">
								<div className="form__label text-uppercase">
									Email Address
								</div>
								<input
									type="email"
									className="form__field"
									ref={(e) => {
										this.email = e;
									}}
									required
									pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
								/>
								<div className="form__msg--error">
									Invalid email address
								</div>
							</label>
						</div>
						<Button className="forgotPasswordModal__btn form__button">
							{!this.state.busy ? "Submit" : "Submitting..."}
						</Button>
						<div
							className="forgotPasswordModal__link auth__link mt-4 animated"
							onClick={() =>
								this.props.showAuthentication("log-in")
							}
						>
							Back to Sign In
						</div>
					</Form>
				</div>
			</Modal>
		);
	}
}

const mapStateToProps = function (store) {
	return {
		authentication: store.authentication,
	};
};
const mapDispatchToProps = function (dispatch) {
	return {
		showAuthentication: bindActionCreators(showAuthentication, dispatch),
		forgotPassword: bindActionCreators(forgotPassword, dispatch),
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ForgotPasswordModal);

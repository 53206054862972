import axios from "axios";
import immer from "immer";

import { memberEndpointURL } from "../base/config";
import { end, getErrorMessage } from "../base/functions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// ACTIONS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * revert store to initial state
 */
export const resetAdhocPayments = () => ({ type: "RESET_ADHOC_PAYMENTS" });

/**
 * fetch api to get adhoc payments listing
 * use both basic auth and bearer token for authentication to fetch adhoc payments listing
 * because listing can differ according to account type
 */
export const getAdhocPayments = () => (dispatch, getState) => {
	let store = getState();
	let accessToken = store.authentication.accessToken;

	dispatch({ type: "GET_ADHOC_PAYMENTS" });

	return axios
		.get(memberEndpointURL + "GetAdHocPayment", {
			params: {},
			headers: {
				Authorization: "Bearer " + accessToken,
				"Content-type": "application/json",
			},
		})
		.then((res) =>
			dispatch({
				type: "GET_ADHOC_PAYMENTS_RESOLVE",
				res: res.data,
			})
		)
		.catch((err) =>
			dispatch({
				type: "GET_ADHOC_PAYMENTS_ERROR",
				error: err,
			})
		);
};

/**
 * Purchase an adhoc item
 * @param {object} cardInfo 		Information about card
 * @param {object} classPack 		classPack object
 */
export const purchaseAdhocItem =
	({ stripe_token, stripe_card_id }, selectedAdhocPayment) =>
	(dispatch, getState) => {
		let store = getState();
		let accessToken = store.authentication.accessToken;
		dispatch({ type: "PURCHASE_ADHOC_ITEM" });

		return axios
			.post(
				memberEndpointURL + "PurchaseAdHoc",
				{
					transaction_id: selectedAdhocPayment.transaction_id,
					platform: "web",
					stripe_token,
					stripe_card_id,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			)
			.then((res) => {
				dispatch({
					type: "PURCHASE_ADHOC_ITEM_RESOLVE",
					// response will be { payment_info: {bill_amount, bill_no } }
					res: res.data,
				});
			})
			.catch((err) =>
				dispatch({
					type: "PURCHASE_ADHOC_ITEM_ERROR",
					error: err,
				})
			);
	};

/**
 * Save purcahsedClasspack inside redux store.
 * We might need to save the card info which is used to satisfy the classPack payment,
 * Coz the last 4 number of purchased card will use to show in `ClassPackPurchaseSuccess`.
 */
export const setPurchasedAdhocItem = (selectedAdhocPayment, card) => ({
	type: "SET_PURCHASED_ADHOC_ITEM",
	selectedAdhocPayment,
	card,
});

/**
 * select ad hoc payment and store in adhocPaymentsReducer as a selectedAdhocPayment property.
 * selectAdhocPayment will be stored in memory.
 * selectAdhocPayment state will be lost when the browser is refreshed.
 */
export const selectAdhocPayment = (selectedAdhocPayment) => ({
	type: "SELECT_ADHOC_PAYMENT",
	selectedAdhocPayment,
});

/**
 * resets error to null
 */
export const resetError = () => ({
	type: "RESET_ERROR",
});

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//// REDUCER
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const initialState = {
	status: null,
	list: null,
	selectedAdhocPayment: null,
	purchasedAdhocItem: {},
	purchasedWithCard: {}, // the last 4 number of Card that used to satisfy purchased class pack's payment
	error: null,
};
export function adhocPayments(state = initialState, action) {
	return immer(state, (draft) => {
		switch (action.type) {
			case "RESET_ADHOC_PAYMENTS":
				draft = Object.assign(draft, initialState);
				break;

			// Get Adhoc Payments
			case "GET_ADHOC_PAYMENTS":
				draft.status = action.type;
				break;

			case "GET_ADHOC_PAYMENTS_RESOLVE":
				draft.status = "GET_ADHOC_PAYMENTS_SUCCESS";
				draft.list = action.res.data.payment_list;
				break;

			// Select Adhoc Payment
			case "SELECT_ADHOC_PAYMENT":
				draft.selectedAdhocPayment = action.selectedAdhocPayment;
				break;

			// Purchase Adhoc Item
			case "PURCHASE_ADHOC_ITEM":
				draft.status = action.type;
				break;

			case "PURCHASE_ADHOC_ITEM_RESOLVE":
				if (action.res.errorCode == 0) {
					draft.status = "PURCHASE_ADHOC_ITEM_SUCCESS";
				} else {
					draft.status = "PURCHASE_ADHOC_ITEM_ERROR";
					draft.error = getErrorMessage(action.res);
				}
				break;

			// Set Purchased Adhoc Item
			case "SET_PURCHASED_ADHOC_ITEM":
				draft.status = action.type;
				draft.purchasedAdhocItem = action.selectedAdhocPayment;
				draft.purchasedWithCard = action.card;
				break;

			// Reset Error
			case "RESET_ERROR":
				draft.status = action.type;
				draft.error = null;
				break;
		}
	});
}

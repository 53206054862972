import React from "react";
import PropTypes from "prop-types";
import "./AuthHeader.scss";

const defaultProps = {
	onHandleIconClick: () => null,
	profileDetail: {},
	checkProfile: false,
};
const propTypes = {
	onHandleIconClick: PropTypes.func,
	profileDetail: PropTypes.object,
	checkProfile: PropTypes.bool,
};

function AuthHeader({ onHandleIconClick, profileDetail, checkProfile }) {
	const cssPrefix = "AuthHeader";

	const userPicStyle =
		profileDetail &&
		profileDetail.avatar_img != null &&
		profileDetail.avatar_img.length > 0
			? {
					background: `url(${profileDetail.avatar_img}) no-repeat center / cover`,
			  }
			: {};

	return (
		<div
			className={`${cssPrefix} d-flex align-items-center justify-content-between`}
		>
			<div className="position-relative">
				<img
					src="/assets/img/ui/logo-yoga-movement.svg"
					alt="Welcome to Yoga Movement"
					className={`${cssPrefix}__icon`}
				/>
				{checkProfile && profileDetail != null && (
					<div
						className="loginForm__user-pic circle position-absolute"
						style={userPicStyle}
					></div>
				)}
			</div>

			<div
				className="d-flex align-items-center"
				onClick={onHandleIconClick}
			>
				<img
					src="img/icons/newbie-icon.svg"
					alt="newbie"
					className={`${cssPrefix}__newbie-icon`}
				/>

				<span className={`${cssPrefix}__text text-capitalize`}>
					NEWBIE SIGNUPS
				</span>

				<img
					src="img/icons/forward-icon.svg"
					alt="newbie"
					className={`${cssPrefix}__forward-icon`}
				/>
			</div>
		</div>
	);
}

AuthHeader.propsTypes = propTypes;
AuthHeader.defaultProps = defaultProps;

export default AuthHeader;

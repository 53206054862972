import React, { useState, useRef } from "react";
// import PropTypes from "prop-types";

import Form from "../Form/Form.jsx";
import Button from "../Button/Button.jsx";

import "./Search.scss";

// const propTypes = {
// 	/** @type {string} 		react className prop */
// 	className: PropTypes.string,
// 	/** @type {number} 		specify the short hint for search field */
// 	placeholder: PropTypes.string,
// 	/** @type {boolean} 	show error state */
// 	error: PropTypes.bool,
// 	/** @type {function}	triggers when form submits */
// 	onSubmit: PropTypes.func.isRequired,
// 	/** @type {string} 		defalut value for search field */
// 	value: PropTypes.string,
// 	/** @type {boolean} 	disable form if true */
// 	busy: PropTypes.bool,
// 	/** @type {boolean} 	allow for empty text submit */
// 	allowNull: PropTypes.bool,
// };

const defaultProps = {
	className: "",
	placeholder: "",
	error: false,
	value: "",
	busy: false,
	allowNull: false,
};

/**
 * standard search field component
 */
export default function YMSearch({
	className,
	placeholder,
	error,
	onSubmit,
	value,
	busy,
	allowNull,
}) {
	const [showRemove, toggleRemove] = useState(value.length > 0);
	const inputRef = useRef(null);
	let errorClass = error && " ymSearch--error error";
	if (busy) className += " ymSearch--busy ";

	function handleSubmit(e, searchTerm) {
		e.preventDefault();
		if (inputRef.current.value.trim() !== "" || allowNull) {
			onSubmit(inputRef.current.value || "");
		}
	}

	function handleRemove(e) {
		e.preventDefault();
		inputRef.current.value = "";
		toggleRemove(false);
		onSubmit("");
	}

	return (
		<Form
			className={`form--do-validate ymSearch ${className} ${errorClass}`}
			onSubmit={(e) =>
				handleSubmit(
					e,
					document.querySelector(".ymSearch__field").value
				)
			}
		>
			<label className="ymSearch__inner d-flex align-items-center justify-content-between ">
				<input
					type="text"
					className={
						"ymSearch__field form__field " +
						(error && "form__field--error")
					}
					ref={inputRef}
					onChange={(e) => toggleRemove(e.target.value.length > 0)}
					placeholder={placeholder}
					defaultValue={value !== "" ? value : ""}
				/>
				{showRemove ? (
					<img
						src="/img/icons/input-close.svg"
						className="mr-4"
						onClick={(e) => handleRemove(e)}
					/>
				) : (
					<Button className="ymSearch__btn">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="18"
							height="18"
							viewBox="0 0 18 18"
						>
							<g fill="none" fillRule="evenodd">
								<g stroke="#000">
									<g>
										<g>
											<g transform="translate(-1326 -1142) translate(80 1127) translate(1120) translate(126 15)">
												<circle
													cx="7.5"
													cy="7.5"
													r="6.5"
												/>
												<path
													strokeLinecap="round"
													strokeWidth=".5"
													d="M7.5 12C9.985 12 12 9.985 12 7.5"
												/>
												<circle
													cx="7.5"
													cy="7.5"
													r="6.25"
													strokeWidth=".5"
												/>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M16.5 16.5L12.464 12.446"
												/>
											</g>
										</g>
									</g>
								</g>
							</g>
						</svg>
					</Button>
				)}
			</label>
		</Form>
	);
}
// YMSearch.propTypes = propTypes;
YMSearch.defaultProps = defaultProps;

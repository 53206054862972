import React from "react";

export const DownArrowSVG = () => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.759 5.15108C15.1186 4.73189 15.0702 4.10057 14.6511 3.741C14.2641 3.40908 13.6964 3.42473 13.3285 3.75881L13.241 3.84895L7.997 9.96301L2.75951 3.8495C2.42787 3.46231 1.86447 3.39095 1.44984 3.66484L1.3495 3.74052C0.962312 4.07216 0.890955 4.63556 1.16484 5.05019L1.24052 5.15053L7.23609 12.1505C7.60842 12.5852 8.26072 12.6144 8.67066 12.238L8.7546 12.1511L14.759 5.15108Z"
				fill="#59BAAE"
			/>
		</svg>
	);
};

export const RightArrowSVG = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.15108 1.241C4.73189 0.881423 4.10057 0.929755 3.741 1.34895C3.40908 1.7359 3.42473 2.30359 3.75881 2.67145L3.84895 2.75903L9.963 8.003L3.8495 13.2405C3.46231 13.5721 3.39095 14.1355 3.66484 14.5502L3.74052 14.6505C4.07215 15.0377 4.63556 15.109 5.05019 14.8352L5.15053 14.7595L12.1505 8.76391C12.5852 8.39158 12.6144 7.73928 12.238 7.32934L12.1511 7.2454L5.15108 1.241Z"
				fill="#59BAAE"
			/>
		</svg>
	);
};

export const LeftArrowSVG = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.8489 14.759C11.2681 15.1186 11.8994 15.0702 12.259 14.6511C12.5909 14.2641 12.5753 13.6964 12.2412 13.3286L12.1511 13.241L6.037 7.997L12.1505 2.75951C12.5377 2.42788 12.609 1.86447 12.3352 1.44984L12.2595 1.3495C11.9278 0.962314 11.3644 0.890956 10.9498 1.16484L10.8495 1.24053L3.84949 7.23609C3.41478 7.60842 3.38557 8.26072 3.76205 8.67066L3.84893 8.7546L10.8489 14.759Z"
				fill="#59BAAE"
			/>
		</svg>
	);
};

//google analytics
export const gaTrackingID = "";

let settings = {
	endpointDomain: null,
	endpointVersion: null,
	glovbalEndpointURL: null,
	baseEndpointURL: null,
	memberEndpointURL: null,
	memberEndpointURLV2: null,
	webEndpointURL: null,
	endpointCreds: null,

	siteURL: null,

	facebookAppID: null,
	GoogleAppID: null,
	googleAnalyticsKey: null,

	stripeEndpointURL: null,
	stripeAPIKey: null,
	googleMapsAPIKey: null,
	zendeskChatEmbedKey: null,

	firebaseAPIKey: null,
	firebaseAuthDomain: null,
	firebaseDatabaseURL: null,
	firebaseProjectId: null,
	firebaseStorageBucket: null,
	firebaseMessagingSenderId: null,

	classRatingList: ["", "Exhausted", "Tired", "Ok", "Good", "Awesome"],
};

switch (process.env.NODE_ENV) {
	case "development":
		settings.endpointDomain = "https://api-stag.yogamovement.com/";
		settings.endpointVersion = "global-fea";
		settings.baseEndpointURL = `${settings.endpointDomain}${settings.endpointVersion}/`;
		settings.memberEndpointURL = `${settings.baseEndpointURL}Member/`;
		settings.stripeEndpointURL = `${settings.baseEndpointURL}Stripe/`;
		settings.webEndpointURL = `${settings.baseEndpointURL}Web/`;

		/** FOR GLOBAL UPGRADE */
		settings.globalEndpointDomain = `https://api-stag.yogamovement.com/global-fea/`;
		settings.globalMemberEndpointURL = `${settings.globalEndpointDomain}Member/`;
		settings.globalStripeEndpointURL = `${settings.globalEndpointDomain}Stripe/`;
		settings.globalWebEndpointURL = `${settings.globalEndpointDomain}Web/`;

		settings.endpointCreds = {
			username: "apiMember",
			password: "cx_C8$9qC%b",
		};

		settings.siteURL = "http://localhost:8080";

		settings.facebookAppID = "439519366517645";
		settings.appleID = "com.yogamovement.android";
		settings.redirectURI = `https://api-stag.yogamovement.com/${settings.endpointVersion}/Apple/CallBackAppleWeb`;

		settings.GoogleAppID = "";

		//stripe
		settings.stripeAPIURL = "https://api.stripe.com/v1/";
		settings.stripeAPIKey = "pk_test_60GZkc69GflAYO9ZACzNuAbI";

		//google maps
		settings.googleMapsAPIKey = "AIzaSyBG-zzPnPn8Rnln0802s9gqlZ_PkFKKQ5Y";

		//zendesk chat
		settings.zendeskChatEmbedKey = "315177bc-bf4f-45d5-9821-ca6abf723914";

		//google access
		settings.googleClientID =
			"13346239379-foqv904qa02coj0p8jrmlrrk9ujt46n9.apps.googleusercontent.com";

		//firebase
		settings.firebaseAPIKey = "AIzaSyBG-zzPnPn8Rnln0802s9gqlZ_PkFKKQ5Y";
		settings.firebaseAuthDomain = "yogamovement-staging.firebaseapp.com";
		settings.firebaseDatabaseURL =
			"https://yogamovement-staging.firebaseio.com";
		settings.firebaseProjectId = "yogamovement-staging";
		settings.firebaseStorageBucket = "yogamovement-staging.appspot.com";
		settings.firebaseMessagingSenderId = "13346239379";
		break;
	case "staging":
		settings.endpointDomain = "https://api-stag.yogamovement.com/";
		settings.endpointVersion = "global-fea"; // staging endpoint now change to global, previously was v3;
		settings.baseEndpointURL = `${settings.endpointDomain}${settings.endpointVersion}/`;

		settings.memberEndpointURL = `${settings.baseEndpointURL}Member/`;
		settings.stripeEndpointURL = `${settings.baseEndpointURL}Stripe/`;
		settings.webEndpointURL = `${settings.baseEndpointURL}Web/`;

		/** FOR GLOBAL UPGRADE */
		settings.globalEndpointDomain = `https://api-stag.yogamovement.com/global-fea/`;
		settings.globalMemberEndpointURL = `${settings.globalEndpointDomain}Member/`;
		settings.globalStripeEndpointURL = `${settings.globalEndpointDomain}Stripe/`;
		settings.globalWebEndpointURL = `${settings.globalEndpointDomain}Web/`;

		settings.endpointCreds = {
			username: "apiMember",
			password: "cx_C8$9qC%b",
		};

		//google access
		settings.googleClientID =
			"13346239379-foqv904qa02coj0p8jrmlrrk9ujt46n9.apps.googleusercontent.com";

		settings.siteURL = "https://webfront-staging.yogamovement.com";

		settings.facebookAppID = "439519366517645";
		settings.appleID = "com.yogamovement.android";
		settings.redirectURI = `https://api-stag.yogamovement.com/${settings.endpointVersion}/Apple/CallBackAppleWeb`;
		settings.GoogleAppID = "";

		//stripe
		settings.stripeAPIURL = "https://api.stripe.com/v1/";
		settings.stripeAPIKey = "pk_test_60GZkc69GflAYO9ZACzNuAbI";

		//google maps
		settings.googleMapsAPIKey = "AIzaSyBG-zzPnPn8Rnln0802s9gqlZ_PkFKKQ5Y";

		//zendesk chat
		settings.zendeskChatEmbedKey = "315177bc-bf4f-45d5-9821-ca6abf723914";

		//firebase
		settings.firebaseAPIKey = "AIzaSyBG-zzPnPn8Rnln0802s9gqlZ_PkFKKQ5Y";
		settings.firebaseAuthDomain = "yogamovement-staging.firebaseapp.com";
		settings.firebaseDatabaseURL =
			"https://yogamovement-staging.firebaseio.com";
		settings.firebaseProjectId = "yogamovement-staging";
		settings.firebaseStorageBucket = "yogamovement-staging.appspot.com";
		settings.firebaseMessagingSenderId = "13346239379";
		break;
	case "uat":
		settings.endpointDomain = "https://api-stag.yogamovement.com/";
		settings.endpointVersion = "global-fea"; // staging endpoint now change to global, previously was v3;
		settings.baseEndpointURL = `${settings.endpointDomain}${settings.endpointVersion}/`;

		settings.memberEndpointURL = `${settings.baseEndpointURL}Member/`;
		settings.stripeEndpointURL = `${settings.baseEndpointURL}Stripe/`;
		settings.webEndpointURL = `${settings.baseEndpointURL}Web/`;

		/** FOR GLOBAL UPGRADE */
		settings.globalEndpointDomain = `https://api-stag.yogamovement.com/global-fea/`;
		settings.globalMemberEndpointURL = `${settings.globalEndpointDomain}Member/`;
		settings.globalStripeEndpointURL = `${settings.globalEndpointDomain}Stripe/`;
		settings.globalWebEndpointURL = `${settings.globalEndpointDomain}Web/`;

		settings.endpointCreds = {
			username: "apiMember",
			password: "cx_C8$9qC%b",
		};

		//google access
		settings.googleClientID =
			"13346239379-foqv904qa02coj0p8jrmlrrk9ujt46n9.apps.googleusercontent.com";

		settings.siteURL = "https://webfront-uat.yogamovement.com";

		settings.facebookAppID = "439519366517645";
		settings.appleID = "com.yogamovement.android";
		settings.redirectURI = `https://api-stag.yogamovement.com/${settings.endpointVersion}/Apple/CallBackAppleWeb`;
		settings.GoogleAppID = "";

		//stripe
		settings.stripeAPIURL = "https://api.stripe.com/v1/";
		settings.stripeAPIKey = "pk_test_60GZkc69GflAYO9ZACzNuAbI";

		//google maps
		settings.googleMapsAPIKey = "AIzaSyBG-zzPnPn8Rnln0802s9gqlZ_PkFKKQ5Y";

		//zendesk chat
		settings.zendeskChatEmbedKey = "315177bc-bf4f-45d5-9821-ca6abf723914";

		//firebase
		settings.firebaseAPIKey = "AIzaSyBG-zzPnPn8Rnln0802s9gqlZ_PkFKKQ5Y";
		settings.firebaseAuthDomain = "yogamovement-staging.firebaseapp.com";
		settings.firebaseDatabaseURL =
			"https://yogamovement-staging.firebaseio.com";
		settings.firebaseProjectId = "yogamovement-staging";
		settings.firebaseStorageBucket = "yogamovement-staging.appspot.com";
		settings.firebaseMessagingSenderId = "13346239379";
		break;
	case "production":
		settings.endpointDomain = "https://api.yogamovement.com/";
		settings.endpointVersion = "global-fea";
		settings.baseEndpointURL = `${settings.endpointDomain}${settings.endpointVersion}/`;
		settings.memberEndpointURL = `${settings.baseEndpointURL}Member/`;
		settings.stripeEndpointURL = `${settings.baseEndpointURL}Stripe/`;
		settings.webEndpointURL = `${settings.baseEndpointURL}Web/`;

		/** FOR GLOBAL UPGRADE */
		settings.globalEndpointDomain = `https://api.yogamovement.com/global-fea/`;
		settings.globalMemberEndpointURL = `${settings.globalEndpointDomain}Member/`;
		settings.globalStripeEndpointURL = `${settings.globalEndpointDomain}Stripe/`;
		settings.globalWebEndpointURL = `${settings.globalEndpointDomain}Web/`;

		settings.endpointCreds = {
			username: "apiMember",
			password: "cx_C8$9qC%b",
		};

		//google access
		settings.googleClientID =
			"186286696540-gtb8ideqtvrgrg5nh1n8je9dm86j6f6u.apps.googleusercontent.com";

		settings.siteURL = "https://www.yogamovement.com";

		settings.facebookAppID = "307318826542112";
		settings.appleID = "com.yogamovement.android";
		settings.redirectURI =
			"https://api.yogamovement.com/global-fea/Apple/CallBackAppleWeb";

		settings.GoogleAppID = "";
		settings.googleAnalyticsKey = "UA-136595522-1";

		//stripe
		settings.stripeAPIURL = "https://api.stripe.com/v1/";
		settings.stripeAPIKey = "pk_live_03uF9il0qrnCN1kRgAALlEqC";

		//google maps
		settings.googleMapsAPIKey = "AIzaSyCeGM_a83BDFPbXmjONkEAxjjmYs8vGxqU";

		//zendesk chat
		settings.zendeskChatEmbedKey = "315177bc-bf4f-45d5-9821-ca6abf723914";

		//firebase
		settings.firebaseAPIKey = "AIzaSyCeGM_a83BDFPbXmjONkEAxjjmYs8vGxqU";
		settings.firebaseAuthDomain = "yogamovement-production.firebaseapp.com";
		settings.firebaseDatabaseURL =
			"https://yogamovement-production.firebaseio.com";
		settings.firebaseProjectId = "yogamovement-production";
		settings.firebaseStorageBucket = "yogamovement-production.appspot.com";
		settings.firebaseMessagingSenderId = "186286696540";
		break;
}

export const endpointDomain = settings.endpointDomain;
export const endpointVersion = settings.endpointVersion;
export const baseEndpointURL = settings.baseEndpointURL;
export const memberEndpointURL = settings.memberEndpointURL;
export const memberEndpointURLV2 = settings.memberEndpointURLV2;
export const webEndpointURL = settings.webEndpointURL;
export const stripeEndpointURL = settings.stripeEndpointURL;
export const endpointCreds = settings.endpointCreds;

export const googleClientID = settings.googleClientID;

export const siteURL = settings.siteURL;

export const facebookAppID = settings.facebookAppID;
export const appleID = settings.appleID;
export const redirectURI = settings.redirectURI;
export const GoogleAppID = settings.GoogleAppID;
export const googleAnalyticsKey = settings.googleAnalyticsKey;

export const stripeAPIURL = settings.stripeAPIURL;
export const stripeAPIKey = settings.stripeAPIKey;
export const googleMapsAPIKey = settings.googleMapsAPIKey;

export const classRatingList = settings.classRatingList;
export const zendeskChatEmbedKey = settings.zendeskChatEmbedKey;

export const firebaseAPIKey = settings.firebaseAPIKey;
export const firebaseAuthDomain = settings.firebaseAuthDomain;
export const firebaseDatabaseURL = settings.firebaseDatabaseURL;
export const firebaseProjectId = settings.firebaseProjectId;
export const firebaseStorageBucket = settings.firebaseStorageBucket;
export const firebaseMessagingSenderId = settings.firebaseMessagingSenderId;

export const globalMemberEndpoint = settings.globalMemberEndpointURL;
export const globalStripeEndpoint = settings.globalStripeEndpointURL;
export const globalWebEndpointURL = settings.globalWebEndpointURL;

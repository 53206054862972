import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "../../Modal/Modal.jsx";
import Button from "../../Button/Button.jsx";

import NewbiePaymentOption from "./NewbiePaymentOption/NewbiePaymentOption.jsx";
import NewbiePaymentOptionLoading from "./NewbiePaymentOption/NewbiePaymentOptionLoading.jsx";

import "./NewbiePaymentModal.scss";
import { scrollElemTop } from "../../../base/functions.jsx";
import { useSelector } from "react-redux";
import axios from "axios";
import { memberEndpointURL } from "../../../base/config.jsx";
import { useHistory } from "react-router-dom";
import { getUnlockedPacksFromTiers } from "../../../views/Classes/ClassHelpers.jsx";

const NewbiePaymentModal = ({
	profile,
	onModalClose,
	onClickNewbiePaid,
	onProceed,
	classPacks,
	pendingBilling,
	busy,
	error,
	activeBreakPoint,
}) => {
	const [selectedClassPackID, setSelectedClassPackID] = useState(null);
	const [allUnlimitedPacks, setAllUnlimitedPacks] = useState([]);
	const [classPackError, setClassPackError] = useState(false);
	const [allClassPacks, setAllClassPacks] = useState([]);
	const { accessToken } = useSelector(({ authentication }) => authentication);
	const { memberClassPacks = [] } = useSelector(({ profile }) => profile);

	const unlockedUnlimitedPack = getUnlockedPacksFromTiers(allUnlimitedPacks);

	const history = useHistory();

	const pendingBillingPack = {
		pack_id: pendingBilling ? pendingBilling.bill_id : null,
		total_credit: 1,
		pack_name: "Pay for this class only",
		newbie_note: null,
		pack_description: null,
		pack_price: pendingBilling ? pendingBilling.billing_amount : 0,
	};

	useEffect(() => {
		let rawClassPacksArray = classPacks?.flat();
		let formattedClassPacksArray = rawClassPacksArray?.map((pack) => {
			if (pack?.pack_type === "unlimited") {
				return pack?.unlimited_tiers?.map((tier) => {
					return { ...pack, ...tier };
				});
			} else {
				return pack;
			}
		});

		const _unlimitedPacks = formattedClassPacksArray
			?.flat()
			?.filter((c) => c?.pack_type === "unlimited");
		setAllUnlimitedPacks(_unlimitedPacks);
	}, [classPacks]);

	useEffect(() => {
		let rawClassPacksArray = classPacks?.flat();

		let formattedClassPacksArray = rawClassPacksArray?.map((pack) => {
			if (pack?.pack_type === "unlimited") {
				return {
					...(unlockedUnlimitedPack || {
						...pack,
						...pack?.unlimited_tiers[0],
					}),
				};
			} else {
				return pack;
			}
		});

		setAllClassPacks(formattedClassPacksArray.flat());
	}, [classPacks]);

	useEffect(() => {
		const pack = allClassPacks?.find((val) => !val.is_soldout);
		setSelectedClassPackID(pack?.pack_id);
	}, [allClassPacks]);

	const handleClickProceed = () => {
		if (selectedClassPackID) {
			if (
				pendingBilling &&
				pendingBilling.bill_id == selectedClassPackID
			) {
				onProceed({
					pack_id: pendingBilling ? pendingBilling.pack_id : null,
					bill_id: pendingBilling ? pendingBilling.bill_id : null,
					total_credit: 1,
					pack_name: "Pay for this class only",
					newbie_note: null,
					pack_description: null,
					pending_billing: true,
					pack_price: pendingBilling
						? pendingBilling.billing_amount
						: 0,
					gst_amount: pendingBilling.gst_amount,
				});
			} else {
				onProceed(
					allClassPacks.find(
						(classPack) => classPack.pack_id == selectedClassPackID
					)
				);
			}
		} else {
			setClassPackError(true);
			scrollElemTop(
				document.querySelector(".newbie-payment-modal .overlay__inner"),
				0
			);
		}
	};

	const onHaveClassPack = async () => {
		const pendingExists = memberClassPacks.find(
			(classPack) => classPack.share_status === "pending"
		);
		const acceptedExists = memberClassPacks.find(
			(classPack) => classPack.share_status === "accepted"
		);
		if (memberClassPacks.length === 0) {
			alert("You do not have a class pack.");
		} else if (pendingExists || acceptedExists) {
			history.push("/classes/my-class-packs");
		}
	};

	const onPaidAtCounter = async () => {
		try {
			const { data } = await axios.post(
				memberEndpointURL + "CheckPendingBilling",
				{
					bill_id: pendingBilling.bill_id,
				},
				{
					headers: {
						Authorization: "Bearer " + accessToken,
						"Content-type": "application/json",
					},
				}
			);
			if (data.errorCode) {
				alert(data.message);
			} else {
				history.push("/");
			}
		} catch (error) {}
	};

	return (
		<Modal
			title="Pay for your class"
			className="newbie-payment-modal "
			onClose={onModalClose}
			icon={<img src="./img/icons/card.svg" />}
			maxWidth={900}
		>
			<div className="newbie-payment-modal__content">
				<div className="newbie-payment-modal__left">
					<div className="newbie-payment-modal__left__title">
						PAY FOR YOUR CLASS
					</div>
					<div className="newbie-payment-modal__left__description">
						Way to go — you've completed your first class at YM! If
						you purchase a non-shareable Class Pack now, the class
						you've just attended will be on us!
					</div>
					<div className="newbie-payment-modal__left__packs-include">
						<div className="newbie-payment-modal__left__packs-include__icon" />
						<div className="newbie-payment-modal__left__packs-include__text">
							All class packs include:
						</div>
					</div>
					<div className="newbie-payment-modal__left__packs-include__description">
						<div>
							&#9642; Access to all 10 YM Studios and our virtual
							studio, YM Live
						</div>
						<div>&#9642; Over 500 weekly classes</div>
						<div>
							&#9642; A 14-day schedule overview for you to plan
							your sweat life
						</div>
						<div>
							&#9642; A booking window that opens 6 days prior...
							to the hour
						</div>
					</div>
				</div>
				<div className="newbie-payment-modal__right">
					<div className="newbie-payment-modal__right__title">
						Select Class Pack to purchase:
					</div>
					<div className="newbie-payment-modal__right__description">
						All prices stated are inclusive of GST.
					</div>
					<ul>
						{!classPacks ||
							(!allClassPacks.length && (
								<Fragment>
									<NewbiePaymentOptionLoading />
									<NewbiePaymentOptionLoading />
								</Fragment>
							))}
						{allClassPacks.map((classPack, index) => (
							<NewbiePaymentOption
								classPack={classPack}
								selected={
									selectedClassPackID == classPack.pack_id
									/** OLD logic
										classPack?.pack_type === "unlimited"
										? classPack?.tier_id ==
										  selectedPack?.tier_id
										: selectedClassPackID ==
										  classPack.pack_id
										   */
								}
								onClick={() => {
									setSelectedClassPackID(classPack.pack_id);
									setClassPackError(false);
								}}
								key={index}
							/>
						))}
					</ul>
					<div className="newbie-payment-modal__right__or">OR</div>
					<ul>
						<NewbiePaymentOption
							classPack={pendingBillingPack}
							selected={
								selectedClassPackID ==
								pendingBillingPack.pack_id
							}
							onClick={() => {
								setSelectedClassPackID(
									pendingBillingPack.pack_id
								);
								setClassPackError(false);
							}}
							isPendingBillingPack={true}
						/>
					</ul>
					<div className="newbie-payment-modal__right__actions">
						<div>
							<div
								className="newbie-payment-modal__right__actions__text"
								onClick={onPaidAtCounter}
							>
								Already paid at counter
							</div>
							<div
								className="newbie-payment-modal__right__actions__text"
								onClick={onHaveClassPack}
							>
								I have a Class Pack
							</div>
						</div>
						<Button
							className="newbie-payment-modal__right__actions__button"
							onClick={handleClickProceed}
						>
							Next
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

const mapStateToProps = function (store) {
	return {
		profile: store.profile,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(NewbiePaymentModal)
);

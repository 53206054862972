import React from "react";
import "./FooterInstagram.scss";

function FooterInstagram() {
	return (
		<div className="FooterInstagram">
			<div>
				<img
					src="/assets/img/ui/yoga-logo-with-instagram.svg"
					alt="Yoga Movement"
					className="FooterInstagram__logo"
				/>
			</div>
			<div>
				<div className="FooterInstagram__text">
					Be the first to find out about the latest and greatest on
					our Instagram Page!
				</div>
				<a
					href="https://www.instagram.com/yogamovement"
					target="_blank"
					rel="noopener"
					className="FooterInstagram__button animated click-efx"
					title="Opens in a new window"
				>
					Follow
				</a>
			</div>
		</div>
	);
}

export default FooterInstagram;

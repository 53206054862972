import Axios from "axios";
import { connect } from "react-redux";
import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { bindActionCreators } from "redux";
import { getGlobalCoutriesMasterData } from "../../redux/classPack";
import { DownArrowSVG } from "../Icons/Arrows";
import {
	formatCountryObj,
	getCustomStyles,
	renderFlagIcon,
	getCountryByCountryCode,
	formatCountryArray,
	getCountryByCountryID,
} from "./CountrySelect.config";

export const defaultCountries = [
	{
		country_id: 1,
		country_name: "Singapore",
		country_code: "SG",
		country_currency: "SGD",
		mobile_code: "65",
		country_logo:
			"https://s3.ap-southeast-1.amazonaws.com/yogamovement-app.com.staging/Country/Flag-SG.svg",
		utc_diff: 8,
	},

	{
		country_id: 1,
		country_name: "Thailand",
		country_code: "TH",
		country_currency: "THB",
		mobile_code: "125",
		country_logo:
			"https://s3.ap-southeast-1.amazonaws.com/yogamovement-app.com.staging/Country/Flag-TH.svg",
		utc_diff: 8,
	},
];

const formatRawCountryData = (countries) => {
	// console.log("countries in format func : ", countries);
	return countries?.map((c) => formatCountryObj(c));
};
const _defaultCountries = formatRawCountryData(defaultCountries);

const CountrySelect = ({
	onSelect = () => {},
	selectWidth = "250px",
	selectHeight = "50px",
	selectOptions = null,
	defaultValue = null,
	isAllowGetDataFromGlobal = false,
	allCountries,
}) => {
	const [selectedCountry, setSelectedCountry] = useState(
		formatCountryObj(_defaultCountries[0])
	);
	const [countries, setCountries] = useState([]);
	const globalSelectCountryId = useSelector(
		(state) => state.country?.countryId
	);

	const firstUpdate = useRef(true);

	useEffect(() => {
		if (!isAllowGetDataFromGlobal && defaultValue && countries.length > 0) {
			const toUpdateCountry = getCountryByCountryID(
				defaultValue,
				countries
			);

			setSelectedCountry(toUpdateCountry);
			if (firstUpdate.current && toUpdateCountry) {
				onSelect(toUpdateCountry);
			}
			firstUpdate.current = false;
		}
	}, [defaultValue, countries]);

	useEffect(() => {
		if (selectOptions && !isAllowGetDataFromGlobal) {
			setCountries(selectOptions);
		}
	}, [selectOptions]);

	useEffect(() => {
		if (allCountries && isAllowGetDataFromGlobal) {
			setCountries(allCountries);
		}
	}, [allCountries]);

	useLayoutEffect(() => {
		if (isAllowGetDataFromGlobal) {
			setSelectedCountry(
				getCountryByCountryID(globalSelectCountryId, countries)
			);
			if (countries.length > 0 && globalSelectCountryId) {
				onSelect(
					getCountryByCountryID(globalSelectCountryId, countries)
				);
			}
		}
	}, [globalSelectCountryId, countries]);

	const DropdownIndicator = (props) => {
		return (
			<components.DropdownIndicator {...props}>
				<DownArrowSVG />
			</components.DropdownIndicator>
		);
	};

	// console.log("formatted Countries :>> ", countries);
	// console.log("currrent selected country :>> ", defaultValue);

	return (
		<Select
			value={selectedCountry}
			options={
				formatCountryArray(countries, formatCountryObj) ||
				_defaultCountries
			}
			components={{ DropdownIndicator }}
			onChange={(val) => {
				setSelectedCountry(val);
				onSelect(val);
			}}
			styles={getCustomStyles(selectWidth, selectHeight)}
			isSearchable={false}
			getOptionLabel={(option) => (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						gap: "10px",
					}}
					key={option.value}
				>
					{renderFlagIcon(option?.icon)}
					<span>{option.label}</span>
				</div>
			)}
			getOptionValue={(option) => option.value}
			placeholder="Select a country"
		/>
	);
};

const mapStateToProps = (store) => ({
	allCountries: store?.masterData?.data?.countries,
});

export default connect(mapStateToProps)(CountrySelect);

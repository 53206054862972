import React from "react";
import momentZone from "moment-timezone";
import PropTypes from "prop-types";
import qs from "qs";
import "./YMFriendDetail.scss";
import { useState } from "react";

const propTypes = {
	/* @type {object} friend 				detail info about friend */
	friend: PropTypes.object,
	/** @type {function}  onRemove prop from Delete */
	onRemove: PropTypes.func.isRequired,
	/** @type {function}  onAddFriend prop from Add */
	onAddFriend: PropTypes.func.isRequired,
	/** @type {function}  Respond to friend request */
	onResponseFriendRequest: PropTypes.func.isRequired,
	onResponseSharePack: PropTypes.func.isRequired,
};

const defaultProps = {
	friend: {},
	onResponseSharePack: () => null,
	onResponseFriendRequest: () => null,
};

/**
 *
 */
export default function YMFriendDetail({
	friend,
	className,
	is_share = false,
	onRemove,
	onAddFriend,
	onResponseSharePack,
	onResponseFriendRequest,
}) {
	const [requestConfirm, toggleRequestConfirm] = useState(false);
	const [confirmCancel, toggleConfirmCancel] = useState(false);
	const [highlight, setHighlight] = useState("");
	const [showCancelBtn, setShowCancelBtn] = useState(false);

	function handleRespondToFriendRequest(friend, action) {
		const body = {
			action: action === "accept" ? 1 : 2,
			member_id: friend.member_id,
			friend_id: friend.friend_id,
		};
		onResponseFriendRequest(body);
	}

	function handleRemove(member_id, member_name) {
		onRemove(member_id);
	}

	function handleAdd(member_id, member_name) {
		onAddFriend(member_id);
	}
	function handleRespondToShare(member_id, action) {
		const body = {
			action: action === "share" ? 1 : 2,
			member_id,
		};
		if (action === "share") {
			setShowCancelBtn(true);
		} else {
			setShowCancelBtn(false);
		}
		onResponseSharePack(body);
	}

	function handleShareRemove(member_name, member_id) {
		handleRespondToShare(member_id, "unshare");
	}
	function handleInvite(member_name, member_id) {
		handleRespondToShare(member_id, "share");
	}
	const renderLinkContent = (status) => {
		const { member_id, member_name } = friend;
		switch (status) {
			case "rejected":
			case "removed":
				return (
					<span className="packMemberInfo__link packMemberInfo__link--removed">
						Removed
					</span>
				);
			case "pending":
				return (
					<span
						className="packMemberInfo__link packMemberInfo__link--pending"
						onClick={() => handleRemove(member_name, member_id)}
					>
						Cancel Invite
					</span>
				);
			case "shared":
				return (
					<span
						className="packMemberInfo__link packMemberInfo__link--pending"
						onClick={() => handleRemove(member_name, member_id)}
					>
						Unshare
					</span>
				);
			case "accepted":
				return (
					<span
						className="packMemberInfo__link packMemberInfo__link--pending"
						onClick={() => handleRemove(member_name, member_id)}
					>
						Unshare
					</span>
				);
			default:
				if (showCancelBtn) {
					return (
						<span
							className="packMemberInfo__link packMemberInfo__link--pending"
							onClick={() => handleRemove(member_name, member_id)}
						>
							Cancel Invite
						</span>
					);
				}
				return (
					<span
						className="packMemberInfo__link packMemberInfo__link--add"
						onClick={() => handleInvite(member_name, member_id)}
					>
						Share
					</span>
				);
				break;
		}
	};
	function renderActionBtn(friend) {
		switch (true) {
			// ignore action buttons for friend request, it will be handled inside the friend info section according to the zeplin mock
			case friend.friend_status === "pending":
				return null;

			case friend.friend_status === "requested":
				return (
					<div className="ml-auto">
						{requestConfirm ? (
							<Confirm
								onCancel={() => {
									setHighlight("");
									toggleRequestConfirm(false);
								}}
								onConfirm={() => {
									onRemove(friend.member_id);
								}}
							/>
						) : (
							<div
								className="friendsModalList__requested"
								onClick={() => {
									setHighlight("Undo Friend Request");
									toggleRequestConfirm(true);
								}}
							>
								Requested
							</div>
						)}
					</div>
				);

			case friend.friend_status === "accepted":
				return (
					<div className="ml-auto ">
						{requestConfirm ? (
							<Confirm
								onCancel={() => {
									setHighlight("");
									toggleRequestConfirm(false);
								}}
								onConfirm={() => {
									handleRemove(
										friend.member_id,
										friend.member_name
									);
								}}
							/>
						) : (
							<img
								src="img/icons/remove.svg"
								alt="remove friends"
								className="cursor-pointer click-efx"
								onClick={() => {
									setHighlight("Remove From List");
									toggleRequestConfirm(true);
								}}
							/>
						)}
					</div>
				);
			case !friend.is_friend:
				return (
					<div className="ml-auto">
						{/* <span
							className="ymFriendDetail__share__btn"
							onClick={(e) => {
								handleRespondToShare(friend, "share");
							}}
						>
							Share
						</span> */}
						{is_share ? (
							renderLinkContent(friend?.friend_status)
						) : (
							<img
								src="img/icons/add.svg"
								alt="remove friends"
								className="cursor-pointer click-efx"
								onClick={() => {
									handleAdd(
										friend.member_id,
										friend.member_name
									);
								}}
							/>
						)}
					</div>
				);
			default:
				return null;
		}
	}

	let style = {};
	if (friend.avatar_img && friend.avatar_img != "") {
		style.background = `url(${friend.avatar_img}) no-repeat center / cover`;
	}

	return (
		<div
			className={`friendsModalList__item-contents d-flex ${
				friend.friend_status === "pending"
					? "align-items-top"
					: "align-items-center"
			}`}
		>
			<div className="w-auto">
				{style.background ? (
					<div
						className="friendsModalList__item-pic circle"
						style={style}
					/>
				) : (
					<div className="friendsModalList__item-pic circle">
						{friend.first_name && friend.first_name.substr(0, 1)}
						{friend.last_name && friend.last_name.substr(0, 1)}
					</div>
				)}
			</div>
			<div className="friendsModalList__item-name animated">
				{highlight && (
					<div className="ymFriendDetail__highlight">{highlight}</div>
				)}{" "}
				{friend.friend_status === "pending" ? (
					<>
						<div className="friend-request">
							<b className="text-capitalize">
								{friend.member_name}
							</b>{" "}
							has sent you a friend request.
						</div>
						<div className="friendFeed__date request__date">
							{momentZone(friend.Date)
								.tz("Asia/Singapore")
								.format("ddd, D MMM YYYY")}
						</div>
						<button
							className="button button--outline-2 click-efx mr-2"
							onClick={() =>
								handleRespondToFriendRequest(friend, "accept")
							}
						>
							Accept
						</button>
						<button
							className="button button--outline-2 click-efx"
							onClick={() =>
								handleRespondToFriendRequest(friend, "reject")
							}
						>
							Reject
						</button>
					</>
				) : (
					<div>
						<span
							className={
								highlight ? "ymFriendDetail__ellipsis" : ""
							}
						>
							{friend.member_name}
						</span>
						<br />
						{friend?.is_friend === false && (
							<span className="ymFriendDetail__not__fri__yet">
								Not friends yet
							</span>
						)}
					</div>
				)}
			</div>
			{renderActionBtn(friend)}
		</div>
	);
}

YMFriendDetail.propTypes = propTypes;
YMFriendDetail.defaultProps = defaultProps;

function Confirm({ onCancel, onConfirm }) {
	return (
		<div className="d-flex align-items-center">
			<button
				className="button button--outline-none click-efx mr-2"
				onClick={onCancel}
			>
				Cancel
			</button>
			<button
				className="button button--outline-2 click-efx"
				onClick={onConfirm}
			>
				Confirm
			</button>
		</div>
	);
}

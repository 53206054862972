import React from "react";
import PropTypes from "prop-types";

import "./SchedulesListHeader.scss";

const propTypes = {
	/** @type {string} 	className prop for .schedulesListHeader */
	className: PropTypes.string,
	/** @type {array} 	all the column values */
	columns: PropTypes.array,
	/** @type {bool} 	shows loading state */
	isLoading: PropTypes.bool
};
const defaultProps = {
	className: "",
	columns: ["Date", "Timing", "Class", "Location", "Instructor"],
	isLoading: false
};

/**
 * schedules list thead
 * only visible in desktop
 */
export default function SchedulesListHeader({ className, columns, isLoading }) {
	let isLoadingClass = isLoading ? "isLoading" : "";
	return (
		<header className={"schedulesListHeader text-uppercase " + className}>
			{columns.map((column, i) => (
				<div className="schedulesListHeader__col" key={i}>
					<span className={isLoadingClass}>{column}</span>
				</div>
			))}
		</header>
	);
}
SchedulesListHeader.propTypes = propTypes;
SchedulesListHeader.defaultProps = defaultProps;

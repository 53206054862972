import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import qs from "qs";

import AddFriendsModal from "../../Modals/AddFriendsModal/AddFriendsModal.jsx";
import { disableScroll, showAuthentication } from "../../../redux/ui";

class MyYMFriends extends Component {
	handleClose() {
		this.props.history.push({
			pathname: this.props.location.pathname,
		});
	}

	render() {
		const queryActions = qs.parse(this.props.location.search, {
			ignoreQueryPrefix: true,
		});

		switch (queryActions.action) {
			case "add-friends":
			case "ym-friends":
				return (
					<AddFriendsModal
						onClose={() => this.handleClose()}
						isYmFriends={queryActions.action === "ym-friends"}
					/>
				);
			default:
				return null;
		}
	}
}

const mapStateToProps = function (store) {
	return {
		ui: store.ui,
		authentication: store.authentication,
		profile: store.profile,
	};
};

const mapDispatchToProps = function (dispatch) {
	return {
		disableScroll: bindActionCreators(disableScroll, dispatch),
	};
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(MyYMFriends)
);

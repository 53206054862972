import React, { Component } from "react";
import PropTypes from "prop-types";
import Rating from "react-rating";
import { motion } from "framer-motion";
import moment from "moment";

import { scrollElemTop, getAvatarLetter } from "../../../base/functions";
import Modal from "../../Modal/Modal.jsx";
import ModalFooter from "../../Modal/ModalFooter/ModalFooter.jsx";
import Button from "../../Button/Button.jsx";
import SchedulesListHeader from "../../SchedulesList/SchedulesListHeader/SchedulesListHeader.jsx";
import SchedulesListRow from "../../SchedulesList/SchedulesListRow/SchedulesListRow.jsx";
import ErrorMessage from "../../Alerts/ErrorMessage/ErrorMessage.jsx";

import "./RateClassModal.scss";
import { formatClassDateTime } from "../../../views/Classes/MyClasses/MyClassesDetail/MyClassesDetailHelpers";

const feelingIconVariants = {
	inactive: { backgroundColor: "#fff" },
	active: { backgroundColor: "#59baae" },
	error: { backgroundColor: "#d32f2f", color: "#d32f2f" },
};

const feelingTextVariants = {
	normal: { color: "#59baae" },
	error: { color: "#d32f2f" },
};

/**
 * rate class after completing it
 */
export default class RateClassModal extends Component {
	static propTypes = {
		/** @type {function}  callback on modal window close */
		onModalClose: PropTypes.func.isRequired,
		/** @type {function}  callback on clicking CTA button at the bottom */
		onClickCTA: PropTypes.func.isRequired,
		/** @type {Boolean}   whether user is a newbie */
		isNewbie: PropTypes.bool,
		/** @type {object}    the booked class object from API */
		// bookedClass: PropTypes.object.isRequired,
		/** @type {boolean}   controls busy state */
		busy: PropTypes.bool,
		/** @type {string}    shows error message */
		error: PropTypes.string,
	};

	static defaultProps = {
		isNewbie: false,
		busy: false,
		error: null,
	};

	constructor(props) {
		super(props);
		this.state = {
			moodError: false,
			ratingError: false,
			mood: null,
			rating: 0,
			feedback: "",
			imageError: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.error && this.props.error) {
			scrollElemTop(
				document.querySelector(".rateClassModal .overlay__inner"),
				0
			);
		}
	}

	/**
	 * onClick prop for CTA button
	 * validate locally before calling prop
	 */
	handleClickCTA() {
		this.setState({
			moodError: this.state.mood == null || this.state.mood <= 0,
			ratingError: this.state.rating == null || this.state.rating <= 0,
		});
		if (this.state.mood && this.state.rating) {
			this.props.onClickCTA(
				this.state.mood,
				this.state.rating,
				this.state.feedback
			);
		} else {
			scrollElemTop(
				document.querySelector(".rateClassModal .overlay__inner"),
				document
					.querySelector(".rateClassModal .rateClassModal__title")
					.getBoundingClientRect().top +
					document.querySelector(".rateClassModal .overlay__inner")
						.scrollTop -
					20
			);
		}
	}

	handleFeedback = (e) => {
		const { value: feedback } = e.target;
		this.setState({ feedback });
	};

	render() {
		const { bookedClass } = this.props;
		console.log(
			"here bookedClass name instructor:",
			bookedClass?.instructor_name
		);
		return (
			<Modal
				title={
					!this.props.isNewbie
						? "Class completed!"
						: "First class completed!"
				}
				className={
					"rateClassModal " +
					(this.props.busy && " rateClassModal--busy ")
				}
				onClose={() => this.props.onModalClose()}
				icon={<img src="./img/icons/mat-tick.svg" />}
				maxWidth={900}
			>
				<div className="rateClassModal__content">
					<div className="rateClassModal__left">
						<h2 className="rateClassModal__rateThisClass">
							RATE THIS CLASS
						</h2>
						<p className="rateClassModal__class__name">
							{bookedClass?.class_name}
						</p>
						<p className="rateClassModal__class__label">
							DATE & TIME
						</p>
						<p className="rateClassModal__class__description">
							<time>
								{bookedClass?.study_date &&
									moment(bookedClass?.study_date)
										.utcOffset(bookedClass?.study_date)
										.format("ddd D/M")}

								<br />
								{moment(
									bookedClass?.start_time,
									"HH:mm:ss"
								).format("h:mma")}
								{" to "}
								{moment(
									bookedClass?.end_time,
									"HH:mm:ss"
								).format("h:mma")}
								{" ("}
							</time>
							{moment
								.duration(
									moment(
										bookedClass?.end_time,
										"HH:mm:ss"
									).diff(
										moment(
											bookedClass?.start_time,
											"HH:mm:ss"
										)
									)
								)
								.asMinutes()}
							{"min)"}
						</p>
						<p className="rateClassModal__class__label">STUDIO</p>
						<p className="rateClassModal__class__description">
							{bookedClass?.studio_name}
						</p>
						<p className="rateClassModal__class__label">TEACHER</p>
						<p className="rateClassModal__class__description">
							{bookedClass?.instructor_name}
						</p>
					</div>
					<div className="rateClassModal__right">
						<p className="rateClassModal__rate__today__howYouFeeling">
						  How do you feel after your class?
						</p>
						<div className="rateClassModal__rate__today__button__container">
							{[
								["01_exhausted.svg", "Exhausted"],
								["02_tired.svg", "Tired"],
								["03_ok.svg", "OK"],
								["04_good.svg", "Good"],
								["05_awesome.svg", "Awesome"],
							].map(([feeling, title], index) => (
								<div
									className="rateClassModal__rate__today__button"
									key={feeling}
									onClick={() =>
										this.setState({
											moodError: false,
											mood: index + 1,
										})
									}
								>
									<motion.div
										animate={
											this.state.moodError
												? "error"
												: this.state.mood === index + 1
												? "active"
												: "inactive"
										}
										className={`rateClassModal__rate__today__button__icon__container`}
										variants={feelingIconVariants}
										initial={{ backgroundColor: "#fff" }}
										whileHover={{
											backgroundColor: "#59baae",
										}}
									>
										<img
											src={`img/icons/${feeling}`}
											alt="rate"
										/>
									</motion.div>
									<motion.p
										className="rateClassModal__rate__today__button__icon__label"
										animate={
											this.state.moodError
												? "error"
												: "normal"
										}
										variants={feelingTextVariants}
										initial={{ color: "#59baae" }}
									>
										{title}
									</motion.p>
								</div>
							))}
						</div>
						{/* NOTE: hide this layout in mobile */}
						<div className="rateClassModal__desktop-show">
							<p className="rateClassModal__rate__teacher__name">
								Rate {bookedClass?.instructor_name}'s class
							</p>
							<div className="rateClassModal__rate__teacher__container">
								<div className="rateClassModal__rate__teacher__profile__container">
									{bookedClass?.instructor_img &&
									!this.state?.imageError ? (
										<img
											src={bookedClass?.instructor_img}
											alt={bookedClass?.instructor_name}
											className="rateClassModal__rate__teacher__profile"
											onError={() =>
												this.setState({
													imageError: true,
												})
											}
										/>
									) : (
										<>
											{bookedClass?.instructor_name &&
												getAvatarLetter({
													member_name:
														bookedClass?.instructor_name,
												})}
										</>
									)}
								</div>
								<div className="rateClassModal__rate__teacher__star__container">
									<Rating
										initialRating={this.state.rating}
										emptySymbol={
											<img
												src={
													!this.state.ratingError
														? "img/icons/star-2-inactive.svg"
														: "img/icons/star-red.svg"
												}
												className="rateClassModal__rate__teacher__star"
											/>
										}
										fullSymbol={
											<img
												src="img/icons/star-2-active.svg"
												className="rateClassModal__rate__teacher__star"
											/>
										}
										onChange={(v) =>
											this.setState({
												ratingError: false,
												rating: v,
											})
										}
									/>
								</div>
							</div>

							<div className="rateClassModal__rate__textArea__container">
								<label
									htmlFor="feedback"
									className="rateClassModal__rate__textArea__label"
								>
									ANY FEEDBACK?
								</label>
								<textarea
									name="feedback"
									id="feedback"
									className="rateClassModal__rate__textArea"
									maxLength="100"
									value={this.state.feedback}
									onChange={this.handleFeedback}
								/>
								<div className="rateClassModal__rate__textArea__count">
									{this.state.feedback.length || 0} / 100
								</div>
							</div>

							<Button
								className="rateClassModal__rate__done"
								onClick={() => this.handleClickCTA()}
								disabled={
									this.props.busy ||
									!this.state.mood ||
									this.state.rating < 1
								}
							>
								{!this.props.busy
									? !this.props.isNewbie
										? "Done"
										: "Next - payment"
									: "Loading..."}
							</Button>
						</div>
						<div className="rateClassModal__mobile-show">
							<div className="rateClassModal__rate__teacher__mobile">
								<div className="rateClassModal__rate__teacher__profile__mobile-container">
									<div className="rateClassModal__rate__teacher__profile__container">
										{bookedClass?.instructor_img ? (
											<img
												src={
													bookedClass?.instructor_img
												}
												alt="profile"
												className="rateClassModal__rate__teacher__profile"
											/>
										) : (
											<>
												{bookedClass?.instructor_name &&
													getAvatarLetter({
														member_name:
															bookedClass?.instructor_name,
													})}
											</>
										)}
									</div>
								</div>
								<p className="rateClassModal__rate__teacher__name">
									Rate {bookedClass?.instructor_name}'s class
								</p>
								<div className="rateClassModal__rate__teacher__star__container">
									<Rating
										initialRating={this.state.rating}
										emptySymbol={
											<img
												src={
													!this.state.ratingError
														? "img/icons/star-2-inactive.svg"
														: "img/icons/star-red.svg"
												}
												className="rateClassModal__rate__teacher__star"
											/>
										}
										fullSymbol={
											<img
												src="img/icons/star-2-active.svg"
												className="rateClassModal__rate__teacher__star"
											/>
										}
										onChange={(v) =>
											this.setState({
												ratingError: false,
												rating: v,
											})
										}
									/>
								</div>
								<div className="rateClassModal__rate__textArea__container">
									<label
										htmlFor="feedback"
										className="rateClassModal__rate__textArea__label"
									>
										ANY FEEDBACK?
									</label>
									<textarea
										name="feedback"
										id="feedback"
										className="rateClassModal__rate__textArea"
										maxLength="100"
										value={this.state.feedback}
										onChange={this.handleFeedback}
									/>
									<div className="rateClassModal__rate__textArea__count">
										{this.state.feedback.length || 0} / 100
									</div>
								</div>

								<Button
									className="rateClassModal__rate__done"
									onClick={() => this.handleClickCTA()}
									disabled={this.props.busy}
								>
									{!this.props.busy
										? !this.props.isNewbie
											? "Done"
											: "Next - payment"
										: "Loading..."}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

import { motion } from "framer-motion";
import React from "react";
import { formatPrice } from "../../../../base/functions";
import "./NewbiePaymentOption.scss";

const HOVER_ANIMATION = {
	boxShadow: "0 0 16px 0 rgba(0, 0, 0, 0.2)",
};
const NEUTRAL_ANIMATION = {
	boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
};



const NewbiePaymentOption = ({
	classPack,
	selected,
	onClick,
	isPendingBillingPack = false,
}) => {
	return (
		<motion.div
			className={`newbie-payment-option ${
				classPack.is_feature ? "newbie-payment-option--special" : ""
			} ${classPack.is_soldout ? "newbie-payment-option--soldout" : ""}
			${
				classPack.is_soldout || classPack.tag_name
					? "newbie-payment-option--has-tag"
					: ""
			}`}
			whileHover={HOVER_ANIMATION}
			onClick={!classPack.is_soldout ? onClick : () => null}
			animate={selected ? HOVER_ANIMATION : NEUTRAL_ANIMATION}
			initial={NEUTRAL_ANIMATION}
		>
			<div className="newbie-payment-option__row__1">
				<img
					className="newbie-payment-option__checked"
					src={`img/icons/circle-box-${
						selected ? "on-green" : "normal"
					}.svg `}
					alt="checked"
				/>
				<div className="newbie-payment-option__title">
					{classPack.pack_name}
				</div>
				<div className="newbie-payment-option__price">
					{classPack.pack_type === "unlimited"
						? `${formatPrice(parseInt(classPack.tier_price))} ${
								classPack?.country_currency
						  }`
						: `${formatPrice(parseInt(classPack.pack_price))} ${
								classPack?.country_currency
						  }`}
				</div>
			</div>
			{(classPack.is_soldout || classPack.tag_name) && (
				<div className="newbie-payment-option__tag">
					{classPack.is_soldout ? "SOLD OUT" : classPack.tag_name}
				</div>
			)}
			{classPack.estimate_price > 0 &&
				classPack.show_estimate_price === true && (
					<div className="newbie-payment-option__row__2">
						~ {formatPrice(parseInt(classPack.estimate_price))}{" "}
						{classPack?.country_currency} per class
					</div>
				)}
			{!isPendingBillingPack && (
				<div className="validityMonth">
					{classPack?.pack_type === "unlimited" ? (
						<p className="info">{classPack?.tier_name}</p>
					) : (
						<p className="info">
							{classPack?.validity_month} - month validity
						</p>
					)}
				</div>
			)}
		</motion.div>
	);
};

export default NewbiePaymentOption;
